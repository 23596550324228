import React , {useState , useEffect} from 'react';
import { connect } from 'react-redux';
import Aos from 'aos';
import ft_tick from '../../../assets/images/ft_tick.svg';
import ft1 from './images/ht1.webp';
import ft2 from './images/ht2.webp';
import ft3 from './images/ht3.webp';
import ft4 from './images/ht4.webp';
import ft5 from './images/ht5.webp';
import ft6 from './images/ht6.webp';
import ft7 from './images/ht7.webp';
import ft8 from './images/ht8.webp';

import ft_girl from '../images/HTfeature.png';
import ftgirltab from './images/ftgirl-tab.png';
import ftgirlmbl from './images/ftgirl-mbl.png';

import 'aos/dist/aos.css';
import './vdotok_features.css';



const HTFeatures = (props) => {
    const [livestream , setlivestream] = useState(true);
    const [one2one , setone2one] = useState(false);
    const [screenshare , setscreenshare] = useState(false);
    const [mixandmash , setmixandmash] = useState(false);
    const [engaging , setEngaging] = useState(false);
    const [messaging , setmessaging] = useState(false);
    const [filesharing , setfilesharing] = useState(false);
    const [sensordata , setSensorData] = useState(false);
    const [innerwdt , setinnerwdt] = useState(window.innerWidth);
    useEffect(() => {
        // if(livestream){
        //     setEngaging(false); setone2one(false);setscreenshare(false);
        //     setmixandmash(false); setmessaging(false); setfilesharing(false);

        // }
        // if(one2one){
        //     setEngaging(false); setlivestream(false);setscreenshare(false);
        //     setmixandmash(false); setmessaging(false); setfilesharing(false);
        // }
        // if(screenshare){
        //     setEngaging(false); setone2one(false);setlivestream(false);
        //     setmixandmash(false); setmessaging(false); setfilesharing(false);
        // }
        // if(mixandmash){
        //     setEngaging(false); setone2one(false);setscreenshare(false);
        //     setlivestream(false); setmessaging(false); setfilesharing(false);
        // }
        // if(engaging){
        //     setlivestream(false); setone2one(false);setscreenshare(false);
        //     setmixandmash(false); setmessaging(false); setfilesharing(false);
        // }
        // if(messaging){
        //     setEngaging(false); setone2one(false);setscreenshare(false);
        //     setmixandmash(false); setlivestream(false); setfilesharing(false);
        // }
        // if(filesharing){
        //     setEngaging(false); setone2one(false);setscreenshare(false);
        //     setmixandmash(false); setmessaging(false); setlivestream(false);
        // }

    } , [livestream,one2one,screenshare,mixandmash,engaging,messaging,filesharing])
    useEffect(() => {
        Aos.init();
        setinnerwdt(window.innerWidth);
        window.addEventListener('resize' , () => {
            setinnerwdt(window.innerWidth);
        })
    }, []);
    const HandleClick = (id) => {
        if(id=="LS"){
                setlivestream(true);
                setEngaging(false); setone2one(false);setscreenshare(false);
                setmixandmash(false); setmessaging(false); setfilesharing(false);setSensorData(false);
    
            }
            if(id=="O2O"){
                setone2one(true);
                setEngaging(false); setlivestream(false);setscreenshare(false);
                setmixandmash(false); setmessaging(false); setfilesharing(false);setSensorData(false);
            }
            if(id=="ScSh"){
                setscreenshare(true);
                setEngaging(false); setone2one(false);setlivestream(false);
                setmixandmash(false); setmessaging(false); setfilesharing(false);setSensorData(false);
            }
            if(id=="MandM"){
                setmixandmash(true);
                setEngaging(false); setone2one(false);setscreenshare(false);
                setlivestream(false); setmessaging(false); setfilesharing(false);setSensorData(false);
            }
            if(id=="EF"){
                setEngaging(true);
                setlivestream(false); setone2one(false);setscreenshare(false);
                setmixandmash(false); setmessaging(false); setfilesharing(false);setSensorData(false);
            }
            if(id=="Mess"){
                setmessaging(true);
                setEngaging(false); setone2one(false);setscreenshare(false);
                setmixandmash(false); setlivestream(false); setfilesharing(false);setSensorData(false);
            }
            if(id=="FS"){
                setfilesharing(true);
                setEngaging(false); setone2one(false);setscreenshare(false);
                setmixandmash(false); setmessaging(false); setlivestream(false);  setSensorData(false);
            }
            if(id=="SD"){
                setSensorData(true);
                setEngaging(false); setone2one(false);setscreenshare(false);
                setmixandmash(false); setmessaging(false); setlivestream(false); setfilesharing(false);

            }

    }

    return (
        <>
        <div className='_feature'>
            {innerwdt > 1024 ? 
            <p className='typekit_h2 webkit_primary_black _feature_bold'>What features can you integrate with VdoTok ?</p>
            : innerwdt > 600 ? 
            <p className='typekit_h2 webkit_primary_black _feature_bold'>What features can you integrate <br></br>with VdoTok ?</p>
            :
            <p className='typekit_h2 webkit_primary_black _feature_bold'>What features can you integrate with VdoTok ?</p>

            }
            {innerwdt > 1024 ? 
            <p className='_featureht_normal typekit_subheadline2 webkit_primary_grey'>VdoTok APIs and SDKs allow the integration of live and on-demand video & voice calls to enable remote conversations between patients, doctors, and healthcare professionals.</p>
            : innerwdt > 600 ?
            <p className='_featureht_normal typekit_subheadline1 webkit_primary_grey'>VdoTok APIs and SDKs allow the integration of live and on-demand video & voice calls to enable remote conversations between patients, doctors, and healthcare professionals.</p>
            :
            <p className='_featureht_normal typekit_subheadline2 webkit_primary_grey'>VdoTok APIs and SDKs allow the integration of live and on-demand video & voice calls to enable remote conversations between patients, doctors, and healthcare professionals.</p>

            }
            <div className='_feature_btns'>
                {innerwdt > 1024 ?
                <div className='_feature_btn_R1'>
                    <button onClick={()=> {HandleClick('LS')}} id='LS'  className={ livestream ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"}>Live streaming (one-to-many)</button>
                    <button onClick={()=> {HandleClick('O2O')}} id='O2O' className={ one2one ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"}>(one-to-one / many-to-many)</button>
                    <button onClick={()=> {HandleClick('ScSh')}} id='ScSh' className={ screenshare ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"}>Screen sharing</button>
                    <button onClick={()=> {HandleClick('MandM')}} id='MandM'  className={ mixandmash ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"}>mix & mash features</button>
                </div> : 
                <div className='_feature_btn_R1'>
                    <button onClick={()=> {HandleClick('LS')}} id='LS'  className={ livestream ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"}>Live streaming (one-to-many)</button>
                    <button onClick={()=> {HandleClick('O2O')}} id='O2O' className={ one2one ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"}>(one-to-one / many-to-many)</button>
                    <button onClick={()=> {HandleClick('ScSh')}} id='ScSh' className={ screenshare ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"}>Screen sharing</button>
                    <button onClick={()=> {HandleClick('MandM')}} id='MandM'  className={ mixandmash ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"}>mix & mash features</button>
                    <button onClick={()=> {HandleClick('EF')}}  id='EF' className={ engaging ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"}>Engaging features</button>
                    <button onClick={()=> {HandleClick('Mess')}} id='Mess' className={ messaging ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"}>Messaging</button>
                    <button onClick={()=> {HandleClick('FS')}} id='FS' className={ filesharing ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"}>file sharing</button>
                </div>}
               {innerwdt > 1024 ?

                <div className='_feature_btn_R2'>
                <button onClick={()=> {HandleClick('EF')}}  id='EF' className={ engaging ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"}>Engaging features</button>
                <button onClick={()=> {HandleClick('Mess')}} id='Mess' className={ messaging ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"}>Messaging</button>
                <button onClick={()=> {HandleClick('FS')}} id='FS' className={ filesharing ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"}>file sharing</button>
                {/* <button onClick={()=> {HandleClick('SD')}} id='SD' className={ sensordata ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"}>Sensor data</button> */}
                </div> :
                <></>
               }
         
            </div>
            {
                livestream && (
                    <div className='_feature_content'>
                    {innerwdt > 1024 ?
                    <img src={ft1} className="ft_girl" />: innerwdt > 600 ?
                    <img src={ft1} className="ft_girl" />:
                    <img src={ft1} className="ft_girl" />


                    }
                    <div className='ft_right'>
                        <div className='ft_flx'>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>Host Live seminars and conferences to reach and engage a larger and broader audience.</p>
                        </div>
                        <div className='ft_flx'>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>Compatible with multiple platforms (Safari, Chrome, FireFox, Opera) and mobile operating systems (iOS, Android, Flutter)</p>
                        </div>
                        <div className='ft_flx'>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>Reliable HD audio and video streams with a 300 ms average and 79 ms lowest latency to ensure minimal to no hiccups and delays.</p>
                        </div>
                        <div className='ft_flx'>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>Stream content over Wi-Fi, 4G or 3G networks.</p>
                        </div>
                        <div className='ft_flx'>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>Protected by DTLS encryption.</p>
                        </div>
   
                    </div>
    
    
                    </div>
                )
            }
             {
                one2one && (
                    <div className='_feature_content'>
                 {innerwdt > 1024 ?
                    <img src={ft2} className="ft_girl" />: innerwdt > 600 ?
                    <img src={ft2} className="ft_girl" />:
                    <img src={ft2} className="ft_girl" />


                    }
                    <div className='ft_right'>
                        <div className='ft_flx'>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>Real-time voice and video interactions without lagging.</p>
                        </div>
                        <div className='ft_flx'>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>HD audio and video quality together with low-latency network to ensure minimal to no hiccups and delays.</p>
                        </div>
                        <div className='ft_flx'>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>Immediate video rendering provides a reliable stream without delay.</p>
                        </div>
                        <div className='ft_flx' style={{marginBottom:0}}>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>Advanced in-house algorithms provide adaptive resolution.</p>
                        </div>
                    </div>
    
    
                    </div>
                )
            }
             {
                screenshare && (
                    <div className='_feature_content'>
                {innerwdt > 1024 ?
                    <img src={ft3} className="ft_girl" />: innerwdt > 600 ?
                    <img src={ft3} className="ft_girl" />:
                    <img src={ft3} className="ft_girl" />


                    }
                    <div className='ft_right'>
                        <div className='ft_flx'>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>HD Screen sharing for pixel perfect presentations.</p>
                        </div>
                        <div className='ft_flx'>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>Shows lab results, CT scans, and other clinical reports in real-time.</p>
                        </div>
  
                    </div>
    
    
                    </div>
                )
            }
             {
                mixandmash && (
                    <div className='_feature_content'>
                   {innerwdt > 1024 ?
                    <img src={ft4} className="ft_girl" />: innerwdt > 600 ?
                    <img src={ft4} className="ft_girl" />:
                    <img src={ft4} className="ft_girl" />


                    }
                    <div className='ft_right'>
                        <div className='ft_flx'>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>Mix & mash any features that suit your product. For instance, combine video calling, screen sharing, and internal app audio sharing to provide the best service between doctors and patients.</p>
                        </div>
                    </div>
    
    
                    </div>
                )
            }
             {
                engaging && (
                    <div className='_feature_content'>
                 {innerwdt > 1024 ?
                    <img src={ft5} className="ft_girl" />: innerwdt > 600 ?
                    <img src={ft5} className="ft_girl" />:
                    <img src={ft5} className="ft_girl" />


                    }
                    <div className='ft_right'>
                        <div className='ft_flx'>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>Doodling/drawing feature for easier demonstrations of patient reports or scans.</p>
                        </div>
                        <div className='ft_flx'>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>Lets patients share documents with doctors in real-time. </p>
                        </div>
                        <div className='ft_flx'>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>Easier engagement to educate through doctor/medical conferences.</p>
                        </div>
                    </div>
    
    
                    </div>
                )
            }
             {
                messaging && (
                    <div className='_feature_content'>
               {innerwdt > 1024 ?
                    <img src={ft6} className="ft_girl" />: innerwdt > 600 ?
                    <img src={ft6} className="ft_girl" />:
                    <img src={ft6} className="ft_girl" />


                    }
                    <div className='ft_right'>
                        <div className='ft_flx'>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>Stay connected with doctors globally with group chat and one-to-one chat features.</p>
                        </div>
                        <div className='ft_flx'>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>Easy scalability to handle millions of messages per second.</p>
                        </div>
                        <div className='ft_flx'>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>Tracks online/offline status.</p>
                        </div>
                        <div className='ft_flx'>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>Tracks message delivery status (read, sent, delivered etc.).</p>
                        </div>
                        <div className='ft_flx'>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>Messaging features free of cost.</p>
                        </div>
                        <div className='ft_flx'>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>Supports messaging through unlimited devices.</p>
                        </div>
                    </div>
    
    
                    </div>
                )
            }
             {
                filesharing && (
                    <div className='_feature_content'>
                   {innerwdt > 1024 ?
                    <img src={ft7} className="ft_girl" />: innerwdt > 600 ?
                    <img src={ft7} className="ft_girl" />:
                    <img src={ft7} className="ft_girl" />


                    }
                    <div className='ft_right'>
                        <div className='ft_flx'>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>Share health and other clinical reports.</p>
                        </div>
                        <div className='ft_flx'>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>Supports file sharing of rich media up to 6MB in size.</p>
                        </div>
            
                    </div>
    
    
                    </div>
                )
            }
             {
                sensordata && (
                    <div className='_feature_content'>
                   {innerwdt > 1024 ?
                    <img src={ft8} className="ft_girl" />: innerwdt > 600 ?
                    <img src={ft8} className="ft_girl" />:
                    <img src={ft8} className="ft_girl" />


                    }
                    <div className='ft_right'>
                        <div className='ft_flx'>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>Track and monitor patients heart rate and other sensory data in rea-time through IoT devices.</p>
                        </div>
                        <div className='ft_flx'>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>???</p>
                        </div>
                        <div className='ft_flx'>
                            <img src={ft_tick} className="ft_tick" />
                            <p className='typekit_BodyRegular webkit_primary_black eltspace'>???</p>
                        </div>
            
                    </div>
    
    
                    </div>
                )
            }
        </div>
      

        </>
    )
}


function map(state){
    return state;
}
export default connect(map)(HTFeatures);