import React , {useState , useEffect} from "react";
import { connect } from "react-redux";
import './mission.css';
import '../about.css';
import Aos from 'aos';
import 'aos/dist/aos.css'; 
import missiondesktop from './images/missiondesktop.png';
import missiontab from './images/missiontab.webp';

import missionmbl from './images/missionmbl.webp';

const Mission = (props) => {
    const [innerwdt , setinnerwdt] = useState(window.innerWidth);
    useEffect(() => {
        Aos.init();
        setinnerwdt(window.innerWidth);
        window.addEventListener('resize' , () => {
            setinnerwdt(window.innerWidth);
        })
    }, []);
    return(
        <>
        <div className="main_mission">
                <div data-aos="zoom-out-down" className="mission_text">
                    <h2 className="webkit_primary_black vision_headings typekit_h2">{window.innerWidth>1024 ? 'Our' : ''} Mission</h2>
                    <p className={innerwdt > 500 ? "webkit_primary_black typekit_subheadline1 mdesc" :
                    "webkit_primary_black typekit_subheadline2 mdesc" }>To accelerate the world's transition from messaging/text to live media by making it easy, quick and inexpensive for any business.</p>
                </div>
                <div data-aos="zoom-out-down" className="mission_image">
                    {innerwdt > 1024  ?
                    <img className="" src={missiondesktop} alt="vision" /> : 
                    innerwdt > 600 && innerwdt <=1024 ?
                    <img className="" src={missiontab} alt="vision" /> :
                    <img className="" src={missionmbl} alt="vision" />


                }
                </div>
        </div>  
        </>
    )
}


function map(state){
    return state;
}
export default connect(map)(Mission);