import React , {useState , useEffect} from 'react';
import { connect } from 'react-redux';
import CSmbl from '../images/CS-HT.png';
import toggee from '../images/twoact.png';
import cscat from '../images/categories.png';
import Aos from 'aos';


import 'aos/dist/aos.css';
import './customer_story.css';



const CustomerStoryHT = (props) => {
    const [innerwdt , setinnerwdt] = useState(window.innerWidth);
    useEffect(() => {
        Aos.init();
        setinnerwdt(window.innerWidth);
        window.addEventListener('resize' , () => {
            setinnerwdt(window.innerWidth);
        })
    }, []);

    return (
        <>
        <div className='_CustomerStoryHT'>
        <p className='typekit_h2 webkit_primary_black _customerstory_b'>Customer story</p>

        <p className={innerwdt > 1024 ? 'typekit_subheadline2 webkit_primary_grey _customerstory_p' : 
        innerwdt > 500 ? 
        'typekit_subheadline1 webkit_primary_grey _customerstory_p' :
        'typekit_subheadline2 webkit_primary_grey _customerstory_p' }>Our success story with TwoAct</p>
        <div className='_cusStory_flx'>
            <div className='_cs_ht_box1'>
                <img src={CSmbl} className="cs-ht-mbl" alt='image' />
                {innerwdt > 1024 ? 
                <p style={{cursor:"pointer"}} onClick={() => {window.open("https://www.twoact.org")}} className='_cs_ht_txtabsolute webkit_primary_black'>www.twoact.org</p>
                :
                <p style={{cursor:"pointer"}} onClick={() => {window.open("https://www.twoact.org")}}  className='_cs_ht_txtabsolute webkit_primary_black'>www.twoact.org</p>


                }
            </div>
            {innerwdt > 1024 ? 
                <div className='_cs_ht_box2'>
                     <div className='_cs_ht_boxabsolute'>
                         <img src={toggee} className="toggee_svg" alt="toggee-logo" />
                     </div>
                     <div className='_cs_ht_b2'>
                         <p className='typekit_h4 webkit_primary_h _cs_ht_bp1'>
                         Our Success story with TwoAct
                         </p>
                         <p className='typekit_BodyRegular webkit_primary_black _cs_ht_bp2 ' >
                         TwoAct has developed a unique holistic concept called Handle-IT, that creates the best possible conditions for good mental health and schooling for students.
                         <br></br><br></br>
                         Together with VdoTok and our video bot API, TwoAct has been able to quickly and easily build a video bot supporting children with their mental health. 
                         <br></br><br></br>
                         VdoTok’s AI-based video bot analyses a user’s mental health condition by learning from the audio responses as well as facial analyses captured during a live communication and provides the user with the best recommendations to improve their mental health.
                         <br></br>
                         <br></br>
                         </p>
                         <img src={cscat} className='_cs_ht_categories' alt='categories' />
                     </div>
    
                 </div> :
                    <div className='_cs_ht_box2tab'>
                          <div className='_cs_ht_boxabsolutetab'>
                              <img src={toggee} className="toggee_svg" alt="toggee-logo" />
                          </div>
                          <div className='_cs_ht_b2tab'>
                              <p className='typekit_h4 webkit_primary_h _cs_ht_bp1'>
                              Our Success story with TwoAct
                              </p>
                              {innerwdt > 1024 ? 
                                <div>
                                <p className='typekit_BodyRegular webkit_primary_black _cs_ht_bp2' >
                                TwoAct has developed a unique holistic concept called Handle-IT, that creates the best possible conditions for good mental health and schooling for students.
                                <br></br><br></br>
                                Together with VdoTok and our video bot API, TwoAct has been able to quickly and easily build a video bot supporting children with their mental health. 
                                <br></br><br></br>
                                VdoTok’s AI-based video bot analyses a user’s mental health condition by learning from the audio responses as well as facial analyses captured during a live communication and provides the user with the best recommendations to improve their mental health.

                                </p>
                                </div>
                             :
                                <div>
                                <p className='typekit_BodyRegular webkit_primary_black _cs_ht_bp2' >
                                TwoAct has developed a unique holistic concept called Handle-IT, that creates the best possible conditions for good mental health and schooling for students.
                                <br></br><br></br>
                                Together with VdoTok and our video bot API, TwoAct has been able to quickly and easily build a video bot supporting children with their mental health. 
                                <br></br><br></br>
                                VdoTok’s AI-based video bot analyses a user’s mental health condition by learning from the audio responses as well as facial analyses captured during a live communication and provides the user with the best recommendations to improve their mental health.
                                </p>
                                </div>
                              
                              }
                            
                              <img src={cscat} className='_cs_ht_categories' alt='categories' />
                          </div>
          
                      </div>
            }
   

        </div>
        </div>
      
        </>
    )
}


function map(state){
    return state;
}
export default connect(map)(CustomerStoryHT);