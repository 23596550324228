import React , {useEffect , useState , useRef} from "react";
// import makeusunique from '../../assets/images/makeusunique.png';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import makeusunique from '../../assets/images/What-makes-us-different.webp';
import Screen from './images/screendesktop.webp';
import screentab from './images/screentab.webp';
import screenmbl from './images/screenmbl.webp';
import video from './VdoTok_full.mp4';
import './unique.css';
import Aos from "aos";


const UniqueComponent = () => {
    const vidRef=useRef();

    const [innerwdt , setinnerwdt] = useState(window.innerWidth);
    useEffect(() => {
        setinnerwdt(window.innerWidth);
        window.addEventListener('resize' , () => {
            setinnerwdt(window.innerWidth);
        })
    }, []);
    useEffect(() => {
        Aos.init(); 
       document.getElementById("dockvideo").style.display = "none";
  
    }, []);
    const showvdo = () => {
        if(document.getElementById("dockvideo")){
            document.getElementById("dockvideo").style.display = "block";
            // setTimeout(() => {
            // document.getElementById("dockvideo").autoPlay = true;
                
            // }, 300);
            vidRef.current.play();

        }
        else{
            document.getElementById("twodock").style.display = "block";
            // document.getElementById("twodock").autoPlay = true;

        }
    }
    return (
        <>
            <div className="uniquecomponent" id="demo">
                <div className="uniquecontent" data-aos="zoom-in-left">
                    <p className="uniqueboldtext webkit_primary_h typekit_h2">
                        Join live revolution
                    </p>
                    {/* <p className="uniquenormal">
                        
                    We support live revolution of the digital by providing you with new generation of video and voice calls, messaging, live streaming and screen sharing that will boost your digital product and transform your customers’ lives by bringing them into the new reality.
                    </p> */}
                    <p className="typekit_BodyElevated webkit_primary_h uniquedesc">
                        VdoTok's vision is to open the world of <span className="yellowBack"> live media & web3 </span> {" "}to every entrepreneur & developer and empower them to bring people to a
                        {" "}
                        <span className="yellowBack">new engaging reality. </span> {"  "} We call it live revolution.
                    </p>
                </div>
                <div className="uniqueimg" data-aos="zoom-in-right">
                    <img onClick={()=>showvdo()} id="makeusunique" className="makeusunique makeusunique1" src={makeusunique} alt="make-us-unique-img" />
                    <video  ref={ vidRef } id="dockvideo" className="dockvideo" controls playsInline src={video} />
                     
                    {innerwdt > 1024 ? 
                    <img onClick={()=>showvdo()} id="twodock"  className="screen" src={Screen} alt="screen" />:
                    innerwdt > 420 ?
                    <img onClick={()=>showvdo()} id="twodock"  className="screen" src={screentab} alt="screen" />:
                    <img onClick={()=>showvdo()} id="twodock"  className="screen" src={screenmbl} alt="screen" />

                    }
                </div>
            </div>
        </>
    )
}

export default UniqueComponent;