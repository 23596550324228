import { Splide, SplideSlide } from '@splidejs/react-splide';
// Default theme
import '@splidejs/react-splide/css';


import React , {useEffect , useState} from "react";
import { connect } from "react-redux";
import './openpositions.css';
import '../about.css';
import nodejs from './images/nodejs.webp';
import vuejs from './images/vuejs.webp';
import symfonyjs from './images/symfony.webp';
import vuejstab from './images/vuejstab.webp';
import nodejstab from './images/nodejstab.webp';
import symfonytab from './images/symfony.webp';
const OpenPosition = (props) => {
    const [innerwdt , setinnerwdt] = useState(window.innerWidth);
    useEffect(() => {
        setinnerwdt(window.innerWidth);
        if(window.innerWidth >= 1024 ){
            document.getElementById('desktoppos').style.display="flex";
            document.getElementById('ipadpos').style.display="none";
            document.getElementById('mobpos').style.display="none";
        }
        if(window.innerWidth > 600 && window.innerWidth < 1024){
            document.getElementById('desktoppos').style.display="none";
            document.getElementById('ipadpos').style.display="flex";
            document.getElementById('mobpos').style.display="none";
        }
        if(window.innerWidth <= 600){
            document.getElementById('desktoppos').style.display="none";
            document.getElementById('ipadpos').style.display="none";
            document.getElementById('mobpos').style.display="block";

        }
        window.addEventListener('resize', function(event){
            var newWidth = window.innerWidth;
            setinnerwdt(window.innerWidth);
            var newHeight = window.innerHeight;
            if(newWidth > 1024 ){
                document.getElementById('desktoppos').style.display="flex";
                document.getElementById('ipadpos').style.display="none";
                document.getElementById('mobpos').style.display="none";
            }
            else if(newWidth > 600 && newWidth <= 1024){
                document.getElementById('desktoppos').style.display="none";
                document.getElementById('ipadpos').style.display="flex";
                document.getElementById('mobpos').style.display="none";
            }
            else{
                document.getElementById('desktoppos').style.display="none";
                document.getElementById('ipadpos').style.display="none";
                document.getElementById('mobpos').style.display="block";

            }
        });
        
    }, [])
    return(
        <>
        <div className="main_position">
            <div className="position_text">
                <h2 className="webkit_primary_h openposh typekit_h2">Our open positions</h2>
                <p className={innerwdt > 1024 ? "webkit_primary_h typekit_subheadline2 mdesc eltspace" :
                innerwdt > 600 && innerwdt <= 1024 ?
                 "webkit_primary_h typekit_subheadline3 mdesc eltspace" :
                 "webkit_primary_h typekit_subtitle2 mdesc eltspace"
                 }>Join our international team</p>
            </div>
            <div className="positionrow" id='desktoppos'>
                        <div className="positionbox">
                            <div className="poshead">
                                <div className="profession">
                                    <img src={nodejs} />
                                </div>
                                <div className="proftext">
                                    <p className="typekit_LabelLarge h_pos webkit_primary_h">Stockholm</p>
                                    <p className="typekit_LabelSmall webkit_primary_grey">3 Days ago</p>
                                </div>
                                </div>
                                <p className="typekit_LabelXL webkit_primary_h hh_pos">Senior NodeJS developer</p>
                                <div className="hh_pos btns">
                                    <button className="webkit_FreshYellow typekit_LabelSmall webkit_YellowDark btnpos">Full Time</button>
                                    <button className="webkit_FreshGrey typekit_LabelSmall webkit_Blue1 btnpos btnspace">Distance</button>
                                </div>
                                <p className="typekit_LabelLight webkit_primary_grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit amet feugiat urna posuere adipiscing. Non, sed ut non amet, odio etiam ridiculus arcu malesuada. Nibh at auctor porta aliquam est integer non. </p>
                                <div className="btns">
                                    <button className="Darkbluebtn btapply">Apply</button>
                                    <button className="Whitebtn btcontact">Contact</button>
                                </div>
                        </div>
      
                        <div className="positionbox box-space">
                            <div className="poshead">
                                <div className="profession">
                                    <img src={vuejs} />
                                </div>
                                <div className="proftext">
                                    <p className="typekit_LabelLarge h_pos webkit_primary_h">Stockholm</p>
                                    <p className="typekit_LabelSmall webkit_primary_grey">3 Days ago</p>
                                </div>
                            </div>
                            <p className="typekit_LabelXL webkit_primary_h hh_pos">Vue Developer </p>
                            <div className="hh_pos btns">
                                <button className="webkit_FreshYellow typekit_LabelSmall webkit_YellowDark btnpos">Full Time</button>
                                <button className="webkit_FreshGrey typekit_LabelSmall webkit_Blue1 btnpos btnspace">Distance</button>
                            </div>
                            <p className="typekit_LabelLight webkit_primary_grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit amet feugiat urna posuere adipiscing. Non, sed ut non amet, odio etiam ridiculus arcu malesuada. Nibh at auctor porta aliquam est integer non. </p>
                            <div className="btns">
                                <button className="Darkbluebtn btapply">Apply</button>
                                <button className="Whitebtn btcontact">Contact</button>
                            </div>
                        </div>
 
                        <div className="positionbox box-space">
                            <div className="poshead">
                                <div className="profession">
                                    <img src={symfonyjs} />
                                </div>
                                <div className="proftext">
                                    <p className="typekit_LabelLarge h_pos webkit_primary_h">Stockholm</p>
                                    <p className="typekit_LabelSmall webkit_primary_grey">3 Days ago</p>
                                </div>
                            </div>
                            <p className="typekit_LabelXL webkit_primary_h hh_pos">Symfony Developer</p>
                            <div className="hh_pos btns">
                                <button className="webkit_FreshYellow typekit_LabelSmall webkit_YellowDark btnpos">Full Time</button>
                                <button className="webkit_FreshGrey typekit_LabelSmall webkit_Blue1 btnpos btnspace">Distance</button>
                            </div>
                            <p className="typekit_LabelLight webkit_primary_grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit amet feugiat urna posuere adipiscing. Non, sed ut non amet, odio etiam ridiculus arcu malesuada. Nibh at auctor porta aliquam est integer non. </p>
                            <div className="btns">
                                <button className="Darkbluebtn btapply">Apply</button>
                                <button className="Whitebtn btcontact">Contact</button>
                            </div>
                        </div>
            </div>
            <div className="positionrow" id='ipadpos'>
                <Splide options={{
                                rewind: true,
                                gap   : '1rem',
                                perPage:2
                            }}>
                        <SplideSlide>
                        <div className="positionbox">
                            <div className="poshead">
                                <div className="profession">
                                    <img src={nodejstab} />
                                </div>
                                <div className="proftext">
                                    <p className="typekit_LabelRegularBold h_pos webkit_primary_h">Stockholm</p>
                                    <p className="typekit_LabelSmall webkit_primary_grey">3 Days ago</p>
                                </div>
                            </div>
                            <p className="typekit_LabelXL webkit_primary_h hh_pos">Senior NodeJS developer</p>
                            <div className="hh_pos btns">
                                <button className="webkit_FreshYellow typekit_LabelSmall webkit_YellowDark btnpos">Full Time</button>
                                <button className="webkit_FreshGrey typekit_LabelSmall webkit_Blue1 btnpos btnspace">Distance</button>
                            </div>
                            <p className="typekit_LabelLight webkit_primary_grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit amet feugiat urna posuere adipiscing. Non, sed ut non amet, odio etiam ridiculus arcu malesuada. Nibh at auctor porta aliquam est integer non. </p>
                            <div className="btns">
                                <button className="Darkbluebtn btapply">Apply</button>
                                <button className="Whitebtn btcontact">Contact</button>
                            </div>
                        </div>
                        </SplideSlide>
                        <SplideSlide>
                        <div className="positionbox">
                            <div className="poshead">
                                <div className="profession">
                                    <img src={vuejstab} />
                                </div>
                                <div className="proftext">
                                    <p className="typekit_LabelRegularBold h_pos webkit_primary_h">Stockholm</p>
                                    <p className="typekit_LabelSmall webkit_primary_grey">3 Days ago</p>
                                </div>
                            </div>
                            <p className="typekit_LabelXL webkit_primary_h hh_pos">Vue Developer </p>
                            <div className="hh_pos btns">
                                <button className="webkit_FreshYellow typekit_LabelSmall webkit_YellowDark btnpos">Full Time</button>
                                <button className="webkit_FreshGrey typekit_LabelSmall webkit_Blue1 btnpos btnspace">Distance</button>
                            </div>
                            <p className="typekit_LabelLight webkit_primary_grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit amet feugiat urna posuere adipiscing. Non, sed ut non amet, odio etiam ridiculus arcu malesuada. Nibh at auctor porta aliquam est integer non. </p>
                            <div className="btns">
                                <button className="Darkbluebtn btapply">Apply</button>
                                <button className="Whitebtn btcontact">Contact</button>
                            </div>
                        </div>
                        </SplideSlide>
                        <SplideSlide>
                        <div className="positionbox">
                            <div className="poshead">
                                <div className="profession">
                                    <img src={symfonytab} />
                                </div>
                                <div className="proftext">
                                    <p className="typekit_LabelRegularBold h_pos webkit_primary_h">Stockholm</p>
                                    <p className="typekit_LabelSmall webkit_primary_grey">3 Days ago</p>
                                </div>
                            </div>
                            <p className="typekit_LabelXL webkit_primary_h hh_pos">Symfony Developer</p>
                            <div className="hh_pos btns">
                                <button className="webkit_FreshYellow typekit_LabelSmall webkit_YellowDark btnpos">Full Time</button>
                                <button className="webkit_FreshGrey typekit_LabelSmall webkit_Blue1 btnpos btnspace">Distance</button>
                            </div>
                            <p className="typekit_LabelLight webkit_primary_grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit amet feugiat urna posuere adipiscing. Non, sed ut non amet, odio etiam ridiculus arcu malesuada. Nibh at auctor porta aliquam est integer non. </p>
                            <div className="btns">
                                <button className="Darkbluebtn btapply">Apply</button>
                                <button className="Whitebtn btcontact">Contact</button>
                            </div>
                        </div>
                        </SplideSlide>
                </Splide>
            </div>
            <div className="positionrow" id='mobpos'>
                        <Splide options={{
                        rewind: true,
                        gap   : '1rem',
                        perPage:1
                        }} >
                        <SplideSlide>
                         <div className="positionbox">
                            <div className="poshead">
                                <div className="profession">
                                    <img src={nodejs} />
                                </div>
                                <div className="proftext">
                                    <p className="typekit_LabelRegularBold h_pos webkit_primary_black">Stockholm</p>
                                    <p className="typekit_LabelSmall webkit_primary_grey">3 Days ago</p>
                                </div>
                            </div>
                            <p className="typekit_LabelXL webkit_primary_h hh_pos">Senior NodeJS developer</p>
                            <div className="hh_pos btns">
                                <button className="webkit_FreshYellow typekit_LabelSmall webkit_YellowDark btnpos">Full Time</button>
                                <button className="webkit_FreshGrey typekit_LabelSmall webkit_Blue1 btnpos btnspace">Distance</button>
                            </div>
                            <p className="typekit_LabelLight webkit_primary_grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit amet feugiat urna posuere adipiscing. Non, sed ut non amet, odio etiam ridiculus arcu malesuada. Nibh at auctor porta aliquam est integer non. </p>
                            <div className="btns">
                                <button className="Darkbluebtn btapply">Apply</button>
                                <button className="Whitebtn btcontact">Contact</button>
                            </div>
                        </div>
                        </SplideSlide>
                        <SplideSlide>
                        <div className="positionbox">
                            <div className="poshead">
                                <div className="profession">
                                    <img src={vuejs} />
                                </div>
                                <div className="proftext">
                                    <p className="typekit_LabelRegularBold h_pos webkit_primary_black">Stockholm</p>
                                    <p className="typekit_LabelSmall webkit_primary_grey">3 Days ago</p>
                                </div>
                            </div>
                            <p className="typekit_LabelXL webkit_primary_h hh_pos">Vue Developer </p>
                            <div className="hh_pos btns">
                                <button className="webkit_FreshYellow typekit_LabelSmall webkit_YellowDark btnpos">Full Time</button>
                                <button className="webkit_FreshGrey typekit_LabelSmall webkit_Blue1 btnpos btnspace">Distance</button>
                            </div>
                            <p className="typekit_LabelLight webkit_primary_grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit amet feugiat urna posuere adipiscing. Non, sed ut non amet, odio etiam ridiculus arcu malesuada. Nibh at auctor porta aliquam est integer non. </p>
                            <div className="btns">
                                <button className="Darkbluebtn btapply">Apply</button>
                                <button className="Whitebtn btcontact">Contact</button>
                            </div>
                        </div>
                        </SplideSlide>
                        <SplideSlide>
                        <div className="positionbox ">
                            <div className="poshead">
                                <div className="profession">
                                    <img src={symfonyjs} />
                                </div>
                                <div className="proftext">
                                    <p className="typekit_LabelRegularBold h_pos webkit_primary_black">Stockholm</p>
                                    <p className="typekit_LabelSmall webkit_primary_grey">3 Days ago</p>
                                </div>
                            </div>
                            <p className="typekit_LabelXL webkit_primary_h hh_pos">Symfony Developer</p>
                            <div className="hh_pos btns">
                                <button className="webkit_FreshYellow typekit_LabelSmall webkit_YellowDark btnpos">Full Time</button>
                                <button className="webkit_FreshGrey typekit_LabelSmall webkit_Blue1 btnpos btnspace">Distance</button>
                            </div>
                            <p className="typekit_LabelLight webkit_primary_grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit amet feugiat urna posuere adipiscing. Non, sed ut non amet, odio etiam ridiculus arcu malesuada. Nibh at auctor porta aliquam est integer non. </p>
                            <div className="btns">
                                <button className="Darkbluebtn btapply">Apply</button>
                                <button className="Whitebtn btcontact">Contact</button>
                            </div>
                        </div>
                        </SplideSlide>
                        </Splide>
            </div>
            <div className="divv">
                <button className="Darkbluebtn viewall">view all</button>
            </div>
        </div>  
        </>
    )
}


function map(state){
    return state;
}
export default connect(map)(OpenPosition);