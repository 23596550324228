import React from "react";
import { createPortal } from "react-dom";
import { ReactComponent as Xcircle } from "../../assets/images/x-circle.svg";
import { ReactComponent as Close } from "../../assets/images/white_close.svg";
import VdoTok from "../../assets/images/logo.png";
import check from "../../assets/images/success-check.png";
import tabletLogo from "../../assets/images/vdotok_logo_tablet.png";
import mobileLogo from "../../assets/images/vdotok_logo_mobile.png";
import iphone from "../../assets/images/iphoneVideo.png";
import horizontal_iphone from "../../assets/images/horizontal_iphone.png";
import mobileBackground from "../../assets/images/mobile_backgound_video.png";
import Button from "../../components/common/button";
import "./styles.css";

const SuccessPassword = (props) => {
  const { setSelectedScreen, openLoginPopup } = props;
  const cancelHandler = () => {
    setSelectedScreen(null);
  };
  const forgotNode = document.getElementById("resetPasswordContainer");
  return createPortal(
    <div className="success_password_container">
      <div className="success_password_wraper">
        <div className="cpw_left_container">
          <div className="cpw_form">
            <div className="cpw_form_logo_container">
              <img src={VdoTok} alt="logo" className="cpw_form_logo" />
            </div>
            <div className="cpwf_content_container">
              <div className="cpwf_check_icon_container">
                <img src={check} alt="check icon" className="cpwf_check_icon" />
              </div>
              <p className="cpwfc_heading">Password Changed!</p>

              <p className="cpwfc_desc">
                Your password has been changed successfully.<br/>Click on the button
                below to sign in to your account.
              </p>

              <Button
                title="sign in"
                className="cpwfc_send_btn"
                onClick={() => {
                  openLoginPopup();
                  cancelHandler();
                }}
              />
            </div>
          </div>
        </div>
        <div className="cpw_right_container">
          <Xcircle className="cpwrc_close_icon" onClick={cancelHandler} />
          <Close className="cpwrc_white_close_icon" onClick={cancelHandler} />
          <div className="cpwrc_logo_container">
            <img src={tabletLogo} alt="logo" className="cpwrc_logo" />
          </div>
          <div className="cpwrc_mobile_logo_container">
            <img src={mobileLogo} alt="logo" className="cpwrcm_logo" />
          </div>
          {/* <div className="cpwrc_video_container">
            <img src={iphone} alt="iphone" className="cpwrc_video" />
          </div>
          <div className="cpwrc_horizontal_video_container">
            <img
              src={horizontal_iphone}
              alt="horizontal iphone"
              className="cpwrc_horizontal_video"
            />
          </div>
          <div className="cpwrc_mobile_video_container">
            <img
              src={mobileBackground}
              alt="mobile video"
              className="cpwrc_mobile_video"
            />
          </div> */}
        </div>
      </div>
    </div>,
    forgotNode
  );
};

export default SuccessPassword;
