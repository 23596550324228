import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import logo from "./images/logo2.webp";
import cross from "./images/x-circle.webp";
// import phoneverticle from './images/phoneverticle.webp';
import bgtab from "./images/bgtab.png";
import bgmbl from "./images/bg-mbl.webp";
import xcirclewhite from "./images/x-circle-white.webp";
import logowhite from "./images/logo-white.webp";
import hideeye from "./images/hideeye.webp";
import showeye from "./images/showeye.webp";
import keydown from "./images/keydown.webp";
import { connect } from "react-redux";
import keyup from "./images/keyup.webp";
import axios from "axios";
import tickyellow from "./images/hhtick.webp";
import "./style.css";
import APIs from "../../APIs/API";
import mixpanel from "mixpanel-browser";
import videointro from "./images/reduced.mp4";
import videointrombl from "./images/reducedmbl.mp4";
import videointrotab from "./images/reducedmbl.mp4";

const SignupPopup = (props) => {
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const [showCountryDropDown, setShowCountryDropDown] = useState(false);
  const [passwordErr, setPasswordErr] = useState("");
  const [agreedErr, setAgreedErr] = useState("");
  const [countryErr, setCountryErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [firstNameErr, setFirstNameErr] = useState("");
  const [secondNameErr, setSecondNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [genericError, setGenericError] = useState("");
  const [searchString, setSearchString] = useState("");
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");

  const [email, setEmail] = useState("");
  let API_Object = new APIs();
  const [innerwdt, setinnerwdt] = useState(window.innerWidth);
  useEffect(() => {
    setinnerwdt(window.innerWidth);
    window.addEventListener("resize", () => {
      setinnerwdt(window.innerWidth);
    });
  }, []);
  useEffect(() => {
    mixpanel.init("5c76f1828597f867946d015a224673b8", { debug: true });
  }, []);
  useEffect(() => {
    axios
      .get("https://extreme-ip-lookup.com/json/?key=gGJby1xemMxbBa2pfT2S")
      .then((response) => {
        let data = response.data;
        if (data.countryCode) {
          let specificCountry = props.countries.filter(
            (e) => e.code === data.countryCode
          );
          if (specificCountry) {
            setSelectedCountry(specificCountry[0]);
          } else {
            setSelectedCountry(props.countries[0]);
          }
        }
      })
      .catch((error) => {});
  }, []);
  //  const onCLickTandS = useCallback(() => navigate('/termsofservices' , {replace: true}), [navigate]);
  //  const onCLickPPs = useCallback(() => navigate('/privacypolicy' , {replace: true}), [navigate]);
  const onCLickTandS = () => {
    mixpanel.track("terms_of_services_link");
    window.open("/termsofservices");
  };
  const onCLickPPs = () => {
    mixpanel.track("privacy_policy_link");
    window.open("/privacypolicy");
  };
  useEffect(() => {
    var elmnt = document.getElementById("updiv");
    if (elmnt) {
      setTimeout(() => {
        elmnt.scrollIntoView({ behavior: "smooth" });
        // console.log("scrolling");
      }, 100);
      document.getElementById("firstname").focus();
    }
  }, []);
  useEffect(() => {
    if (Object.keys(props.tempuser).length > 0) {
      setFirstName(props.tempuser.first_name);
      setSecondName(props.tempuser.last_name);
      setEmail(props.tempuser.email);
      setValues({ password: props.tempuser.password });
      setAgreed(true);
    }
  }, [Object.keys(props.tempuser).length > 0]);
  const HideMe = () => {
    props.dispatch({ type: "TEMPUSER", userData: "" });
    props.closeSignupPopup();
  };
  const validate = () => {
    return;
  };
  //   useEffect(()=> {
  //    document.getElementById("dockvideo").style.display = "none";
  //   } , []);
  const playvdo = () => {
    document.getElementById("vdotokvd").style.display = "none";
    if (document.getElementById("dockvideo")) {
      document.getElementById("dockvideo").style.display = "block";
      document.getElementById("dockvideo").autoPlay = "true";
    } else {
      document.getElementById("twodock").style.display = "block";
      document.getElementById("twodock").autoPlay = "true";
    }
  };
  const onRegister = () => {
    let validate = true;
    let passwordd = values.password.trim();
    let specialChFormat = /[~!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    let chFormatForOneUpperCase = "(?=.*?[A-Z])";
    let chFormatForOneLowerCase = "(?=.*?[a-z])";
    let chFormatForOneDigitCase = "(?=.*?[0-9])";
    let emailFormat = "[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}";
    if (firstName.trim().length === 0) {
      setFirstNameErr("Please enter first name !");
      validate = false;
    } else if (firstName.trim().length < 2) {
      validate = false;
      setFirstNameErr("Minimum 2 characters required !");
    }
    if (secondName.trim().length === 0) {
      setSecondNameErr("Please enter last name !");
      validate = false;
    } else if (secondName.trim().length < 2) {
      validate = false;
      setSecondNameErr("Minimum 2 characters required !");
    }
    if (email.trim().length === 0) {
      setEmailErr("Please enter email !");
      validate = false;
    } else if (!email.match(emailFormat)) {
      setEmailErr("Invalid email.");
      validate = false;
    }
    if (!passwordd) {
      setPasswordErr("Please enter password");
      validate = false;
    } else if (
      passwordd.length < 8 ||
      passwordd.length > 16 ||
      !passwordd.match(specialChFormat) ||
      !passwordd.match(chFormatForOneUpperCase) ||
      !passwordd.match(chFormatForOneLowerCase) ||
      !passwordd.match(chFormatForOneDigitCase)
    ) {
      validate = false;
      setPasswordErr(
        "Minimum 8 and maximum 16 characters required containing one special character, one uppercase, one lowercase and one numeric character"
      );
    } else if (!passwordd.match(specialChFormat)) {
      validate = false;
      setPasswordErr("Password should contain atleast one special character");
    } else if (!passwordd.match(chFormatForOneUpperCase)) {
      validate = false;
      setPasswordErr("Password should contain atleast one uppercase letter");
    } else if (!passwordd.match(chFormatForOneLowerCase)) {
      validate = false;
      setPasswordErr("Password should contain atleast one lowercase letter");
    } else if (!passwordd.match(chFormatForOneDigitCase)) {
      validate = false;
      setPasswordErr("Password should contain atleast one numeric character");
    }
    if (!agreed) {
      validate = false;
      setAgreedErr("Please agree to Terms and Condition!");
    } else {
      setAgreedErr("");
    }
    if (!selectedCountry) {
      validate = false;
      setCountryErr("Please Choose country !");
    } else {
      setCountryErr("");
    }
    if (validate) {
      setLoading(true);
      setGenericError("");
      var object = {
        first_name: firstName,
        last_name: secondName,
        email: email,
        password: values.password,
        country: selectedCountry.id,
      };

      API_Object.SignUp(object, props.urls.apiBaseURL).then((data) => {
        if (data.status == 200) {
          HideMe();
          props.openEmailVerificationPopup();
          props.dispatch({ type: "TEMPUSER", userData: object });
          setLoading(false);
          mixpanel.track("signup_success");
        } else {
          setGenericError(data.message);
          setLoading(false);
          mixpanel.track("signup_error");
        }
      });
    }
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handlePasswordChange = (prop) => (event) => {
    mixpanel.track("password");
    setPasswordErr("");
    setValues({ ...values, [prop]: event.target.value });
    validate();
  };
  let countriesList =
    searchString.trim().length === 0
      ? props.countries
      : props.countries.filter((e) =>
          e.name.toLowerCase().startsWith(searchString.toLowerCase())
        );
  return (
    <>
      <div
        onClick={() => {
          setShowCountryDropDown(false);
          setSearchString("");
        }}
        className="signupContainer"
        id="signup_container"
      >
        <div className="modalmain-signup" id="modalmain-signup">
          <div className="navmodal-signup" id="navmodal-signup">
            <div className="partition">
              <img
                src={logo}
                alt="vdotok-logo"
                id="vdtlogo"
                className="vdtlogo"
              />
              <p className="heading-signup typekit_h3 webkit_primary_h">
                Sign up
              </p>
              {genericError && (
                <p className="errorMessage  start">{genericError}</p>
              )}
              <p
                style={{ textAlign: "start" }}
                className="p-signup typekit_subheadline4 webkit_primary_h"
              >
                No credit card required.
              </p>
              <div className="parent-inputs desktop">
                <div>
                  <p className="label-signup typekit_LabelRegularBold webkit_primary_h">
                    First name
                  </p>
                  <input
                    id="firstname"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onRegister();
                      }
                    }}
                    value={firstName}
                    onChange={(e) => {
                      mixpanel.track("first_name");
                      setFirstName(e.target.value);
                      setFirstNameErr("");
                      validate();
                    }}
                    className="input-signup half typekit_LabelRegular webkit_primary_grey"
                  />
                  {firstNameErr && (
                    <p className="errorMessage erMNew start">{firstNameErr}</p>
                  )}
                </div>
                <div>
                  <p className="label-signup typekit_LabelRegularBold webkit_primary_h">
                    Last name
                  </p>
                  <input
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onRegister();
                      }
                    }}
                    value={secondName}
                    onChange={(e) => {
                      mixpanel.track("last_name");
                      setSecondName(e.target.value);
                      setSecondNameErr("");
                      validate();
                    }}
                    className="input-signup full  typekit_LabelRegular webkit_primary_grey"
                  />
                  {secondNameErr && (
                    <p className="errorMessage erMNew start">{secondNameErr}</p>
                  )}
                </div>
              </div>
              <div className="parent-inputs tablet">
                <div>
                  <p className="label-signup typekit_LabelRegularBold webkit_primary_h">
                    First name
                  </p>
                  <input
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onRegister();
                      }
                    }}
                    value={firstName}
                    onChange={(e) => {
                      mixpanel.track("first_name");
                      setFirstName(e.target.value);
                      setFirstNameErr("");
                      validate();
                    }}
                    className="input-signup half"
                  />
                  {firstNameErr && (
                    <p className="errorMessage erMNew start">{firstNameErr}</p>
                  )}
                </div>
                <div>
                  <p className="label-signup typekit_LabelRegularBold webkit_primary_h">
                    Last name
                  </p>
                  <input
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onRegister();
                      }
                    }}
                    value={secondName}
                    onChange={(e) => {
                      mixpanel.track("last_name");
                      setSecondName(e.target.value);
                      setSecondNameErr("");
                      validate();
                    }}
                    className="input-signup half"
                  />
                  {secondNameErr && (
                    <p className="errorMessage erMNew start">{secondNameErr}</p>
                  )}
                </div>
                <div>
                  <p className="label-signup typekit_LabelRegularBold webkit_primary_h">
                    E-mail
                  </p>
                  <input
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onRegister();
                      }
                    }}
                    value={email}
                    onChange={(e) => {
                      mixpanel.track("email");
                      setEmail(e.target.value);
                      setEmailErr("");
                      validate();
                    }}
                    className={
                      emailErr
                        ? "input-signup full BorderRed"
                        : "input-signup full"
                    }
                  />
                  {emailErr && (
                    <p className="errorMessage erMNew start">{emailErr}</p>
                  )}
                </div>
              </div>
              <div className="parent-inputs mobile">
                <div>
                  <p className="label-signup typekit_LabelRegularBold webkit_primary_h">
                    First name
                  </p>
                  <input
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onRegister();
                      }
                    }}
                    value={firstName}
                    onChange={(e) => {
                      mixpanel.track("firstname");
                      setFirstName(e.target.value);
                      setFirstNameErr("");
                      validate();
                    }}
                    className="input-signup full"
                  />
                  {firstNameErr && (
                    <p className="errorMessage erMNew start">{firstNameErr}</p>
                  )}
                </div>
                <div>
                  <p className="label-signup typekit_LabelRegularBold webkit_primary_h">
                    Last name
                  </p>
                  <input
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onRegister();
                      }
                    }}
                    value={secondName}
                    onChange={(e) => {
                      mixpanel.track("lastname");
                      setSecondName(e.target.value);
                      setSecondNameErr("");
                      validate();
                    }}
                    className="input-signup full"
                  />
                  {secondNameErr && (
                    <p className="errorMessage erMNew start">{secondNameErr}</p>
                  )}
                </div>
                <div>
                  <p className="label-signup typekit_LabelRegularBold webkit_primary_h">
                    E-mail
                  </p>
                  <input
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onRegister();
                      }
                    }}
                    value={email}
                    onChange={(e) => {
                      mixpanel.track("email");
                      setEmail(e.target.value);
                      setEmailErr("");
                      validate();
                    }}
                    className={
                      emailErr
                        ? "input-signup full BorderRed"
                        : "input-signup full"
                    }
                  />
                  {emailErr && (
                    <p className="errorMessage erMNew start">{emailErr}</p>
                  )}
                </div>
              </div>
              <div className="web-email">
                <p className="label-signup typekit_LabelRegularBold webkit_primary_h">
                  E-mail
                </p>
                <input
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      onRegister();
                    }
                  }}
                  value={email}
                  onChange={(e) => {
                    mixpanel.track("email");
                    setEmail(e.target.value);
                    setEmailErr("");
                    setGenericError("");
                    validate();
                  }}
                  className={
                    emailErr
                      ? "input-signup full  typekit_LabelRegular webkit_primary_grey BorderRed"
                      : "input-signup full  typekit_LabelRegular webkit_primary_grey"
                  }
                  type="email"
                />
                {emailErr && (
                  <p className="errorMessage erMNew start">{emailErr}</p>
                )}
              </div>
              <div className="parent-country">
                <p className="label-signup typekit_LabelRegularBold webkit_primary_h">
                  Country
                </p>
                <div
                  onClick={(e) => {
                    setShowCountryDropDown(!showCountryDropDown);
                    e.stopPropagation();
                  }}
                  className="input-password input-country input-signup full"
                >
                  {selectedCountry ? (
                    <div className="dropdowntext typekit_LabelRegular webkit_primary_grey">
                      <img
                        src={`https://flagcdn.com/28x21/${selectedCountry.code.toLowerCase()}.png`}
                        className="dropFlag"
                        alt={selectedCountry.code}
                      />{" "}
                      {selectedCountry.name}
                    </div>
                  ) : (
                    <div>Loading...</div>
                  )}
                  <div>
                    {showCountryDropDown ? (
                      <img src={keyup} className="dropIcon" />
                    ) : (
                      <img src={keydown} className="dropIcon" />
                    )}
                  </div>
                </div>
                {showCountryDropDown && (
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="dropdownBody"
                  >
                    <div className="dropbodySearchBar">
                      <input
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            onRegister();
                          }
                        }}
                        value={searchString}
                        onChange={(e) => {
                          mixpanel.track("country");
                          setSearchString(e.target.value);
                        }}
                        // type="text"
                        placeholder="Search..."
                        className="searchInputSignup"
                      />
                    </div>
                    <div className="countryScroller">
                      {countriesList.length == 0 && (
                        <div className="singleCountryContainer">
                          <div className="dropdowntext typekit_LabelRegular webkit_primary_grey">
                            No Result..
                          </div>
                        </div>
                      )}
                      {countriesList.map((item, index) => {
                        return (
                          <div
                            onClick={() => {
                              setSelectedCountry(item);
                              setShowCountryDropDown(false);
                              setSearchString("");
                            }}
                            key={index}
                            className="singleCountryContainer"
                          >
                            <div className="dropdowntext typekit_LabelRegular webkit_primary_grey">
                              <img
                                src={`https://flagcdn.com/28x21/${item.code.toLowerCase()}.png`}
                                className="dropFlag"
                                alt={item.code}
                              />{" "}
                              {item.name}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {countryErr && (
                  <p className="errorMessage erMNew start">{countryErr}</p>
                )}
                {/* <input onClick={filterFunction} id="search-input" className="input-signup full" type="email" value="Doe"/> */}
              </div>
              <div>
                <p className="label-signup typekit_LabelRegularBold webkit_primary_h">
                  Password
                </p>
                <div
                  className={
                    passwordErr
                      ? "input-password input-signup full BorderRed"
                      : "input-password input-signup full"
                  }
                >
                  <input
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onRegister();
                      }
                    }}
                    maxLength={16}
                    className="password  typekit_LabelRegular webkit_primary_grey"
                    type={values.showPassword ? "text" : "password"}
                    onChange={handlePasswordChange("password")}
                    value={values.password}
                  />

                  <img
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    className="eye"
                    src={values.showPassword ? showeye : hideeye}
                  />
                </div>
                {passwordErr && (
                  <p className="errorMessage erMNew start">{passwordErr}</p>
                )}
              </div>
              <div className="parent-inputs">
                {/* <div 
                    onClick={() => {
                    setAgreed(!agreed);
                    setAgreedErr("");
                    }}
                    style={{ backgroundColor: agreed ? "#F7E23E" : "white" }}
                    className="customCheckbox"
                    >
                 <input  type="checkbox" className="checkbox" />
                    {agreed && <img src={check} className="checkIcon" />}
                 </div> */}
                <div
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      onRegister();
                    }
                  }}
                  onClick={() => {
                    mixpanel.track("terms_of_services");
                    setAgreed(!agreed);
                    setAgreedErr("");
                    validate();
                  }}
                  className="customCheckbox"
                >
                  {agreed ? (
                    <img
                      className="tick_yellow"
                      src={tickyellow}
                      alt="agreed"
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <p className="p-terms">
                  I have read and agree to the VdoTok{" "}
                  <b style={{ cursor: "pointer" }} onClick={onCLickTandS}>
                    Terms of Service
                  </b>{" "}
                  and{" "}
                  <b style={{ cursor: "pointer" }} onClick={onCLickPPs}>
                    Privacy Policy.
                  </b>
                </p>
              </div>
              {agreedErr && <p className="errorMessage erMNew start">{agreedErr}</p>}
              <div className="wrapper" id="wrapper">
                <button
                  onClick={() => {
                    if (
                      firstName.trim().length > 0 &&
                      secondName.trim().length > 0 &&
                      email.trim().length > 0 &&
                      selectedCountry &&
                      values.password.trim().length > 0 &&
                      agreed &&
                      !loading
                    ) {
                      onRegister();
                    }
                  }}
                  className="btn-signup"
                  style={{
                    cursor:
                      firstName.trim().length > 0 &&
                      secondName.trim().length > 0 &&
                      email.trim().length > 0 &&
                      selectedCountry &&
                      values.password.trim().length > 0 &&
                      agreed
                        ? "pointer"
                        : "not-allowed",
                    backgroundColor:
                      firstName.trim().length > 0 &&
                      secondName.trim().length > 0 &&
                      email.trim().length > 0 &&
                      selectedCountry &&
                      values.password.trim().length > 0 &&
                      agreed
                        ? "#54C295"
                        : "#8e9d97",
                  }}
                  id="btn-signup"
                >
                  SIGN UP FOR FREE
                </button>
              </div>
              <p className="p-terms center">
                Already have an account?{" "}
                <b
                  style={{ marginLeft: "3px", cursor: "pointer" }}
                  onClick={() => {
                    mixpanel.track("sign_in_here");
                    HideMe();
                    props.openLoginPopup();
                  }}
                >
                  Sign in here{" "}
                </b>
              </p>
            </div>

            <div className="withimg" id="withimg">
              <div id="updiv" style={{ height: 0, width: 0 }}></div>
              <div className="cross-div">
                <img src={logo} alt="vdotok-logo" className="vdtlogo-tab" />
                <img
                  src={logowhite}
                  alt="vdotok-logo"
                  className="vdtlogo-mbl"
                />
                <img
                  onClick={HideMe}
                  className="x-cross lblack"
                  src={cross}
                  alt="cross-button"
                />
                <img
                  onClick={HideMe}
                  className="x-cross-mbl"
                  src={xcirclewhite}
                  alt="cross-button"
                />
              </div>
              <div className="phonediv" id="phonediv">
                <img
                  id="bgtab"
                  className="bgtab"
                  src={bgtab}
                  alt="background"
                />
                <img
                  id="bgmbl"
                  className="bgmbl"
                  src={bgmbl}
                  alt="background"
                />
                {/* <img className="phoneicon" id="vdotokvd" src={phoneverticle} alt="phone-image" onClick={playvdo} /> */}
                {innerwdt > 1024 ? (
                  <video
                    id="dockvideo"
                    className="phoneicon"
                    controls
                    autoPlay
                    muted
                    playsInline
                  >
                    <source src={videointro} type="video/mp4" />
                  </video>
                ) : innerwdt > 550 ? (
                  <video
                    className="bgtabvdo"
                    controls
                    autoPlay
                    muted
                    playsInline
                  >
                    <source src={videointrotab} type="video/mp4" />
                  </video>
                ) : (
                  <video
                    className="bgmblvdo"
                    controls
                    autoPlay
                    muted
                    playsInline
                  >
                    <source src={videointrombl} type="video/mp4" />
                  </video>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
function map(state) {
  return state;
}
export default connect(map)(SignupPopup);
