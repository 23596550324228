import React, { useState, useEffect } from "react";
import speaker from "../../assets/images/speaker.svg";
import Icons from "../../assets/images/Icons.svg";
import slider from "../../assets/images/Slider.png";
import slider2 from "../../assets/images/Slider1.png";
import { connect } from "react-redux";
import mixpanel from "mixpanel-browser";
import "./paycomponent.css";

const PayComponent = (props) => {
  const [slidertimeVal, setSlidertimeVal] = useState(42.5);
  const [sliderusersVal, setSliderusersVal] = useState(24);
  const [totaltime, setTotalTime] = useState(
    parseFloat(42.5 * 24 * 0.004)
      .toFixed(2)
      .split(".")[0]
  );
  const [totaltimeb, setTotalTimeb] = useState(
    parseFloat(42.5 * 24 * 0.004)
      .toFixed(2)
      .split(".")[1]
  );
  const [checkedIn, setCheckedIn] = useState(false);
  const [innerwdt, setinnerwdt] = useState(window.innerWidth);
  useEffect(() => {
    setinnerwdt(window.innerWidth);
    window.addEventListener("resize", () => {
      setinnerwdt(window.innerWidth);
    });
  }, []);
  const reverse = (encoded) => {
    var newString = "";
    for (var i = encoded.length - 1; i >= 0; i--) {
      newString += encoded[i];
    }
    return newString;
  };
  const setCookie = (cname, cvalue, exdays) => {
    let encoded = btoa(cvalue);
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    if (window.location.href.includes(".dev")) {
      // document.cookie=`user=; Expires=Fri, 5 Oct 2018 14:28:00 GMT;path=/;Domain=vdotok.dev;SameSite=Strict`;
      document.cookie =
        cname +
        "=" +
        reverse(encoded) +
        ";" +
        expires +
        ";path=/;Domain=vdotok.dev;SameSite=Strict";
    } else {
      document.cookie =
        cname +
        "=" +
        reverse(encoded) +
        ";" +
        expires +
        ";path=/;Domain=vdotok.com;SameSite=Strict";
      // document.cookie=`user=; Expires=Fri, 5 Oct 2018 14:28:00 GMT;path=/;Domain=vdotok.com;SameSite=Strict`;
    }
    // document.cookie = cname + "=" + reverse(encoded) + ";" + expires + ";path=/;Domain=vdotok.dev;SameSite=Strict";
  };
  const updateTotalVal = (time, user) => {
    var totaltime = parseFloat(time * user * 0.004).toFixed(3);
    setTotalTime(totaltime.split(".")[0]);
    setTotalTimeb(totaltime.split(".")[1]);
  };
  useEffect(() => {
    if (Object.keys(props.userdone).length == 0) {
      setCheckedIn(false);
    } else {
      setCheckedIn(true);
    }
  }, [props]);
  const customstyle = {
    background: `linear-gradient(to right, #F2E33A 0%, #F2E33A,  ${slidertimeVal}%, #F8F9FA ${slidertimeVal}%, #F8F9FA 100%)`,
    // background:`red`
  };
  const customstyleusers = {
    background: `linear-gradient(to right, #F2E33A 0%, #F2E33A ${sliderusersVal}%, #F8F9FA ${sliderusersVal}%, #F8F9FA 100%)`,
    // background: `green`
  };
  return (
    <>
      <div id="pricing"></div>
      <div className="paycomponent">
        <div data-aos="zoom-in-right" className="paychild1">
          <div className="paycontent">
            <p className="typekit_h2 webkit_primary_h payboldtext">
              Pay as you grow
            </p>
            <p className="paydescription typekit_BodyRegular webkit_primary_h">
              We believe everybody should only pay for the what they use. That
              is why we don’t have subscribtion models and you pay for the exact
              amount of minutes you burn. Easy, huh?
            </p>
          </div>
          <div className="paycards">
            <div className="paycardcontent">
              <img className="paycardimg1" src={speaker} alt="speaker" />
              <div className="paycardhspace" style={{ display: "flex" }}>
                <p
                  className={
                    innerwdt > 600
                      ? "typekit_LabelXXL webkit_primary_h eltspace"
                      : "typekit_LabelRegularBold webkit_primary_h eltspace"
                  }
                  style={{ whiteSpace: "nowrap" }}
                >
                  15,000
                </p>
                <p
                  style={{ whiteSpace: "nowrap" }}
                  className={
                    innerwdt > 600
                      ? "pn eltspace typekit_LabelXL webkit_primary_h"
                      : "pn eltspace typekit_LabelSmall webkit_primary_h"
                  }
                >
                  free minutes
                </p>
              </div>
              <p className="_min_Desk tohide typekit_LabelLight webkit_primary_black">
                Use these minutes to play around with our APIs and SDKs and find
                what works best for your product
              </p>
            </div>
            <div className="paycardcontent">
              <img className="paycardimg2" src={Icons} alt="pay-card-image" />
              <p
                className={
                  innerwdt > 600
                    ? "typekit_LabelXXL webkit_primary_h eltspace"
                    : "typekit_LabelRegularBold webkit_primary_h eltspace"
                }
              >
                Video & Audio
              </p>
              <p className=" tohide typekit_LabelLight webkit_primary_black">
                By signing up for VdoTok you always get access to both types of
                APIs.
              </p>
            </div>
          </div>
        </div>
        <div data-aos="zoom-in-left" className="paychild2">
          <div className="child2card paycardcontent2">
            <div className="forcentering" style={{ display: "flex" }}>
              <p
                className={
                  innerwdt > 1024
                    ? "pcardbold2 webkit_primary_h eltspace"
                    : innerwdt > 500
                    ? "pcardbold2 webkit_primary_blue eltspace"
                    : "pcardbold2 webkit_primary_h eltspace"
                }
              >
                $ {totaltime}
              </p>
              <p
                className={
                  innerwdt > 1024
                    ? "zerodiv webkit_primary_h eltspace"
                    : innerwdt > 500
                    ? "zerodiv webkit_primary_blue eltspace"
                    : "zerodiv webkit_primary_h eltspace"
                }
              >
                .{totaltimeb}
              </p>
            </div>

            <p className=" paygrow typekit_LabelLight webkit_primary_black">
              Pay as you grow
            </p>
            {/* <img className="sliderimg" src={slider} /> */}
            <div className="parentsflex">
              <div className="sliderparent">
                <div className="slidecontainer">
                  <input
                    style={customstyle}
                    type="range"
                    min="1"
                    max="100"
                    value={slidertimeVal}
                    onChange={(e) => {
                      mixpanel.track("change_time");
                      setSlidertimeVal(e.target.value);
                      updateTotalVal(e.target.value, sliderusersVal);
                    }}
                    className="slider"
                    id="myRange"
                  />
                </div>
                <div className="inputpay">
                  <p
                    className={
                      innerwdt > 1024
                        ? "inputpaycount typekit_LabelSmall webkit_primary_Dark"
                        : "inputpaycount typekit_LabelSmall webkit_primary_black"
                    }
                  >
                    {" "}
                    {slidertimeVal}
                  </p>
                  <p className="inputpaynum typekit_LabelSmall webkit_primary_grey">
                    /Min
                  </p>
                </div>
              </div>
              <div className="sliderparent">
                <div className="slidecontainer">
                  <input
                    style={customstyleusers}
                    type="range"
                    min="1"
                    max="100"
                    value={sliderusersVal}
                    onChange={(e) => {
                      mixpanel.track("change_user");
                      setSliderusersVal(e.target.value);
                      updateTotalVal(slidertimeVal, e.target.value);
                    }}
                    className="slider"
                    id="myRange"
                  />
                </div>
                <div className="inputpay">
                  <p className="inputpaycount typekit_LabelSmall webkit_primary_Dark">
                    {sliderusersVal}
                  </p>
                  <p className="inputpaynum typekit_LabelSmall webkit_primary_grey">
                    /Users
                  </p>
                </div>
              </div>
            </div>

            {/* <img className="sliderimg" src={slider2} /> */}
            <div className="1stbuttondiv">
              {!checkedIn ? (
                <button
                  type="button"
                  className=" Darkbluebtn paybtnblue"
                  // onClick={() => {
                  //   props.openSignupPopup();
                  // }}
                  style={{ cursor: "pointer" }}
                >
                  START FOR FREE
                </button>
              ) : (
                <button
                  type="button"
                  className=" Darkbluebtn paybtnblue"
                  onClick={() => {
                    setCookie("user", props.user.auth_token, 4);
                    props.dispatch({ type: "USERDONE", userData: props.user });
                    window.open(`https://${props.urls.adminPanelURL}/overview`);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  DASHBOARD
                </button>
              )}
            </div>
            <div className="line"></div>
            <div className="afterlineflex">
              {innerwdt > 1024 ? (
                <p className="underlinetext typekit_LabelRegular">
                  Looking for an <br></br>
                  <b className="typekit_LabelRegularBold">
                    {" "}
                    Enterprise Solution?
                  </b>
                </p>
              ) : innerwdt > 500 ? (
                <p className="underlinetext typekit_LabelRegular">
                  Looking for an
                  <b className="typekit_LabelRegularBold">
                    {" "}
                    Enterprise Solution?
                  </b>
                </p>
              ) : (
                <p className="underlinetext typekit_LabelRegular">
                  Looking for an <br></br>
                  <b className="typekit_LabelRegularBold">
                    {" "}
                    Enterprise Solution?
                  </b>
                </p>
              )}
              <div className="">
                <button
                  type="button"
                  className=" Whitebtn paybtnwhite"
                  // onClick={() => {
                  //   props.openContactusPopup();
                  // }}
                >
                  CONTACT US
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function map(state) {
  return state;
}
export default connect(map)(PayComponent);
