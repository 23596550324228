import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  Fragment,
} from "react";
import arrowdown from "./images/arrowdown.webp";
import { useNavigate } from "react-router-dom";
import arrowup from "./images/arrowup.webp";
import axios from "axios";
import { connect } from "react-redux";
import mixpanel from "mixpanel-browser";
import Footer from "../Footer/Footer";
import NavComponent from "../Nav/NavComponent";
import SignupPopup from "../Nav/SignUpPopup";
import UseCasesPopup from "../Nav/UseCasesPopup";
import LoginPopup from "../Nav/LoginPopup";
import AboutYou from "../Verification/AboutYou";
import EmailVerification from "../Verification/EmailVerification";
import EnterPhone from "../Verification/EnterPhone";
import PhoneVerification from "../Verification/PhoneVerification";
import LoginVerification from "../Verification/LoginVerification";
import Thankyou from "../Verification/Thankyou";
import ContactUsPopup from "../Nav/ContactUsPopup";
import APIs from "../../APIs/API";
import "./privacyploicy.css";
import ForgotPassword from "../../screens/forgot-password";
import VerificationCode from "../../screens/verification-code";
import ResetPassword from "../../screens/reset-password";
import SuccessPassword from "../../screens/success-password";

const PrivacyPolicy = (props) => {
  const API_Object = new APIs();
  const [forgotResponse, setForgotResponse] = useState({
    attempts: "",
    token: "",
    otp: "",
    email: "",
  });

  const [selectedScreen, setSelectedScreen] = useState(null);
  const [FAQSubCategories, setFAQSubCategories] = useState([]);
  const [content, setContent] = useState([]);
  const [answer, setAnswer] = useState("");
  const [statesubcat, setstatesubcat] = useState("");
  const [ans, setAns] = useState("");
  const [signupPopup, setSignupPopup] = useState(false);
  const [login, setLogin] = useState(false);
  const [useCasesPopup, setUseCasesPopup] = useState(false);
  const [aboutYou, setAboutYou] = useState(false);
  const [emailVerification, setEmailVerification] = useState(false);
  const [enterPhone, setEnterPhone] = useState(false);
  const [phoneVerification, setPhoneVerification] = useState(false);
  const [loginverification, setLoginVerification] = useState(false);
  const [thankyou, setThankyou] = useState(false);
  const [contactusPopup, setContactusPopup] = useState(false);

  const navigate = useNavigate();

  const [countries, setCountries] = useState([]);
  useEffect(() => {
    if (props.privacypolicy.questions.length > 0) {
      // console.log("ppppps", "ok", props.privacypolicy.questions);
      updateContent(props.privacypolicy.questions);
    }
  }, [props.privacypolicy]);
  const sendMail = () => {
    mixpanel.track("email");
    var link = "mailto:info@vdotok.com";
    window.location.href = link;
  };
  const customerSupport = () => {
    mixpanel.track("email");
    var link = "mailto:support@vdotok.com";
    window.location.href = link;
  };
  const sendEmailtoImy = () => {
    mixpanel.track("email");
    var link = "imy@imy.se";
    window.location.href = link;
  };

  const onCLickToS = useCallback(
    () => navigate("/termsofservices", { replace: true }),
    [navigate]
  );
  const onCLickPP = useCallback(
    () => navigate("/privacypolicy", { replace: true }),
    [navigate]
  );
  useEffect(() => {
    axios
      .get(`https://${props.urls.apiBaseURL}/API/GetAllCountries`)
      .then((res) => {
        setCountries(res.data.data);
      })
      .catch((err) => {});
  }, [props.urls.apiBaseURL]);
  useEffect(() => {
    if (
      window.location.href.includes("termsofservices") ||
      window.location.href.includes("privacypolicy")
    ) {
      document.getElementById("vdtnavbar").style.boxShadow =
        "0px 8px 40px rgb(1 0 3 / 5%)";
    }
  }, []);
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }, []);
  useEffect(() => {
    if (window.location.href.includes("termsofservices")) {
      document.getElementById("toss").style.textDecoration = "underline";
      document.getElementById("toss").style.letterSpacing = "-0.6px";
      document.getElementById("toss").style.fontWeight = "600";
      document.getElementById("tossv").style.textDecoration = "underline";
      document.getElementById("tossv").style.letterSpacing = "-0.6px";
      document.getElementById("tossv").style.fontWeight = "600";
    }
    if (window.location.href.includes("privacypolicy")) {
      document.getElementById("ppp").style.textDecoration = "underline";
      document.getElementById("ppp").style.letterSpacing = "-0.6px";
      document.getElementById("ppp").style.fontWeight = "600";
      document.getElementById("pppv").style.textDecoration = "underline";
      document.getElementById("pppv").style.letterSpacing = "-0.6px";
      document.getElementById("pppv").style.fontWeight = "600";
    }
  }, [window.location.href]);
  const closeSignupPopup = () => {
    document.body.style.overflow = "auto";

    setSignupPopup(false);
  };
  const openSignupPopup = () => {
    setSignupPopup(true);
    document.body.style.overflow = "hidden";
  };
  const closeLoginPopup = () => {
    document.body.style.overflow = "auto";
    setLogin(false);
  };
  const openLoginPopup = () => {
    setLogin(true);
    document.body.style.overflow = "hidden";
  };
  const closeAboutYouPopup = () => {
    document.body.style.overflow = "auto";
    setAboutYou(false);
  };
  const openAboutYouPopup = () => {
    document.body.style.overflow = "hidden";
    setAboutYou(true);
  };
  const closeEmailVerificationPopup = () => {
    document.body.style.overflow = "auto";
    setEmailVerification(false);
  };
  const openEmailVerificationPopup = () => {
    document.body.style.overflow = "hidden";
    setEmailVerification(true);
  };
  const closeEnterPhonePopup = () => {
    document.body.style.overflow = "auto";
    setEnterPhone(false);
  };
  const openEnterPhonePopup = () => {
    document.body.style.overflow = "hidden";
    // document.body.style.position = 'fixed';

    setEnterPhone(true);
    setTimeout(() => {
      const phoneInputField = document.querySelector("#val2");

      const phoneInput = window.intlTelInput(phoneInputField, {
        preferredCountries: ["PK", "SE"],
        dropdownContainer: document.getElementById("inpsum"),

        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
      });

      // console.log("my phone input", phoneInput);
    }, 500);
  };
  const closePhoneVerificationPopup = () => {
    document.body.style.overflow = "auto";
    setPhoneVerification(false);
  };
  const openPhoneVerificationPopup = () => {
    document.body.style.overflow = "hidden";
    setPhoneVerification(true);
  };
  const closeThankyouPopup = () => {
    document.body.style.overflow = "auto";
    setThankyou(false);
  };
  const openThankyouPopup = () => {
    document.body.style.overflow = "hidden";
    setThankyou(true);
  };
  const openContactusPopup = () => {
    setContactusPopup(true);
    document.body.style.overflow = "hidden";
  };
  const closeContactusPopup = () => {
    document.body.style.overflow = "auto";
    setContactusPopup(false);
  };
  const openUseCasesPopup = () => {
    document.body.style.overflow = "hidden";
    setUseCasesPopup(true);
  };
  const closeUseCasesPopup = () => {
    document.body.style.overflow = "auto";
    setUseCasesPopup(false);
  };
  const openLoginVerification = () => {
    document.body.style.overflow = "hidden";
    setLoginVerification(true);
  };
  const closeLoginVerification = () => {
    document.body.style.overflow = "auto";
    setLoginVerification(false);
  };
  const showAnswer = (id) => {
    if (document.getElementById(id).style.display == "none") {
      document.getElementById(id).style.display = "block";
      document.getElementById(`${id}img`).src = arrowup;
    } else {
      document.getElementById(id).style.display = "none";
      document.getElementById(`${id}img`).src = arrowdown;
    }
  };
  const showSubCat = (datap, index) => {
    if (statesubcat == "") {
      updateContent(datap);

      setFAQSubCategories(datap.sub_categories);
      setstatesubcat(index);
      document.getElementById(`${index}arrow`).src = arrowup;
    } else {
      updateContent(datap);
      setstatesubcat("");
      setFAQSubCategories([]);
      document.getElementById(`${index}arrow`).src = arrowdown;
    }
  };
  const updateContent = (privacydata) => {
    // console.log("privacydara", privacydata);
    setContent(
      <>
        {privacydata.map((det, index) => {
          return (
            <Fragment key={index}>
              <div className="contentboxpp">
                <div className="frmcontent">
                  <p
                    className="typekit_LabelRegularBold webkit_primary_h eltspace"
                    onClick={() => {
                      showAnswer(`smk${det.id}`);
                    }}
                  >
                    {det.question}
                  </p>

                  <div
                    style={{ display: "none" }}
                    className="ppsanswer typekit_LabelRegular webkit_primary_black"
                    id={`smk${det.id}`}
                  >
                    <div dangerouslySetInnerHTML={{ __html: det.answer }} />
                  </div>
                </div>
                <img
                  id={`smk${det.id}img`}
                  onClick={() => {
                    showAnswer(`smk${det.id}`);
                  }}
                  className="arrowbottom"
                  src={arrowdown}
                  alt="arrow-down"
                />
              </div>
            </Fragment>
          );
        })}
      </>
    );
  };

  const forgotScreenRender = useMemo(() => {
    if (selectedScreen === "forgot") {
      return (
        <ForgotPassword
          setSelectedScreen={setSelectedScreen}
          openLoginPopup={openLoginPopup}
          setForgotResponse={setForgotResponse}
        />
      );
    } else if (selectedScreen === "verification") {
      return (
        <VerificationCode
          setSelectedScreen={setSelectedScreen}
          openLoginPopup={openLoginPopup}
          setForgotResponse={setForgotResponse}
          forgotResponse={forgotResponse}
        />
      );
    } else if (selectedScreen === "reset") {
      return (
        <ResetPassword
          setSelectedScreen={setSelectedScreen}
          openLoginPopup={openLoginPopup}
          forgotResponse={forgotResponse}
          setForgotResponse={setForgotResponse}
        />
      );
    } else if (selectedScreen === "success") {
      return (
        <SuccessPassword
          setSelectedScreen={setSelectedScreen}
          openLoginPopup={openLoginPopup}
        />
      );
    }
  }, [selectedScreen, forgotResponse]);

  return (
    <>
      <div className="vdtbody">
        {aboutYou && (
          <AboutYou
            closeAboutYouPopup={closeAboutYouPopup}
            openThankyouPopup={openThankyouPopup}
          />
        )}
        {emailVerification && (
          <EmailVerification
            openSignupPopup={openSignupPopup}
            closeEmailVerificationPopup={closeEmailVerificationPopup}
            openEnterPhonePopup={openEnterPhonePopup}
          />
        )}
        {loginverification && (
          <LoginVerification
            closeLoginVerification={closeLoginVerification}
            openLoginPopup={openLoginPopup}
            openEnterPhonePopup={openEnterPhonePopup}
            openAboutYouPopup={openAboutYouPopup}
            openThankyouPopup={openThankyouPopup}
          />
        )}
        {enterPhone && (
          <EnterPhone
            closeEnterPhonePopup={closeEnterPhonePopup}
            openPhoneVerificationPopup={openPhoneVerificationPopup}
            openAboutYouPopup={openAboutYouPopup}
            countries={countries}
          />
        )}
        {phoneVerification && (
          <PhoneVerification
            openEnterPhonePopup={openEnterPhonePopup}
            closePhoneVerificationPopup={closePhoneVerificationPopup}
            openAboutYouPopup={openAboutYouPopup}
          />
        )}
        {thankyou && <Thankyou closeThankyouPopup={closeThankyouPopup} />}
        {phoneVerification && (
          <PhoneVerification
            openEnterPhonePopup={openEnterPhonePopup}
            closePhoneVerificationPopup={closePhoneVerificationPopup}
            openAboutYouPopup={openAboutYouPopup}
          />
        )}
        {thankyou && <Thankyou closeThankyouPopup={closeThankyouPopup} />}
        {signupPopup && (
          <SignupPopup
            mixpanel={mixpanel}
            openLoginPopup={openLoginPopup}
            closeSignupPopup={closeSignupPopup}
            openEmailVerificationPopup={openEmailVerificationPopup}
            countries={countries}
          />
        )}
        {login && (
          <LoginPopup
            openLoginVerification={openLoginVerification}
            openEnterPhonePopup={openEnterPhonePopup}
            openAboutYouPopup={openAboutYouPopup}
            openThankyouPopup={openThankyouPopup}
            closeLoginPopup={closeLoginPopup}
            openSignupPopup={openSignupPopup}
            setSelectedScreen={setSelectedScreen}
          />
        )}
        {contactusPopup && (
          <ContactUsPopup
            closeContactusPopup={closeContactusPopup}
            countries={countries}
          />
        )}
        {useCasesPopup && (
          <UseCasesPopup
            closeUseCasesPopup={closeUseCasesPopup}
            openContactusPopup={openContactusPopup}
          />
        )}
        <NavComponent
          openLoginPopup={openLoginPopup}
          openSignupPopup={openSignupPopup}
          closeSignupPopup={closeSignupPopup}
          openContactusPopup={openContactusPopup}
          closeContactusPopup={closeContactusPopup}
          openUseCasesPopup={openUseCasesPopup}
          closeUseCasesPopup={closeUseCasesPopup}
          useCasesPopup={useCasesPopup}
        />
        <div className="privacy">
          <div className="prvformleft">
            <p
              id="ppp"
              className="prv_category webkit_primary_h typekit_LabelRegular"
              onClick={onCLickPP}
            >
              Privacy & Policy
            </p>
            <p
              id="toss"
              className="prv_category webkit_primary_h typekit_LabelRegular"
              onClick={onCLickToS}
            >
              Terms of Service
            </p>
          </div>
          <div className="prvformlefttab">
            <div className="prvfmleft">
              <p
                id="pppv"
                className="prv_category webkit_primary_h typekit_LabelRegular"
                onClick={onCLickPP}
              >
                Privacy & Policy
              </p>
              <p
                id="tossv"
                className="prv_category webkit_primary_h typekit_LabelRegular"
                onClick={onCLickToS}
              >
                Terms of Service
              </p>
            </div>
            <div className="prvfmright">
              <p className="typekit_h3 webkit_primary_black">
                {" "}
                {props.privacypolicy.heading[0].question}
              </p>
              <p className="pp_para typekit_BodyRegularMedium webkit_primary_black">
                {props.privacypolicy.heading[0].answer}
              </p>
            </div>
          </div>
          <div className="prvformright">
            <p className="typekit_h3 webkit_primary_black privacy_h">
              {" "}
              {props.privacypolicy.heading[0].question}
            </p>
            <p className="typekit_BodyRegularMedium webkit_primary_black privacy_p">
              {props.privacypolicy.heading[0].answer}
            </p>
            <div className="ppline"></div>
            {content}
          </div>
        </div>
        <Footer
               openContactusPopup={openContactusPopup}
               openSignupPopup={openSignupPopup}
                />
      </div>
      {forgotScreenRender}
    </>
  );
};
function map(state) {
  return state;
}
export default connect(map)(PrivacyPolicy);
