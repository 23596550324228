
const tempuser=(state=[],action)=>{
    switch(action.type){
      case 'TEMPUSER':
        return action.userData
        default:
          return state;
    }
  };
  export {tempuser}
  
  