import React , {useState , useEffect} from "react";
import { connect } from "react-redux";
import Ticker from 'react-ticker'




import Aos from 'aos';
import 'aos/dist/aos.css'; 
import './HTSlider.css';
import '../../AboutUs/about.css';


const HTSlider = (props) => {
    const [innerwdt , setinnerwdt] = useState(window.innerWidth);
    const [move , setMove] = useState(true);

    useEffect(() => {
        Aos.init();
        setinnerwdt(window.innerWidth);
        window.addEventListener('resize' , () => {
            setinnerwdt(window.innerWidth);
        })
    }, []);
    return (
        <>
        
        <div onMouseLeave={()=>{
            setMove(true)
        }} onMouseEnter={()=>{
            setMove(false)
        }}  className="HTSliderParent">
        <div className="htbefore">
        </div>
         <Ticker style={{ }} offset={0} mode={"chain"}  move={move} speed={5} direction="toRight">
        {() => (
                <div  style={{ display:'flex',
                flexDirection:'row',
                width:'auto',
                marginRight:100  }} className="HTSlider">
            
                <div className="HTB1 webkit_FreshBird">
                    <p className= { innerwdt > 1024 ?  "HTB1_txt_b typekit_LabelRegularBold webkit_DarkBird" : "HTB1_txt_b typekit_LabelSmall webkit_DarkBird"}>Live diagnostics</p>
                    <p className={innerwdt > 1024 ? "HTB1_txt_p typekit_LabelSmall webkit_primary_grey" : "HTB1_txt_p typekit_LabelLight webkit_primary_grey"}>Connect & monitor with a gadget</p>
                </div>
        
                <div className="HTB2 webkit_FreshPink">
                    <p className={ innerwdt > 1024 ?  "HTB1_txt_b typekit_LabelRegularBold webkit_PinkandRed" : "HTB1_txt_b typekit_LabelSmall webkit_PinkandRed"}>Online Doctor</p>
                    <p className={innerwdt > 1024 ? "HTB1_txt_p typekit_LabelSmall webkit_primary_grey" : "HTB1_txt_p typekit_LabelLight webkit_primary_grey"}>24/7 Live streaming</p>
                </div>
        
                <div className="HTB3 webkit_FreshYellow">
                    <p className={ innerwdt > 1024 ?  "HTB1_txt_b typekit_LabelRegularBold webkit_YellowDark" : "HTB1_txt_b typekit_LabelSmall webkit_YellowDark"}>Pharmacy</p>
                    <p className={innerwdt > 1024 ? "HTB1_txt_p typekit_LabelSmall webkit_primary_grey" : "HTB1_txt_p typekit_LabelLight webkit_primary_grey"}>Video advice</p>
                </div>
        
                <div className="HTB4 webkit_FreshGrey">
                    <p className={ innerwdt > 1024 ?  "HTB1_txt_b typekit_LabelRegularBold webkit_Blue1" : "HTB1_txt_b typekit_LabelSmall webkit_Blue1"}>Lab tests</p>
                    <p className={innerwdt > 1024 ? "HTB1_txt_p typekit_LabelSmall webkit_primary_grey" : "HTB1_txt_p typekit_LabelLight webkit_primary_grey"}>Interpretation & counselling</p>
                </div>
        
                <div className="HTB5 webkit_FreshGreen">
                    <p className={ innerwdt > 1024 ?  "HTB1_txt_b typekit_LabelRegularBold webkit_GreenGrass" : "HTB1_txt_b typekit_LabelSmall webkit_GreenGrass"}>Virtual healthcare conference</p>
                    <p className={innerwdt > 1024 ? "HTB1_txt_p typekit_LabelSmall webkit_primary_grey" : "HTB1_txt_p typekit_LabelLight webkit_primary_grey"}>Connect & engage</p>
                </div>
                </div>
         )} 

          </Ticker> 
        <div className="htafter">
        </div>
        </div>
        </>
    )
}

function map(state) {
    return state;
  }
  export default connect(map)(HTSlider);