import React, { useEffect , useState } from "react";
import { connect } from 'react-redux';
import keydown from './images/keydown.webp';
import cross from './images/x-circle.webp';
// import backarrow from './images/backarrow.webp';
import keyup from './images/keyup.webp';
import APIs from "../../APIs/API";
import './verification.css';
import mixpanel from "mixpanel-browser";

const AboutYou = (props) => {
const API_Object = new APIs();
const [typeofBusiness , setShowTypeofBusiness] = useState(false);
const [businessList , setBusinessList] = useState(null);
const [selectedBusinessID , setSelectedBusinessID] = useState('');
const [organizationName , setOrganizationName] = useState('');
const [selectedBusiness , setSelectedBusiness] = useState(null);
const [genericError , setGenericError] = useState('');
const HideMe = () => {    
   mixpanel.track("about_you_cross")  
    props.closeAboutYouPopup();
}
useEffect(()=> {
   
   var object = {
      'auth_token': props.user.auth_token,
   };
    API_Object.GetBusinessType(object , props.urls.apiBaseURL).then(data => {
       if(data.status == 200 && data.data.length >0){

          setBusinessList(data.data);
          setSelectedBusiness(data.data[0].business_type)
          setSelectedBusinessID(data.data[0].id)
         //  HideMe();
         //  props.openThankYouPopup();  
       }
       else{
       }
    })
}, []);
const submitTenantInfo = () => {
   mixpanel.track("Done_TenantInfo")
   let object = {
      'auth_token': props.user.auth_token,
      'organization_name':organizationName,
      'business_type':selectedBusinessID
   }
    API_Object.AddTenantInformation(object , props.urls.apiBaseURL).then(data => {
       if(data.status == 200){
         mixpanel.track('tenant_added');
          HideMe();
          props.openThankyouPopup();  
       }
         else{
            setGenericError(data.message);
            mixpanel.track('add_tenant_error');
          }
    })
}
return(
    <>
    <div>
        <div className="verification" id="verification">
            <div className="modal-verification abtyou" id="modal-verification">
            <img  
                    onClick={HideMe} className="x-cross-ver" src={cross} alt="cross-button" />
               <div className="sepodiv">
                <p className="verification-bold typekit_h3 webkit_primary_h">Tell us more about yourself</p>
                <p className="verification-normal typekit_BodyRegular webkit_primary_grey">Now that you’re all signed up, let’s personalize your experience. </p>
                  {genericError && (
                    <p className="errorMessage">{genericError}</p>
                  )}
                <div className="row3">
                    <div className="input-parent business">
                    <label className="ver-label typekit_LabelRegularBold webkit_primary_h">Type of Business?</label>
                    <div
                    onClick={(e) =>
                    {
                     mixpanel.track("type_of_business")
                    setShowTypeofBusiness(!typeofBusiness);
                    e.stopPropagation();
                    }}
                    className="ver-input input-password input-country input-signup full"
                    >
                    {selectedBusiness ? (
                    <div className="dropdowntext typekit_LabelRegular webkit_primary_black">
                       {selectedBusiness}
                    </div>
                    ) : (
                    <div>Loading...</div>
                    )}
                    <div>
                       {typeofBusiness ? 
                       <img src={keyup} className="dropIcon" /> :
                       <img src={keydown} className="dropIcon" />
                       }
                    </div>
                 </div>
                 {typeofBusiness && (
                    
                 <div
                    onClick={(e) =>
      
                    e.stopPropagation()}
                    className="dropdownBody"
                    >
                    <div className="countryScroller">
                       {businessList.length == 0 && (
                       <div
                          className="singleCountryContainer"
                          >
                          <div className="dropdowntext typekit_LabelRegular webkit_primary_black">No Result..</div>
                       </div>
                       )}
                       {businessList.map((item, index) => {
                       return (
                       <div
                          onClick={() =>
                          {
                              mixpanel.track("organization_name")
                            setSelectedBusiness(item.business_type);
                            setSelectedBusinessID(item.id);
                            setShowTypeofBusiness(false);
                          }}
                          key={index}
                          className="singleCountryContainer"
                          >
                          <div className="dropdowntext typekit_LabelRegular webkit_primary_black">
                             {item.business_type}
                          </div>
                       </div>
                       );
                       })}
                    </div>
                 </div>
                 )}
                    </div>
                    <div className="input-parent mbl-zero">
                    <label className="ver-label typekit_LabelRegularBold webkit_primary_h">Organization name</label>
                    <input
                    placeholder="Organization name" onKeyDown={(e) => {
                 if (e.key === "Enter") {
                  submitTenantInfo();
                 }
                 }} className="ver-input" value={organizationName}  onChange={(e) => setOrganizationName(e.target.value)} />
                    </div>
                </div>
                <div className="donebtn">
                <button onClick={submitTenantInfo} className="ver-button">Done</button>
                </div>
                </div>
                {/* <div className="back-row">
                   <img className="backarrow" src={backarrow} alt="back-arrow" />
                   <p className="backtext">Back</p>
                </div> */}
            </div>        
        </div>
    </div>
    </>
 )
};

function map(state) {
   return state;
 }
 export default connect(map)(AboutYou);

