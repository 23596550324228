import React , {useState , useEffect} from "react";
import { connect } from "react-redux";
import img from '../../../assets/images/deniel.png';
import Star from '../../../assets/images/Star.png';
import mixpanel from "mixpanel-browser";
import LSlabels from '../../../assets/images/lslabels.webp';
import lsphone from '../../../assets/images/lsphone.webp';
import lsphonetab from '../../../assets/images/lsphonetab.webp';
import lsphonembl from '../../../assets/images/lsphonembl.webp';
import Aos from 'aos';
import 'aos/dist/aos.css'; 
import './LiveShoppingHeader.css';
import '../../AboutUs/about.css';


const LiveShoopingHeader = (props) => {
    const [innerwdt , setinnerwdt] = useState(window.innerWidth);
    useEffect(() => {
        Aos.init();
        setinnerwdt(window.innerWidth);
        window.addEventListener('resize' , () => {
            setinnerwdt(window.innerWidth);
        })
    }, []);
    return (
        <>
            <div className="LSheaderComp">
                <div className="LSheaderchild1" data-aos="zoom-in-left">
                        { innerwdt > 300 ? 
                        <p className="typekit_h1 webkit_primary_black LSh1bp">
                            Convert & retain with Live Shopping
                        </p> :
                        <p className="typekit_h1 webkit_primary_black LSh1bp">
                            Convert & retain with Live Shopping
                        </p>             
                        }
                        {innerwdt > 1024 ? 
                        <p className= "typekit_subheadline1 webkit_primary_black LShdrdesc LSh1p2">
                            Provide a deeply engaging shopping experience <br></br>and supercharge your sales by building <br></br>your own interactive live shopping solution. 
                        </p> :
                        <p className="typekit_subheadline1 webkit_primary_black LShdrdesc LSh1p2" >
                            Provide a deeply engaging shopping experience and supercharge your sales by building your own interactive live shopping solution. 
                        </p>
                        }
                        
                    <div className="LSheaderbtns LScmp1">
                        <div className="LS1stbuttondiv LSfstbtndiv">
                            <button
                                type="button"
                                className=" Darkbluebtn LSjoinfree"
                                // onClick={()=> {
                                //     mixpanel.track("try_for_free");
                                //     props.openSignupPopup()}
                                //
                                // }
                                style={{cursor:"pointer"}}
                                >
                            start building now
                            </button>
                        </div>
                    </div>
                    <div className="LSratingsdiv">
                        <img src={img} alt="person" className="LSperson-img" />
                        <div>
                            <p className="LSratingtextbold">Daniel</p>
                            <p className="LSratingtextnormal">CEO, Togee AB</p>
                            <img className="LSratingstars" src={Star} alt="rating-stars" />
                        </div>
                    </div>
                </div>
                <div className="LSheaderchild2" data-aos="zoom-in-right">
         

                    <div className="bgbtns">
                        
                        <div className="lsrow1">
                            <div className="lsbtr typekit_LabelThin webkit_Darkmoon webkit_Freshmoon">Makeup tutorials</div>
                            <div className="lsbtr typekit_LabelThin webkit_BlueSky webkit_FreshSky">Product demo</div>
                            <div className="lsbtr typekit_LabelThin webkit_PinkandRed webkit_FreshPink">Shoppable video</div>
                            <div className="lsbtr typekit_LabelThin webkit_FreshGrey webkit_Blue1">Influencer selling  </div>
                            <div className="lsbtr typekit_LabelThin webkit_FreshYellow webkit_YellowDark">Live fashion show  </div>
                        </div>
                        <div className="lsrow2" >
                            <div className="lsbtr typekit_LabelThin webkit_DarkBird webkit_FreshBird">Makeup tutorials</div>
                            <div className="lsbtr typekit_LabelThin webkit_FreshYellow webkit_YellowDark">Product demo</div>
                            <div className="lsbtr typekit_LabelThin webkit_PinkandRed webkit_FreshPink">Shoppable video</div>
                            <div className="lsbtr typekit_LabelThin webkit_Darkmoon webkit_Freshmoon">Influencer selling  </div>
                            <div className="lsbtr typekit_LabelThin webkit_BlueSky webkit_FreshSky"> Live fashion show  </div>
                        </div>
                        <div className="lsrow3">
                            <div className="lsbtr typekit_LabelThin webkit_Darkmoon webkit_Freshmoon">Makeup tutorials</div>
                            <div className="lsbtr typekit_LabelThin webkit_PinkandRed webkit_FreshPink">Product demo</div>
                            <div className="lsbtr typekit_LabelThin webkit_FreshYellow webkit_YellowDark">Shoppable video</div>
                            <div className="lsbtr typekit_LabelThin webkit_BlueSky webkit_FreshSky">Influencer selling  </div>
                            <div className="lsbtr typekit_LabelThin webkit_DarkBird webkit_FreshBird">Live fashion show  </div>
                        </div>
                        <div className="lsrow4">
                            <div className="lsbtr typekit_LabelThin ebkit_BlueSky webkit_FreshSky">Makeup tutorials</div>
                            <div className="lsbtr typekit_LabelThin webkit_FreshGrey webkit_Blue1">Product demo</div>
                            <div className="lsbtr typekit_LabelThin webkit_Darkmoon webkit_Freshmoon">Shoppable video</div>
                            <div className="lsbtr typekit_LabelThin webkit_DarkBird webkit_FreshBird">Influencer selling  </div>
                            <div className="lsbtr typekit_LabelThin webkit_PinkandRed webkit_FreshPink">Live fashion show  </div>
                        </div>
                        <div className="lsrow5">
                            <div className="lsbtr typekit_LabelThin webkit_FreshGrey webkit_Blue1">Makeup tutorials</div>
                            <div className="lsbtr typekit_LabelThin webkit_PinkandRed webkit_FreshPink">Product demo</div>
                            <div className="lsbtr typekit_LabelThin webkit_FreshYellow webkit_YellowDark">Shoppable video</div>
                            <div className="lsbtr typekit_LabelThin webkit_Darkmoon webkit_Freshmoon">Influencer selling  </div>
                            <div className="lsbtr typekit_LabelThin webkit_PinkandRed webkit_FreshPink">Live fashion show  </div>
                        </div>
                    </div>
                     {/* <img src={LSlabels} className="lsring" alt="phone-image" /> */}
                     {innerwdt > 1024 ?
                     <img src={lsphone} className="lsphone" alt="phone" /> : innerwdt > 500 ?
                     <img src={lsphonetab} className="lsphonetab" alt="phone" /> :
                     <img src={lsphonembl} className="lsphonembl" alt="phone" /> }
                    {/* <img src={svg} className="ring" alt="phone-image" />
                    <img className="phonetab" src={Phonetab} alt="phone-img" />
                    <img className="phonembl" src={Phonembl} alt="phone-img" /> */}
                    {innerwdt < 500 ?
                    <div className="LSheaderbtns LScmp1show">
                    <div className="LS1stbuttondiv LSfstbtndiv">
                        <button
                            type="button"
                            className=" Darkbluebtn LSjoinfree"
                            // onClick={()=> {
                            //     mixpanel.track("try_for_free");
                            //     props.openSignupPopup()}
                            //
                            // }
                            style={{cursor:"pointer"}}
                            >
                        start building now 
                        </button>
                    </div>
                </div> :
                <></>}
                </div>
            </div>
        </>
    )
}

function map(state) {
    return state;
  }
  export default connect(map)(LiveShoopingHeader);