import React, { useCallback, useEffect, useState } from "react";
import AOS from "aos";
import { useNavigate } from "react-router-dom";
import Toggle from "../../assets/images/toggle.svg";
import yellowcircle from "../../assets/images/yellowcircle.png";
import Ellipse from "../../assets/images/Ellipse.png";
import girl from "../../assets/images/girl.png";
import Group from "../../assets/images/Group.svg";
// import messagegirl from '../../assets/images/messagegirl.png';
import messagegirl from "../../assets/images/Img.webp";
// import newexp from '../../assets/images/newexp.png';
import newexp from "../../assets/images/plus-many-more.webp";

// import colorphone from '../../assets/images/colorphone.png';
import colorphone from "../../assets/images/Phone.webp";
import colorphonembl from "../../assets/images/colorphonembl.webp";

import newexpmbl from "../../assets/images/newexpmbl.webp";
import newexptab from "../../assets/images/newexptab.webp";
// import messagegirltab from '../../assets/images/messagegirl-tab.png';
// import messagegirlmbl from '../../assets/images/messagegirl-mbl.png';
import messagegirltab from "../../assets/images/messagegirltab.webp";
import messagegirlmbl from "../../assets/images/messagegirlmbl.webp";
import "aos/dist/aos.css";
import { connect } from "react-redux";
import mixpanel from "mixpanel-browser";
import "./slider.css";

const SliderComponent = (props) => {
  useEffect(() => {
    AOS.init();
  }, []);
  const navigate = useNavigate();
  const [checkedIn, setCheckedIn] = useState(false);
  const [innerwdt, setinnerwdt] = useState(window.innerWidth);
  useEffect(() => {
    setinnerwdt(window.innerWidth);
    window.addEventListener("resize", () => {
      setinnerwdt(window.innerWidth);
    });
  }, []);
  const reverse = (encoded) => {
    var newString = "";
    for (var i = encoded.length - 1; i >= 0; i--) {
      newString += encoded[i];
    }
    return newString;
  };
  const setCookie = (cname, cvalue, exdays) => {
    let encoded = btoa(cvalue);
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    if (window.location.href.includes(".dev")) {
      // document.cookie=`user=; Expires=Fri, 5 Oct 2018 14:28:00 GMT;path=/;Domain=vdotok.dev;SameSite=Strict`;
      document.cookie =
        cname +
        "=" +
        reverse(encoded) +
        ";" +
        expires +
        ";path=/;Domain=vdotok.dev;SameSite=Strict";
    } else {
      document.cookie =
        cname +
        "=" +
        reverse(encoded) +
        ";" +
        expires +
        ";path=/;Domain=vdotok.com;SameSite=Strict";
      // document.cookie=`user=; Expires=Fri, 5 Oct 2018 14:28:00 GMT;path=/;Domain=vdotok.com;SameSite=Strict`;
    }
    // document.cookie = cname + "=" + reverse(encoded) + ";" + expires + ";path=/;Domain=vdotok.dev;SameSite=Strict";
  };
  useEffect(() => {
    if (Object.keys(props.userdone).length == 0) {
      setCheckedIn(false);
    } else {
      setCheckedIn(true);
    }
  }, [props]);
  const onCLickFAQ = useCallback(
    () => navigate("/FAQ", { replace: true }),
    [navigate]
  );
  return (
    <>
      <div className="mainSliderDiv">
        <div className="toggles">
          <div className="toggle">
            <img className="imgtoggle" src={Toggle} alt="toggle-button" />
            <p
              className={
                innerwdt > 1024
                  ? "texttoggle eltspace webkit_primary_h typekit_LabelXL"
                  : innerwdt > 600 && innerwdt <= 1024
                  ? "texttoggle eltspace webkit_primary_black typekit_LabelLarge"
                  : "texttoggle eltspace webkit_primary_black typekit_LabelSmall"
              }
            >
              Smooth
            </p>
          </div>
          <div className="toggle">
            <img className="imgtoggle" src={Toggle} alt="toggle-button" />
            <p
              className={
                innerwdt > 1024
                  ? "texttoggle eltspace webkit_primary_h typekit_LabelXL"
                  : innerwdt > 600 && innerwdt <= 1024
                  ? "texttoggle eltspace webkit_primary_black typekit_LabelLarge"
                  : "texttoggle eltspace webkit_primary_black typekit_LabelSmall"
              }
            >
              Scalable
            </p>
          </div>
          <div className="toggle">
            <img className="imgtoggle" src={Toggle} alt="toggle-button" />
            <p
              className={
                innerwdt > 1024
                  ? "texttoggle eltspace webkit_primary_h typekit_LabelXL"
                  : innerwdt > 600 && innerwdt <= 1024
                  ? "texttoggle eltspace webkit_primary_black typekit_LabelLarge"
                  : "texttoggle eltspace webkit_primary_black typekit_LabelSmall"
              }
            >
              Secure
            </p>
          </div>
        </div>
        <div className="SliderTextbolddiv animate__animated animate__bounce">
          <p className="textBold typekit_h2 webkit_primary_black">
            Why integrating live media with VdoTok?
          </p>
          <p className="textnarrow typekit_subheadline2 webkit_primary_black">
            Enter a new, post-pandemic era with your business. Together with us.
          </p>
        </div>
        <div className="section" data-aos="zoom-in-left">
          <div className="sectionheading">
            <img className="slidercircle" src={yellowcircle} alt="numbering" />
            <p className="numbering typekit_BodyElevated webkit_primary_h">1</p>
            <p
              style={{ whiteSpace: "nowrap" }}
              className="heading typekit_h6 webkit_primary_black"
            >
              Messaging is old...
            </p>
          </div>
          <div className="sectionimg" data-aos="zoom-in">
            {/* <img className="ellipse" src={Ellipse} /> */}

            <img className="girl girl1" src={messagegirl} alt="girl-image" />
            <img
              className="girl girl1tab"
              src={messagegirltab}
              alt="girl-image"
            />
            <img
              className="girl girl1mbl"
              src={messagegirlmbl}
              alt="girl-image"
            />
          </div>
          <div className="sectioncontent">
            <p className="sliderhide slidernarrowtext typekit_BodySmallRegular webkit_primary_grey">
              What have we learned during the pandemic?
            </p>
            <p className="sliderboldtext typekit_h3 webkit_primary_black">
              Messaging is so 2020
            </p>
            <p className="slidernarrowtext typekit_BodySmallRegular webkit_primary_grey eltspace">
              It’s time to say goodbye to chat bots, decision trees and live
              chat queues.
            </p>
            <div className="reverseflex">
              <div className="flexslider">
                <div className="1stbuttondiv">
                  <button
                    type="button"
                    className="Darkbluebtn explore "
                    onClick={() => {
                      mixpanel.track("explore_alternatives");
                      document
                        .getElementById("stage2")
                        .scrollIntoView({ behavior: "smooth" });
                    }}
                  >
                    EXPLORE ALTERNATIVES
                  </button>
                </div>
                <div className="secondbuttondiv sliderc1 ">
                  <button
                    type="button"
                    className="workingbtn Whitebtn"
                    // onClick={() => {
                    //   mixpanel.track("doc_and_tool");
                    //   window.open(`https://${props.urls.docURL}`);
                    // }}
                  >
                    HOW DOES IT WORK
                  </button>
                </div>
              </div>
              <p id= {innerwdt >= 500 ? "stage2" : ""} 
                className="sliderdescription webkit_primary_black typekit_BodyRegular"
                
              >
                Work, shopping, seeing a doctor, training... The pandemic has
                pushed us to do many things online. Today a quick, personal and
                engaging live experience with your product or service has
                transitioned from “a nice to have” to “a must”.
              </p>
            </div>
          </div>
        </div>
        <div id= {innerwdt < 500 ? "stage2" : ""} className="section stagetwo" data-aos="zoom-in-right">
          <div className="sectionheading">
            <img className="slidercircle" src={yellowcircle} alt="numbering" />
            <p className="numbering typekit_BodyElevated webkit_primary_h">2</p>
            <p className="heading typekit_h6 webkit_primary_black">
              What we offer
            </p>
          </div>
          <div  className="sectioncontent">
            <p className=" sliderhide slidernarrowtext typekit_BodySmallRegular webkit_primary_grey">
              We know what’s trending
            </p>
            <p  className="sliderboldtext typekit_h3 webkit_primary_black">
              Time for live experience
            </p>
            <p className="slidernarrowtext typekit_BodySmallRegular webkit_primary_grey eltspace">
              Integrate live streaming, screen sharing, video and voice calls
              into your app or product.
            </p>
            <p className="sliderdescription st2 webkit_primary_black typekit_BodyRegular">
              VdoTok's mission is to accelerate the world's transition from
              messaging/text to live media by making it easy, quick and
              inexpensive for any business.
            </p>
            <div className="slidergrp">
              <img className="grpimg" src={Group} alt="group-image" />
              <p className="sliderdescription typekit_LabelRegularBold webkit_primary_black MSP">
                Multiplay service provider
              </p>
            </div>
            <div className="headerbtns secsliderbtns">
              <div className="1stbuttondiv">
                {!checkedIn ? (
                  <button
                    type="button"
                    className="s2btnblue Darkbluebtn"
                    // onClick={() => {
                    //   mixpanel.track("try_for_free");
                    //   props.openSignupPopup();
                    // }}
                    style={{ cursor: "pointer" }}
                  >
                    TRY FOR FREE
                  </button>
                ) : (
                  <button
                    type="button"
                    className="s2btnblue Darkbluebtn"
                    onClick={() => {
                      mixpanel.track("dashboard");
                      setCookie("user", props.user.auth_token, 4);
                      props.dispatch({
                        type: "USERDONE",
                        userData: props.user,
                      });
                      window.open(
                        `https://${props.urls.adminPanelURL}/overview`
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    DASHBOARD
                  </button>
                )}
              </div>
              <div className="">
                <button
                  type="button"
                  className="s2btnwhite Whitebtn"
                  // onClick={() => {
                  //   mixpanel.track("doc_and_tool");
                  //   window.open(`https://${props.urls.docURL}`);
                  // }}
                >
                  HOW DOES IT WORK
                </button>
              </div>
            </div>
          </div>
          <div className="sectionimg">
            {/* <img className="ellipse" src={Ellipse} /> */}
            <img className="girl girl2" src={newexp} alt="girl-image" />
            <img className="girl girl2tab" src={newexptab} alt="girl-image" />
            <img className="girl girl2mbl" src={newexpmbl} alt="girl-image" />
          </div>
        </div>
        <div className="section sectionthree " data-aos="zoom-in-left">
          <div className="sectionheading">
            <img className="slidercircle" src={yellowcircle} alt="numbering" />
            <p className="numbering typekit_BodyElevated webkit_primary_h">3</p>
            <p
              style={{ whiteSpace: "nowrap" }}
              className="heading typekit_h6 webkit_primary_black "
            >
              Join live revolution
            </p>
          </div>
          <div className="sectionimg">
            {/* <img className="ellipse" src={Ellipse} /> */}
            {innerwdt > 1024 ? (
              <img className="girl girl3" src={colorphone} alt="color-phone" />
            ) : (
              <img
                className="girl girl3"
                src={colorphonembl}
                alt="color-phone"
              />
            )}
          </div>
          <div className="sectioncontent">
            <p className="sliderhide slidernarrowtext typekit_BodySmallRegular webkit_primary_grey">
              Welcome to the new era
            </p>

            <p className="sliderboldtext typekit_h3 webkit_primary_black">
              Become a trendsetter
            </p>

            <p className="slidernarrowtext typekit_BodySmallRegular webkit_primary_grey eltspace">
              Open the world of live media & web3 and bring your customers to a
              new engaging reality
            </p>
            <div className="reverseflex">
              <div className="headerbtns">
                <div className="1stbuttondiv">
                  <button
                    type="button"
                    className="s3btnblue Darkbluebtn "
                    // onClick={() => {
                    //   props.openSignupPopup();
                    // }}
                  >
                    TRY FOR FREE
                  </button>
                </div>
              </div>
              <p className="_st3 sliderdescription webkit_primary_black typekit_BodyRegular">
                In-built live streaming, video & voice calls are just a
                beginning. Our R&D department is already developing solutions
                for connected cities, cars & Metaverse. Become a part of this
                journey and make sure that your product is always at the cutting
                edge of technology.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function map(state) {
  return state;
}
export default connect(map)(SliderComponent);
