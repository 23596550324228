import React, { useEffect, useState } from "react";
import keydown from "./images/keydown.webp";
import { connect } from "react-redux";
import keyup from "./images/keyup.webp";
import cross from "./images/x-circle.webp";
// import backarrow from './images/backarrow.webp';
import axios from "axios";
import APIs from "../../APIs/API";
import "./verification.css";
import mixpanel from "mixpanel-browser";

const EnterPhone = (props) => {
  // console.log("data", props);
  useEffect(() => {
    // console.log("scrollingg 7");

    window.scrollTo(0, 0);
  }, []);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [showCountryDropDown, setShowCountryDropDown] = useState(false);
  const [val1, setval1] = useState();
  const [val2, setval2] = useState();
  const [searchString, setSearchString] = useState("");
  const [genericError, setGenericError] = useState("");
  const [numberError, setNumberError] = useState("");
  useEffect(() => {
    console.log("countriesssss" , props.countries);
    axios
      .get("https://extreme-ip-lookup.com/json/?key=gGJby1xemMxbBa2pfT2S")
      .then((response) => {
        let data = response.data;
        if (data.countryCode) {
          let specificCountry = props.countries.filter(
            (e) => e.code === data.countryCode
          );
          if (specificCountry) {
            setSelectedCountry(specificCountry[0]);
          } else {
            setSelectedCountry(props.countries[0]);
          }
        }
      })
      .catch((error) => {});
  }, []);
  const containsSpecialChars = (str) => {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    return specialChars.test(str);
  };

  const sendCode = () => {
    setGenericError("");
    const phoneInputField = document.querySelector(".iti__selected-flag");

    // phoneInputField.addEventListener('click' , (e) => {

    //    console.log('eeeee' , e.currentTarget)

    // })

    let validate = false;

    // if (val2 > 0 ) {
    //   // validate = true;
    //   console.log("no value")
    //   setNumberError("Please enter value");
    // }
    if (val2 > 0 && !containsSpecialChars(val2)) {
      validate = true;
      document.getElementById("sendcodebtn").disabled = true;
    } else if (containsSpecialChars(val2)) {
      setNumberError("Please Remove special characters");
    } else {
      setNumberError("Please Enter VALID Contact Number.");
    }

    var object = {
      auth_token: props.user.auth_token,

      dial_code: phoneInputField.title.split(": ")[1],

      country_code: phoneInputField.firstChild.className.split("iti__")[2],

      phone_number: val2,
    };

    var obb = {
      phone_number: val2,
    };

    if (validate) {
      API_Object.EnterPhoneNumber(object, props.urls.apiBaseURL).then(
        (data) => {
          if (data.status == 200) {
            document.getElementById("sendcodebtn").disabled = true;

            props.dispatch({ type: "TEMPUSER", userData: obb });

            HideMe();

            props.openPhoneVerificationPopup();

            window.localStorage.setItem(
              "number",
              selectedCountry.dial_code + val2
            );
            window.localStorage.setItem(
              "country_code",
              phoneInputField.firstChild.className.split("iti__")[2]
            );

            // console.log("country code set--->");

            mixpanel.track("phone_number_added");
          } else {
            setval2("");

            setGenericError(data.message);
            document.getElementById("sendcodebtn").disabled = false;

            mixpanel.track("phone_number_error");
          }
        }
      );
    }
  };
  useEffect(() => {
    if (Object.keys(props.tempuser).length > 0) {
      setval2(props.tempuser.phone_number);
    }
  }, [props]);
  const HideMe = () => {
    props.closeEnterPhonePopup();
  };
  const API_Object = new APIs();
  useEffect(() => {
    // API_Object.getme();
  });
  const OpenNext = () => {
    mixpanel.track("skip_on_PhoneScreen");
    HideMe();
    props.openAboutYouPopup();
  };
  let countriesList =
    searchString.trim().length === 0
      ? props.countries
      : props.countries.filter((e) =>
          e.name.toLowerCase().startsWith(searchString.toLowerCase())
        );

  return (
    <>
      <div>
        <div className="verification">
          <div
            id="modal-verification"
            className="modal-verification phone enterphone"
          >
            <img
              onClick={HideMe}
              className="x-cross-ver"
              src={cross}
              alt="cross-button"
            />
            <div className="sepodiv">
              <p className="verification-bold typekit_h3 webkit_primary_h">
                Verify your phone number
              </p>
              <p className="verification-normal typekit_BodyRegular webkit_primary_grey ">
                To receive <b className="normal-b">15,000 free minutes</b>, you
                must verify your phone number. You can skip this step if you
                would like to look around first and verify your phone number
                later from the dashboard.
              </p>
              {genericError && <p className="errorMessage">{genericError}</p>}
              <div className="rowphone">
                <div className="input-parent-email">
                  <label className="ver-label typekit_LabelRegularBold webkit_primary_h">
                    Phone number
                  </label>
                  {numberError && (
                    <p className="errorMessage start">{numberError}</p>
                  )}
                  <div className="input-sep">
                    {/*                      
                     {selectedCountry ? 
                     <div id="val1"  className="ver-input val1"  >
                       <img
                          src={`https://flagcdn.com/28x21/${selectedCountry.code.toLowerCase()}.png`}
                          className="dropFlag"
                          alt={selectedCountry.code}
                          />{" "}
                       {selectedCountry.dial_code}
                    </div> : 
                    <p className="ver-input val1">...</p>
                     } */}
                    <div
                      style={{
                        height: 250,
                        width: "100%",
                        position: "absolute",
                        top: 47,
                        // zIndex: "9999"
                        // pointerEvents: "none",
                      }}
                      id="inpsum"
                    ></div>
                    <input
                      minLength={5}
                      maxLength={13}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          sendCode();
                        }
                      }}
                      type="tel"
                      id="val2"
                      className="ver-input val2"
                      value={val2}
                      onChange={(e) => {
                        // changeEvent(e);
                        setGenericError("");
                        setNumberError("");
                        setval2(e.target.value.trim());
                      }}
                    />
                  </div>
                </div>
                <div style={{ zIndex: "1" }} className="button-parent">
                  <button
                    onClick={() => {
                      sendCode();
                    }}
                    className="phone-button Darkbluebtn"
                    id="sendcodebtn"
                  >
                    SEND CODE
                  </button>
                </div>
              </div>
              <div className="rowskip">
                <p
                  onClick={OpenNext}
                  className="typekit_LabelRegularBold webkit_primary_grey"
                  style={{ cursor: "pointer", zIndex: "1" }}
                >
                  Skip for now
                </p>
              </div>
            </div>
            {/* <div className="back-row">
                   <img className="backarrow" src={backarrow} alt="back-arrow" />
                   <p className="backtext">Back</p>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

function map(state) {
  return state;
}
export default connect(map)(EnterPhone);
