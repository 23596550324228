import React, { useEffect, useState, useRef } from "react";
import cross from "./images/x-circle.webp";
import APIs from "../../APIs/API";
import backarrow from "./images/backarrow.webp";
import { connect } from "react-redux";
import codesentimg from "./images/codesent.webp";

// import emailver from  './images/emailverification.webp';
import bellicon from "./images/bellicon.webp";
import ReCAPTCHA from "react-google-recaptcha";
import "./verification.css";
import mixpanel, { reset } from "mixpanel-browser";
const EmailVerification = (props) => {
  const Ref = useRef(null);
  let API_Object = new APIs();
  useEffect(() => {
    // console.log("scrollingg 6");

    window.scrollTo(0, 0);
    clearTimer();
  }, []);

  const [val1, setval1] = useState("");
  const [val2, setval2] = useState("");
  const [val3, setval3] = useState("");
  const [val4, setval4] = useState("");
  const [attempts, setAttempts] = useState(0);
  const [codeSent, setCodeSent] = useState(false);
  const [timerDiv, setTimerDiv] = useState(false);
  const [verificationSt, setVerificationst] = useState(true);
  const [blockDiv, setBlockDiv] = useState(false);

  const [genericError, setGenericError] = useState("");
  const [timer, setTimer] = useState("00:00");
  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());

    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor(((total / 1000) * 60 * 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };
  useEffect(() => {
    console.log("attempt count", attempts);
    if (attempts > 9) {
      setTimeout(() => {
        if (document.getElementById("el_tm_ver")) {
          document.getElementById("el_tm_ver").style.display = "none";
        }
      }, 300);
    }
  }, [attempts]);
  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        // (minutes > 9 ? minutes : "0" + minutes) +
        //   ":" +
        seconds > 9 ? seconds : "0" + seconds
      );
    }
    if (total == 0) {
      setVerificationst(true);
      setTimerDiv(false);
    }
  };
  function onChange(value) {
    if (value) {
      enableCode();
      setval1("");
      setval2("");
      setval3("");
      setval4("");
      document.getElementById("val1").focus();
      diableRecaptcha();
    }
  }
  const EnableRecaptcha = () => {
    mixpanel.track("recaptcha_enabled");
    document.getElementById("recaptcha").style.display = "flex";
  };
  const diableRecaptcha = () => {
    document.getElementById("recaptcha").style.display = "none";
  };
  const clearTimer = (e) => {
    setTimer("59");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 60);
    return deadline;
  };

  const onClickReset = () => {
    clearTimer(getDeadTime());
  };
  const updateErrordesign = () => {
    document.querySelectorAll(".blockk").forEach((item) => {
      item.style.border = "1px solid #EB5545";

      // item.style.background= "rgba(253, 238, 236, 0.1)";
    });
    document.querySelectorAll(".count").forEach((item) => {
      // item.addEventListener("focus" , function(){
      //    item.style.border="1px solid #EB5545";
      //    item.style.boxSizing="border-box";
      //    item.style.borderRadius="10px";
      //    item.style.fontStyle="normal";
      //    item.style.outline="none";
      //    item.style.height="100%";
      //    item.style.background= "white";
      //    item.style.textDecoration="underline";
      //    item.style.color= "#EB5545";
      // });
      // item.style.textDecoration="none";
      // item.style.border="1px solid #EB5545";
      item.style.color = "#EB5545";
    });
    setTimeout(() => {
      resetColor();
      setval1("");
      setval2("");
      setval3("");
      setval4("");
      document.getElementById("val1").focus();
    }, 1000);
  };
  const resetColor = () => {
    document.querySelectorAll(".blockk").forEach((item) => {
      item.style.border = "1px solid #E2E2EA";
    });
    document.querySelectorAll(".count").forEach((count) => {
      count.style.color = "#222254";
      // count.style.border = "1px solid #E2E2EA";
      // count.style.textDecoration="none";
      // count.addEventListener("focus" , function(){
      //    count.style.background= "#F5F6FA";
      //    count.style.color= "#E2E2EA";
      //    count.style.outline="none";
      //    count.style.border="1px solid #190354";
      //    count.style.textDecoration="underline";
      //    count.style.boxSizing="border-box";
      //    count.style.borderRadius="10px";
      //    count.style.fontStyle="normal";
      //    count.style.outline="none";
      //    count.style.height="100%";

      // });
    });
    setGenericError("");
  };
  const disableCode = () => {
    document.getElementById("val1").disabled = true;
    document.getElementById("val2").disabled = true;
    document.getElementById("val3").disabled = true;
    document.getElementById("val4").disabled = true;
    resetColor();
    document.querySelectorAll(".count").forEach((item) => {
      item.style.background = "white";
    });
  };
  const disableblockCode = () => {
    mixpanel.track("account_blocked");
    disableCode();
    setval1("");
    setval2("");
    setval3("");
    setval4("");
    document.querySelectorAll(".blockk").forEach((item) => {
      item.style.background = "rgba(253, 238, 236, 0.1)";
      item.style.border = "1px solid #EB5545";
    });
    document.querySelectorAll(".count").forEach((item) => {
      item.style.background = "rgba(253, 238, 236, 0.1)";
    });
  };
  const enableCode = () => {
    document.getElementById("val1").disabled = false;
    document.getElementById("val2").disabled = false;
    document.getElementById("val3").disabled = false;
    document.getElementById("val4").disabled = false;
  };
  const verify = (object) => {
    mixpanel.track("verify_Email");
    document.getElementById("focuss").focus();
    document.getElementById("val4").blur();

    API_Object.EmailVerification(object, props.urls.apiBaseURL).then((data) => {
      setAttempts(data.total_attempts);
      if (
        data.status === 200 &&
        data.total_attempts <= 9 &&
        data.total_attempts !== 3
      ) {
        mixpanel.track("email_verification");
        props.dispatch({ type: "TEMPUSER", userData: "" });
        props.dispatch({ type: "USER", userData: data });
        document.getElementById("val4").blur();
        HideMe();
        props.openEnterPhonePopup();
        return;
      } else if (data.total_attempts == 3) {
        if (data.status === 200) {
          mixpanel.track("email_verification");
          props.dispatch({ type: "TEMPUSER", userData: "" });
          props.dispatch({ type: "USER", userData: data });
          document.getElementById("val4").blur();
          HideMe();
          props.openEnterPhonePopup();
        } else {
          EnableRecaptcha();
          document.getElementById("val4").blur();
          disableCode();
        }
      } else if (data.total_attempts > 9) {
        setBlockDiv(true);
        setVerificationst(false);
        disableblockCode();
        setTimerDiv(false);
        setCodeSent(false);
        setGenericError(false);
        document.getElementById("backrow").style.display = "none";
        document.getElementById("val4").blur();
        if (document.getElementById("el_tm_ver")) {
          document.getElementById("el_tm_ver").style.display = "none";
        }

        // setGenericError(false);
        // setTimeout(() => {
        //    HideMe();
        // }, 3000);
      } else {
        mixpanel.track("email_verification_error");
        updateErrordesign();
        setGenericError("Incorrect Code");
        document.getElementById("val4").blur();
        document.getElementById("focuss").focus();
        // setval1(''); setval2(''); setval3(''); setval4('');
        // document.getElementById('val1').focus();
      }
    });
  };
  useEffect(() => {
    document.getElementById("val1").focus();
    document.getElementById("val4").blur();
    document.getElementById("recaptcha").style.display = "none";
  }, []);
  useEffect(() => {
    if (val1.length == 1) {
      document.getElementById("val2").focus();
    }
    if (val2.length == 1) {
      document.getElementById("val3").focus();
    }
    if (val3.length == 1) {
      document.getElementById("val4").focus();
    }
    if (val4.length == 1) {
      document.getElementById("val4").style.outline = "none";
    }
    if (
      val1.trim().length > 0 &&
      val2.trim().length > 0 &&
      val3.trim().length > 0 &&
      val4.trim().length > 0
    ) {
      var object = {
        email: props.tempuser.email,
        verification_token: val1 + val2 + val3 + val4,
      };
      verify(object);
    }
  }, [val1, val2, val3, val4]);
  const backpage = () => {
    mixpanel.track("back_button_clicked");
    HideMe();
    props.openSignupPopup();
  };
  const Resend = () => {
    mixpanel.track("resend_button_clicked");
    if (attempts < 9) {
      setCodeSent(true);
      setVerificationst(false);
      setval1("");
      setval2("");
      setval3("");
      setval4("");
      document.getElementById("val1").focus();
      resetColor();

      setCodeSent(false);
      onClickReset();
      startTimer();
      setTimerDiv(true);

      let object = {
        email: props.tempuser.email,
      };
      API_Object.Resend(object, props.urls.apiBaseURL).then((data) => {
        // console.log("TTEPTS", data);
        setAttempts(data.total_attempts);

        if (
          data.status === 200 &&
          data.total_attempts <= 9 &&
          data.total_attempts !== 3
        ) {
          // console.log("TTEPTS 1", data);
          mixpanel.track("resend_email_code");
        } else if (data.total_attempts === 3) {
          // console.log("TTEPTS 2", data);
          EnableRecaptcha();
          disableCode();
        } else if (data.total_attempts > 9) {
          setBlockDiv(true);
          setVerificationst(false);
          disableblockCode();
          setTimerDiv(false);
          setCodeSent(false);
          setGenericError(false);
          document.getElementById("backrow").style.display = "none";
          document.getElementById("val4").blur();
          document.getElementById("backrow").style.display = "none";
          if (document.getElementById("el_tm_ver")) {
            document.getElementById("el_tm_ver").style.display = "none";
          }

          // setTimeout(() => {
          //    HideMe();
          // }, 3000);
        } else {
          // console.log("TTEPTS 4", data);
          setGenericError(data.message);
          setval1("");
          setval2("");
          setval3("");
          setval4("");
          document.getElementById("val1").focus();
          mixpanel.track("phone_number_error");
        }
      });
    } else {
      setBlockDiv(true);
      setVerificationst(false);
      disableblockCode();
      setTimerDiv(false);
      setCodeSent(false);
      setGenericError(false);
      document.getElementById("backrow").style.display = "none";
      document.getElementById("val4").blur();
      document.getElementById("backrow").style.display = "none";
      if (document.getElementById("el_tm_ver")) {
        document.getElementById("el_tm_ver").style.display = "none";
      }
      let object = {
        email: props.tempuser.email,
      };
      API_Object.Resend(object, props.urls.apiBaseURL).then((data) => {
        console.log("oek", data);
        props.dispatch({ type: "TEMPUSER", userData: "" });
      });
    }
  };
  const HideMe = () => {
    props.closeEmailVerificationPopup();
    if (attempts > 9) {
      props.dispatch({ type: "TEMPUSER", userData: "" });
    }
  };
  return (
    <>
      <div>
        <div className="verification">
          <div id="modal-verification" className="modal-verification email">
            <img
              onClick={HideMe}
              className="x-cross-ver"
              src={cross}
              alt="cross-button"
            />
            <div className="sepodiv">
              <p
                className="verification-bold typekit_h3 webkit_primary_h"
                id="focuss"
              >
                Verify your email address
              </p>
              <p className="verification-normal typekit_BodyRegular webkit_primary_grey">
                We’ve sent a 4 digit security code to{" "}
                <b className="normal-b">{props.tempuser.email}</b> to verify
                your e-mail address and activate your account. Please enter this
                code below to verify.
              </p>
              <div className="rowcount">
                <div className="blockk">
                  <input
                    maxlength="1"
                    id="val1"
                    className="count"
                    value={val1}
                    onKeyDown={(e) => {
                      if (e.key === "Backspace" && val1.length == 0) {
                        document.getElementById("val1").focus();
                      }
                    }}
                    onChange={(e) => {
                      setGenericError("");
                      setval1(e.target.value);
                    }}
                  />
                </div>
                <div className="blockk">
                  <input
                    maxlength="1"
                    id="val2"
                    className="count"
                    value={val2}
                    onKeyDown={(e) => {
                      if (e.key === "Backspace" && val2.length == 0) {
                        document.getElementById("val1").focus();
                      }
                    }}
                    onChange={(e) => {
                      setGenericError("");
                      setval2(e.target.value);
                    }}
                  />
                </div>
                <div className="blockk">
                  <input
                    maxlength="1"
                    id="val3"
                    className="count"
                    value={val3}
                    onKeyDown={(e) => {
                      if (e.key === "Backspace" && val3.length == 0) {
                        document.getElementById("val2").focus();
                      }
                    }}
                    onChange={(e) => {
                      setGenericError("");
                      setval3(e.target.value);
                    }}
                  />
                </div>
                <div className="blockk">
                  <input
                    maxlength="1"
                    id="val4"
                    className="count"
                    value={val4}
                    onKeyDown={(e) => {
                      if (e.key === "Backspace" && val4.length == 0) {
                        document.getElementById("val3").focus();
                      }
                    }}
                    onChange={(e) => {
                      setGenericError("");
                      setval4(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="recaptcha" id="recaptcha">
                <ReCAPTCHA
                  sitekey="6Le-LAQfAAAAAEay4C6SwRnO7mg7u1qeiyhZ1K2-"
                  onChange={onChange}
                />
              </div>
              {genericError && <p className="errorMessage">{genericError}</p>}
              {codeSent && (
                <>
                  <div className="codesent" id="codesent">
                    <p className="verificationsent">Code sent</p>
                    <img
                      className="codesentimg"
                      id="greencheck"
                      src={codesentimg}
                      alt="check"
                    />
                  </div>
                  <p
                    className="verification-normal"
                    id="verificationstate"
                    style={{ cursor: "pointer", marginTop: "5px" }}
                  >
                    We have sent you a new code
                  </p>
                </>
              )}
              {/* <p style={{cursor:"pointer" , marginTop:"3%" , marginBottom:"-20px" , color:"red"}} className="verification-normal" onClick={Resend}>Resend Code</p> */}
              {verificationSt && (
                <p className="verification-lbl" id="verificationstate">
                  Didn’t get it?{" "}
                  <b className="normal-b" id="resendb" onClick={Resend}>
                    Resend Code
                  </b>
                </p>
              )}
              {blockDiv && (
                <p className="verification-block" id="">
                  {" "}
                  <img src={bellicon} alt="icon" className="bellicon" />
                  You are blocked for 24 hours due to multiple wrong entries
                </p>
              )}
              {timerDiv && (
                <>
                  <div id="el_tm_ver">
                    <p
                      className="verificationsent"
                      style={{ marginTop: "25px", marginBottom: "10px" }}
                    >
                      Re-enter code
                    </p>
                    <p id="resend_timer" className="resend_timer">
                      Didn’t get it?{" "}
                      <b className="blurrry">Next resend code available in: </b>{" "}
                      <b className="green">{timer}s</b>{" "}
                    </p>
                  </div>
                </>
              )}
            </div>
            <div id="backrow" className="back-row" onClick={backpage}>
              <img className="backarrow" src={backarrow} alt="back-arrow" />
              <p className="backtext">Back</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function map(state) {
  return state;
}
export default connect(map)(EmailVerification);
