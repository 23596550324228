import React , {useState , useEffect} from 'react';
import { connect } from 'react-redux';
import folders from '../../../assets/images/folders.svg';
import browser from '../../../assets/images/browser.svg';
import codeminus from '../../../assets/images/code-minus.svg';
import chartline from '../../../assets/images/chart-line.svg';
import screenshareimg from '../../../assets/images/screen-share.svg';
import lock from '../../../assets/images/lock.svg';

import Aos from 'aos';

import 'aos/dist/aos.css';
import './why_vdotok_ls.css';



const WhyVdoTokLiveShopping = (props) => {
    const [innerwdt , setinnerwdt] = useState(window.innerWidth);
    useEffect(() => {
        Aos.init();
        setinnerwdt(window.innerWidth);
        window.addEventListener('resize' , () => {
            setinnerwdt(window.innerWidth);
        })
    }, []);

    return (
        <>
        <div className='_whyLS'>
            {innerwdt > 1024 ?
            <p className='typekit_h2 webkit_primary_black _why_b'>Why use VdoTok for building <br></br>your own Live Shopping solution?</p>: 
            innerwdt > 600 ?
            <p className='typekit_h2 webkit_primary_black _why_b'>Why use VdoTok for building a Live <br></br> Shopping solution?</p>:
            innerwdt > 390 ?
            <p className='typekit_h2 webkit_primary_black _why_b'>Why use VdoTok for <br></br> building a Live Shopping  <br></br>solution?</p>
            :
            <p className='typekit_h2 webkit_primary_black _why_b'>Why use VdoTok for building a Live Shopping solution?</p>
            }
            {innerwdt > 1024 ? 
            <p className='typekit_subheadline4 webkit_primary_grey _why_p'>VdoTok is developed by true live media and WebRTC enthusiasts, so we took care of the smallest details <br></br> when it comes to the quality and scalability, while also making it easier for developers to deploy.</p>
            : innerwdt > 600 ? 
            <p className='typekit_subheadline1 webkit_primary_grey _why_p'>VdoTok is developed by true live media and WebRTC enthusiasts, so we took care of the smallest details when it comes to the quality and scalability, while also making it easier for developers to deploy.</p>
            :
            <p className='typekit_subheadline3 webkit_primary_grey _why_p'>VdoTok is developed by true live media and WebRTC enthusiasts, so we took care of the smallest details when it comes to the quality and scalability, while also making it easier for developers to deploy.</p>

            }
            <div>
                {
                innerwdt > 1024 ? 
                <>
                <div className='_whyls_R1'>
                <div className='_why_box1'>
                    <img src={folders} className="folders" alt='folders' />
                    <p className='typekit_h5 webkit_primary_black _why_flx_b'>Easy to integrate SDKs</p>
                    <p className='typekit_LabelRegular webkit_primary_black _why_flx_p'>Integrate our SDKs using your favorite programming languages within a few minutes with our easy-to-follow developer documentation and sample code.</p>
                </div>
                <div className='_why_box2'>
                    <img src={codeminus} className="folders" alt='folders' />
                    <p className='typekit_h5 webkit_primary_black _why_flx_b'>Rapid deployment</p>
                    <p className='typekit_LabelRegular webkit_primary_black _why_flx_p'>Use our sample codes to build live shopping solutions ready for deployment within a day.</p>
                </div>
                <div className='_why_box2'>
                    <img src={browser} className="folders" alt='folders' />
                    <p className='typekit_h5 webkit_primary_black _why_flx_b'>Low latency</p>
                    <p className='typekit_LabelRegular webkit_primary_black _why_flx_p'>We not only provide HD quality solutions but ensure low latency <span>(&lt;1%)</span> across the continents. In-built bandwidth management with seamless switching between wifi and cellular.</p>
                </div>
            </div>
            <div className='_whyls_R2'>
            <div className='_why_box1'>
                    <img src={chartline} className="folders" alt='folders' />
                    <p className='typekit_h5 webkit_primary_black _why_flx_b'>Analytics</p>
                    <p className='typekit_LabelRegular webkit_primary_black _why_flx_p'>Dashboard provides detailed usage analytics down to the session level: keep track of each sessions quality, length of the session, number of participants and bandwidth usage (how much data was consumed during each session).</p>
                </div>
                <div className='_why_box2'>
                    <img src={screenshareimg} className="folders" alt='folders' />
                    <p className='typekit_h5 webkit_primary_black _why_flx_b'>Compatible with major programming languages</p>
                    <p className='typekit_LabelRegular webkit_primary_black _why_flx_p'>Use your favourite programming languages (JavaScript, Swift, Kotlin) or use Flutter to build cross-platform products quickly.</p>
                </div>
                <div className='_why_box2'>
                    <img src={lock} className="folders" alt='folders' />
                    <p className='typekit_h5 webkit_primary_black _why_flx_b'>Security</p>
                    <p className='typekit_LabelRegular webkit_primary_black _why_flx_p'>We are GDPR ready. You own your customers data. We don't store end-customer data. We provide end-to-end secure (encrypted) communication.</p>
                </div>
            </div>
            </> : 
            innerwdt > 600 ? 
            <>
            <div className='_whyls_R1'>
            <div className='_why_box1'>
                <img src={folders} className="folders" alt='folders' />
                <p className='typekit_h5 webkit_primary_black _why_flx_b'>Easy to integrate SDKs</p>
                <p className='typekit_LabelRegular webkit_primary_black _why_flx_p'>Integrate our SDKs using your favorite programming languages within a few minutes with our easy-to-follow developer documentation and sample code.</p>
            </div>
            <div className='_why_box2'>
                <img src={codeminus} className="folders" alt='folders' />
                <p className='typekit_h5 webkit_primary_black _why_flx_b'>Rapid deployment</p>
                <p className='typekit_LabelRegular webkit_primary_black _why_flx_p'>Use our sample codes to build live shopping solutions ready for deployment within a day.</p>
            </div>
            </div>
            <div className='_whyls_R1'>
            <div className='_why_box1'>
                <img src={browser} className="folders" alt='folders' />
                <p className='typekit_h5 webkit_primary_black _why_flx_b'>Low latency</p>
                <p className='typekit_LabelRegular webkit_primary_black _why_flx_p'>We not only provide HD quality solutions but ensure low latency <span>(&lt;1%)</span> across the continents. In-built bandwidth management with seamless switching between wifi and cellular.</p>
            </div>
            <div className='_why_box2'>
                <img src={chartline} className="folders" alt='folders' />
                <p className='typekit_h5 webkit_primary_black _why_flx_b'>Analytics</p>
                <p className='typekit_LabelRegular webkit_primary_black _why_flx_p'>Dashboard provides detailed usage analytics down to the session level: keep track of each sessions quality, length of the session, number of participants and bandwidth usage (how much data was consumed during each session).</p>
            </div>
            </div>
   
        <div className='_whyls_R2'>
            <div className='_why_box2'>
                <img src={screenshareimg} className="folders" alt='folders' />
                <p className='typekit_h5 webkit_primary_black _why_flx_b'>Compatible with major programming languages</p>
                <p className='typekit_LabelRegular webkit_primary_black _why_flx_p'>Use your favourite programming languages (JavaScript, Swift, Kotlin) or use Flutter to build cross-platform products quickly.</p>
            </div>
            <div className='_why_box2'>
                <img src={lock} className="folders" alt='folders' />
                <p className='typekit_h5 webkit_primary_black _why_flx_b'>Security</p>
                <p className='typekit_LabelRegular webkit_primary_black _why_flx_p'>We are GDPR ready. You own your customers data. We don't store end-customer data. We provide end-to-end secure (encrypted) communication.</p>
            </div>
        </div>
        </> :
        <>
                  <div className='_whyls_R1'>
                  <div className='_why_box1'>
                      <img src={folders} className="folders" alt='folders' />
                      <p className='typekit_h5 webkit_primary_black _why_flx_b'>Easy to integrate SDKs</p>
                      <p className='typekit_LabelRegular webkit_primary_black _why_flx_p'>Integrate our SDKs using your favorite programming languages within a few minutes with our easy-to-follow developer documentation and sample code.</p>
                  </div>
                  </div>
                  <div className='_whyls_R1'>
                  <div className='_why_box1'>
                      <img src={codeminus} className="folders" alt='folders' />
                      <p className='typekit_h5 webkit_primary_black _why_flx_b'>Rapid deployment</p>
                      <p className='typekit_LabelRegular webkit_primary_black _why_flx_p'>Use our sample codes to build live shopping solutions ready for deployment within a day.</p>
                  </div>
                  </div>
                  <div className='_whyls_R1'>
                  <div className='_why_box1'>
                      <img src={browser} className="folders" alt='folders' />
                      <p className='typekit_h5 webkit_primary_black _why_flx_b'>Low latency</p>
                      <p className='typekit_LabelRegular webkit_primary_black _why_flx_p'>We not only provide HD quality solutions but ensure low latency <span>(&lt;1%)</span> across the continents. In-built bandwidth management with seamless switching between wifi and cellular.</p>
                  </div>
                    </div>
              <div className='_whyls_R1'>
              <div className='_why_box1'>
                      <img src={chartline} className="folders" alt='folders' />
                      <p className='typekit_h5 webkit_primary_black _why_flx_b'>Analytics</p>
                      <p className='typekit_LabelRegular webkit_primary_black _why_flx_p'>Dashboard provides detailed usage analytics down to the session level: keep track of each sessions quality, length of the session, number of participants and bandwidth usage (how much data was consumed during each session).</p>
                  </div>
                  </div>
                  <div className='_whyls_R1'>
                  <div className='_why_box1'>
                      <img src={screenshareimg} className="folders" alt='folders' />
                      <p className='typekit_h5 webkit_primary_black _why_flx_b'>Compatible with major programming languages</p>
                      <p className='typekit_LabelRegular webkit_primary_black _why_flx_p'>Use your favourite programming languages (JavaScript, Swift, Kotlin) or use Flutter to build cross-platform products quickly.</p>
                  </div>
                  </div>
                  <div className='_whyls_R2'>
                  <div className='_why_box1'>
                      <img src={lock} className="folders" alt='folders' />
                      <p className='typekit_h5 webkit_primary_black _why_flx_b'>Security</p>
                      <p className='typekit_LabelRegular webkit_primary_black _why_flx_p'>We are GDPR ready. You own your customers data. We don't store end-customer data. We provide end-to-end secure (encrypted) communication.</p>
                  </div>
              </div>
              </>
                }
      
            </div>

        </div>
        </>
    )
}


function map(state){
    return state;
}
export default connect(map)(WhyVdoTokLiveShopping);