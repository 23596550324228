import React , {useState , useEffect} from 'react';
import { connect } from 'react-redux';
import Aos from 'aos';
import _build1 from '../images/_build1.svg';
import _build2 from '../images/_build2.svg';
import _build3 from '../images/_build3.svg';
import _build4 from '../../../assets/images/_build4.svg';
import 'aos/dist/aos.css';
import './build_healthtech.css';



const Build_Healthtech = (props) => {
    const [innerwdt , setinnerwdt] = useState(window.innerWidth);
    useEffect(() => {
        Aos.init();
        setinnerwdt(window.innerWidth);
        window.addEventListener('resize' , () => {
            setinnerwdt(window.innerWidth);
        })
    }, []);

    return (
        <>
        <div className='Build_ht'>
            {innerwdt > 1024 ? 
            <p className='build_ht_b typekit_h2 webkit_primary_h'>Why build your own TeleHealth solution?</p>
            :
            <p className='build_ht_b typekit_h2 webkit_primary_h'>Why building your own <br></br> TeleHealth solution?</p>
            }
            {innerwdt > 500 ? 
                        <>
                        <div className='build_ht_R1'>
                        <div data-aos="zoom-in-right" className='_build_ht_box1'>
                            <div className='_build_ht_bold'>
                                <img src={_build1} className="_build_img_ht" alt='image' />
        
                                <p className='typekit_h3 _build_ht_b webkit_primary_h'>Own and control your product</p>
                            </div>
                            <p className={innerwdt > 1024 ? 'typekit_BodyLargeRegular _build_p webkit_primary_grey' : '_build_p typekit_BodyRegular webkit_primary_grey' } >Take ownership of your product. Our APIs only provide a tool for the back end, giving you the freedom to design and develop the front end how you like. This gives you full control over how to use your product, sell it, or manage it. </p>
                        </div>
                        <div data-aos="zoom-in-left" className='_build_ht_box2'>
                            <div className='_build_ht_bold'>
                                <img src={_build2} className="_build_img_ht" alt='image' />
                                <p className='typekit_h3 _build_ht_b webkit_primary_h'>More than a TeleHealth solution</p>
                            </div>
                            <p className={innerwdt > 1024 ? 'typekit_BodyLargeRegular _build_p webkit_primary_grey' : '_build_p typekit_BodyRegular webkit_primary_grey' }>Create a Remote Health Clinic. With our TeleHealth solutions, you get unique features such as hardware, screen sharing, face recognition, and medical report sharing. </p>
                        </div>
                    </div>
                    <div className='build_ht_R2'>
                        <div data-aos="zoom-in-right" className='_build_ht_box1'>
                            <div className='_build_ht_bold'>
                                <img src={_build3} className="_build_img_ht" alt='image' />
                                <p className='typekit_h3 _build_ht_b webkit_primary_h'>Reduce time to market</p>
                            </div>
                            <p className={innerwdt > 1024 ? 'typekit_BodyLargeRegular _build_p webkit_primary_grey' : '_build_p typekit_BodyRegular webkit_primary_grey' }>Hardware and software solutions take at least 8 months to implement. VdoTok gives you a fast implementation within weeks so you can get started with your Telehealth business right away.</p>
                        </div>
                        <div data-aos="zoom-in-left" className='_build_ht_box2'>
                            <div className='_build_ht_bold'>
                                <img src={_build4} className="_build_img_ht" alt='image' />
                                <p className='typekit_h3 _build_ht_b webkit_primary_h'>Get 100% control over your data </p>
                            </div>
                            <p className={innerwdt > 1024 ? 'typekit_BodyLargeRegular _build_p webkit_primary_grey' : '_build_p typekit_BodyRegular webkit_primary_grey' }>We don’t store or ask for any end user/customer data.</p>
                        </div>
                    </div>
                    </> :
                    <>
                    <div className='build_ht_R1'>
                    <div data-aos="zoom-in-right" className='_build_ht_box1'>
                        <div className='_build_ht_bold'>
                            <img src={_build1} className="_build_img_ht" alt='image' />
    
                            <p className='typekit_h3 _build_ht_b webkit_primary_h'>Own and control your product</p>
                        </div>
                        <p className={innerwdt > 1024 ? 'typekit_BodyLargeRegular _build_p webkit_primary_grey' : '_build_p typekit_BodyRegular webkit_primary_grey' } >Take ownership of your product. Our APIs only provide a tool for the back end, giving you the freedom to design and develop the front end how you like. This gives you full control over how to use your product, sell it, or manage it. </p>
                    </div>
                    <div data-aos="zoom-in-right" className='_build_ht_box1'>
                        <div className='_build_ht_bold'>
                            <img src={_build3} className="_build_img_ht" alt='image' />
                            <p className='typekit_h3 _build_ht_b webkit_primary_h'>Reduce time to market</p>
                        </div>
                        <p className={innerwdt > 1024 ? 'typekit_BodyLargeRegular _build_p webkit_primary_grey' : '_build_p typekit_BodyRegular webkit_primary_grey' }>Hardware and software solutions take at least 8 months to implement. VdoTok gives you a fast implementation within weeks so you can get started with your Telehealth business right away.</p>
                    </div>
                   
                    
                </div>
                <div className='build_ht_R2'>
                    <div data-aos="zoom-in-right" className='_build_ht_box1'>
                        <div className='_build_ht_bold'>
                            <img src={_build2} className="_build_img_ht" alt='image' />
                            <p className='typekit_h3 _build_ht_b webkit_primary_h'>More than a TeleHealth solution</p>
                        </div>
                        <p className={innerwdt > 1024 ? 'typekit_BodyLargeRegular _build_p webkit_primary_grey' : '_build_p typekit_BodyRegular webkit_primary_grey' }>Create a Remote Health Clinic. With our TeleHealth solutions, you get unique features such as hardware, screen sharing, face recognition, and medical report sharing. </p>
                    </div>
                    <div data-aos="zoom-in-left" className='_build_ht_box2'>
                        <div className='_build_ht_bold'>
                            <img src={_build4} className="_build_img_ht" alt='image' />
                            <p className='typekit_h3 _build_ht_b webkit_primary_h'>Get 100% control over your data </p>
                        </div>
                        <p className={innerwdt > 1024 ? 'typekit_BodyLargeRegular _build_p webkit_primary_grey' : '_build_p typekit_BodyRegular webkit_primary_grey' }>We don’t store or ask for any end <br></br> user/customer data.</p>
                    </div>
                </div>
                </>

            }

        </div>
        </>
    )
}


function map(state){
    return state;
}
export default connect(map)(Build_Healthtech);