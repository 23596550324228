import { applyMiddleware,  createStore } from "redux";
import {createLogger,  logger} from 'redux-logger'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunkMiddleware from 'redux-thunk';
import AllReducer from "./reducers/CombinedReducers";
import { userdone } from "./reducers/userDone";
import { faqs } from "./reducers/faqs";
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user' , 'openPopups' , 'urls' , 'tempuser' , 'userdone' , 'faqs', 'privacypolicy' , 'termsofservice' ]
  }
   
    const persistedReducer = persistReducer(persistConfig, AllReducer)
  
    let Store = createStore(persistedReducer , applyMiddleware( thunkMiddleware  )  );
    let persistor = persistStore(Store)
    export { Store, persistor }