import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import stillsell from "../../../assets/images/LS_stillsell.webp";
import frameleft from "../../../assets/images/frameleft.webp";
import frameup from "../../../assets/images/frameup.webp";
import frameright from "../../../assets/images/frameright.webp";
import stsl_l1 from "../../../assets/images/stsl_l1.webp";
// import stsl_l2 from '../../../assets/images/stsl_l2.webp';
// import stsl_l3 from '../../../assets/images/stsl_l3.webp';
import stsl_like1 from "../../../assets/images/like-icons.webp";
// import stsl_like2 from '../../../assets/images/stsl_like2.webp';
import stsl_like_mbl from "../../../assets/images/like-icons-mbl.webp";
import stsl_plus from "../../../assets/images/stsl_plus.webp";
import stsl_mkup from "../../../assets/images/stsl_mkup.webp";
import stsl_livebtn from "../../../assets/images/stsl_livebtn.webp";
import rocket from "../../../assets/images/rocket.png";
import speaker from "../../../assets/images/stsl_speaker.webp";
import filter from "../../../assets/images/filter.png";
import x from "../../../assets/images/X.webp";
import xtab from "./images/xtab.webp";
import xmbl from "./images/xmbl.webp";

import likebig from "../../../assets/images/likes.webp";
import likebigtab from "./images/likebigtab.webp";
import likebigmbl from "./images/likebigmbl.webp";

// import like from '../../../assets/images/stsl_like.png';
import girl from "../../../assets/images/stsl_girl.webp";

import "../../AboutUs/about.css";
import Aos from "aos";
import "aos/dist/aos.css";

import "./StillSelling.css";
const StillSelling = (props) => {
  Aos.init();
  const [innerwdt, setinnerwdt] = useState(window.innerWidth);
  useEffect(() => {
    setinnerwdt(window.innerWidth);
    window.addEventListener("resize", () => {
      setinnerwdt(window.innerWidth);
    });
  }, []);
  return (
    <>
      <div className="StillSelling ">
        <div className="still_sell_textdiv">
          <p className="stillsellbold typekit_h2 webkit_primary_black">
            Still selling like it’s 2020?
          </p>
          {innerwdt > 1024 ? (
            <p className="stillsellnormal typekit_subheadline2 webkit_primary_grey eltspace">
              It’s time to dive into the world of live commerce
            </p>
          ) : innerwdt > 600 && innerwdt <= 1024 ? (
            <p className="stillsellnormal typekit_subheadline1 webkit_primary_grey eltspace">
              It’s time to dive into the world of live commerce
            </p>
          ) : (
            <p className="stillsellnormal typekit_subheadline2 webkit_primary_grey eltspace">
              It’s time to dive into the world of live<br></br> commerce
            </p>
          )}
        </div>
        <div className="stillsell_imgbg">
          <div className="d-top">
            <div className="d-top1"></div>
            <div className="d-top2"></div>
          </div>
          <div className="dr1 bdr-right">
            <div
              data-aos="zoom-in-right"
              className="stillsell_pt1"
              style={{ position: "relative" }}
            >
              {/* <div style={{height:1,width:'95%',position:'absolute',bottom:0,backgroundColor:'#E2E2EA',right:0}}></div> */}

              {/* <img src={stsl_l2} className='stsl_l2' data-aos="fade-up"/>
                        <img  src={stsl_like2} className='stsl_like2' data-aos="fade-up"/>
                        <img src={stsl_l3} className='stsl_l3' data-aos="fade-up"/> */}
              <img src={stsl_l1} className="stsl_l1" data-aos="fade-up" />

              <div className="stillsell_box1">
                {innerwdt > 500 ? (
                  <img
                    src={stsl_like1}
                    className="stsl_like1"
                    data-aos="fade-up"
                  />
                ) : (
                  <img
                    src={stsl_like_mbl}
                    className="stsl_like1"
                    data-aos="fade-up"
                  />
                )}
                <img src={stsl_livebtn} className="stsl_livebtn" />
                <div className="stsl_innerflex">
                  <img src={stsl_mkup} className="stsl_mkup" />
                  <div className="stsl_txt">
                    <p className="typekit_h6 webkit_primary_black stsl-txt-b">
                      Make up pro +
                    </p>
                    <p className="typekit_LabelSmall webkit_primary_grey eltspace">
                      9.99 USD
                    </p>
                  </div>
                  <img src={stsl_plus} className="stsl_plus" />
                </div>
              </div>
            </div>
            <div className="stsl_vertical_line"></div>
            <div
              data-aos="zoom-in-left"
              className="r1_b2 stillsell_pt2 "
            >
              <div>
                <div className="r1_b2_txt stsl_inner_txt">
                  <div className="rocket-box">
                    <img src={rocket} className="rocket" />
                  </div>
                  <p
                    className={
                      innerwdt > 1024
                        ? "typekit_h6 webkit_primary_h stsl-txt2-b"
                        : "typekit_stsl_bold r1-b2tab webkit_primary_h stsl-txt2-b"
                    }
                  >
                    76<b className="b_smoll">%</b>
                  </p>

                  {innerwdt > 1024 ? (
                    <p
                      style={{ zIndex: 3 }}
                      className={
                        innerwdt > 1024
                          ? "typekit_BodyRegularMedium webkit_primary_black eltspace"
                          : "typekit_BodySmallMedium webkit_primary_black eltspace"
                      }
                    >
                      Livestream shopping purchases during the <br></br>{" "}
                      pandemic increased by 76%
                    </p>
                  ) : (
                    <p
                      style={{ zIndex: 3 }}
                      className={
                        innerwdt > 1024
                          ? "typekit_BodyRegularMedium webkit_primary_black eltspace"
                          : "typekit_BodySmallMedium webkit_primary_black eltspace stsl-overflow"
                      }
                    >
                      Livestream shopping purchases during the pandemic
                      increased by 76%
                    </p>
                  )}
                </div>
              </div>
            </div>
            <img src={frameright} className="stillsell_frmright" />
          </div>
          <div className={innerwdt > 600 ? "dr2 bdr-left" : "dr2 bdr-left"}>
            <div
              data-aos="zoom-in-right"
              className={
                innerwdt > 600
                  ? "stillsell_pt1 stsl_pt1"
                  : "stillsell_pt1 stsl_pt1"
              }
            >
              <div>
                <div className="stsl_inner_txt">
                  {/* <img src={filter} className="filter"  /> */}

                  <div className="x-box">
                    {innerwdt > 1024 ? (
                      <img src={x} className="x" />
                    ) : innerwdt > 500 && innerwdt <= 1024 ? (
                      <img src={xtab} className="x" />
                    ) : (
                      <img src={xmbl} className="x" />
                    )}
                  </div>
                  <p
                    className={
                      innerwdt > 1024
                        ? "typekit_h6 webkit_YellowDark stsl-txt2-b"
                        : "typekit_stsl_bold webkit_YellowDark r2-b1tab stsl-txt2-b"
                    }
                  >
                    10<b className="b_smoll">x</b>
                  </p>
                  <p
                    style={{ zIndex: 3 }}
                    className={
                      innerwdt > 1024
                        ? "typekit_BodyRegularMedium webkit_primary_black eltspace"
                        : "_st_centre typekit_BodySmallMedium webkit_primary_black eltspace"
                    }
                  >
                    Companies report live commerce conversion rates are up to
                    10x higher than conventional ecommerce
                  </p>
                </div>
              </div>
            </div>
            <div className="stsl_horz_line"></div>
            <div data-aos="zoom-in-left" className="stillsell_pt2 stsl_pt2">
              {/* <div style={{height:1,width:'95%',position:'absolute',top:"-1px",backgroundColor:'#E2E2EA',left:-2}}></div> */}

              <div>
                <div className="stsl_inner_txt">
                  <div className="likebig-box">
                    {innerwdt > 1024 ? (
                      <img src={likebig} className="likebig" />
                    ) : innerwdt > 500 && innerwdt <= 1024 ? (
                      <img src={likebigtab} className="likebig" />
                    ) : (
                      <img src={likebigmbl} className="likebig" />
                    )}
                  </div>
                  <p
                    className={
                      innerwdt > 1024
                        ? "typekit_h6 webkit_PinkandRed stsl-txt2-b"
                        : "typekit_stsl_bold r2-b2tab webkit_PinkandRed stsl-txt2-b"
                    }
                  >
                    60<b className="b_smoll">%</b>
                  </p>
                  <p
                    style={{ zIndex: 3 }}
                    className={
                      innerwdt > 1024
                        ? "typekit_BodyRegularMedium webkit_primary_black eltspace"
                        : "typekit_BodySmallMedium _st_centre webkit_primary_black eltspace"
                    }
                  >
                    <span
                      className={innerwdt > 1024 ? "webkit_PinkandRed" : ""}
                    >
                      60%
                    </span>{" "}
                    of shoppers who tried livestream shopping said it improved
                    their shopping experience
                  </p>
                </div>
              </div>
            </div>
            <img src={frameleft} className="stillsell_frmleft" />
          </div>
          <div className="dr3 bdr-right2">
            <div
              data-aos="zoom-in-right"
              className="r3_b1 stillsell_pt1 stsl_pt1 "
            >
              <div>
                <div className="r3_b1_txt stsl_inner_txt">
                  <div className="speaker-box">
                    <img src={speaker} className="speaker" />
                  </div>

                  <p
                    className={
                      innerwdt > 1024
                        ? "typekit_h6 webkit_BlueSky stsl-txt2-b"
                        : "typekit_stsl_bold webkit_BlueSky r3-b1tab stsl-txt2-b"
                    }
                  >
                    80<b className="b_smoll">%</b>
                  </p>
                  <p
                    style={{ zIndex: 3 }}
                    className={
                      innerwdt > 1024
                        ? "typekit_BodyRegularMedium webkit_primary_black eltspace"
                        : "typekit_BodySmallMedium webkit_primary_black eltspace _st_start stsl_overflow2"
                    }
                  >
                    <span className={innerwdt > 1024 ? "webkit_BlueSky" : ""}>
                      80%
                    </span>{" "}
                    of marketers think livestream commerce will be an important
                    part of sharing products in the coming years
                  </p>
                </div>
              </div>
            </div>
            <div className="stsl_vertical_line"></div>
            <div data-aos="zoom-in-left" className="stillsell_pt2 ">
              <div className="stillsell_b6">
                <div className="stsl_innerflex6">
                  <img src={girl} className="stsl_girl" />
                  <div className="stsl_txt">
                    <p className="typekit_h6 webkit_primary_black stsl-txt-b">
                      Live session with influencer Isabella
                    </p>
                    <div style={{ display: "flex" }} className="span_parent">
                      <div>
                        <p className="typekit_LabelSmall webkit_primary_grey txt_span eltspace">
                          <span style={{ color: "black" }}>Start:</span> Jun 9,
                          10:30 IST
                        </p>
                        <p className="typekit_LabelSmall webkit_primary_grey txt_span eltspace">
                          <span style={{ color: "black" }}>End:</span> Jun 9,
                          12:30 IST
                        </p>
                      </div>
                      <button className="stsl_x6_btn">Go Live</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <img src={frameright} className="stillsell_frmright2" />
          </div>
          {/* <img src={stillsell} className="still-selling" alt='lines' /> */}
          <img src={frameup} className="stillsell_frmup" />
        </div>

        <p className="stillsellblur typekit_LabelSmall">
          Stats source:{" "}
          <span
            onClick={() => {
              window.open(
                "https://fitsmallbusiness.com/livestream-shopping-statistics/"
              );
            }}
            style={{ textDecoration: "underline", cursor: "pointer" }}
          >
            fitsmallbusiness.com/livestream-shopping-statistics
          </span>
        </p>
      </div>
    </>
  );
};

function map(state) {
  return state;
}
export default connect(map)(StillSelling);
