import React, { useEffect } from "react";
import "./style.css";
import Aos from "aos";
import "aos/dist/aos.css";

const FAQHeader = () => {
  useEffect(() => {
    Aos.init();
    // console.log("scrollingg 2");
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="parent-faqheader">
        {/* <img id ="myAnimation" src={starss} className="star-patterns" alt="star-pattern" /> */}

        <div className="FAQHeader ">
          <p data-aos="zoom-in-down" className="headerp typekit_h2">
            We are here to help!👋
          </p>
        </div>
        {/* <img id ="myAnimation2" src={starpattern} className="star-pattern2" alt="star-pattern" /> */}
      </div>
    </>
  );
};

export default FAQHeader;
