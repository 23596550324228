const privacypolicy=(state=[],action)=>{
  switch(action.type){
    case 'PPs':
      return action.payload
      default:
        return state;
  }

};
export {privacypolicy}
