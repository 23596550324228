import React, {useState , useEffect} from "react";
import { connect } from "react-redux";
import './values.css';
import '../about.css';
import mission from '../../../assets/images/mission.png';
import valuegrp from './images/valuegrp.webp';
import valuestar from './images/valuestar.webp';
const Values = (props) => {
    const [innerwdt , setinnerwdt] = useState(window.innerWidth);
    useEffect(() => {
        setinnerwdt(window.innerWidth);
        window.addEventListener('resize' , () => {
            setinnerwdt(window.innerWidth);
        })
    }, [])
    return(
        <>
        <div className="main_value">
                <div className="values_text">
                    {innerwdt > 1024 ?
                    
                    <h2 className="webkit_primary_black valuehead typekit_h2">Our Values</h2> :
                    <h2 className="webkit_primary_black valuehead typekit_h2"> Values</h2>
                    }
                    <p className={innerwdt > 500 ? "webkit_primary_black typekit_subheadline2 mdesc" 
                    : "webkit_primary_black typekit_subheadline3 mdesc" }>It’s not just something we value, this is who we are</p>
                </div>
                <div className="valueparent valuedesktop">
                    <div className="valuerow">
                        <div className="valuebox webkit_FreshGreen">
                            <div className="txtValue">
                                <div className="rdivg"><img className="grppimg" alt="group-icon" src={valuegrp} /> <p className={innerwdt > 1024 ? "typekit_LabelXXL valuecard_h webkit_GreenGrass " : "typekit_LabelXL valuecard_h webkit_GreenGrass " }>Mission</p> </div>
                                <p className={innerwdt > 1024 ? "typekit_LabelRegular webkit_GreenGrass valuecard_p" : "typekit_LabelLight webkit_GreenGrass valuecard_p" }>To accelerate the world's transition from messaging/text to live media by making it easy, quick and inexpensive for any business.</p>
                            </div>
                            <div className="linesdiv">
                                <div className="valueL1"></div>
                                <div className="valueL2"></div>
                                <div className="valueL3"></div>
                            </div>
               
                        </div>
                        <div className="valuebox box-space webkit_FreshYellow">
                            <div className="txtValue">
                                <div className="rdivg"><img className="grppimg" alt="group-icon" src={valuegrp} /> <p className={innerwdt > 1024 ? "typekit_LabelXXL valuecard_h webkit_YellowDark " : "typekit_LabelXL valuecard_h webkit_YellowDark " }>Mission</p> </div>
                                <p className={innerwdt > 1024 ? "typekit_LabelRegular webkit_YellowDark valuecard_p" : "typekit_LabelLight webkit_YellowDark valuecard_p" }>To accelerate the world's transition from messaging/text to live media by making it easy, quick and inexpensive for any business.</p>
                            </div>
                            <div className="linesdiv">
                                <div className="Lstar"></div>
                                <img className="valuestar" alt="stars" src={valuestar} />
                            </div>
                
                            </div>
                        <div className="valuebox box-space webkit_FreshBlue">
                            <div className="txtValue">
                                <div className="rdivg"><img className="grppimg" alt="group-icon" src={valuegrp} /> <p className={innerwdt > 1024 ? "typekit_LabelXXL valuecard_h webkit_Bluemoon " : "typekit_LabelXL valuecard_h webkit_Bluemoon " }>Mission</p> </div>
                                <p className={innerwdt > 1024 ? "typekit_LabelRegular webkit_Bluemoon valuecard_p" : "typekit_LabelLight webkit_Bluemoon valuecard_p" }>To accelerate the world's transition from messaging/text to live media by making it easy, quick and inexpensive for any business.</p>
                            </div>
                            <div className="linesdiv">
                                <div className="valueL1"></div>
                                <div className="valueL2"></div>
                                <div className="valueL3"></div>
                            </div>
            
                        </div>
                    </div>
                    <div className="valuerow2 ">
                        <div className="valuebox webkit_FreshPink">
                            <div className="txtValue">
                                <div className="rdivg"><img className="grppimg" alt="group-icon" src={valuegrp} /> <p className={innerwdt > 1024 ? "typekit_LabelXXL valuecard_h webkit_PinkandRed " : "typekit_LabelXL valuecard_h webkit_PinkandRed " }>Mission</p> </div>
                                <p className={innerwdt > 1024 ? "typekit_LabelRegular webkit_PinkandRed valuecard_p" : "typekit_LabelLight webkit_PinkandRed valuecard_p" }>To accelerate the world's transition from messaging/text to live media by making it easy, quick and inexpensive for any business.</p>
                            </div>
                            <div className="linesdiv">
                                <div className="valueL1"></div>
                                <div className="valueL2"></div>
                                <div className="valueL3"></div>
                            </div>
               

                        </div>
                        <div className="valuebox box-space webkit_FreshGrey">
                            <div className="txtValue">
                                <div className="rdivg"><img className="grppimg" alt="group-icon" src={valuegrp} /> <p className={innerwdt > 1024 ? "typekit_LabelXXL valuecard_h webkit_Blue1 " : "typekit_LabelXL valuecard_h webkit_Blue1 " }>Mission</p> </div>
                                <p className={innerwdt > 1024 ? "typekit_LabelRegular webkit_Blue1 valuecard_p" : "typekit_LabelLight webkit_Blue1 valuecard_p"} >To accelerate the world's transition from messaging/text to live media by making it easy, quick and inexpensive for any business.</p>
                            </div>
                            <div className="linesdiv">
                                <div className="valueL1"></div>
                                <div className="valueL2"></div>
                                <div className="valueL3"></div>
                            </div>
               
                        </div>
                        <div className="valuebox box-space webkit_FreshSky">
                        <div className="txtValue">
                                <div className="rdivg"><img className="grppimg" alt="group-icon" src={valuegrp} /> <p className={innerwdt > 1024 ? "typekit_LabelXXL valuecard_h webkit_BlueSky " : "typekit_LabelXL valuecard_h webkit_BlueSky " }>Mission</p> </div>
                                <p className={innerwdt > 1024 ? "typekit_LabelRegular webkit_BlueSky valuecard_p" : "typekit_LabelLight webkit_BlueSky valuecard_p" }>To accelerate the world's transition from messaging/text to live media by making it easy, quick and inexpensive for any business.</p>
                            </div>
                            <div className="linesdiv">
                                <div className="valueL1"></div>
                                <div className="valueL2"></div>
                                <div className="valueL3"></div>
                            </div>
               
                        </div>
                    </div>
                </div>
                <div className="valueparent valuemobile">
                    <div className="valuerowmbl">
                        <div className="valuebox webkit_FreshGreen">
                            <div className="txtValue">
                                <div className="rdivg"><img className="grppimg" alt="group-icon" src={valuegrp} /> <p className="typekit_LabelRegularBold valuecard_h webkit_GreenGrass ">Mission</p> </div>
                                <p className="typekit_LabelThin webkit_GreenGrass valuecard_p" >To accelerate the world's transition from messaging/text to live media by making it easy, quick and inexpensive for any business.</p>
                            </div>
                            <div className="linesdiv">
                                <div className="valueL1"></div>
                                <div className="valueL2"></div>
                                <div className="valueL3"></div>
                            </div>
               
                        </div>
                        <div className="valuebox  webkit_FreshYellow">
                            <div className="txtValue">
                                <div className="rdivg"><img className="grppimg" alt="group-icon" src={valuegrp} /> <p className= "typekit_LabelRegularBold valuecard_h webkit_YellowDark ">Mission</p> </div>
                                <p className= "typekit_LabelThin webkit_YellowDark valuecard_p">To accelerate the world's transition from messaging/text to live media by making it easy, quick and inexpensive for any business.</p>
                            </div>
                            <div className="linesdiv">
                                <div className="Lstar"></div>
                                <img className="valuestar" alt="stars" src={valuestar} />
                            </div>
                
                            </div>
                     
                    </div>
                    <div className="valuerowmbl mblvaluespace">
                        <div className="valuebox  webkit_FreshBlue">
                            <div className="txtValue">
                                <div className="rdivg"><img className="grppimg" alt="group-icon" src={valuegrp} /> <p className="typekit_LabelRegularBold valuecard_h webkit_Bluemoon " >Mission</p> </div>
                                <p className= "typekit_LabelThin webkit_Bluemoon valuecard_p" >To accelerate the world's transition from messaging/text to live media by making it easy, quick and inexpensive for any business.</p>
                            </div>
                            <div className="linesdiv">
                                <div className="valueL1"></div>
                                <div className="valueL2"></div>
                                <div className="valueL3"></div>
                            </div>
            
                        </div>
                        <div className="valuebox  webkit_FreshPink">
                            <div className="txtValue">
                                <div className="rdivg"><img className="grppimg" alt="group-icon" src={valuegrp} /> <p className= "typekit_LabelRegularBold valuecard_h webkit_PinkandRed ">Mission</p> </div>
                                <p className= "typekit_LabelThin webkit_PinkandRed valuecard_p">To accelerate the world's transition from messaging/text to live media by making it easy, quick and inexpensive for any business.</p>
                            </div>
                            <div className="linesdiv">
                                <div className="valueL1"></div>
                                <div className="valueL2"></div>
                                <div className="valueL3"></div>
                            </div>
               

                        </div>
                       
                    </div>
                    <div className="valuerowmbl mblvaluespace">
                        <div className="valuebox webkit_FreshGrey">
                            <div className="txtValue">
                                <div className="rdivg"><img className="grppimg" alt="group-icon" src={valuegrp} /> <p className= "typekit_LabelRegularBold valuecard_h webkit_Blue1 " >Mission</p> </div>
                                <p className= "typekit_LabelThin webkit_Blue1 valuecard_p" >To accelerate the world's transition from messaging/text to live media by making it easy, quick and inexpensive for any business.</p>
                            </div>
                            <div className="linesdiv">
                                <div className="valueL1"></div>
                                <div className="valueL2"></div>
                                <div className="valueL3"></div>
                            </div>
               
                        </div>
                        <div className="valuebox webkit_FreshSky">
                        <div className="txtValue">
                                <div className="rdivg"><img className="grppimg" alt="group-icon" src={valuegrp} /> <p className={"typekit_LabelRegularBold valuecard_h webkit_BlueSky "}>Mission</p> </div>
                                <p className="typekit_LabelThin webkit_BlueSky valuecard_p">To accelerate the world's transition from messaging/text to live media by making it easy, quick and inexpensive for any business.</p>
                            </div>
                            <div className="linesdiv">
                                <div className="valueL1"></div>
                                <div className="valueL2"></div>
                                <div className="valueL3"></div>
                            </div>
               
                        </div>
                    </div>
                </div>
        </div>  
        </>
    )
}


function map(state){
    return state;
}
export default connect(map)(Values);