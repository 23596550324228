import React, { useState, useMemo } from "react";
import { createPortal } from "react-dom";
import axios from "axios";
import { connect } from "react-redux";
import { ReactComponent as Xcircle } from "../../assets/images/x-circle.svg";
import { ReactComponent as Close } from "../../assets/images/white_close.svg";
import VdoTok from "../../assets/images/logo.png";
import tabletLogo from "../../assets/images/vdotok_logo_tablet.png";
import mobileLogo from "../../assets/images/vdotok_logo_mobile.png";
import iphone from "../../assets/images/iphoneVideo.png";
import horizontal_iphone from "../../assets/images/horizontal_iphone.png";
import mobileBackground from "../../assets/images/mobile_backgound_video.png";
import Button from "../../components/common/button";
import CancelButton from "../../components/common/cancelButton";
import Input from "../../components/common/input";
import "./styles.css";

const ResetPassword = (props) => {
  const {
    setSelectedScreen,
    forgotResponse,
    openLoginPopup,
    setForgotResponse,
  } = props;
  const [inputValue, setInputValue] = useState({
    password: "",
    confirmPassword: "",
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [hitAgain, setHitAgain] = useState(true);

  const emptyMessage = () => {
    setErrorMessage(null);
  };

  const passwordHandler = (event) => {
    const { name, value } = event.target;
    if (name === "password") {
      emptyMessage();
    }
    setInputValue((prevInputValue) => ({ ...prevInputValue, [name]: value }));
  };

  const focusHandler = () => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(\-_)<>,.?'";:|`~[\]{\\}\/])[A-Za-z\d!@#$%^&*(\-_)<>,.?'";:|`~[\]{\\}\/]{8,16}$/;
    const validatePassword = passwordRegex.test(inputValue.password);
    if (!validatePassword) {
      return setErrorMessage(
        "Please follow the mentioned instructions for a strong password."
      );
    }
  };

  const updatePasswordHandler = () => {
    // emptyMessage();
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(\-_)<>,.?'";:|`~[\]{\\}\/])[A-Za-z\d!@#$%^&*(\-_)<>,.?'";:|`~[\]{\\}\/]{8,16}$/;
    const validatePassword = passwordRegex.test(inputValue.password);
    // if (inputValue.password.length < 8) {
    //   return setErrorMessage("Password must contain at least 8 characters.");
    // }
    // if (!validatePassword) {
    //   return setErrorMessage("Incorrect password format");
    // }
    if (!validatePassword) {
      return setErrorMessage(
        "Please follow the mentioned instructions for a strong password."
      );
    }

    if (inputValue.password !== inputValue.confirmPassword) {
      return setErrorMessage("Password doesn't match");
    }
    if (hitAgain && inputValue.password) {
      setHitAgain(false);
      emptyMessage();
      const body = {
        verification_token: forgotResponse.token,
        verification_otp: forgotResponse.otp,
        new_password: inputValue.password,
      };
      axios
        .post(
          `https://${props.urls.apiBaseURL}/AdminAPI/v0/ResetPassword`,
          body
        )
        .then((res) => {
          if (res.data.status === 200) {
            setForgotResponse({
              attempts: "",
              token: "",
              otp: "",
              email: "",
            });
            setSelectedScreen("success");
          } else {
            setErrorMessage(res.data.message);
          }
        })
        .catch((err) => {
          setErrorMessage(err.message);
        })
        .finally(() => {
          setHitAgain(true);
        });
    }
  };
  const cancelHandler = () => {
    setSelectedScreen(null);
    setForgotResponse({
      attempts: "",
      token: "",
      otp: "",
      email: "",
    });
  };

  const disabledHandler = useMemo(() => {
    if (inputValue.password && inputValue.confirmPassword) {
      return false;
    }
    return true;
  }, [inputValue.password, inputValue.confirmPassword]);

  const forgotNode = document.getElementById("resetPasswordContainer");
  return createPortal(
    <div className="reset_password_container">
      <div className="reset_password_wraper">
        <div className="rpw_left_container">
          <div className="rpw_form">
            <div className="rpw_form_logo_container">
              <img src={VdoTok} alt="logo" className="rpw_form_logo" />
            </div>
            <div className="rpwf_content_container">
              <p className="rpwfc_heading">Reset your password</p>

              <p className="rpwfc_desc">Choose a new password</p>
              <Input
                title="New password"
                type="password"
                placeholder="Enter password"
                onChange={passwordHandler}
                value={inputValue.password}
                name="password"
                maxLength="16"
              />
              <p className="rpwfc_password_desc">
                Please set a Password with a combination of at least one capital
                letter, alpha-numeric and special character. Character range
                shall be between 8-16.
              </p>
              <Input
                title="Re-enter password"
                type="password"
                placeholder="Re-enter password"
                onChange={passwordHandler}
                value={inputValue.confirmPassword}
                className="rpwfc_re_enter_password"
                name="confirmPassword"
                maxLength="16"
                onFocus={focusHandler}
              />
              <Button
                title="update"
                className="rpwfc_send_btn"
                onClick={updatePasswordHandler}
                disabled={disabledHandler}
              />

              <CancelButton
                title="cancel"
                onClick={() => {
                  cancelHandler();
                  openLoginPopup();
                }}
              />
            </div>
          </div>
          {errorMessage && <p className="rpw_error_message">{errorMessage}</p>}
        </div>
        <div className="rpw_right_container">
          <Xcircle className="rpwrc_close_icon" onClick={cancelHandler} />
          <Close className="rpwrc_white_close_icon" onClick={cancelHandler} />
          <div className="rpwrc_logo_container">
            <img src={tabletLogo} alt="logo" className="rpwrc_logo" />
          </div>
          <div className="rpwrc_mobile_logo_container">
            <img src={mobileLogo} alt="logo" className="rpwrcm_logo" />
          </div>
          {/* <div className="rpwrc_video_container">
            <img src={iphone} alt="iphone" className="rpwrc_video" />
          </div>
          <div className="rpwrc_horizontal_video_container">
            <img
              src={horizontal_iphone}
              alt="horizontal iphone"
              className="rpwrc_horizontal_video"
            />
          </div>
          <div className="rpwrc_mobile_video_container">
            <img
              src={mobileBackground}
              alt="mobile video"
              className="rpwrc_mobile_video"
            />
          </div> */}
        </div>
      </div>
    </div>,
    forgotNode
  );
};
function mapStatetoprops(state) {
  return state;
}
export default connect(mapStatetoprops)(ResetPassword);
