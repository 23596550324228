import React, { useEffect, useState } from "react";
// import readytotry from '../../assets/images/readytotry.png';
import readytotry from "../../assets/images/Mask-Group.webp";

import trygirltab from "../../assets/images/trygirltab.webp";
import trygirlmbl from "../../assets/images/trygirlmbl.webp";

import { connect } from "react-redux";
import mixpanel from "mixpanel-browser";
import "./trycomponent.css";

const TryComponent = (props) => {
  const [checkedIn, setCheckedIn] = useState(false);
  const [innerwdt, setinnerwdt] = useState(window.innerWidth);
  useEffect(() => {
    setinnerwdt(window.innerWidth);
    window.addEventListener("resize", () => {
      setinnerwdt(window.innerWidth);
    });
  }, []);
  const reverse = (encoded) => {
    var newString = "";
    for (var i = encoded.length - 1; i >= 0; i--) {
      newString += encoded[i];
    }
    return newString;
  };
  const setCookie = (cname, cvalue, exdays) => {
    let encoded = btoa(cvalue);
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    if (window.location.href.includes(".dev")) {
      // document.cookie=`user=; Expires=Fri, 5 Oct 2018 14:28:00 GMT;path=/;Domain=vdotok.dev;SameSite=Strict`;
      document.cookie =
        cname +
        "=" +
        reverse(encoded) +
        ";" +
        expires +
        ";path=/;Domain=vdotok.dev;SameSite=Strict";
    } else {
      document.cookie =
        cname +
        "=" +
        reverse(encoded) +
        ";" +
        expires +
        ";path=/;Domain=vdotok.com;SameSite=Strict";
      // document.cookie=`user=; Expires=Fri, 5 Oct 2018 14:28:00 GMT;path=/;Domain=vdotok.com;SameSite=Strict`;
    }
    // document.cookie = cname + "=" + reverse(encoded) + ";" + expires + ";path=/;Domain=vdotok.dev;SameSite=Strict";
  };
  useEffect(() => {
    if (Object.keys(props.userdone).length == 0) {
      setCheckedIn(false);
    } else {
      setCheckedIn(true);
    }
  }, [props]);
  return (
    <>
      <div className="trycomponent">
        <div data-aos="zoom-in-right" className="readytotrycontent">
          <div className="d-content">
            <p className="txbold typekit_h2 webkit_primary_white">
              Ready to try it out?
            </p>
            <p
              className={
                innerwdt > 1024
                  ? "txnormal typekit_LabelXL webkit_primary_white"
                  : innerwdt > 600 && innerwdt <= 1024
                  ? "txnormal typekit_LabelLight webkit_primary_white"
                  : "txnormal typekit_BodyRegularMedium webkit_primary_white"
              }
            >
              Try VdoTok for free while we’re in beta. Test our powerful and
              easy-to-use APIs to integrate new generation of video and voice
              calls, messaging, live streaming and screen sharing to your
              product and transform your customers’ lives by bringing them into
              the new reality.
            </p>
          </div>
          {!checkedIn ? (
            <button
              type="button"
              className="trybtn"
              // onClick={() => {
              //   mixpanel.track("try_for_free");
              //   props.openSignupPopup();
              // }}
              style={{ cursor: "pointer" }}
            >
              TRY IT FOR FREE NOW
            </button>
          ) : (
            <button
              type="button"
              className="trybtn"
              onClick={() => {
                mixpanel.track("dashboard");
                setCookie("user", props.user.auth_token, 4);
                props.dispatch({ type: "USERDONE", userData: props.user });
                window.open(`https://${props.urls.adminPanelURL}/overview`);
              }}
              style={{ cursor: "pointer" }}
            >
              DASHBOARD
            </button>
          )}
        </div>
        <div data-aos="zoom-in-left" className="readytotryimg">
          {innerwdt > 1024 ? (
            <img src={readytotry} className="readytotry" alt="ready-to-try" />
          ) : innerwdt > 600 ? (
            <img src={trygirltab} className="readytotry" alt="ready-to-try" />
          ) : (
            <div className="try_gradient_parent">
              <img src={trygirlmbl} className="readytotry" alt="ready-to-try" />
              <div className="trygradient"></div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

function map(state) {
  return state;
}
export default connect(map)(TryComponent);
