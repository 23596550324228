import React from "react";
import "./styles.css";

const Button = (props) => {
  const { title = "send", onClick = () => {}, disabled, className } = props;
  return (
    <button
      className={`button-container green_bg_btn ${className}`}
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export default Button;
