import React from 'react';
import { connect } from 'react-redux';
import axios from "axios";
class APIs   {
    constructor(){

    }
    SignUp(data , url){
    
        let object = {};
        object.first_name = data.first_name;
        object.last_name = data.last_name;
        object.email = data.email;
        object.password = data.password;
        object.country = data.country;
        return fetch(`https://${url}/AdminAPI/v0/SignUp`, {
        method: 'POST',

        body: JSON.stringify(object),
        headers: {
            'Content-Type': 'text/plain'
        }
        })
        .then(response => response.json())
        .then(data => {
            return data;

        })
        .catch(error => {
        return error;
        });
    }
    Login(data , url){
        let object = {};
        object.email = data.email;
        object.password = data.password;
        return fetch(`https://${url}/AdminAPI/v0/Login`, {
        method: 'POST',
        // mode: 'CORS',
        body: JSON.stringify(object),
        headers: {
            'Content-Type': 'text/plain'
        }
        })
        .then(response => response.json())
        .then(data => {
            return data;

        })
        .catch(error => {
        return error;
        });
    }
    Logout(data , url){
        let object = {};
        object.auth_token = data.auth_token;
        return fetch(`https://${url}/AdminAPI/v0/Logout`, {
        method: 'POST',
        // mode: 'CORS',
        body: JSON.stringify(object),
        headers: {
            'Content-Type': 'text/plain'
        }
        })
        .then(response => response.json())
        .then(data => {
            return data;

        })
        .catch(error => {
        return error;
        });
    }
    Resend(data , url){
        let object = {};
        object.email = data.email;
        return fetch(`https://${url}/AdminAPI/v0/ResendEmailCode`, {
        method: 'POST',
        // mode: 'CORS',
        body: JSON.stringify(object),
        headers: {
            'Content-Type': 'text/plain'
        }
        })
        .then(response => response.json())
        .then(data => {
            return data;

        })
        .catch(error => {
        return error;
        });
    }
    EmailVerification(data , url){
        let object = {};
        object.email = data.email;
        object.verification_token = data.verification_token;
        return fetch(`https://${url}/AdminAPI/v0/ValidateTenant`, {
        method: 'POST',
        body: JSON.stringify(object),
        headers: {
            'Content-Type': 'text/plain'
        }
        })
        .then(response => response.json())
        .then(data => {
            return data;

        })
        .catch(error => {
        return error;
        });
    }
    EnterPhoneNumber(data , url){
        
        let object = {};
        object.auth_token = data.auth_token;

        object.phone_number = data.phone_number;

        object.dial_code = data.dial_code;

        object.country_code = data.country_code;
        return fetch(`https://${url}/AdminAPI/v0/AddPhoneNumber`, {
        method: 'POST',
        body: JSON.stringify(object),
        headers: {
            'Content-Type': 'text/plain'
        }
        })
        .then(response => response.json())
        .then(data => {
            return data;

        })
        .catch(error => {
        return error;
        });
    }
    PhoneVerification(data , url){
        let object = {};
        object.auth_token=data.auth_token;
        object.verification_token = data.verification_token;
        return fetch(`https://${url}/AdminAPI/v0/VerifyPhoneNumber`, {
        method: 'POST',
        body: JSON.stringify(object),
        headers: {
            'Content-Type': 'text/plain'
        }
        })
        .then(response => response.json())
        .then(data => {
            return data;

        })
        .catch(error => {
        return error;
        });
    }
    GetBusinessType(data , url){
        let object = {};
        object.auth_token=data.auth_token;
        return fetch(`https://${url}/AdminApi/GetBusinessTypes`, {
        method: 'POST',
        body: JSON.stringify(object),
        headers: {
            'Content-Type': 'text/plain'
        }
        })
        .then(response => response.json())
        .then(data => {
            return data;

        })
        .catch(error => {
        return error;
        
        });
    }
    AddTenantInformation(data , url){
        let object = {};
        object.auth_token=data.auth_token;
        object.organization_name = data.organization_name;
        object.business_type = data.business_type;
        return fetch(`https://${url}/AdminAPI/v0/AddTenantInformation`, {
        method: 'POST',
        body: JSON.stringify(object),
        headers: {
            'Content-Type': 'text/plain'
        }
        })
        .then(response => response.json())
        .then(data => {
            return data;

        })
        .catch(error => {
        return error;
        });
    }
    GetRoles(data , url){
        let object = {};
        object.auth_token=data.auth_token;
        return fetch(`https://${url}/AdminApi/GetRoles`, {
        method: 'POST',
        body: JSON.stringify(object),
        headers: {
            'Content-Type': 'text/plain'
        }
        })
        .then(response => response.json())
        .then(data => {
            return data;

        })
        .catch(error => {
        return error;
        });
    }
    AddProject(data , url){
        let object = {};
        object.auth_token=data.auth_token;
        object.project_title = data.project_title;
        object.role_id = data.role_id;
        return fetch(`https://${url}/AdminAPI/v0/AddProject`, {
        method: 'POST',
        body: JSON.stringify(object),
        headers: {
            'Content-Type': 'text/plain'
        }
        })
        .then(response => response.json())
        .then(data => {
            return data;

        })
        .catch(error => {
        return error;
        });
    }
    ContactUs(data , url){
        let object = {};
        object.auth_token= data.auth_token;
        object.first_name=data.first_name;
        object.last_name = data.last_name;
        object.email = data.email;
        object.job_title = data.job_title;
        object.company_name=data.company;
        object.description = data.anything_else;
        object.country = data.country;
        return (`https://${url}/AdminApi/v0/ContactUs`, {
        method: 'POST',
        body: JSON.stringify(object),
        headers: {
            'Content-Type': 'text/plain'
        }
        })
        .then(response => response.json())
        .then(data => {
            return data;

        })
        .catch(error => {
        return error;
        });
    }
    GetBlogs(url){
        return fetch(`https://${url}/AdminAPI/v0/GetBlogs`, {
        method: 'GET',
        headers: {
            'Content-Type': 'text/plain'
        }
        })
        .then(response => response.json())
        .then(data => {
            return data;

        })
        .catch(error => {
        return error;
        });
    }
    GetHubSpotBlogs(url){
        return fetch(`https://${url}/adminapi/v0/GetHubSpotBlogs`, {
        method: 'GET',
        headers: {
            'Content-Type': 'text/plain'
        }
        })
        .then(response => response.json())
        .then(data => {
            return data;

        })
        .catch(error => {
        return error;
        });
    }
    GetBlogsHTML(url){
        return fetch(`https://${url}/AdminAPI/v0/GetBlogsHTML`, {
        method: 'GET',
        headers: {
            'Content-Type': 'text/plain'
        }
        })
        .then(response => response.json())
        .then(data => {
            return data;

        })
        .catch(error => {
        return error;
        });
    }
    ForgetPassword(data , url){
        let object = {};
        object.email= data.email;
        return fetch(`https://${url}/AdminApi/v0/ForgetPasswordTemp`, {
        method: 'POST',
        body: JSON.stringify(object),
        headers: {
            'Content-Type': 'text/plain'
        }
        })
        .then(response => response.json())
        .then(data => {
            return data;

        })
        .catch(error => {
        return error;
        });
    }
    LoginVerification(data , url){
        let object = {};
        object.email = data.email;
        object.verification_token = data.verification_token;
        return fetch(`https://${url}/AdminAPI/v0/VerifyLogin`, {
        method: 'POST',
        body: JSON.stringify(object),
        headers: {
            'Content-Type': 'text/plain'
        }
        })
        .then(response => response.json())
        .then(data => {
            return data;
    
        })
        .catch(error => {
        return error;
        });    
    }
    GetFaqCategories (data , url){
        let object = {};
        object.data = {parent_id : data.id};
        return fetch(`https://${url}/AdminAPI/GetFaqCategories`, {
            method: 'POST',
            body: JSON.stringify(object),
            headers: {
                'Content-Type': 'text/plain'
            }
            })
            
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
            return error;
        });   
    }
    GetFaqs(url){
        return fetch(`https://${url}/AdminAPI/GetFaqs`, {
            method: 'GET',
            })
            
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
            return error;
        });   
    }

    GetPrivacyPolicy(url) {
        return fetch(`https://${url}/AdminAPI/v0/GetPrivacyPolicy`, {
            method: 'GET',
            })
            
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
            return error;
        }); 
    }
    GetTermsOfServices(url) {
        return fetch(`https://${url}/AdminAPI/v0/GetTermsOfServices`, {
            method: 'GET',
            })
            
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
            return error;
        }); 
    }
    GetCategoriesWithFaqs(url){
        return fetch(`https://${url}/AdminAPI/v0/GetCategoriesWithFaqs`, {
            method: 'GET',
            })
            
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
            return error;
        });   
    }
    GetTestimonials(url){
        return fetch(`https://${url}/AdminAPI/GetTestimonials`, {
            method: 'GET',
            })           
            .then(response => response.json())
            .then(data => {
                return data;
            })
            .catch(error => {
            return error;
        });   
    }

    
}
export default APIs;

