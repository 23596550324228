/* eslint-disable */
import React, { useState, useEffect } from "react";
// import blogimg1 from '../../assets/images/blogimg1.png';
// import blogimg2 from '../../assets/images/blogimg2.png';
import blogimg1 from "../../assets/images/SOCIAL-MEDIA.webp";
import blogimg3 from "../../assets/images/RESOURCES.webp";
import blogimg1tab from "../../assets/images/blogimg1tab.webp";
import blogimg2tab from "../../assets/images/blogimg2tab.webp";
import blogimg3tab from "../../assets/images/blogimg3tab.webp";
import { Splide, SplideSlide } from "@splidejs/react-splide";
// Default theme
import "@splidejs/react-splide/css";
import { connect } from "react-redux";
import blogimg2 from "../../assets/images/TOOLS.webp";
// import blogimg3 from '../../assets/images/blogimg3.png';

import blogimg1mbl from "../../assets/images/blogimg1mbl.webp";
import blogimg2mbl from "../../assets/images/blogimg2mbl.webp";
import blogimg3mbl from "../../assets/images/blogimg3mbl.webp";

import blog1 from "./images/blog1.png";
import blog2 from "./images/blog2.jpg";
import blog3 from "./images/blog3.jpg";
import blog4 from "./images/blog4.jpg";

import teststar from "../../assets/images/teststar.png";
import Slider from "react-slick";
import mixpanel from "mixpanel-browser";
import APIs from "../../APIs/API";
import "./blog.css";

const TestinomialComponent = (props) => {
  const parser = new DOMParser();
  const API_Object = new APIs();
  const [items, setItems] = useState([]);
  const [imagesArray, setImagesArray] = useState([]);
  // const [TagsArray, setTagsArray] = useState([]);

  const [count, setcount] = useState(5);
  const [innerwdt, setinnerwdt] = useState(window.innerWidth);

  function htmlToJson(div, obj) {
    if (!obj) {
      obj = [];
    }
    var tag = {};
    tag["tagName"] = div.tagName;
    tag["children"] = [];
    for (var i = 0; i < div.children.length; i++) {
      tag["children"].push(htmlToJson(div.children[i]));
    }
    for (var i = 0; i < div.attributes.length; i++) {
      var attr = div.attributes[i];
      tag["@" + attr.name] = attr.value;
    }
    return tag;
  }
  useEffect(() => {
    setinnerwdt(window.innerWidth);
    if (window.innerWidth >= 1024) {
      document.getElementById("desktopss").style.display = "flex";
      document.getElementById("ipadss").style.display = "none";
      document.getElementById("mobss").style.display = "none";
    }
    if (window.innerWidth > 600 && window.innerWidth < 1024) {
      document.getElementById("desktopss").style.display = "none";
      document.getElementById("ipadss").style.display = "flex";
      document.getElementById("mobss").style.display = "none";
    }
    if (window.innerWidth <= 600) {
      document.getElementById("desktopss").style.display = "none";
      document.getElementById("ipadss").style.display = "none";
      document.getElementById("mobss").style.display = "flex";
    }
    window.addEventListener("resize", function (event) {
      var newWidth = window.innerWidth;
      var newHeight = window.innerHeight;
      if (newWidth > 1024) {
        document.getElementById("desktopss").style.display = "flex";
        document.getElementById("ipadss").style.display = "none";
        document.getElementById("mobss").style.display = "none";
      } else if (newWidth > 600 && newWidth <= 1024) {
        document.getElementById("desktopss").style.display = "none";
        document.getElementById("ipadss").style.display = "flex";
        document.getElementById("mobss").style.display = "none";
      } else {
        document.getElementById("desktopss").style.display = "none";
        document.getElementById("ipadss").style.display = "none";
        document.getElementById("mobss").style.display = "block";
      }
    });
  }, []);

  const getRss = () => {
    API_Object.GetHubSpotBlogs(props.urls.apiBaseURL).then((newblogs) => {
      if (newblogs.status == 200) {
        console.log("hello peeps" , newblogs.hubspot);
        setItems(newblogs.hubspot);
      } else {
      }

    })
    // API_Object.GetBlogs(props.urls.apiBaseURL).then((data) => {
    //   if (data.status == 200) {
    //     // let text = data.data.text();
    //     var json = xmlToJson.parse(data.data);
    //     setItems(json.rss.channel.item);
    //   } else {
    //   }
    // });
    // API_Object.GetBlogsHTML(props.urls.apiBaseURL).then((data) => {
    //   if (data.status == 200) {
    //     let div = document.createElement("div");
    //     div.innerHTML = data.data;
    //     if (document.getElementById("documentation")) {
    //       let bdy = document.getElementById("documentation");
    //       bdy.appendChild(div);
    //       div.style.display = "none";
    //     }


    //     var element = div.getElementsByClassName(
    //       "blog-listings__content blog-listings__content--side-left"
    //     )[0];
    //     //  This gives you a string representing that element and its content
    //     var ok = element.outerHTML;

    //     var data = { html: ok };
    //     var json = JSON.stringify(data);
    //     let imagesarray = [];
    //     // console.log("i want to get tags" , htmlToJson(element).children )
    //     let arr = htmlToJson(element).children;
    //     let mainTags = [];
    //     arr.forEach((e) => {
    //       imagesarray.push(e.children[0].children[0].children[0]["@src"]);

    //       let minarray = [];
    //       e.children[1].children[1].children.map((elm) => {
    //         minarray.push(elm["@href"].split("tag=")[1].replace("+", " "));
    //       });
    //       mainTags.push(minarray);
    //     });
    //     setTagsArray(mainTags);
    //     setTimeout(() => {
    //       setImagesArray(imagesarray);
    //     }, 200);
    //   } else {
    //   }
    // });
  };
  useEffect(() => {
    getRss();
  }, [props.urls]);
  return (
    <>
      <div className="blogcomp" id="documentation">
        <div className="blogdiv">
          <p className="typekit_h2 textBold testbold eltspace">Read our Blog</p>
        </div>
        <div className="testflex blogflex" id="desktopss">
          <Splide
            options={{
              type: "loop",
              perPage: 3,
              width: "100%",
              gap: "1rem",
            }}
          >
            {items.map((item, index) => {
              return (
                <SplideSlide key={index}>
                  {index == 1 ? (
                    <div
                      data-aos="zoom-in-center"
                      className="blogcard blogshadow"
                    >
                      {/* {index == 0 ? 
                                            <img src={blog1} onClick={()=>{  mixpanel.track("blog1"); window.open(item.published_url)}}  style={{cursor:"pointer"}}  className="blogimg" /> : index == 1?
                                            <img src={blog2} onClick={()=>{mixpanel.track("blog2"); window.open(item.published_url)}}  style={{cursor:"pointer"}}  className="blogimg img2" /> : index == 2?
                                            <img src={blog3} onClick={()=>{ mixpanel.track("blog3"); window.open(item.published_url)}}  style={{cursor:"pointer"}}  className="blogimg img3" /> :
                                            <img src={blog4} onClick={()=>{ mixpanel.track("blog3"); window.open(item.published_url)}}  style={{cursor:"pointer"}}  className="blogimg img3" /> 
                                            } */}
                      <img
                        src={item.post_list_summary_featured_image}
                        onClick={() => {
                          mixpanel.track("blog1");
                          window.open(item.published_url);
                        }}
                        style={{ cursor: "pointer" }}
                        className="blogimg"
                      />
                      <div className="txtcard">
                        { item.tags_

                        }
                        {item.tag_ids.length > 0 && item.tag_ids.map((tag) => {
                          return(
                            <>
                            <button
                            onClick={() => {
                              mixpanel.track("blog2");
                              window.open(item.published_url);
                            }}
                            style={{ cursor: "pointer" }}
                            className={ "webkit_FreshGreen webkit_GreenGrass card2button"       
                            }
                          >{tag}
                          </button>
                            </>
                          )
                        })          
                        }
                        <p
                          onClick={() => {
                            mixpanel.track("blog");
                            window.open(item.published_url);
                          }}
                          style={{ cursor: "pointer" }}
                          className="blogtext typekit_LabelXXL webkit_primary_black"
                        >
                          {item.page_title}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div data-aos="zoom-in-center" className="blogcard">
                      {/* {index == 0 ? 
                                              <img src={blog1} onClick={()=>{  mixpanel.track("blog1"); window.open(item.published_url)}}  style={{cursor:"pointer"}}  className="blogimg" /> : index == 1?
                                              <img src={blog2} onClick={()=>{mixpanel.track("blog2"); window.open(item.published_url)}}  style={{cursor:"pointer"}}  className="blogimg img2" /> : index == 2? 
                                              <img src={blog3} onClick={()=>{ mixpanel.track("blog3"); window.open(item.published_url)}}  style={{cursor:"pointer"}}  className="blogimg img3" />:
                                              <img src={blog4} onClick={()=>{ mixpanel.track("blog3"); window.open(item.published_url)}}  style={{cursor:"pointer"}}  className="blogimg img3" />

                                              } */}
                      <img
                        src={item.post_list_summary_featured_image}
                        onClick={() => {
                          mixpanel.track("blog1");
                          window.open(item.published_url);
                        }}
                        style={{ cursor: "pointer" }}
                        className="blogimg"
                      />

                      <div className="txtcard">
                      {item.tag_ids.length > 0 && item.tag_ids.map((tag) => {
                          return(
                            <>
                            <button
                            onClick={() => {
                              mixpanel.track("blog2");
                              window.open(item.published_url);
                            }}
                            style={{ cursor: "pointer" }}
                            className={ "webkit_FreshGreen webkit_GreenGrass card2button"    }
                          >{tag}
                          </button>
                            </>
                          )
                        })          
                        }

                        <p
                          onClick={() => {
                            mixpanel.track("blog");
                            window.open(item.published_url);
                          }}
                          style={{ cursor: "pointer" }}
                          className="blogtext typekit_LabelXXL webkit_primary_black"
                        >
                          {item.page_title}
                        </p>
                      </div>
                    </div>
                  )}
                </SplideSlide>
              );
            })}
          </Splide>
        </div>
        <div className="testflex blogflex" id="ipadss">
          <Splide
            options={{
              type: "loop",
              perPage: 3,
              width: "103%",
              gap: "1rem",
            }}
          >
            {items.map((item, index) => {
              return (
                <SplideSlide key={index}>
                  <div data-aos="zoom-in-center" className="blogcard">
                    <img
                      src={item.post_list_summary_featured_image}
                      onClick={() => {
                        mixpanel.track("blog1");
                        window.open(item.published_url);
                      }}
                      style={{ cursor: "pointer" }}
                      className="blogimg"
                    />
                    {/* {index == 0 ? 
                                        <img src={blog1} onClick={()=>{  mixpanel.track("blog1"); window.open(item.published_url)}}  style={{cursor:"pointer"}}  className="blogimg" /> : index == 1?
                                        <img src={blog2} onClick={()=>{mixpanel.track("blog2"); window.open(item.published_url)}}  style={{cursor:"pointer"}}  className="blogimg img2" /> : index == 2?
                                        <img src={blog3} onClick={()=>{ mixpanel.track("blog3"); window.open(item.published_url)}}  style={{cursor:"pointer"}}  className="blogimg img3" /> :
                                        <img src={blog4} onClick={()=>{ mixpanel.track("blog3"); window.open(item.published_url)}}  style={{cursor:"pointer"}}  className="blogimg img3" />

                                        } */}

                    <div className="txtcard">
                    {item.tag_ids.length > 0 && item.tag_ids.map((tag) => {
                          return(
                            <>
                            <button
                            onClick={() => {
                              mixpanel.track("blog2");
                              window.open(item.published_url);
                            }}
                            style={{ cursor: "pointer" }}
                            className={ "webkit_FreshGreen webkit_GreenGrass card2button" }
                          >{tag}
                          </button>
                            </>
                          )
                        })          
                        }

                      <p
                        onClick={() => {
                          mixpanel.track("blog");
                          window.open(item.published_url);
                        }}
                        style={{ cursor: "pointer" }}
                        className="blogtext typekit_LabelRegularBold"
                      >
                        {item.page_title}
                      </p>
                    </div>
                  </div>
                </SplideSlide>
              );
            })}
          </Splide>
        </div>
        <div className="mobfl" id="mobss">
          <Splide
            options={{
              type: "loop",
              padding: "5%",
              autoplay: true,
              lazyLoadL: true,
            }}
          >
            {items.map((item, index) => {
              return (
                <SplideSlide key={index}>
                  <div data-aos="zoom-in-center" className="blogcard">
                    {/* {index == 0 ? 
                                                <img src={blog1} onClick={()=>{  mixpanel.track("blog1"); window.open(item.published_url)}}  style={{cursor:"pointer"}}  className="blogimg" /> : index == 1?
                                                <img src={blog2} onClick={()=>{mixpanel.track("blog2"); window.open(item.published_url)}}  style={{cursor:"pointer"}}  className="blogimg img2" /> : index == 2?
                                                <img src={blog3} onClick={()=>{ mixpanel.track("blog3"); window.open(item.published_url)}}  style={{cursor:"pointer"}}  className="blogimg img3" /> :
                                                <img src={blog4} onClick={()=>{ mixpanel.track("blog3"); window.open(item.published_url)}}  style={{cursor:"pointer"}}  className="blogimg img3" />

                                                } */}
                    <img
                      src={item.post_list_summary_featured_image}
                      onClick={() => {
                        mixpanel.track("blog1");
                        window.open(item.published_url);
                      }}
                      style={{ cursor: "pointer" }}
                      className="blogimg"
                    />

                    <div className="txtcard">
                    {item.tag_ids.length > 0 && item.tag_ids.map((tag) => {
                          return(
                            <>
                            <button
                            onClick={() => {
                              mixpanel.track("blog2");
                              window.open(item.published_url);
                            }}
                            style={{ cursor: "pointer" }}
                            className={ "webkit_FreshGreen webkit_GreenGrass card2button"
                            }
                          >{tag}
                          </button>
                            </>
                          )
                        })          
                        }
                      <p
                        onClick={() => {
                          mixpanel.track("blog");
                          window.open(item.published_url);
                        }}
                        style={{ cursor: "pointer" }}
                        className="blogtext typekit_LabelRegularBold webkit_primary_black"
                      >
                        {item.page_title}
                      </p>
                    </div>
                  </div>
                </SplideSlide>
              );
            })}
          </Splide>
        </div>
      </div>
    </>
  );
};

function map(state) {
  return state;
}
export default connect(map)(TestinomialComponent);
