import React , {useState , useEffect} from "react";
import { connect } from "react-redux";
import Aos from 'aos';
import 'aos/dist/aos.css'; 
import './vision.css';
import '../about.css';
import vision from './images/vision.webp';
import visiontab from './images/visiontab.webp';
import visionmbl from './images/visionmbl.webp';


const Vision = (props) => {
    const [innerwdt , setinnerwdt] = useState(window.innerWidth);
    useEffect(() => {
        Aos.init();
        setinnerwdt(window.innerWidth);
        window.addEventListener('resize' , () => {
            setinnerwdt(window.innerWidth);
        })
    }, []);

    return(
        <>
        <div className="main_vision">
            <div className="main_grey">
                <div data-aos="zoom-out-down" className="vision_text">
                    <h2 className="webkit_primary_h vision_headings typekit_h2">Our Vision</h2>
                    <p className={innerwdt > 500 ? "webkit_primary_h typekit_subheadline1 vdesc" : "webkit_primary_h typekit_subheadline2 vdesc" }>Open the world of live media & web3 to every entrepreneur & developer
                    and empower them to bring people to a new engaging reality.<br></br>
                    We call it live revolution.</p>
                </div>
               

            </div>
            <div data-aos="zoom-out-down" className="vision_image">
                    
                    {innerwdt > 1024 ? <img className="vision" src={vision} alt="vision" /> 
                    : innerwdt > 600 && innerwdt <= 1024 ? 
                    <img className="vision" src={visiontab} alt="vision" />  :
                    <img className="vision" src={visionmbl} alt="vision" />
                     }

                </div>
        </div>  
        </>
    )
}


function map(state){
    return state;
}
export default connect(map)(Vision);