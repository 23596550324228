import { combineReducers } from "redux";
import { user } from "./User";
import { tempuser } from "./tempUser";
import { urls } from "./URLS";
import { openPopups } from "./Popup";
import { faqs } from "./faqs";
import { userdone } from "./userDone";
import { termsofservice } from "./termsofservice";
import { privacypolicy } from "./privacypolicy";
const AllReducer=combineReducers({user , openPopups , urls , tempuser , userdone , faqs , privacypolicy , termsofservice});
export default AllReducer;