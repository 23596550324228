import { Splide, SplideSlide } from "@splidejs/react-splide";
// Default theme
import "@splidejs/react-splide/css";

// or other themes

import React, { useEffect, useState, Fragment } from "react";
import test1 from "../../assets/images/test1.png";
import test2 from "../../assets/images/test2.png";
import test3 from "../../assets/images/test3.png";
import testblank from "../../assets/images/testblank.png";
import testfill from "../../assets/images/testfill.png";
import teststar from "../../assets/images/teststar.png";
import roundyellow from "../../assets/images/roundyellow.png";
import greystar from "../../assets/images/greystar.png";
import fillstar from "../../assets/images/fillstar.png";
import ReactStars from "react-rating-stars-component";
import { connect } from "react-redux";
import APIs from "../../APIs/API";
import Slider from "react-slick";
import "./testimonials.css";

const TestinomialComponent = (props) => {
  // console.log("first",props)
  const API_Object = new APIs();
  const [items, setItems] = useState([]);
  const [count, setcount] = useState(5);
  const [innerwdt, setinnerwdt] = useState(window.innerWidth);
  useEffect(() => {
    setinnerwdt(window.innerWidth);
    window.addEventListener("resize", () => {
      setinnerwdt(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    API_Object.GetTestimonials(props.urls.apiBaseURL).then((data) => {
      if (data.status == 200) {
        setItems(data.data);
      }
    });
  }, [props.urls]);

  useEffect(() => {
    if (window.innerWidth >= 1024) {
      document.getElementById("desktops").style.display = "flex";
      document.getElementById("ipads").style.display = "none";
      document.getElementById("mobs").style.display = "none";
    }
    if (window.innerWidth > 600 && window.innerWidth < 1024) {
      document.getElementById("desktops").style.display = "none";
      document.getElementById("ipads").style.display = "flex";
      document.getElementById("mobs").style.display = "none";
    }
    if (window.innerWidth < 700) {
      document.getElementById("desktops").style.display = "none";
      document.getElementById("ipads").style.display = "none";
      document.getElementById("mobs").style.display = "block";
    }
    window.addEventListener("resize", function (event) {
      var newWidth = window.innerWidth;
      var newHeight = window.innerHeight;
      // console.log("resize")
      if (newWidth > 1024) {
        document.getElementById("desktops").style.display = "flex";
        document.getElementById("ipads").style.display = "none";
        document.getElementById("mobs").style.display = "none";
      } else if (newWidth > 700 && newWidth <= 1024) {
        document.getElementById("desktops").style.display = "none";
        document.getElementById("ipads").style.display = "flex";
        document.getElementById("mobs").style.display = "none";
      } else {
        document.getElementById("desktops").style.display = "none";
        document.getElementById("ipads").style.display = "none";
        document.getElementById("mobs").style.display = "block";
      }
    });
  }, []);

  return (
    <>
      <div className="testinomial">
        <div className="Textbolddiv testdiv">
          <p className="webkit_primary_black testimonialhbold typekit_h2">
            Testimonials
          </p>
          {innerwdt > 700 ? (
            <p className="webkit_primary_black typekit_subheadline2">
              We empower customers in dozens of industries and occupations.
              <br></br>
              Read what they find VdoTok useful for.
            </p>
          ) : (
            <p className="webkit_primary_black typekit_subheadline2">
              We empower customers in dozens of industries and occupations. Read
              what they find VdoTok useful for.
            </p>
          )}
        </div>
        <div className="testflex" id="desktops">
          <Splide
            options={{
              rewind: true,
              gap: "1rem",
              perPage: 2,
            }}
            aria-label="My Favorite Images"
          >
            {items.map((dy, index) => {
              return (
                <Fragment key={dy.id}>
                  <SplideSlide>
                    <div data-aos="zoom-in-left" className="testcard">
                      <img
                        src={dy.image}
                        className="testimg img2 img3"
                        alt="test-img"
                      />
                      <div className="txtcard">
                        <p className="webkit_primary_black typekit_LabelRegular">
                          {dy.description}
                        </p>
                      </div>
                      <div className="greydiv">
                        <div className="testbottomflex">
                          <div className="descriptiontest">
                            <p className="testp2 typekit_LabelRegularBold webkit_primary_h eltspace">
                              {dy.name}
                            </p>
                            <p className="testp3 typekit_LabelLight webkit_primary_grey eltspace">
                              {dy.designation}
                            </p>
                          </div>
                          <img
                            className="companylogo"
                            src={dy.company_logo}
                            alt="company-logo"
                          />
                        </div>
                        <div>
                          <p className="testp4 typekit_LabelLight webkit_primary_grey">
                            {dy.company_description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </SplideSlide>
                </Fragment>
              );
            })}
          </Splide>
        </div>
        <div className="testflex" id="ipads">
          <Splide
            options={{
              type: "loop",
              padding: "20%",
              perPage: 1,
              autoplay: true,
              lazyLoadL: true,
            }}
            aria-label="My Favorite Images"
          >
            {items.map((dy, index) => {
              return (
                <Fragment key={dy.id}>
                  <SplideSlide>
                    <div data-aos="zoom-in-left" className="testcard">
                      <img
                        src={dy.image}
                        className="testimg img2 img3"
                        alt="test-img"
                      />
                      <div className="txtcard">
                        <p className="typekit_LabelRegular webkit_primary_Dark testp1">
                          {dy.description}
                        </p>
                      </div>
                      <div className="greydiv">
                        <div className="testbottomflex">
                          <div className="descriptiontest">
                            <p className="testp2 typekit_LabelRegularBold webkit_primary_h eltspace">
                              {dy.name}
                            </p>
                            <p className="testp3 typekit_LabelLight webkit_primary_grey eltspace">
                              {dy.designation}
                            </p>
                          </div>
                          <img
                            className="companylogo"
                            src={dy.company_logo}
                            alt="company-logo"
                          />
                        </div>
                        <div>
                          <p className="testp4 typekit_LabelThin webkit_primary_grey">
                            {dy.company_description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </SplideSlide>
                </Fragment>
              );
            })}
          </Splide>
        </div>
        <div id="mobs" className="mobfl">
          <Splide
            options={{
              type: "loop",
              padding: "5%",
              perPage: 1,
              autoplay: true,
              lazyLoadL: true,
            }}
            aria-label="My Favorite Images"
          >
            {items.map((dy, index) => {
              return (
                <Fragment key={dy.id}>
                  <SplideSlide>
                    <div data-aos="zoom-in-left" className="testcard">
                      <img
                        src={dy.image}
                        className="testimg img2 img3"
                        alt="test-img"
                      />
                      <div className="txtcard">
                        <p className="typekit_LabelRegular webkit_primary_Dark testp1">
                          {dy.description}
                        </p>
                      </div>
                      <div className="greydiv">
                        <div className="testbottomflex">
                          <div className="descriptiontest">
                            <p className="testp2 typekit_LabelRegularBold webkit_primary_h eltspace">
                              {dy.name}
                            </p>
                            <p className="testp3 typekit_LabelLight webkit_primary_grey eltspace">
                              {dy.designation}
                            </p>
                          </div>
                          <img
                            className="companylogo"
                            src={dy.company_logo}
                            alt="company-logo"
                          />
                        </div>
                        <div>
                          <p className="testp4 typekit_LabelLight webkit_primary_grey">
                            {dy.company_description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </SplideSlide>
                </Fragment>
              );
            })}
          </Splide>
        </div>
      </div>
    </>
  );
};

function map(state) {
  return state;
}
export default connect(map)(TestinomialComponent);
