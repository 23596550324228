
const openPopups=(state=
{

} , action)=>{
    switch(action.type){
    case 'SignUpPopup':         
        return {...state , SignupPopup: true}
    case 'EmailVerPopup':
        return {...state , EmailVerPopup: true}
    case 'EnterPhonePopup':
        return {...state , EnterPhonePopup: true}
    case 'PhoneVerPopup':
        return {...state , PhoneVerPopup: true}
    case 'ThankYouPopup':
        return {...state , ThankYouPopup: true}
    case 'AboutYouPopup':
        return {...state , AboutYouPopup: true}
    default:
        return state;
    }
  
  };
export {openPopups}