import React, {useState , useEffect} from "react";
import { connect } from "react-redux";
import './locations.css';
import Aos from 'aos';
import 'aos/dist/aos.css'; 
import sweden from './images/Sweden.webp';
import pakistan from './images/Pakistan.webp';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import mapimg from './images/Map.webp';
import tabmap from './images/tabmap.webp';
import mblmap from './images/mblmap.webp';

import '../about.css';
const containerStyle = {
    height: '100%'
  };
  
  const center = {
    lat: -3.745,
    lng: -38.523
  };
const Locations = (props) => {
    const [innerwdt , setinnerwdt] = useState();
    useEffect(() => {
        Aos.init();
        setinnerwdt(window.innerWidth);
        if(window.innerWidth >= 1024 ){
            document.getElementById('mapdesktop').style.display="flex";
            document.getElementById('maptab').style.display="none";
            document.getElementById('mapmbl').style.display="none";
        }
        if(window.innerWidth > 600 && window.innerWidth < 1024){
            document.getElementById('mapdesktop').style.display="none";
            document.getElementById('maptab').style.display="flex";
            document.getElementById('mapmbl').style.display="none";
        }
        if(window.innerWidth <= 600){
            document.getElementById('mapdesktop').style.display="none";
            document.getElementById('maptab').style.display="none";
            document.getElementById('mapmbl').style.display="flex";

        }
        window.addEventListener('resize' , () => {
            setinnerwdt(window.innerWidth);
            if(window.innerWidth >= 1024 ){
                document.getElementById('mapdesktop').style.display="flex";
                document.getElementById('maptab').style.display="none";
                document.getElementById('mapmbl').style.display="none";
            }
            if(window.innerWidth > 600 && window.innerWidth < 1024){
                document.getElementById('mapdesktop').style.display="none";
                document.getElementById('maptab').style.display="flex";
                document.getElementById('mapmbl').style.display="none";
            }
            if(window.innerWidth <= 600){
                document.getElementById('mapdesktop').style.display="none";
                document.getElementById('maptab').style.display="none";
                document.getElementById('mapmbl').style.display="flex";
    
            }
        })
    }, []);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDEBTjQOzsYrbjBT7tFQyJ5vZkw5YBji2E&v=3.exp&libraries=geometry,drawing,places"
      })
    
      const [map, setMap] = React.useState(null)
    
      const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map)
      }, [])
    
      const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
      }, [])
    return(
        <>
        <div className="main_location">
            <div data-aos="zoom-out-down" className="location_text">
                <h2 className="webkit_primary_h vision_headings typekit_h2">Our locations</h2>
                <p className= {innerwdt > 1024 ? "webkit_primary_h typekit_subheadline2 ldesc" : "webkit_primary_h typekit_subheadline3 ldesc" }>In case you want to meet up</p>
            </div>  
            <div  data-aos="zoom-out-down" className="locationflex">
                <div className="txtlocation">
                    <div className="d1">
                        <div className="locth loch2space">
                            <p className={innerwdt > 1024 ? "typekit_h4 webkit_primary_bluish eltspace" : innerwdt > 500 ? "typekit_h5 webkit_primary_bluish eltspace" :
                        "typekit_h6 webkit_primary_bluish eltspace"} style={{whiteSpace:'nowrap'}}>Headquarters in Stockholm</p>
                            <img className="sweden" src={sweden} />
                        </div>
                        <div className="loctp" >
                           <p className={innerwdt > 500 ? "typekit_BodyRegular webkit_primary_h eltspace" : "typekit_BodySmall webkit_primary_h eltspace" }>
                            Epicenter Stockholm <br></br>
                            Mäster Samuelsgatan 36 <br></br>
                            111 57 Stockholm
                           </p>
                        </div>
                    </div>
                    <div className="d2">
                        <div className="locth loch2space">
                            <p className={innerwdt > 1024 ? "typekit_h4 webkit_primary_bluish eltspace" : innerwdt > 500 ? "typekit_h5 webkit_primary_bluish eltspace" :
                        "typekit_h6 webkit_primary_bluish eltspace"} style={{whiteSpace:'nowrap'}}>Office in Pakistan</p>
                            <img className="sweden" src={pakistan} />
                        </div>
                        <div className="loctp" >
                           <p className={innerwdt > 500 ? "typekit_BodyRegular webkit_primary_h eltspace" : "typekit_BodySmall webkit_primary_h eltspace" } style={{whiteSpace:'nowrap'}}>
                           11-B. Old FCC. Ferozepur Road Gulberg 3 <br></br>
                            Lahore 54000 <br></br>
                            Pakistan
                           </p>
                        </div>
                    </div>
                </div>
                <div className="mapdiv">
                
                <img src={mapimg} id="mapdesktop" className="imgofmap" alt="image-map" />
                <img src={tabmap} id="maptab" className="tabmap" alt="image-map" /> 
                <img src={mblmap} id="mapmbl" className="mblmap" alt="image-map" /> 
                {/* {isLoaded ? (
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={10}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                >
                    <></>
                </GoogleMap> ) : <></> } */}
                </div>
            </div>
        </div>  
        </>
    )
}


function map(state){
    return state;
}
export default connect(map)(Locations);