import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Aos from "aos";
import startls from "../../../assets/images/start_ls.webp";
import startlstab from "./images/imgtab.webp";
import startlsmbl from "./images/imgmbl.webp";

import "aos/dist/aos.css";
import "./start_ls_today.css";

const StartLSToday = (props) => {
  const [innerwdt, setinnerwdt] = useState(window.innerWidth);
  // console.log("myprops" , props);
  useEffect(() => {
    Aos.init();
    setinnerwdt(window.innerWidth);
    window.addEventListener("resize", () => {
      setinnerwdt(window.innerWidth);
    });
  }, []);

  return (
    <>
      <div className="start_LS_today">
        <div className="_start_Ls_main">
          {innerwdt > 1024 ? (
            <p className="typekit_h2 webkit_primary_h">
              Start building your own
              <br></br>live shopping solution today
            </p>
          ) : innerwdt > 500 ? (
            <p className="typekit_h2 webkit_primary_h">
              Start building your own live shopping solution today
            </p>
          ) : (
            <p className="typekit_h2 webkit_primary_h">
              Start building your own live shopping solution today
            </p>
          )}

          <p
            className={
              innerwdt > 1024
                ? "typekit_subheadline4 webkit_primary_h _start_ls_P"
                : innerwdt > 500
                ? "typekit_subheadline1 webkit_primary_h _start_ls_P"
                : "typekit_subheadline3 webkit_primary_h _start_ls_P"
            }
          >
            Use our powerful and easy-to-use APIs to integrate the new
            generation of video and voice calls, messaging, live streaming and
            screen sharing to your e-commerce product and transform your
            customers’ lives by bringing them into the new reality.
          </p>
        </div>
        {innerwdt > 1024 ? (
          <img src={startls} className="start-ls-img" />
        ) : innerwdt > 600 ? (
          <img src={startlstab} className="start-ls-img" />
        ) : (
          <img src={startlsmbl} className="start-ls-img" />
        )}
        <button
          className="start-ls-btn"
          // onClick={() => {
          //   props.openSignupPopup();
          // }}
        >
          TRY FOR FREE
        </button>
      </div>
    </>
  );
};

function map(state) {
  return state;
}
export default connect(map)(StartLSToday);
