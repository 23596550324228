import React, { useEffect, useState } from "react";
import "./style.css";
// import arrowleft from "../../assets/images/arrowleft.webp";
import { connect } from "react-redux";
import APIs from "../../APIs/API";
import mail from "./images/Mail.webp";
import mixpanel from "mixpanel-browser";

const FAQuestions = (props) => {
  const API_Object = new APIs();
  const [FAQData, setFAQData] = useState([]);

  useEffect(() => {
    API_Object.GetFaqs(props.urls.apiBaseURL).then((data) => {
      if (data.status == 200) {
        setFAQData(data.data);
      } else {
      }
    });
  }, []);
  const SendMail = () => {
    mixpanel.track("email");
    var link = "mailto:info@vdotok.com";
    window.location.href = link;
  };

  return (
    <>
      <div className="FAQuestions">
        <div className="faqcontent">
          <p className="faquestionheading01 typekit_h3">
            Most frequent questions
          </p>
          {FAQData.map((det, index) => {
            return (
              <div className="dev" key={index}>
                <div className="directions">
                  <p className="faquesp typekit_h6">{det.question}</p>
                  <p className="curve"></p>
                </div>
                <p id={det.id} className="faquesp grey typekit_BodyRegular">
                  {det.answer}
                </p>
              </div>
            );
          })}
        </div>
        <div className="faqbox">
          <div className="box">
            <div className="faqflex">
              <div className="flextext">
                <p className="small typekit_subheadline3">I need more help</p>
                <p className="greytextbold  typekit_h4">
                  Can't find an answer?
                </p>
              </div>
              <div>
                <img className="mail" src={mail} alt="mail" />
              </div>
            </div>
            <button
              className="Darkbluebtn ctsbtn"
              // onClick={() => {
              //   mixpanel.track("contact_us");
              //   props.openContactusPopup();
              // }}
            >
              Contact us
            </button>
            <div className="greytext001">
              <p className="typekit_BodyRegular webkit_primary_grey">
                We’re here to help! If you have technical issues, want to talk
                to our sales team or developers, please click on the button
                above to get in touch.
              </p>
              <p className="typekit_BodyRegular webkit_primary_grey">
                For general queries, including partnership opportunities, please
                email{" "}
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    SendMail();
                  }}
                >
                  info@vdotok.com
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function map(state) {
  return state;
}
export default connect(map)(FAQuestions);
