import React, { useState } from "react";
import "./styles.css";

const CancelButton = (props) => {
  const { title = "send code", onClick = () => {}, disabled, className } = props;
  return (
    <button
      className={`button-container border_btn ${className}`}
      onClick={() => onClick()}
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export default CancelButton;
