import React, { useEffect, useState } from "react";
import logo from "./images/logo2.webp";
import cross from "./images/x-circle.webp";
// import xcirclewhite from './images/x-circle-white.webp';
// import logowhite from './images/logo-white.webp';
import keydown from "./images/keydown.webp";
import keyup from "./images/keyup.webp";
import axios from "axios";
import arrowright from "./images/arrowright.webp";
import person from "./images/person.webp";
import tick from "./images/tick.webp";
import APIs from "../../APIs/API";
import { connect } from "react-redux";
import "./style.css";
import mixpanel from "mixpanel-browser";

const ContactUsPopup = (props) => {
  let API_Object = new APIs();
  const [companyErrContact, setCompanyErrContact] = useState("");
  const [anythingElseErr, setAnythingElseErr] = useState("");
  const [jobTitleErrContact, setJobTitleErrContact] = useState("");
  const [showCountryDropDownContact, setShowCountryDropDownContact] =
    useState(false);
  const [selectedCountryContact, setSelectedCountryContact] = useState(null);
  const [searchString, setSearchString] = useState("");
  const [firstNameContact, setFirstNameContact] = useState("");
  const [secondNameContact, setSecondNameContact] = useState("");
  const [emailContact, setEmailContact] = useState("");
  const [companyContact, setCompanyContact] = useState("");
  const [jobTitleContact, setJobTitleContact] = useState("");
  const [anythingelse, setAnythingElse] = useState("");
  const [loading, setLoading] = useState(false);
  const [genericError, setGenericError] = useState("");
  const [firstNameErrContact, setFirstNameErrContact] = useState("");
  const [secondNameErrContact, setSecondNameErrContact] = useState("");
  const [emailErrContact, setEmailErrContact] = useState("");
  const [countryErrContact, setCountryErrContact] = useState("");

  const HideMe = () => {
    props.closeContactusPopup();
  };
  const ContactUs = () => {
    mixpanel.track("submit_contact");
    let validate = true;
    let specialChFormat = /[~!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    let chFormatForOneUpperCase = "(?=.*?[A-Z])";
    let chFormatForOneLowerCase = "(?=.*?[a-z])";
    let chFormatForOneDigitCase = "(?=.*?[0-9])";
    let emailFormat = "[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}";
    if (firstNameContact.trim().length === 0) {
      setFirstNameErrContact("Please enter first name !");
      validate = false;
    } else if (firstNameContact.trim().length < 2) {
      validate = false;
      setFirstNameErrContact("Minimum 2 characters required !");
    }
    if (secondNameContact.trim().length === 0) {
      setSecondNameErrContact("Please enter last name !");
      validate = false;
    } else if (secondNameContact.trim().length < 2) {
      validate = false;
      setSecondNameErrContact("Minimum 2 characters required !");
    }
    if (emailContact.trim().length === 0) {
      setEmailErrContact("Please enter email !");
      validate = false;
    } else if (!emailContact.match(emailFormat)) {
      setEmailErrContact("Invalid email format !");
      validate = false;
    }
    if (companyContact.trim().length === 0) {
      setCompanyErrContact("Please enter Company name !");
      validate = false;
    } else if (companyContact.trim().length < 2) {
      validate = false;
      setCompanyErrContact("Minimum 2 characters required !");
    }

    if (!selectedCountryContact) {
      validate = false;
      setCountryErrContact("Please Choose country contact!");
    } else {
      setCountryErrContact("");
    }
    if (validate) {
      //   alert("validated");
      setLoading(true);
      setGenericError("");
      let object = {
        auth_token: props.user.auth_token,
        first_name: firstNameContact,
        last_name: secondNameContact,
        email: emailContact,
        company: companyContact,
        job_title: jobTitleContact,
        country: selectedCountryContact.id,
        anything_else: anythingelse,
      };
      API_Object.ContactUs(object, props.urls.apiBaseURL).then((data) => {
        if (data.status == 200) {
          mixpanel.track("contactUs_successfull");
          HideMe();
          setLoading(false);
        } else {
          mixpanel.track("contactUs_error");
          setGenericError(data.message);
          setLoading(false);
        }
      });
    }
  };
  useEffect(() => {
    var elmnt = document.getElementById("updiv");
    if (elmnt) {
      setTimeout(() => {
        elmnt.scrollIntoView({ behavior: "smooth" });
        // console.log("scrolling");
      }, 100);
    }
  }, []);
  useEffect(() => {
    axios
      .get("https://extreme-ip-lookup.com/json/?key=gGJby1xemMxbBa2pfT2S")
      .then((response) => {
        let data = response.data;
        if (data.countryCode) {
          let specificCountry = props.countries.filter(
            (e) => e.code === data.countryCode
          );
          if (specificCountry) {
            setSelectedCountryContact(specificCountry[0]);
          } else {
            setSelectedCountryContact(props.countries[0]);
          }
        }
      })
      .catch((error) => {});
  }, []);
  useEffect(() => {
    //   document.addEventListener('mouseup', function(e) {
    //   var container_contact = document.getElementById('navmodal-contact');
    //   var main_contact =  document.getElementById('modalmain-contact');
    //   if(container_contact && main_contact){
    //   if (!container_contact.contains(e.target)) {
    //       HideMe();
    //   }
    //   }
    //   });
  }, []);
  let countriesList =
    searchString.trim().length === 0
      ? props.countries
      : props.countries.filter((e) =>
          e.name.toLowerCase().startsWith(searchString.toLowerCase())
        );
  return (
    <>
      <div
        onClick={() => {
          setShowCountryDropDownContact(false);
          setSearchString("");
        }}
        className="signupContainer"
        id="contactus_container"
      >
        <div className="modalmain-contact" id="modalmain-contact">
          <div className="navmodal-contact" id="navmodal-contact">
            <div className="partition">
              <p className="heading-signup headng-contactus">Get in touch</p>
              {genericError && (
                <p className="errorMessage start">{genericError}</p>
              )}
              <div className="parent-inputs contact-desktop">
                <div className="div-contact-in">
                  <p className="p-signup ">First name*</p>
                  <input
                    placeholder="First name"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        ContactUs();
                      }
                    }}
                    value={firstNameContact}
                    onChange={(e) => {
                      mixpanel.track("adding_firstname");
                      setFirstNameContact(e.target.value);
                      setFirstNameErrContact("");
                    }}
                    className="input-contact half"
                  />
                  {firstNameErrContact && (
                    <p className="errorMessage start">{firstNameErrContact}</p>
                  )}
                </div>
                <div className="div-contact-in">
                  <p className="p-signup">Last name*</p>
                  <input
                    placeholder="Last name"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        ContactUs();
                      }
                    }}
                    value={secondNameContact}
                    onChange={(e) => {
                      mixpanel.track("adding_lastname");
                      setSecondNameContact(e.target.value);
                      setSecondNameErrContact("");
                    }}
                    className="input-contact full"
                  />
                  {secondNameErrContact && (
                    <p className="errorMessage start">{secondNameErrContact}</p>
                  )}
                </div>
              </div>
              <div className="parent-inputs contact-mobile">
                <div>
                  <p className="p-signup ">First name*</p>
                  <input
                    placeholder="First name"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        ContactUs();
                      }
                    }}
                    value={firstNameContact}
                    onChange={(e) => {
                      mixpanel.track("adding_contact");
                      setFirstNameContact(e.target.value);
                      setFirstNameErrContact("");
                    }}
                    className="input-contact full"
                  />
                  {firstNameErrContact && (
                    <p className="errorMessage start">{firstNameErrContact}</p>
                  )}
                </div>
                <div>
                  <p className="p-signup">Last name*</p>
                  <input
                    placeholder="Last name"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        ContactUs();
                      }
                    }}
                    value={secondNameContact}
                    onChange={(e) => {
                      mixpanel.track("adding_lastname");
                      setSecondNameContact(e.target.value);
                      setSecondNameErrContact("");
                    }}
                    className="input-contact full"
                  />
                  {secondNameErrContact && (
                    <p className="errorMessage start">{secondNameErrContact}</p>
                  )}
                </div>
                <div>
                  <p className="p-signup"> Work e-mail*</p>
                  <input
                    placeholder="Work email"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        ContactUs();
                      }
                    }}
                    value={emailContact}
                    onChange={(e) => {
                      mixpanel.track("adding_email");
                      setEmailContact(e.target.value);
                      setEmailErrContact("");
                    }}
                    className="input-contact full"
                  />
                  {emailErrContact && (
                    <p className="errorMessage start">{emailErrContact}</p>
                  )}
                </div>
              </div>
              <div className="web-email email-contact">
                <p className="p-signup"> Work e-mail*</p>
                <input
                  placeholder="Work name"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      ContactUs();
                    }
                  }}
                  value={emailContact}
                  onChange={(e) => {
                    mixpanel.track("adding_workemail");
                    setEmailContact(e.target.value);
                    setEmailErrContact("");
                  }}
                  className="input-contact full"
                  type="email"
                />
                {emailErrContact && (
                  <p className="errorMessage start">{emailErrContact}</p>
                )}
              </div>
              <div className="parent-inputs contact-desktop">
                <div className="div-contact-in">
                  <p className="p-signup ">Company*</p>
                  <input
                    placeholder="Company"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        ContactUs();
                      }
                    }}
                    value={companyContact}
                    onChange={(e) => {
                      mixpanel.track("adding_company");
                      setCompanyContact(e.target.value);
                      setCompanyErrContact("");
                    }}
                    className="input-contact half"
                  />
                  {companyErrContact && (
                    <p className="errorMessage start">{companyErrContact}</p>
                  )}
                </div>
                <div className="div-contact-in">
                  <p className="p-signup">Job title</p>
                  <input
                    placeholder="Job title"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        ContactUs();
                      }
                    }}
                    value={jobTitleContact}
                    onChange={(e) => {
                      mixpanel.track("adding_job_title");
                      setJobTitleContact(e.target.value);
                      setJobTitleErrContact("");
                    }}
                    className="input-contact full"
                  />
                  {jobTitleErrContact && (
                    <p className="errorMessage start">{jobTitleErrContact}</p>
                  )}
                </div>
              </div>
              <div className="parent-inputs contact-mobile">
                <div>
                  <p className="p-signup ">Company*</p>
                  <input
                    placeholder="Company"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        ContactUs();
                      }
                    }}
                    value={companyContact}
                    onChange={(e) => {
                      mixpanel.track("adding_company");
                      setCompanyContact(e.target.value);
                      setCompanyErrContact("");
                    }}
                    className="input-contact full"
                  />
                  {companyErrContact && (
                    <p className="errorMessage start">{companyErrContact}</p>
                  )}
                </div>
                <div>
                  <p className="p-signup">Job title</p>
                  <input
                    placeholder="Job title"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        ContactUs();
                      }
                    }}
                    value={jobTitleContact}
                    onChange={(e) => {
                      mixpanel.track("adding_job_title");
                      setJobTitleContact(e.target.value);
                      setJobTitleErrContact("");
                    }}
                    className="input-contact full"
                  />
                  {jobTitleErrContact && (
                    <p className="errorMessage start">{jobTitleErrContact}</p>
                  )}
                </div>
              </div>
              <div className="parent-country">
                <p className="p-signup">Country*</p>
                <div
                  onClick={(e) => {
                    setShowCountryDropDownContact(!showCountryDropDownContact);
                    e.stopPropagation();
                  }}
                  className="input-password input-country input-signup full"
                >
                  {selectedCountryContact ? (
                    <div className="dropdowntext">
                      <img
                        src={`https://flagcdn.com/28x21/${selectedCountryContact.code.toLowerCase()}.png`}
                        className="dropFlag"
                        alt={selectedCountryContact.code}
                      />{" "}
                      {selectedCountryContact.name}
                    </div>
                  ) : (
                    <div>Loading</div>
                  )}
                  <div>
                    {showCountryDropDownContact ? (
                      <img src={keyup} className="dropIcon" />
                    ) : (
                      <img src={keydown} className="dropIcon" />
                    )}
                  </div>
                </div>
                {showCountryDropDownContact && (
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="dropdownBody"
                  >
                    <div className="dropbodySearchBar">
                      <input
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            ContactUs();
                          }
                        }}
                        value={searchString}
                        onChange={(e) => {
                          mixpanel.track("adding_country");
                          setSearchString(e.target.value);
                        }}
                        // type="text"
                        placeholder="Search"
                        className="searchInputSignup"
                      />
                    </div>
                    <div className="countryScroller">
                      {countriesList.length == 0 && (
                        <div className="singleCountryContainer">
                          <div className="dropdowntext">No Result..</div>
                        </div>
                      )}
                      {countriesList.map((item, index) => {
                        return (
                          <div
                            onClick={() => {
                              setSelectedCountryContact(item);
                              setShowCountryDropDownContact(false);
                              setSearchString("");
                            }}
                            key={index}
                            className="singleCountryContainer"
                          >
                            <div className="dropdowntext">
                              <img
                                src={`https://flagcdn.com/28x21/${item.code.toLowerCase()}.png`}
                                className="dropFlag"
                                alt={item.code}
                              />{" "}
                              {item.name}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {countryErrContact && (
                  <p className="errorMessage start">{countryErrContact}</p>
                )}
                {/* <input onClick={filterFunction} id="search-input" className="input-signup full" type="email" value="Doe"/> */}
              </div>
              <div>
                <p className="p-signup">Anything else?</p>
                <textarea
                  placeholder="Tell us more about your project, needs and timeline"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      ContactUs();
                    }
                  }}
                  value={anythingelse}
                  onChange={(e) => {
                    mixpanel.track("adding_description");
                    setAnythingElse(e.target.value);
                    setAnythingElseErr("");
                  }}
                  className=" input-contact full"
                />
                {anythingElseErr && (
                  <p className="errorMessage start">{anythingElseErr}</p>
                )}
              </div>
              <div
                onClick={() => {
                  if (
                    firstNameContact.trim().length > 0 &&
                    secondNameContact.trim().length > 0 &&
                    emailContact.trim().length > 0 &&
                    selectedCountryContact &&
                    !loading
                  ) {
                    ContactUs();
                  }
                }}
                className="div-contactus"
                style={{
                  cursor:
                    firstNameContact.trim().length > 0 &&
                    secondNameContact.trim().length > 0 &&
                    emailContact.trim().length > 0 &&
                    selectedCountryContact
                      ? "pointer"
                      : "not-allowed",
                  backgroundColor:
                    firstNameContact.trim().length > 0 &&
                    secondNameContact.trim().length > 0 &&
                    emailContact.trim().length > 0 &&
                    selectedCountryContact
                      ? "#140243"
                      : "#140243",
                }}
              >
                <p className="btn-contact-submit">Submit</p>
                <img src={arrowright} alt="arrow" className="arrowright" />
              </div>
            </div>
            <div className="withimg-contact">
              <div id="updiv" style={{ height: 0, width: 0 }}></div>
              <div className="cross-div cross-contact">
                <img
                  src={logo}
                  alt="vdotok-logo"
                  className="vdtlogo-tab logocontact"
                />
                <img
                  onClick={HideMe}
                  className="x-cross x-cross-contact"
                  src={cross}
                  alt="cross-button"
                />
              </div>
              <div className="contact-image-div">
                {/* <img className="bgtab" src={bgtab} alt="background" />
                  <img className="bgmbl" src={bgmbl} alt="background" /> */}
                <div className="upper-parent">
                  <div>
                    <p className="image-p">Let’s go live, together</p>
                  </div>
                  <div className="p-parent">
                    <img className="tick" src={tick} alt="icon" />
                    <p className="p-greytext">
                      Get access to our free demo app and put VdoTok to the test
                      with your peers
                    </p>
                  </div>
                  <div className="p-parent">
                    <img className="tick" src={tick} alt="icon" />
                    <p className="p-greytext">
                      Let our experts help you find the right live media
                      solution for your product
                    </p>
                  </div>
                  <div className="p-parent">
                    <img className="tick" src={tick} alt="icon" />
                    <p className="p-greytext">
                      Go live withing an hour with the help of our
                      easy-to-follow developer documentation{" "}
                    </p>
                  </div>
                </div>
                <div className="person-parent">
                  <img className="person" src={person} alt="image" />
                </div>

                {/* <img className="phoneicon" src={phoneverticle} alt="phone-image" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function map(state) {
  return state;
}
export default connect(map)(ContactUsPopup);
