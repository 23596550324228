import React, { useEffect, useState } from "react";
import upwhite from "./images/upwhite.webp";
import edtech from "./images/EdTechDesktop.png";
import htech from "./images/HealTechDesktop.png";
import { connect } from "react-redux";
import shopping from "./images/Shoppingcart.png";
import Social from "./images/Social.webp";
import "./style.css";
import mixpanel from "mixpanel-browser";

const UseCasesPopup = (props) => {
  const [innerwdt, setinnerwdt] = useState(window.innerWidth);
  const HideMe = () => {
    props.closeUseCasesPopup();
  };
  useEffect(() => {
    document.addEventListener("mouseup", function (e) {
      var container = document.getElementById("navmodal");
      var main = document.getElementById("modalmain");
      if (container && main) {
        if (!container.contains(e.target)) {
          HideMe();
        }
      }
    });
  }, []);
  useEffect(() => {
    setinnerwdt(window.innerWidth);
    window.addEventListener("resize", () => {
      setinnerwdt(window.innerWidth);
    });
  }, []);
  return (
    <>
      {innerwdt > 500 ? (
        <div className="modalmain" id="modalmain">
          <div className="navmodal" id="navmodal">
            <img className="upwhite" src={upwhite} alt="arrow-up-white" />
            <div className="cardsparent modal-up">
              <div
                className="modalcard"
                onClick={() => {
                  // mixpanel.track("live_shopping");
                  window.open("/liveshopping", "_blank");
                }}
              >
                <div className="img-div-navicons">
                  <img
                    className="img-icon"
                    src={shopping}
                    alt="icon-shopping"
                  />
                </div>
                <div className="cardcontent">
                  <p
                    className="typekit_LabelRegularBold usecasehspace webkit_primary_black"
                    // onClick={() => {
                    //   mixpanel.track("live_shopping");
                    //   window.open("/liveshopping");
                    // }}
                  >
                    Live shopping
                  </p>
                  <p
                    // onClick={() => {
                    //   mixpanel.track("health_tech");
                    //   window.open("/liveshopping");
                    // }}
                    className="typekit_LabelLight usecasepspace webkit_primary_grey"
                  >
                    Integrate live influencer selling, fashion show streaming
                    and more
                  </p>
                </div>
              </div>
              <div
                className="modalcard"
                style={{
                  cursor: "not-allowed",
                  pointer: "none",
                  opacity: "0.5",
                }}
              >
                <div className="img-div-navicons">
                  <img className="img-icon" src={Social} alt="icon-social" />
                </div>
                <div
                  className="cardcontent"
                  style={{
                    cursor: "not-allowed",
                    pointer: "none",
                    opacity: "0.5",
                  }}
                >
                  <p
                    className="typekit_LabelRegularBold usecasehspace webkit_primary_black"
                    style={{
                      cursor: "not-allowed",
                      pointer: "none",
                      opacity: "0.5",
                    }}
                    //  onClick={()=> {mixpanel.track("social");
                    //   window.open("https://go.vdotok.com/blog?tag=live+revolution")}}
                  >
                    Social (coming soon)
                  </p>
                  <p
                    className="typekit_LabelLight usecasepspace webkit_primary_grey"
                    style={{
                      cursor: "not-allowed",
                      pointer: "none",
                      opacity: "0.5",
                    }}
                  >
                    Boost customer interacion: co-shopping, co-surfing and more
                  </p>
                </div>
              </div>
            </div>
            <div className="cardsparent cards-parent-bottom">
              <div
                className="modalcard "
                onClick={() => {
                  mixpanel.track("health_tech");
                  window.open("/healthtech");
                }}
              >
                <div className="img-div-navicons">
                  <img className="img-icon" src={htech} alt="icon-healtech" />
                </div>
                <div className="cardcontent">
                  <p
                    className="typekit_LabelRegularBold usecasehspace webkit_primary_black"
                    onClick={() => {
                      mixpanel.track("health_tech");
                      window.open("/healthtech");
                    }}
                  >
                    HealthTech{" "}
                  </p>
                  <p
                    onClick={() => {
                      mixpanel.track("health_tech");
                      window.open("/healthtech");
                    }}
                    className="typekit_LabelLight usecasepspace webkit_primary_grey"
                  >
                    Build in real-time video visits, remote monitoring and more
                  </p>
                </div>
              </div>
              <div
                className="modalcard"
                style={{
                  cursor: "not-allowed",
                  pointer: "none",
                  opacity: "0.5",
                }}
              >
                <div className="img-div-navicons">
                  <img className="img-icon" src={edtech} alt="icon-edtech" />
                </div>
                <div className="cardcontent">
                  <p
                    className="typekit_LabelRegularBold usecasehspace webkit_primary_black"
                    //  onClick={()=> {mixpanel.track("ed_tech");
                    //   window.open("https://go.vdotok.com/blog?tag=edtech")}}
                    style={{
                      cursor: "not-allowed",
                      pointer: "none",
                      opacity: "0.5",
                    }}
                  >
                    EdTech (coming soon)
                  </p>
                  <p
                    className="typekit_LabelLight usecasepspace webkit_primary_grey"
                    style={{
                      cursor: "not-allowed",
                      pointer: "none",
                      opacity: "0.5",
                    }}
                  >
                    Create online classrooms, co-learning experiences and more
                  </p>
                </div>
              </div>
            </div>
            <div className="modal-bottom">
              <div className="bottom-content">
                <p className="bottom-h typekit_LabelRegularBold webkit_primary_h">
                  Couldn't find your industry?
                </p>
                <p className="bottom-p typekit_LabelSmall webkit_primary_black">
                  Contact us and we will help you find the right live media
                  solution for your product
                </p>
              </div>
              <div className="bottom-btn">
                <button
                  style={{ whiteSpace: "nowrap" }}
                  className="bottom-future"
                  // onClick={() => {
                  //   mixpanel.track("opening_contactus");
                  //   HideMe();
                  //   props.openContactusPopup();
                  // }}
                >
                  Contact Us
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

function map(state) {
  return state;
}
export default connect(map)(UseCasesPopup);
