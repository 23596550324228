import React, { useState, useEffect } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import backup_star from "./images/backup_star.png";
import client1 from "./images/client1.png";
import client2 from "./images/client2.png";
import client3 from "./images/client3.png";
import card from "./images/Card.png";

// Default theme
import "@splidejs/react-splide/css";
import { connect } from "react-redux";
import Aos from "aos";

import "aos/dist/aos.css";
import "./HT_backup.css";

const HTBackup = (props) => {
  const [innerwdt, setinnerwdt] = useState(window.innerWidth);

  useEffect(() => {
    setinnerwdt(window.innerWidth);
    if (window.innerWidth >= 1024) {
      document.getElementById("LS_backup_des").style.display = "flex";
      document.getElementById("LS_backup_tab").style.display = "none";
      document.getElementById("LS_backup_mbl").style.display = "none";
    }
    if (window.innerWidth > 600 && window.innerWidth < 1024) {
      document.getElementById("LS_backup_des").style.display = "none";
      document.getElementById("LS_backup_tab").style.display = "flex";
      document.getElementById("LS_backup_mbl").style.display = "none";
    }
    if (window.innerWidth < 700) {
      document.getElementById("LS_backup_des").style.display = "none";
      document.getElementById("LS_backup_tab").style.display = "none";
      document.getElementById("LS_backup_mbl").style.display = "block";
    }
    window.addEventListener("resize", function (event) {
      var newWidth = window.innerWidth;
      var newHeight = window.innerHeight;
      // console.log("resize")
      if (newWidth > 1024) {
        document.getElementById("LS_backup_des").style.display = "flex";
        document.getElementById("LS_backup_tab").style.display = "none";
        document.getElementById("LS_backup_mbl").style.display = "none";
      } else if (newWidth > 700 && newWidth <= 1024) {
        document.getElementById("LS_backup_des").style.display = "none";
        document.getElementById("LS_backup_tab").style.display = "flex";
        document.getElementById("LS_backup_mbl").style.display = "none";
      } else {
        document.getElementById("LS_backup_des").style.display = "none";
        document.getElementById("LS_backup_tab").style.display = "none";
        document.getElementById("LS_backup_mbl").style.display = "block";
      }
    });
  }, []);
  useEffect(() => {
    Aos.init();
    setinnerwdt(window.innerWidth);
    window.addEventListener("resize", () => {
      setinnerwdt(window.innerWidth);
    });
  }, []);

  return (
    <>
      <div className="LSBackup">
        {innerwdt > 1024 ? (
          <p className="HT_backup_b typekit_h2 webkit_primary_h">
            My HealthTech solution is backed up by VdoTok
          </p>
        ) : innerwdt > 700 ? (
          <p className="HT_backup_b typekit_h2 webkit_primary_h">
            My HealthTech solution is backed <br></br>up by VdoTok
          </p>
        ) : (
          <p className="HT_backup_b typekit_h2 webkit_primary_h">
            My HealthTech solution is backed up by VdoTok
          </p>
        )}
        <div className="ls-bkp-desk" id="LS_backup_des">
          <Splide
            options={{
              rewind: true,
              gap: "1rem",
              perPage: 3,
            }}
          >
            <SplideSlide>
              <div className="_backup_box">
                <div className="_backup_card">
                  <p className="_backup_box_b typekit_h4 webkit_primary_black">
                    Well organised
                  </p>
                  {innerwdt > 1024 ? (
                    <p className="_backup_box_p typekit_LabelLight  webkit_primary_grey">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed erat nibh tristique ipsum. Lorem ipsum.
                    </p>
                  ) : (
                    <p className="_backup_box_p typekit_LabelLight  webkit_primary_grey">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed erat nibh tristique ipsum.
                    </p>
                  )}
                </div>
                <div className="cone"></div>
                <div className="_backup_bottom">
                  <img src={client1} className="_backup_circle" />
                  <p className="typekit_LabelLarge webkit_primary_black _backup_btm_b">
                    John Doe
                  </p>
                  <p className="typekit_LabelLight webkit_primary_grey _backup_btm_p">
                    CEO at VdoTok
                  </p>
                  <img src={backup_star} className="_backup_stars" />
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="_backup_box">
                <div className="_backup_card">
                  <p className="_backup_box_b typekit_h4 webkit_primary_black">
                    Well organised
                  </p>
                  {innerwdt > 1024 ? (
                    <p className="_backup_box_p typekit_LabelLight  webkit_primary_grey">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed erat nibh tristique ipsum. Lorem ipsum.
                    </p>
                  ) : (
                    <p className="_backup_box_p typekit_LabelLight  webkit_primary_grey">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed erat nibh tristique ipsum.
                    </p>
                  )}
                </div>
                <div className="cone"></div>
                <div className="_backup_bottom">
                  <img src={client2} className="_backup_circle" />
                  <p className="typekit_LabelLarge webkit_primary_black _backup_btm_b">
                    John Doe
                  </p>
                  <p className="typekit_LabelLight webkit_primary_grey _backup_btm_p">
                    CEO at VdoTok
                  </p>
                  <img src={backup_star} className="_backup_stars" />
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="_backup_box">
                <div className="_backup_card">
                  <p className="_backup_box_b typekit_h4 webkit_primary_black">
                    Well organised
                  </p>
                  {innerwdt > 1024 ? (
                    <p className="_backup_box_p typekit_LabelLight  webkit_primary_grey">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed erat nibh tristique ipsum. Lorem ipsum.
                    </p>
                  ) : (
                    <p className="_backup_box_p typekit_LabelLight  webkit_primary_grey">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed erat nibh tristique ipsum.
                    </p>
                  )}
                </div>
                <div className="cone"></div>
                <div className="_backup_bottom">
                  <img src={client3} className="_backup_circle" />
                  <p className="typekit_LabelLarge webkit_primary_black _backup_btm_b">
                    John Doe
                  </p>
                  <p className="typekit_LabelLight webkit_primary_grey _backup_btm_p">
                    CEO at VdoTok
                  </p>
                  <img src={backup_star} className="_backup_stars" />
                </div>
              </div>
            </SplideSlide>
          </Splide>
        </div>
        <div className="ls-bkp-tab" id="LS_backup_tab">
          <Splide
            options={{
              type: "loop",
              padding: "34%",
              perPage: 1,
            }}
          >
            <SplideSlide>
              <div className="_backup_box">
                <div className="_backup_card">
                  <p className="_backup_box_b typekit_h4 webkit_primary_black">
                    Well organised
                  </p>
                  {innerwdt > 1024 ? (
                    <p className="_backup_box_p typekit_LabelLight  webkit_primary_grey">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed erat nibh tristique ipsum. Lorem ipsum.
                    </p>
                  ) : (
                    <p className="_backup_box_p typekit_LabelLight  webkit_primary_grey">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed erat nibh tristique ipsum.
                    </p>
                  )}
                </div>
                <div className="cone"></div>
                <div className="_backup_bottom">
                  <img src={client1} className="_backup_circle" />
                  <p className="typekit_LabelLarge webkit_primary_black _backup_btm_b">
                    John Doe
                  </p>
                  <p className="typekit_LabelLight webkit_primary_grey _backup_btm_p">
                    CEO at VdoTok
                  </p>
                  <img src={backup_star} className="_backup_stars" />
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="_backup_box">
                <div className="_backup_card">
                  <p className="_backup_box_b typekit_h4 webkit_primary_black">
                    Well organised
                  </p>
                  {innerwdt > 1024 ? (
                    <p className="_backup_box_p typekit_LabelLight  webkit_primary_grey">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed erat nibh tristique ipsum. Lorem ipsum.
                    </p>
                  ) : (
                    <p className="_backup_box_p typekit_LabelLight  webkit_primary_grey">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed erat nibh tristique ipsum.
                    </p>
                  )}
                </div>
                <div className="cone"></div>
                <div className="_backup_bottom">
                  <img src={client2} className="_backup_circle" />
                  <p className="typekit_LabelLarge webkit_primary_black _backup_btm_b">
                    John Doe
                  </p>
                  <p className="typekit_LabelLight webkit_primary_grey _backup_btm_p">
                    CEO at VdoTok
                  </p>
                  <img src={backup_star} className="_backup_stars" />
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="_backup_box">
                <div className="_backup_card">
                  <p className="_backup_box_b typekit_h4 webkit_primary_black">
                    Well organised
                  </p>
                  {innerwdt > 1024 ? (
                    <p className="_backup_box_p typekit_LabelLight  webkit_primary_grey">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed erat nibh tristique ipsum. Lorem ipsum.
                    </p>
                  ) : (
                    <p className="_backup_box_p typekit_LabelLight  webkit_primary_grey">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed erat nibh tristique ipsum.
                    </p>
                  )}
                </div>
                <div className="cone"></div>
                <div className="_backup_bottom">
                  <img src={client3} className="_backup_circle" />
                  <p className="typekit_LabelLarge webkit_primary_black _backup_btm_b">
                    John Doe
                  </p>
                  <p className="typekit_LabelLight webkit_primary_grey _backup_btm_p">
                    CEO at VdoTok
                  </p>
                  <img src={backup_star} className="_backup_stars" />
                </div>
              </div>
            </SplideSlide>
          </Splide>
        </div>
        <div className="ls-bkp-mob" id="LS_backup_mbl">
          <Splide
            options={{
              type: "loop",
              padding: "5%",
              perPage: 1,
            }}
          >
            <SplideSlide>
              <div className="_backup_box">
                <div className="_backup_card">
                  <p className="_backup_box_b typekit_h4 webkit_primary_black">
                    Well organised
                  </p>
                  {innerwdt > 1024 ? (
                    <p className="_backup_box_p typekit_LabelLight  webkit_primary_grey">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed erat nibh tristique ipsum. Lorem ipsum.
                    </p>
                  ) : (
                    <p className="_backup_box_p typekit_LabelLight  webkit_primary_grey">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed erat nibh tristique ipsum.
                    </p>
                  )}
                </div>
                <div className="cone"></div>
                <div className="_backup_bottom">
                  <img src={client1} className="_backup_circle" />
                  <p className="typekit_LabelLarge webkit_primary_black _backup_btm_b">
                    John Doe
                  </p>
                  <p className="typekit_LabelLight webkit_primary_grey _backup_btm_p">
                    CEO at VdoTok
                  </p>
                  <img src={backup_star} className="_backup_stars" />
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="_backup_box">
                <div className="_backup_card">
                  <p className="_backup_box_b typekit_h4 webkit_primary_black">
                    Well organised
                  </p>
                  {innerwdt > 1024 ? (
                    <p className="_backup_box_p typekit_LabelLight  webkit_primary_grey">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed erat nibh tristique ipsum. Lorem ipsum.
                    </p>
                  ) : (
                    <p className="_backup_box_p typekit_LabelLight  webkit_primary_grey">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed erat nibh tristique ipsum.
                    </p>
                  )}
                </div>
                <div className="cone"></div>
                <div className="_backup_bottom">
                  <img src={client2} className="_backup_circle" />
                  <p className="typekit_LabelLarge webkit_primary_black _backup_btm_b">
                    John Doe
                  </p>
                  <p className="typekit_LabelLight webkit_primary_grey _backup_btm_p">
                    CEO at VdoTok
                  </p>
                  <img src={backup_star} className="_backup_stars" />
                </div>
              </div>
            </SplideSlide>
            <SplideSlide>
              <div className="_backup_box">
                <div className="_backup_card">
                  <p className="_backup_box_b typekit_h4 webkit_primary_black">
                    Well organised
                  </p>
                  {innerwdt > 1024 ? (
                    <p className="_backup_box_p typekit_LabelLight  webkit_primary_grey">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed erat nibh tristique ipsum. Lorem ipsum.
                    </p>
                  ) : (
                    <p className="_backup_box_p typekit_LabelLight  webkit_primary_grey">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed erat nibh tristique ipsum.
                    </p>
                  )}
                </div>
                <div className="cone"></div>
                <div className="_backup_bottom">
                  <img src={client3} className="_backup_circle" />
                  <p className="typekit_LabelLarge webkit_primary_black _backup_btm_b">
                    John Doe
                  </p>
                  <p className="typekit_LabelLight webkit_primary_grey _backup_btm_p">
                    CEO at VdoTok
                  </p>
                  <img src={backup_star} className="_backup_stars" />
                </div>
              </div>
            </SplideSlide>
          </Splide>
        </div>
      </div>
    </>
  );
};

function map(state) {
  return state;
}
export default connect(map)(HTBackup);
