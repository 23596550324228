/* eslint-disable */
import react, { useState, useEffect, useCallback } from "react";
import "./App.css";
import AOS from "aos";
import "animate.css";
import { useNavigate } from "react-router";
import {
  BrowserRouter as Router,
  Navigate,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import MainScreen from "./screens/mainScreen";
import AboutScreen from "./screens/aboutScreen";
import FAQScreen from "./screens/FAQScreen";
import SignupPopup from "./components/Nav/SignUpPopup";
import PrivacyPolicy from "./components/PrivacyPolicy/privacypolicy";
import axios from "axios";
import { connect } from "react-redux";
import APIs from "./APIs/API";
import mixpanel from "mixpanel-browser";
import Termsofservices from "./components/TermsofServices/termsofservices";
import LiveShopping from "./screens/LiveShopping";
import HealthTechScreen from "./screens/HealthTech";
import DownloadApps from "./screens/downloadApplications/downloadApplications";
function App(props) {
  useEffect(() => {
    API_Object.GetPrivacyPolicy(props.urls.apiBaseURL).then((data) => {
      if (data.status == 200) {
        props.dispatch({ type: "PPs", payload: data });
      }
    });
  }, [props.urls]);
  useEffect(() => {
    API_Object.GetTermsOfServices(props.urls.apiBaseURL).then((data) => {
      if (data.status == 200) {
        props.dispatch({ type: "ToSs", payload: data });
      }
    });
  }, [props.urls]);
  useEffect(() => {
    AOS.init();
  }, []);
  useEffect(() => {}, [window.pathname]);

  useEffect(() => {
    props.dispatch({ type: "USERDONE", userData: "" });
    props.dispatch({ type: "USER", userData: "" });
    props.dispatch({ type: "TEMPUSER", userData: "" });
    // mixpanel.track('logout')
    if (window.location.href.includes(".dev")) {
      document.cookie = `user=; Expires=Fri, 5 Oct 2018 14:28:00 GMT;path=/;Domain=vdotok.dev;SameSite=Strict`;
    } else {
      document.cookie = `user=; Expires=Fri, 5 Oct 2018 14:28:00 GMT;path=/;Domain=vdotok.com;SameSite=Strict`;
    }
  }, []);
  let API_Object = new APIs();
  useEffect(() => {
    window.history.pushState(null, null, document.URL);
    // window.addEventListener(
    //   "popstate",
    //   function (event) {
    //     window.location.href = "/";
    //   },
    //   []
    // );

    mixpanel.init("5c76f1828597f867946d015a224673b8", { debug: true });
  }, []);
  useEffect(() => {
    if (window.location.href.includes("logout")) {
      window.location.href = "/";
    }
  }, [props.user]);

  useEffect(() => {
    fetch("config.json", {
      headers: {
        "Content-Type": "text/plain",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        props.dispatch({ type: "URLS", payload: myJson });
      });
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/logout" element={<MainScreen />} />
        <Route path="/*" element={<MainScreen />} />
        <Route exact path="/pricing" element={<MainScreen />} />
        <Route exact path="/blog" element={<MainScreen />} />
        <Route exact path="/about" element={<AboutScreen />} />
        <Route exact path="/FAQ" element={<FAQScreen />} />
        <Route exact path="/liveshopping" element={<LiveShopping />} />
        <Route exact path="/healthtech" element={<HealthTechScreen />} />
        <Route exact path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route exact path="/termsofservices" element={<Termsofservices />} />
        <Route exact path="/demo" element={<DownloadApps />} />
      </Routes>
    </Router>
  );
}
function map(state) {
  return state;
}
export default connect(map)(App);
