import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import keydown from "./images/keydown.webp";
import cross from "./images/x-circle.webp";
import keyup from "./images/keyup.webp";
import HiIcon from "./images/HiIcon.webp";
import backarrow from "./images/backarrow.webp";
import APIs from "../../APIs/API";
import "./verification.css";
import mixpanel from "mixpanel-browser";
const Thankyou = (props) => {
  useEffect(() => {
    // console.log('scrollingg 9');

    window.scrollTo(0, 0);
  }, []);
  const API_Object = new APIs();
  const [typeofRole, setShowTypeofRole] = useState(false);
  const [RoleList, setRoleList] = useState(null);
  const [selectedRoleID, setSelectedRoleID] = useState("");
  const [projectTitle, setProjectTitle] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [genericError, setGenericError] = useState("");
  const reverse = (encoded) => {
    var newString = "";
    for (var i = encoded.length - 1; i >= 0; i--) {
      newString += encoded[i];
    }
    return newString;
  };
  const setCookie = (cname, cvalue, exdays) => {
    let encoded = btoa(cvalue);
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    if (window.location.href.includes(".dev")) {
      document.cookie =
        cname +
        "=" +
        reverse(encoded) +
        ";" +
        expires +
        ";path=/;Domain=vdotok.dev;SameSite=Strict";
    } else {
      document.cookie =
        cname +
        "=" +
        reverse(encoded) +
        ";" +
        expires +
        ";path=/;Domain=vdotok.com;SameSite=Strict";
    }
  };
  const HideMe = () => {
    mixpanel.track("Thankyou_cross");
    props.closeThankyouPopup();
  };

  useEffect(() => {
    var object = {
      auth_token: props.user.auth_token,
    };
    API_Object.GetRoles(object, props.urls.apiBaseURL).then((data) => {
      if (data.status == 200 && data.data.length > 0) {
        setRoleList(data.data);
        setSelectedRole(data.data[0].role);
        setSelectedRoleID(data.data[0].id);
        //  HideMe();
        //  props.openThankYouPopup();
      } else {
      }
    });
  }, []);
  const AddProject = () => {
    document.getElementById("donebtnthanks").disabled = true;
    let object = {
      auth_token: props.user.auth_token,
      project_title: projectTitle,
      role_id: selectedRoleID,
    };
    API_Object.AddProject(object, props.urls.apiBaseURL).then((data) => {
      if (data.status == 200) {
        // document.getElementById('donebtnthanks').disabled = true;
        setCookie("user", props.user.auth_token, 4);
        props.dispatch({ type: "USERDONE", userData: props.user });
        mixpanel.track("project_added");
        window.open(`https://${props.urls.adminPanelURL}/overview`, "_self");
        HideMe();
      } else {
        document.getElementById("donebtnthanks").disabled = false;
        setGenericError(data.message);
        mixpanel.track("add_project_error");
      }
    });
  };
  return (
    <>
      <div>
        <div className="verification" id="verification">
          <div id="modal-verification" className="modal-verification thankyou">
            <div className="sepodiv">
              <div
                className="yayparent"
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <img src={HiIcon} alt="hi-icon" className="hiIcon" />
                <p className="verification-bold yay typekit_h2 webkit_primary_h">
                  {" "}
                  Yay!{" "}
                </p>
              </div>
              <img
                onClick={HideMe}
                className="x-cross-ver"
                src={cross}
                alt="cross-button"
              />
              <p className=" verification-bold typekit_h3 webkit_primary_h">
                Now you are ready to start your first project
              </p>
              <p className="yaynormal verification-normal typekit_BodyRegular webkit_primary_grey">
                Now you are ready to start your first project, name your
                application and get started. You can change details in your
                account later.
              </p>
              {genericError && <p className="errorMessage">{genericError}</p>}
              <div className="row3 thankyou">
                <div className="input-parent">
                  <label className="ver-label-thankyou">Project name</label>
                  <input
                    placeholder="Project name"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        AddProject();
                      }
                    }}
                    className="ver-input"
                    value={projectTitle}
                    onChange={(e) => {
                      setGenericError("");
                      setProjectTitle(e.target.value);
                    }}
                  />
                </div>
                <div className="input-parent Role">
                  <label className="ver-label-thankyou">Your role?</label>
                  <div
                    onClick={(e) => {
                      setShowTypeofRole(!typeofRole);
                      e.stopPropagation();
                    }}
                    className="ver-input input-password input-country input-signup full"
                  >
                    {selectedRole ? (
                      <div className="dropdowntext">{selectedRole}</div>
                    ) : (
                      <div>Loading...</div>
                    )}
                    <div>
                      {typeofRole ? (
                        <img src={keyup} className="dropIcon" />
                      ) : (
                        <img src={keydown} className="dropIcon" />
                      )}
                    </div>
                  </div>
                  {typeofRole && (
                    <div
                      onClick={(e) => e.stopPropagation()}
                      className="dropdownBody"
                    >
                      <div className="countryScroller">
                        {RoleList.length == 0 && (
                          <div className="singleCountryContainer">
                            <div className="dropdowntext">No Result..</div>
                          </div>
                        )}
                        {RoleList.map((item, index) => {
                          return (
                            <div
                              onClick={() => {
                                setSelectedRole(item.role);
                                setSelectedRoleID(item.id);
                                setShowTypeofRole(false);
                              }}
                              key={index}
                              className="singleCountryContainer"
                            >
                              <div className="dropdowntext">{item.role}</div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="donebtn thankyou">
                <button
                  id="donebtnthanks"
                  onClick={AddProject}
                  className="ver-button"
                >
                  DONE
                </button>
              </div>
              <div className="back-row none">
                <img className="backarrow" src={backarrow} alt="back-arrow" />
                <p className="backtext">Back</p>
              </div>
              <div className="heigt"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function map(state) {
  return state;
}
export default connect(map)(Thankyou);
