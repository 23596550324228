const urls=(state=[],action)=>{
    switch(action.type){
      case 'URLS':
        return action.payload
        default:
          return state;
    }
  
  };
  export {urls}
  