const termsofservice=(state=[],action)=>{
    switch(action.type){
      case 'ToSs':
        return action.payload
        default:
          return state;
    }
  
  };
  export {termsofservice}
  