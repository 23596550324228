const faqs=(state=[],action)=>{
    switch(action.type){
      case 'FAQs':
        return action.payload
        default:
          return state;
    }
  
  };
  export {faqs}
  