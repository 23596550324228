import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import circle from "../images/circle.png";
import tabcircle from "../images/tabcircle.png";
import s1 from "../images/s1.png";
import s2 from "../images/s2.png";
import s3 from "../images/s3.png";
import s4 from "../images/s4.png";
import s5 from "../images/s5.png";
import s6 from "../images/s6.png";
import Aos from "aos";
import "aos/dist/aos.css";
import "./healthTechCircle.css";

const HTCircle = (props) => {
  const [innerwdt, setinnerwdt] = useState(window.innerWidth);
  useEffect(() => {
    // if(document.getElementById('cabs1')){
    //     document.getElementById('cabs1').style.opacity = 0;
    // }
    if (document.getElementById("cabs2")) {
      document.getElementById("cabs2").style.opacity = 0;
    }
    if (document.getElementById("cabs3")) {
      document.getElementById("cabs3").style.opacity = 0;
    }
    if (document.getElementById("cabs4")) {
      document.getElementById("cabs4").style.opacity = 0;
    }
    if (document.getElementById("cabs2mbl")) {
      document.getElementById("cabs2mbl").style.opacity = 0;
    }
    if (document.getElementById("cabs3mbl")) {
      document.getElementById("cabs3mbl").style.opacity = 0;
    }
    if (document.getElementById("cabs4mbl")) {
      document.getElementById("cabs4mbl").style.opacity = 0;
    }
    if (document.getElementById("cabs5")) {
      document.getElementById("cabs5").style.opacity = 0;
    }
    if (document.getElementById("cabs6")) {
      document.getElementById("cabs6").style.opacity = 0;
    }

    let a = null;
    let b = null;
    let c = null;

    if (innerwdt > 600) {
      a = setTimeout(() => {
        if (document.getElementById("cabs2")) {
          document.getElementById("cabs2").style.opacity = 1;
        }
      }, 5000);
      b = setTimeout(() => {
        if (document.getElementById("cabs3")) {
          document.getElementById("cabs3").style.opacity = 1;
        }
      }, 10000);
      c = setTimeout(() => {
        if (document.getElementById("cabs4")) {
          document.getElementById("cabs4").style.opacity = 1;
        }
      }, 15000);
      setTimeout(() => {
        if (document.getElementById("cabs5")) {
          document.getElementById("cabs5").style.opacity = 1;
        }
      }, 20000);
      setTimeout(() => {
        if (document.getElementById("cabs6")) {
          document.getElementById("cabs6").style.opacity = 1;
        }
      }, 25000);
    } else {
      a = setTimeout(() => {
        if (document.getElementById("cabs2mbl")) {
          document.getElementById("cabs2mbl").style.opacity = 1;
        }
      }, 8000);
      b = setTimeout(() => {
        if (document.getElementById("cabs3mbl")) {
          document.getElementById("cabs3mbl").style.opacity = 1;
        }
      }, 16000);
      c = setTimeout(() => {
        if (document.getElementById("cabs4mbl")) {
          document.getElementById("cabs4mbl").style.opacity = 1;
        }
      }, 24000);
    }

    Aos.init();

    return () => {
      // console.log('this is timeouts--->',a,b,c)
      if (a) {
        clearTimeout(a);
      }
      if (b) {
        clearTimeout(b);
      }
      if (c) {
        clearTimeout(c);
      }
    };
  }, []);
  // useEffect(() => {
  //     if(innerwdt > 1024){
  //         document.getElementById('cabs1').style.top = "45%";
  //         document.getElementById('cabs1').style.left = "40%";

  //         document.getElementById('cabs2').style.top = "45%";
  //         document.getElementById('cabs2').style.left = "40%";

  //         document.getElementById('cabs3').style.top = "42%";
  //         document.getElementById('cabs3').style.left = "37%";

  //         document.getElementById('cabs4').style.top = "47%";
  //         document.getElementById('cabs4').style.left = "37%";

  //         document.getElementById('cabs5').style.top = "47%";
  //         document.getElementById('cabs5').style.left = "37%";

  //         document.getElementById('cabs6').style.top = "47%";
  //         document.getElementById('cabs6').style.left = "37%";
  //     }
  //     else if(innerwdt > 600){
  //         document.getElementById('cabs1').style.top = "42%";
  //         document.getElementById('cabs1').style.left = "35%";

  //         document.getElementById('cabs2').style.top = "45%";
  //         document.getElementById('cabs2').style.left = "33%";

  //         document.getElementById('cabs3').style.top = "42%";
  //         document.getElementById('cabs3').style.left = "37%";

  //         document.getElementById('cabs4').style.top = "47%";
  //         document.getElementById('cabs4').style.left = "37%";

  //         document.getElementById('cabs5').style.top = "47%";
  //         document.getElementById('cabs5').style.left = "37%";

  //         document.getElementById('cabs6').style.top = "47%";
  //         document.getElementById('cabs6').style.left = "37%";
  //     }
  //     else{
  //         document.getElementById('cabs1').style.top = "37%";
  //         document.getElementById('cabs1').style.left = "12%";

  //         document.getElementById('cabs2').style.top = "43%";
  //         document.getElementById('cabs2').style.left = "29%";

  //         document.getElementById('cabs3').style.top = "42%";
  //         document.getElementById('cabs3').style.left = "17%";

  //         document.getElementById('cabs4').style.top = "44%";
  //         document.getElementById('cabs4').style.left = "26%";
  //     }

  // } , [innerwdt]);
  useEffect(() => {
    setinnerwdt(window.innerWidth);
    window.addEventListener("resize", () => {
      setinnerwdt(window.innerWidth);
    });
  }, []);
  return (
    <>
      <div className="HTCircle">
        <p
          className={
            innerwdt > 1024
              ? "ht-circle-p1 typekit_subheadline2 webkit_primary_h"
              : innerwdt > 600 && innerwdt <= 1024
              ? "ht-circle-p1 typekit_subheadline1 webkit_primary_h"
              : "ht-circle-p1 typekit_subheadline2 webkit_primary_grey"
          }
        >
          End-user issues in TeleHealth
        </p>
        <p className="ht-circle-p2 typekit_h2 webkit_primary_h">
          Solve them with VdoTok
        </p>
        {innerwdt > 600 ? (
          <img
            className="HT-img-circle"
            src={innerwdt > 1024 ? circle : tabcircle}
            alt="circle-img"
          />
        ) : (
          <></>
        )}

        {innerwdt > 600 ? (
          <div className="ht_circle_container">
            <div className="cirle-abs-1" id="cabs1">
              <img src={s1} alt="s1" className="circleimages" />
              <div className="circle-txt-box">
                <p className="typekit_LabelSmall webkit_primary_blue eltspace _circle_txt">
                  Can the doctor see me well?
                </p>
                <p className="typekit_LabelThin webkit_primary_grey eltspace">
                  Bad picture quality
                </p>
              </div>
            </div>
            <div className="cirle-abs-2" id="cabs2">
              <img src={s2} alt="s1" className="circleimages" />
              <div className="circle-txt-box">
                <p className="typekit_LabelSmall webkit_primary_blue eltspace _circle_txt">
                  Is my health data safe?
                </p>
                <p className="typekit_LabelThin webkit_primary_grey eltspace">
                  Possible leakage of sensitive data
                </p>
              </div>
            </div>
            <div className="cirle-abs-3" id="cabs3">
              <img src={s3} alt="s1" className="circleimages" />
              <div className="circle-txt-box">
                <p className="typekit_LabelSmall webkit_primary_blue eltspace _circle_txt">
                  The connection is bad out here,<br></br> can we still talk?
                </p>
                <p className="typekit_LabelThin webkit_primary_grey eltspace">
                  Patients with poor internet connection
                </p>
              </div>
            </div>
            <div className="cirle-abs-4" id="cabs4">
              <img src={s4} alt="s1" className="circleimages" />
              <div className="circle-txt-box">
                <p className="typekit_LabelSmall webkit_primary_blue eltspace _circle_txt">
                  I don’t understand this with heart rate...
                </p>
                <p className="typekit_LabelThin webkit_primary_grey eltspace">
                  Lack of direct monitoring and check up solutions
                </p>
              </div>
            </div>
            <div className="cirle-abs-5" id="cabs5">
              <img src={s5} alt="s1" className="circleimages" />
              <div className="circle-txt-box">
                <p className="typekit_LabelSmall webkit_primary_blue eltspace _circle_txt">
                  Where shoud I click?
                </p>
                <p className="typekit_LabelThin webkit_primary_grey eltspace">
                  Lack of customer support & onboarding
                </p>
              </div>
            </div>
            <div className="cirle-abs-6" id="cabs6">
              <img src={s6} alt="s1" className="circleimages" />
              <div className="circle-txt-box">
                <p className="typekit_LabelSmall webkit_primary_blue eltspace _circle_txt">
                  I only see a black screen...
                </p>
                <p className="typekit_LabelThin webkit_primary_grey eltspace">
                  Server overload
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="ht_circle_container">
            <img
              className="HT-img-circle"
              src={innerwdt > 1024 ? circle : tabcircle}
              alt="circle-img"
            />
            <div className="cirle-abs-1" id="cabs1mbl">
              <img src={s1} alt="s1" className="circleimages" />
              <div className="circle-txt-box">
                <p className="typekit_LabelSmall webkit_primary_blue eltspace _circle_txt">
                  Can the doctor see me well?
                </p>
                <p className="typekit_LabelThin webkit_primary_grey eltspace">
                  Bad picture quality
                </p>
              </div>
            </div>
            <div className="cirle-abs-2" id="cabs2mbl">
              <img src={s2} alt="s1" className="circleimages" />
              <div className="circle-txt-box">
                <p className="typekit_LabelSmall webkit_primary_blue eltspace _circle_txt">
                  Is my health data safe?
                </p>
                <p className="typekit_LabelThin webkit_primary_grey eltspace">
                  Possible leackage of sensitive data
                </p>
              </div>
            </div>
            <div className="cirle-abs-3" id="cabs3mbl">
              <img src={s3} alt="s1" className="circleimages" />
              <div className="circle-txt-box">
                <p className="typekit_LabelSmall webkit_primary_blue eltspace _circle_txt">
                  The connection is bad out here,<br></br> can we still talk?
                </p>
                <p className="typekit_LabelThin webkit_primary_grey eltspace">
                  Patients with poor internet connection
                </p>
              </div>
            </div>
            <div className="cirle-abs-4" id="cabs4mbl">
              <img src={s4} alt="s1" className="circleimages" />
              <div className="circle-txt-box">
                <p className="typekit_LabelSmall webkit_primary_blue eltspace _circle_txt">
                  I don’t understand this with heart rate...
                </p>
                <p className="typekit_LabelThin webkit_primary_grey eltspace">
                  Lack of direct monitoring and check up solutions
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

function map(state) {
  return state;
}
export default connect(map)(HTCircle);
