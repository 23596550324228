import React, { useState, useMemo } from "react";
import { createPortal } from "react-dom";
import axios from "axios";
import { connect } from "react-redux";
import { useDebounce } from "../../hooks/useDebounce";
import { ReactComponent as Xcircle } from "../../assets/images/x-circle.svg";
import { ReactComponent as Close } from "../../assets/images/white_close.svg";
import VdoTok from "../../assets/images/logo.png";
import tabletLogo from "../../assets/images/vdotok_logo_tablet.png";
import mobileLogo from "../../assets/images/vdotok_logo_mobile.png";
import iphone from "../../assets/images/iphoneVideo.png";
import horizontal_iphone from "../../assets/images/horizontal_iphone.png";
import mobileBackground from "../../assets/images/mobile_backgound_video.png";
import Button from "../../components/common/button";
import CancelButton from "../../components/common/cancelButton";
import Input from "../../components/common/input";
import "./styles.css";

const ForgotPassword = (props) => {
  const { setSelectedScreen, setForgotResponse, openLoginPopup } = props;
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [hitAgain, setHitAgain] = useState(true);
  const debouncedValue = useDebounce(email, 300);
  const sendCodeHandler = () => {
    if (hitAgain) {
      setHitAgain(false);
      setErrorMessage(null);
      if (email) {
        const body = { email: email.toLowerCase() };
        axios
          .post(
            `https://${props.urls.apiBaseURL}/AdminAPI/v0/ForgetPassword`,
            body
          )
          .then((res) => {
            if (res.data.status === 200) {
              setForgotResponse((prevForgotResponse) => ({
                ...prevForgotResponse,
                attempts: res.data.attempts,
                token: res.data.verification_token,
                email: email.toLowerCase(),
              }));
              setSelectedScreen("verification");
            } else {
              setErrorMessage(res.data.message);
            }
          })
          .catch((err) => {
            setErrorMessage(err.message);
          })
          .finally(() => {
            setHitAgain(true);
          });
      }
    }
  };

  const cancelHandler = () => {
    setSelectedScreen(null);
    setForgotResponse({
      attempts: "",
      token: "",
      otp: "",
      email: "",
    });
  };

  const emailHandler = (event) => {
    setEmail(event.target.value);
    if (errorMessage) {
      setErrorMessage(null);
    }
  };

  const disabledButton = useMemo(() => {
    const emailRegex = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    let validateEmail = emailRegex.test(debouncedValue);
    if (validateEmail) {
      return false;
    }
    return true;
  }, [debouncedValue]);

  const forgotNode = document.getElementById("forgotPopupContainer");
  return createPortal(
    <div className="forgot_password_container">
      <div className="forgot_password_wraper">
        <div className="fpw_left_container">
          <div className="forgot_password_form">
            <div className="vdotok_logo_box">
              <img src={VdoTok} alt="logo" className="vdotok_logo" />
            </div>
            <div className="fpf_content_container">
              <p className="fpf_heading">Forgot your password?</p>

              <p className="fpf_desc">
                Don’t worry, we’ll send you a message to help you reset your
                password.
              </p>
              <Input
                title="E-mail"
                type="text"
                placeholder="Enter email"
                onChange={emailHandler}
                value={email}
              />
              <Button
                title="send code"
                className="fpf_send_btn"
                disabled={disabledButton}
                onClick={sendCodeHandler}
              />

              <CancelButton
                title="cancel"
                onClick={() => {
                  cancelHandler();
                  openLoginPopup();
                }}
              />
            </div>
          </div>
          {errorMessage && (
            <p className="forgot_password_massage">{errorMessage}</p>
          )}
        </div>
        <div className="fpw_right_container">
          <Xcircle className="fpwrc_close_icon" onClick={cancelHandler} />
          <Close className="fpwrc_white_close_icon" onClick={cancelHandler} />
          <div className="fpwrc_logo_container">
            <img src={tabletLogo} alt="logo" className="fpwrc_logo" />
          </div>
          <div className="fpwrc_mobile_logo_container">
            <img src={mobileLogo} alt="logo" className="fpwrcm_logo" />
          </div>
          {/* <div className="fpwrc_video_container">
            <img src={iphone} alt="iphone" className="fpwrc_video" />
          </div>
          <div className="fpwrc_horizontal_video_container">
            <img
              src={horizontal_iphone}
              alt="horizontal iphone"
              className="fpwrc_horizontal_video"
            />
          </div>
          <div className="fpwrc_mobile_video_container">
            <img
              src={mobileBackground}
              alt="mobile video"
              className="fpwrc_mobile_video"
            />
          </div> */}
        </div>
      </div>
    </div>,
    forgotNode
  );
};
function mapStatetoprops(state) {
  return state;
}
export default connect(mapStatetoprops)(ForgotPassword);
