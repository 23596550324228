import React , {useState , useEffect} from 'react';
import { connect } from 'react-redux';
import Aos from 'aos';
import _build1 from '../../../assets/images/_build1.svg';
import _build2 from '../../../assets/images/_build2.svg';
import _build3 from '../../../assets/images/_build3.svg';
import _build4 from '../../../assets/images/_build4.svg';

import 'aos/dist/aos.css';
import './build_liveshopping.css';



const Build_LiveShopping = (props) => {
    const [innerwdt , setinnerwdt] = useState(window.innerWidth);
    useEffect(() => {
        Aos.init();
        setinnerwdt(window.innerWidth);
        window.addEventListener('resize' , () => {
            setinnerwdt(window.innerWidth);
        })
    }, []);

    return (
        <>
        <div className='Build_LS'>
            {innerwdt > 1024 ? 
            <p className='build_ls_b typekit_h2 webkit_primary_h'>So why build your own Live Shopping solution?  </p>
            :
            <p className='build_ls_b typekit_h2 webkit_primary_h'>So why build your own Live Shopping solution?  </p>
            }
            {innerwdt > 500 ? 
                        <>
                        <div className='build_ls_R1'>
                        <div data-aos="zoom-in-right" className='_build_ls_box1'>
                            <div className='_build_ls_bold'>
                                <img src={_build1} className="_build_img" alt='image' />
        
                                <p className='typekit_h3 _build_ls_b webkit_primary_h'>Live shopping software is costly </p>
                            </div>
                            <p className={innerwdt > 1024 ? 'typekit_BodyLargeRegular _build_p webkit_primary_grey' : '_build_p typekit_BodyRegular webkit_primary_grey' } >Most Live Shopping software companies make you pay fixed fees even in periods when you don’t use their services. You waste time on making a decision to try out their features and get stressed during the onboarding not willing to waste your budget.</p>
                        </div>
                        <div data-aos="zoom-in-left" className='_build_ls_box2'>
                            <div className='_build_ls_bold'>
                                <img src={_build2} className="_build_img" alt='image' />
                                {innerwdt > 500 && innerwdt < 1024 ? <p className='typekit_h3 _build_ls_b webkit_primary_h'>Social media are <br></br>limited </p> :
                                             <p className='typekit_h3 _build_ls_b webkit_primary_h'>Social media are limited </p>}
                                             
                            </div>
                            <p className={innerwdt > 1024 ? 'typekit_BodyLargeRegular _build_p webkit_primary_grey' : '_build_p typekit_BodyRegular webkit_primary_grey' }>Social media live streaming lacks essential live shopping features, such as an instant add to cart. By depriving your viewers of an opportunity to add products to cart while in a stream you miss out on your spontaneous buyers. </p>
                        </div>
                    </div>
                    <div className='build_ls_R2'>
                        <div data-aos="zoom-in-right" className='_build_ls_box1'>
                            <div className='_build_ls_bold'>
                                <img src={_build3} className="_build_img" alt='image' />
                                <p className='typekit_h3 _build_ls_b webkit_primary_h'>Engaged customers shop more</p>
                            </div>
                            <p className={innerwdt > 1024 ? 'typekit_BodyLargeRegular _build_p webkit_primary_grey' : '_build_p typekit_BodyRegular webkit_primary_grey' }>Don’t limit yourself to what is on the market. Add extra features like mobile web browser screen sharing and annotation to your product to enhance your customer experience and supercharge your conversion rates. </p>
                        </div>
                        <div data-aos="zoom-in-left" className='_build_ls_box2'>
                            <div className='_build_ls_bold'>
                                <img src={_build4} className="_build_img" alt='image' />
                                <p className='typekit_h3 _build_ls_b webkit_primary_h'>Get 100% control over your data </p>
                            </div>
                            <p className={innerwdt > 1024 ? 'typekit_BodyLargeRegular _build_p webkit_primary_grey' : '_build_p typekit_BodyRegular webkit_primary_grey' }>Avoid sharing your customer and visitor data with third party solutions. Less security issues and more control.</p>
                        </div>
                    </div>
                    </> :
                                     <>
                                     <div className='build_ls_R1'>
                                     <div data-aos="zoom-in-right" className='_build_ls_box1'>
                                         <div className='_build_ls_bold'>
                                             <img src={_build1} className="_build_img" alt='image' />
                     
                                             <p className='typekit_h3 _build_ls_b webkit_primary_h'>Live shopping software is costly </p>
                                         </div>
                                         <p className={innerwdt > 1024 ? 'typekit_BodyLargeRegular _build_p webkit_primary_grey' : '_build_p typekit_BodyRegular webkit_primary_grey' } >Most Live Shopping software companies make you pay fixed fees even in periods when you don’t use their services. You waste time on making a decision to try out their features and get stressed during the onboarding not willing to waste your budget.</p>
                                     </div>
                                     <div data-aos="zoom-in-right" className='_build_ls_box1'>
                                         <div className='_build_ls_bold'>
                                             <img src={_build3} className="_build_img" alt='image' />
                                             <p className='typekit_h3 _build_ls_b webkit_primary_h'>Engaged customers shop more</p>
                                         </div>
                                         <p className={innerwdt > 1024 ? 'typekit_BodyLargeRegular _build_p webkit_primary_grey' : '_build_p typekit_BodyRegular webkit_primary_grey' }>Don’t limit yourself to what is on the market. Add extra features like mobile web browser screen sharing and annotation to your product to enhance your customer experience and supercharge your conversion rates.  </p>
                                     </div>
                                     
                                 </div>
                                 <div className='build_ls_R2'>
                                 <div data-aos="zoom-in-left" className='_build_ls_box2'>
                                         <div className='_build_ls_bold'>
                                             <img src={_build2} className="_build_img" alt='image' />
                                             {innerwdt > 500 && innerwdt < 1024 ? <p className='typekit_h3 _build_ls_b webkit_primary_h'>Social media are <br></br>limited </p> :
                                             <p className='typekit_h3 _build_ls_b webkit_primary_h'>Social media are limited </p>}
                                             
                                         </div>
                                         <p className={innerwdt > 1024 ? 'typekit_BodyLargeRegular _build_p webkit_primary_grey' : '_build_p typekit_BodyRegular webkit_primary_grey' }>Social media live streaming lacks essential live shopping features, such as an instant add to cart. By depriving your viewers of an opportunity to add products to cart while in a stream you miss out on your spontaneous buyers. </p>
                                     </div>
                                     <div data-aos="zoom-in-left" className='_build_ls_box2'>
                                         <div className='_build_ls_bold'>
                                             <img src={_build4} className="_build_img" alt='image' />
                                             <p className='typekit_h3 _build_ls_b webkit_primary_h'>Get 100% control over your data </p>
                                         </div>
                                         <p className={innerwdt > 1024 ? 'typekit_BodyLargeRegular _build_p webkit_primary_grey' : '_build_p typekit_BodyRegular webkit_primary_grey' }>Avoid sharing your customer and visitor data with third party solutions. Less security issues and more control.</p>
                                     </div>
                                 </div>
                                 </>

            }

        </div>
        </>
    )
}


function map(state){
    return state;
}
export default connect(map)(Build_LiveShopping);