import React, { useState, useEffect, Fragment } from "react";
import arrowdown from "./images/arrowdown.webp";
import arrowup from "./images/arrowup.webp";

import { connect } from "react-redux";
import APIs from "../../APIs/API";
import mixpanel from "mixpanel-browser";

const FAQForm = (props) => {
  const API_Object = new APIs();
  const [FAQSubCategories, setFAQSubCategories] = useState([]);
  const [content, setContent] = useState([]);
  const [answer, setAnswer] = useState("");
  const [statesubcat, setstatesubcat] = useState("");
  const [storeData, setStoreData] = useState([]);
  const [ans, setAns] = useState("");
  useEffect(() => {
    if (props.faqs.length > 0) {
      updateContent(props.faqs[0]);
      showSubCat(props.faqs[0], 0);
    }
  }, []);
  const scrollToSubCat = (dd, index) => {
    // console.log("scrolling");
    if (document.getElementById(`${dd.id}st`)) {
      document
        .getElementById(`${dd.id}st`)
        .scrollIntoView({ behavior: "smooth" });
    } else {
      updateContent(dd, index);
    }
    // else{
    //   showSubCat(dd, index);
    //   document.getElementById(`${dd.id}st`).scrollIntoView({ behavior: "smooth" });

    // }
  };
  const showAnswer = (id) => {
    if (document.getElementById(id).style.display == "none") {
      document.getElementById(id).style.display = "flex";
      document.getElementById(`${id}img`).src = arrowup;
    } else {
      document.getElementById(id).style.display = "none";
      document.getElementById(`${id}img`).src = arrowdown;
    }
  };
  const showSubCat = (datap, index) => {
    console.trace();
    // console.log("running....");
    if (index !== statesubcat) {
      updateContent(datap);

      setFAQSubCategories(datap.sub_categories);
      setstatesubcat(index);
      document.querySelectorAll('img[id$="_arrow"]').forEach((elm) => {
        elm.src = arrowdown;
      });
      document.getElementById(`${datap.id}_arrow`).src = arrowup;
    } else {
      updateContent(datap);
      setstatesubcat("");
      setFAQSubCategories([]);
      document.getElementById(`${datap.id}_arrow`).src = arrowdown;
    }
  };
  const updateContent = (data) => {
    setStoreData(data.name);
    setContent(
      <>
        {data.have_sub_categories == "Yes" ? (
          <div>
            <div>
              <p className="faquestionheading typekit_h3">{data.name}</p>
              <p className="greytext02 nodeheading typekit_BodyRegular">
                Guides on {data.name}
              </p>
              <div className="faqline"></div>
            </div>
            {data.sub_categories.map((dd, ind) => {
              return (
                <Fragment key={ind}>
                  <p
                    id={`${dd.id}st`}
                    className="faquestionheading medium typekit_h4"
                  >
                    {dd.name}
                  </p>
                  {dd.faqs.map((d, index) => {
                    return (
                      <Fragment key={index}>
                        <div
                          className="contentbox"
                          onClick={() => {
                            mixpanel.track(d.question);
                            showAnswer(`s${d.id}`);
                          }}
                        >
                          <div className="frmcontent">
                            <p className="faqanswerheader">{d.question}</p>
                            <p
                              className="faqanswer01 typekit_LabelRegular"
                              id={`s${d.id}`}
                            >
                              {d.answer}
                            </p>
                          </div>
                          <img
                            id={`s${d.id}img`}
                            className="arrowbottom"
                            src={arrowup}
                            alt="arrow-down"
                          />
                        </div>
                        {answer == index && <p className="faqanswer">{ans}</p>}
                      </Fragment>
                    );
                  })}
                </Fragment>
              );
            })}
          </div>
        ) : (
          <div>
            <div>
              <p className="faquestionheading typekit_h3">{data.name}</p>
              <p className="greytext02 nodeheading typekit_BodyRegular">
                Guides on {data.name}
              </p>
              <div className="faqline"></div>
            </div>
            <p className="faquestionheading medium typekit_h4">{data.name}</p>
            {data.faqs.map((det, index) => {
              return (
                <Fragment>
                  <div
                    key={index}
                    className="contentbox"
                    onClick={() => {
                      mixpanel.track(det.question);
                      showAnswer(`s${det.id}`);
                    }}
                  >
                    <div className="frmcontent">
                      <p className="faqanswerheader">{det.question}</p>
                      <p
                        className="faqanswer01 typekit_LabelRegular"
                        id={`s${det.id}`}
                      >
                        {det.answer}
                      </p>
                    </div>
                    <img
                      id={`s${det.id}img`}
                      className="arrowbottom"
                      src={arrowup}
                      alt="arrow-down"
                    />
                  </div>
                </Fragment>
              );
            })}
          </div>
        )}
      </>
    );
  };
  return (
    <>
      <div className="FAQform">
        <div className="form">
          <div className="formleft">
            <p className="greytext01 typekit_LabelRegularBold">
              FAQ / <b className="setup01">{storeData}</b>
            </p>
            <div className="menu">
              {props.faqs.map((det, index) => {
                return (
                  <div className="menudirections" key={index}>
                    <div className="menudivflex">
                      {det.have_sub_categories == "Yes" ? (
                        <>
                          <div
                            className="dsep"
                            onClick={() => {
                              mixpanel.track(det.name);
                              showSubCat(det, index);
                            }}
                          >
                            <p className="faquesp01">{det.name}</p>
                            {/* <img className="arrowup" src={arrowup} alt="arrow-up"/> */}
                            <img
                              id={`${det.id}_arrow`}
                              className="arrowbottom"
                              src={arrowdown}
                              alt="arrow-bottom"
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <p
                            className="faquesp01"
                            onClick={() => {
                              mixpanel.track(det.name);
                              updateContent(det);
                            }}
                          >
                            {det.name}
                          </p>
                        </>
                      )}
                    </div>
                    <div className="yyy">
                      {statesubcat == index &&
                        FAQSubCategories.map((dt, index) => {
                          return (
                            <Fragment key={index}>
                              <p
                                className="faquesp nodeheading typekit_LabelRegular"
                                onClick={() => scrollToSubCat(dt, index)}
                              >
                                {dt.name}
                              </p>
                            </Fragment>
                          );
                        })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="drawline"></div>
          <div className="formright">{content}</div>
        </div>
      </div>
    </>
  );
};
function map(state) {
  return state;
}
export default connect(map)(FAQForm);
