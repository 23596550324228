import React, { useState, useEffect } from "react";
import blue from "../../assets/images/blue.svg";
import yellow from "../../assets/images/yellow.svg";
import purple from "../../assets/images/purple.svg";
import minutes from "../../assets/images/minutes.png";
import mingraph from "../../assets/images/mingraph.png";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import mintoggle from "../../assets/images/mintoggle.svg";
import toggletab from "../../assets/images/toggletab.png";
import graphtab from "../../assets/images/graphtab.png";
import { connect } from "react-redux";
import blueimg from "../../assets/images/blueimg.png";
import yellowimg from "../../assets/images/yellowimg.png";
import purpleimg from "../../assets/images/purpleimg.png";
import Slider from "react-slick";
import yellowcircles from "../../assets/images/yellowcircles.png";

import "./others.css";
import mixpanel from "mixpanel-browser";

const OthersComponent = (props) => {
  const [checkedIn, setCheckedIn] = useState(false);
  const [innerwdt, setinnerwdt] = useState(window.innerWidth);
  useEffect(() => {
    setinnerwdt(window.innerWidth);
    // console.log("hjh", innerwdt);
    window.addEventListener("resize", () => {
      setinnerwdt(window.innerWidth);
    });
  }, []);
  useEffect(() => {
    if (window.innerWidth > 600) {
      document.getElementById("otherdesktop").style.display = "flex";
      document.getElementById("othermob").style.display = "none";
    } else {
      document.getElementById("otherdesktop").style.display = "none";
      document.getElementById("othermob").style.display = "block";
    }
    window.addEventListener("resize", function (event) {
      var newWidth = window.innerWidth;
      var newHeight = window.innerHeight;
      // console.log("resize");
      if (newWidth > 600) {
        document.getElementById("otherdesktop").style.display = "flex";
        document.getElementById("othermob").style.display = "none";
      } else {
        document.getElementById("otherdesktop").style.display = "none";
        document.getElementById("othermob").style.display = "block";
      }
    });
  }, []);

  const reverse = (encoded) => {
    var newString = "";
    for (var i = encoded.length - 1; i >= 0; i--) {
      newString += encoded[i];
    }
    return newString;
  };
  const setCookie = (cname, cvalue, exdays) => {
    let encoded = btoa(cvalue);
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    if (window.location.href.includes(".dev")) {
      // document.cookie=`user=; Expires=Fri, 5 Oct 2018 14:28:00 GMT;path=/;Domain=vdotok.dev;SameSite=Strict`;
      document.cookie =
        cname +
        "=" +
        reverse(encoded) +
        ";" +
        expires +
        ";path=/;Domain=vdotok.dev;SameSite=Strict";
    } else {
      document.cookie =
        cname +
        "=" +
        reverse(encoded) +
        ";" +
        expires +
        ";path=/;Domain=vdotok.com;SameSite=Strict";
      // document.cookie=`user=; Expires=Fri, 5 Oct 2018 14:28:00 GMT;path=/;Domain=vdotok.com;SameSite=Strict`;
    }
    // document.cookie = cname + "=" + reverse(encoded) + ";" + expires + ";path=/;Domain=vdotok.dev;SameSite=Strict";
  };
  useEffect(() => {
    if (Object.keys(props.userdone).length == 0) {
      setCheckedIn(false);
    } else {
      setCheckedIn(true);
    }
  }, [props]);
  const [settings, setSliderSettings] = useState({
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          className: "center",
          centerMode: true,
          infinite: true,
          centerPadding: "60px",
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  });
  return (
    <>
      <div className="otherscomponent">
        <div data-aos="zoom-in-right" className="othersrow1">
          <div className="othersflex">
            <p className="webkit_primary_black typekit_h2 otherunique">
              What others are building
            </p>
            <p className="othersdescription typekit_BodyRegular webkit_primary_black">
              Build HD quality connections for one-to-one, one-to-many,
              many-to-many calls & streaming.
            </p>
            <div className="headerbtns">
              <div className="1stbuttondiv">
                {!checkedIn ? (
                  <button
                    type="button"
                    className="Darkbluebtn othersbtn"
                    // onClick={() => {
                    //   mixpanel.track("try_for_free");
                    //   props.openSignupPopup();
                    // }}
                    style={{ cursor: "pointer" }}
                  >
                    TRY FOR FREE
                  </button>
                ) : (
                  <button
                    type="button"
                    className="Darkbluebtn othersbtn"
                    onClick={() => {
                      mixpanel.track("dashboard");
                      setCookie("user", props.user.auth_token, 4);
                      props.dispatch({
                        type: "USERDONE",
                        userData: props.user,
                      });
                      window.open(
                        `https://${props.urls.adminPanelURL}/overview`
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    DASHBOARD
                  </button>
                )}
              </div>
            </div>
          </div>
          <div data-aos="zoom-in-right" className="othersrow3">
            <div className="othersmins">
              <img className="mintoggle" src={mintoggle} alt="minutes" />
              <p className="mintext minbold">
                241 Minutes <br></br>
                <b className="ot_aug"> (aug)</b>
              </p>
              <img className="mingraph" src={mingraph} alt="minutes" />
            </div>
          </div>
        </div>
        <div className="nclass">
          <div className="othersrow2" id="otherdesktop">
            <Slider {...settings}>
              <div
                data-aos="zoom-in-left"
                className="otherscard webkit_FreshGreen"
              >
                <div className="upimages">
                  <div className="toggle">
                    <img src={blue} alt="image" />
                  </div>
                  <div className="circle">
                    <img className="roundimg" src={blueimg} alt="image" />
                  </div>
                </div>
                <div className="otherscontent">
                  <p
                    className={
                      innerwdt > 1024
                        ? "cardheading webkit_GreenGrass typekit_LabelXXL"
                        : "cardheading webkit_GreenGrass typekit_LabelXL"
                    }
                  >
                    Live<br></br> shopping app
                  </p>
                  <p
                    className={
                      innerwdt > 1024
                        ? "card_p webkit_GreenGrass typekit_BodySmallRegular"
                        : "card_p webkit_GreenGrass typekit_LabelLight"
                    }
                  >
                    - Live streaming
                  </p>
                  <p
                    className={
                      innerwdt > 1024
                        ? "card_p webkit_GreenGrass typekit_BodySmallRegular"
                        : "card_p webkit_GreenGrass typekit_LabelLight"
                    }
                  >
                    - Social engagement
                  </p>
                  <p
                    className={
                      innerwdt > 1024
                        ? "card_p webkit_GreenGrass typekit_BodySmallRegular"
                        : "card_p webkit_GreenGrass typekit_LabelLight"
                    }
                  >
                    - Messaging
                  </p>
                  <div className="l1"></div>
                  <div className="l2"></div>
                  <div className="l3"></div>
                </div>
              </div>
              <div
                data-aos="zoom-in-left"
                className="otherscard webkit_FreshYellow"
              >
                <div className="upimages">
                  <div className="toggle">
                    <img src={yellow} alt="img" />
                  </div>
                </div>
                <div className="otherscontent ">
                  <p
                    className={
                      innerwdt > 1024
                        ? "cardheading webkit_YellowDark typekit_LabelXXL"
                        : "cardheading webkit_YellowDark typekit_LabelXL"
                    }
                  >
                    Online<br></br> education platform
                  </p>
                  <p
                    className={
                      innerwdt > 1024
                        ? "card_p webkit_YellowDark typekit_BodySmallRegular"
                        : "card_p webkit_YellowDark typekit_LabelLight"
                    }
                  >
                    - Webinars
                  </p>
                  <p
                    className={
                      innerwdt > 1024
                        ? "card_p webkit_YellowDark typekit_BodySmallRegular"
                        : "card_p webkit_YellowDark typekit_LabelLight"
                    }
                  >
                    - Screen sharing
                  </p>
                  <p
                    className={
                      innerwdt > 1024
                        ? "card_p webkit_YellowDark typekit_BodySmallRegular"
                        : "card_p webkit_YellowDark typekit_LabelLight"
                    }
                  >
                    - Video calls
                  </p>
                  <div className="l2 card2_12"></div>
                  <div className="starss">
                    <img src={yellowcircles} alt="circle" />
                  </div>
                  <div className="bottomcircle">
                    <img
                      className="roundimg bottom"
                      src={yellowimg}
                      alt="round-img-bottom"
                    />
                  </div>
                </div>
              </div>
              <div
                data-aos="zoom-in-left"
                className="otherscard webkit_FreshBlue"
              >
                <div className="upimages">
                  <div className="toggle">
                    <img src={purple} alt="purple-img" />
                  </div>
                  <div className="circle">
                    <img
                      className="roundimg"
                      src={purpleimg}
                      alt="purple-img"
                    />
                  </div>
                </div>
                <div className="otherscontent">
                  <p
                    className={
                      innerwdt > 1024
                        ? "cardheading webkit_Bluemoon typekit_LabelXXL"
                        : "cardheading webkit_Bluemoon typekit_LabelXL"
                    }
                  >
                    Online<br></br> doctor
                  </p>
                  <p
                    className={
                      innerwdt > 1024
                        ? "card_p webkit_Bluemoon typekit_BodySmallRegular"
                        : "card_p webkit_Bluemoon typekit_LabelLight"
                    }
                  >
                    - Voice & video calls
                  </p>
                  <p
                    className={
                      innerwdt > 1024
                        ? "card_p webkit_Bluemoon typekit_BodySmallRegular"
                        : "card_p webkit_Bluemoon typekit_LabelLight"
                    }
                  >
                    - Messaging
                  </p>
                  <p
                    className={
                      innerwdt > 1024
                        ? "card_p webkit_Bluemoon typekit_BodySmallRegular"
                        : "card_p webkit_Bluemoon typekit_LabelLight"
                    }
                  >
                    - Remote monitoring
                  </p>
                  <div className="l1"></div>
                  <div className="l2"></div>
                  <div className="l3"></div>
                </div>
              </div>
            </Slider>
          </div>
          <div id="othermob" className="othersrow2">
            <Splide
              options={{
                type: "loop",
                padding: "",
                perPage: 2,
                parMove: 2,
                focus: "right",
                // perMove    : 1,
                autoplay: true,
                lazyLoadL: true,
              }}
              aria-label="My Favorite Images"
            >
              <SplideSlide>
                <div
                  data-aos="zoom-in-left"
                  className="otherscard webkit_FreshGreen"
                >
                  <div className="upimages">
                    <div className="toggle">
                      <img src={blue} alt="image" />
                    </div>
                    <div className="circle">
                      <img className="roundimg" src={blueimg} alt="image" />
                    </div>
                  </div>
                  <div className="otherscontent">
                    <p
                      className={
                        innerwdt > 1024
                          ? "cardheading webkit_GreenGrass typekit_LabelXXL"
                          : "cardheading webkit_GreenGrass typekit_LabelXL"
                      }
                    >
                      Live<br></br> shopping app
                    </p>
                    <p
                      className={
                        innerwdt > 1024
                          ? "card_p webkit_GreenGrass typekit_BodySmallRegular"
                          : "card_p webkit_GreenGrass typekit_LabelLight"
                      }
                    >
                      - Live streaming
                    </p>
                    <p
                      className={
                        innerwdt > 1024
                          ? "card_p webkit_GreenGrass typekit_BodySmallRegular"
                          : "card_p webkit_GreenGrass typekit_LabelLight"
                      }
                    >
                      - Social engagement
                    </p>
                    <p
                      className={
                        innerwdt > 1024
                          ? "card_p webkit_GreenGrass typekit_BodySmallRegular"
                          : "card_p webkit_GreenGrass typekit_LabelLight"
                      }
                    >
                      - Messaging
                    </p>
                    <div className="l1"></div>
                    <div className="l2"></div>
                    <div className="l3"></div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div
                  data-aos="zoom-in-left"
                  className="otherscard webkit_FreshYellow"
                >
                  <div className="upimages">
                    <div className="toggle">
                      <img src={yellow} alt="img" />
                    </div>
                  </div>
                  <div className="otherscontent ">
                    <p
                      className={
                        innerwdt > 1024
                          ? "cardheading webkit_YellowDark typekit_LabelXXL"
                          : "cardheading webkit_YellowDark typekit_LabelXL"
                      }
                    >
                      Online<br></br> education platform
                    </p>
                    <p
                      className={
                        innerwdt > 1024
                          ? "card_p webkit_YellowDark typekit_BodySmallRegular"
                          : "card_p webkit_YellowDark typekit_LabelLight"
                      }
                    >
                      - Webinars
                    </p>
                    <p
                      className={
                        innerwdt > 1024
                          ? "card_p webkit_YellowDark typekit_BodySmallRegular"
                          : "card_p webkit_YellowDark typekit_LabelLight"
                      }
                    >
                      - Screen sharing
                    </p>
                    <p
                      className={
                        innerwdt > 1024
                          ? "card_p webkit_YellowDark typekit_BodySmallRegular"
                          : "card_p webkit_YellowDark typekit_LabelLight"
                      }
                    >
                      - Video calls
                    </p>
                    <div className="l2 card2_12"></div>
                    <div className="starss">
                      <img src={yellowcircles} alt="circle" />
                    </div>
                    <div className="bottomcircle">
                      <img
                        className="roundimg bottom"
                        src={yellowimg}
                        alt="round-img-bottom"
                      />
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div
                  data-aos="zoom-in-left"
                  className="otherscard webkit_FreshBlue"
                >
                  <div className="upimages">
                    <div className="toggle">
                      <img src={purple} alt="purple-img" />
                    </div>
                    <div className="circle">
                      <img
                        className="roundimg"
                        src={purpleimg}
                        alt="purple-img"
                      />
                    </div>
                  </div>
                  <div className="otherscontent">
                    <p
                      className={
                        innerwdt > 1024
                          ? "cardheading webkit_Bluemoon typekit_LabelXXL"
                          : "cardheading webkit_Bluemoon typekit_LabelXL"
                      }
                    >
                      Online<br></br> doctor
                    </p>
                    <p
                      className={
                        innerwdt > 1024
                          ? "card_p webkit_Bluemoon typekit_BodySmallRegular"
                          : "card_p webkit_Bluemoon typekit_LabelLight"
                      }
                    >
                      - Voice & video calls
                    </p>
                    <p
                      className={
                        innerwdt > 1024
                          ? "card_p webkit_Bluemoon typekit_BodySmallRegular"
                          : "card_p webkit_Bluemoon typekit_LabelLight"
                      }
                    >
                      - Messaging
                    </p>
                    <p
                      className={
                        innerwdt > 1024
                          ? "card_p webkit_Bluemoon typekit_BodySmallRegular"
                          : "card_p webkit_Bluemoon typekit_LabelLight"
                      }
                    >
                      - Remote monitoring
                    </p>
                    <div className="l1"></div>
                    <div className="l2"></div>
                    <div className="l3"></div>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>

          <div data-aos="zoom-in-right" className="othersrow3hide">
            <div className="othersmins">
              <img className="mintoggle" src={mintoggle} alt="minutes" />

              {innerwdt > 1024 ? (
                <div style={{ display: "flex" }}>
                  <p className="mintextbold eltspace">241 Minutes</p>{" "}
                  <p className="mintextnormal" style={{ marginLeft: "5px" }}>
                    (aug)
                  </p>
                </div>
              ) : (
                <>
                  <p className="mintext minbold">
                    241 Minutes <br></br>
                    <b className="ot_aug"> (aug)</b>
                  </p>
                </>
              )}
              <img className="mingraph" src={mingraph} alt="minutes" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function map(state) {
  return state;
}
export default connect(map)(OthersComponent);
