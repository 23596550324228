import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import Logo from "../../assets/images/logoupdated.png";
import LinkedIn from "../../assets/images/linkedin2x.png";
import whatsApp from "../../assets/images/whatsapp@2x.png";
import Insta from "../../assets/images/instagram@2x.png";
import Scroll from "../../assets/images/scrollToTop.png";
import fbIcon from "../../assets/images/facebookIcon.png";
import logombl from "../../assets/images/footerlogombl.png";
import { useHistory } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import "./footer.css";

const Footer = (props) => {
  // console.log("noman", props);
  const [year, setyear] = useState();
  const navigate = useNavigate();
  const [innerwdt, setinnerwdt] = useState(window.innerWidth);
  useEffect(() => {
    setinnerwdt(window.innerWidth);
    window.addEventListener("resize", () => {
      setinnerwdt(window.innerWidth);
    });
  }, []);
  const sendMail = () => {
    mixpanel.track("email");
    var link = "mailto:info@vdotok.com";
    window.location.href = link;
  };
  useEffect(() => {
    window.addEventListener("unload", () => {
      window.localStorage.setItem("oldURL", window.location.href);
    });
    let oldURL = window.localStorage.getItem("oldURL");
    //if(!window.location.href.includes('/pricing') || !window.location.href.includes('/blog') ){
    setTimeout(() => {
      if (oldURL === window.location.href) {
        window.scrollTo(0, 0);
      } else {
        // console.log(
        //   "no need to scroll as url differ",
        //   oldURL,
        //   window.location.href
        // );
      }
    }, 700);
    //}
  }, []);
  useEffect(() => {
    const d = new Date();
    setyear(d.getFullYear());
  }, []);
  const onTopp = () => {
    if (window.location.href.includes("/FAQ")) {
      onCLickHome();
    } else {
      // console.log("scrollingg 3");

      window.scrollTo({ top: 0, left: 0 });
    }
  };
  const handlePageshift = useCallback(
    () => navigate("/pricing", { replace: true }),
    [navigate]
  );
  const HandleOnClick = () => {
    mixpanel.track("pricing");
    if (document.getElementById("pricing")) {
      document.getElementById("pricing").scrollIntoView({ behavior: "smooth" });
      // console.log("scrolling");
    } else {
      handlePageshift();
      document.getElementById("pricing").scrollIntoView({ behavior: "smooth" });
    }
  };
  const handleOnClick = useCallback(
    () => navigate("/about", { replace: true }),
    [navigate]
  );
  const onCLickFAQ = useCallback(
    () => navigate("/FAQ", { replace: true }),
    [navigate]
  );
  const onCLickHome = useCallback(
    () => navigate("/", { replace: true }),
    [navigate]
  );
  return (
    <>
      <div className="footerline"></div>
      <div className="footerMainDiv">
        <div className="footerUpDiv">
          <div className="logoMainDiv">
            <div
              className="logoDiv"
              style={{
                cursor: "pointer",
              }}
            >
              <img
                src={Logo}
                alt="Logo"
                className="logoStyle"
                onClick={() => {
                  onCLickHome();
                }}
              />
            </div>
            <div className="paraDiv">
              <p className="paraStyle typekit_BodyRegular webkit_primary_grey">
                Build powerful communication solutions for Live Shopping,
                MedTech, IoT & Social Networking
              </p>
            </div>
          </div>

          <div className="newfooterclass">
            <div className="linkMainDiv">
              <div className="linkHead">This is VdoTok</div>
              <div className="linksStyle">
                <a
                  className="linkParaStyle"
                  // onClick={() => {
                  //   mixpanel.track("sign_up");
                  //   props.openSignupPopup();
                  // }}
                >
                  Try VdoTok Console
                </a>

                {/* {navLinks.map((res) => (
            <a key={res.id} href="#about" className="anchorStyle">
              {res.link}
            </a>
            ))} */}
                <Link to="/about" className="linkParaStyle">
                  About
                </Link>

                <a
                  //  href="#pricing"
                  //onClick={HandleOnClick}
                  className="linkParaStyle"
                >
                  Pricing
                </a>
                <a
                  // onClick={() => {
                  //   mixpanel.track("doc_and_tool");
                  //   window.open(`https://${props.urls.docURL}`);
                  // }}
                  // href="#documentation"
                  className=" linkParaStyle"
                >
                  Documentation
                </a>

                {/*<Link to="/FAQ" className="linkParaStyle">*/}
                {/*  FAQ*/}
                {/*</Link>*/}
                <Link
                    //to="https://partnerships.vdotok.com/"
                    className="linkParaStyle">
                  Partnership
                </Link>
              </div>
            </div>
            <div className="contactMainDiv">
              <div className="linkHead">Use cases</div>
              <div className="linksStyle">
                <a
                  onClick={() => {
                    mixpanel.track("live_shopping");
                    window.open("/liveshopping");
                  }}
                  // href="#about"
                  className="linkParaStyle"
                >
                  Live Shopping
                </a>
                <a
                  onClick={() => {
                    mixpanel.track("Med_Tech");
                    window.open("/healthtech");
                  }}
                  className=" linkParaStyle"
                >
                  MedTech
                </a>
                <a
                  // onClick={() => {
                  //   mixpanel.track("ed_tech");
                  //   window.open("https://go.vdotok.com/blog?tag=edtech")
                  // }}
                  style={{
                    cursor: "not-allowed",
                    pointer: "none",
                    opacity: "0.5",
                  }}
                  className=" linkParaStyle"
                >
                  Social Network
                </a>
              </div>
            </div>
          </div>
          {innerwdt < 600 ? (
            <div className="customborderinfooter"></div>
          ) : (
            <></>
          )}
          <div className="contactMainDiv contactfooterDiv">
            <div className="linkHead contactusmbl">Contact us</div>
            <a className="linkParaStyle email" >
              <span style={{cursor:"default"}}>E-mail:</span> <span style={{cursor:"pointer"}} onClick={sendMail}>info@vdotok.com</span>
            </a>
            {/* <p className=" linkParaStyle" onClick={()=> {mixpanel.track("contact_us"); props.openContactusPopup()}}>Contact us</p> */}
            <div className="iconDiv">
              {/* <div>
                  <img src={LinkedIn} alt="icon" className="logoStyle2"/>
              </div> */}
              <div
                className="_icon_margin"
                onClick={() => {
                  mixpanel.track("facebook");
                  window.open("https://www.facebook.com/vdotok/", "_blank");
                }}
              >
                <img src={fbIcon} alt="fb" className="logoStyle2" />
              </div>
              <div
                className="_icon_margin"
                onClick={() => {
                  mixpanel.track("intagram");
                  window.open("https://www.instagram.com/vdotok/", "_blank");
                }}
              >
                <img src={Insta} alt="insta" className="logoStyle2" />
              </div>
              <div
                className="_icon_margin"
                onClick={() => {
                  mixpanel.track("linkedin");
                  window.open(
                    "https://www.linkedin.com/company/vdotok/",
                    "_blank"
                  );
                }}
              >
                <img src={LinkedIn} alt="linkdin" className="logoStyle2" />
              </div>
            </div>
          </div>
          {innerwdt < 600 ? (
            <div className="customborderinfooter"></div>
          ) : (
            <></>
          )}
          <div className="hidediv">
            <div className="logoDiv">
              <img src={logombl} alt="Logo" className="logoStyle" />
            </div>
            <div className="paraDiv">
              <p className="typekit_LabelLight webkit_primary_grey footerbuild">
                Build powerful communication solutions for Live Shopping,
                MedTech, IoT & Social Networking
              </p>
            </div>
          </div>
        </div>
        <div className="footerDownDiv">
          <div className="footerMailDiv paraStyle footerBottom">
            © {year}, VdoTok AB
          </div>
          {/* <div className="footerEmpty"></div> */}
          <div
            style={{ cursor: "pointer" }}
            onClick={() => window.scrollTo(0, 0)}
            className="footerBack"
          >
            <img src={Scroll} alt="to-top" className="scrollStyle" />
            <p className="backtotop">Back to top</p>
          </div>
        </div>
      </div>
    </>
  );
};

function map(state) {
  return state;
}
export default connect(map)(Footer);

const styles = {
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "green",
  },
  text: {
    color: "white",
  },
};
