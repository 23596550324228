import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { connect } from "react-redux";
import BlogComponent from "../components/Blog/BlogComponent";
// import Blog from "../components/blogCompnent";
import Footer from "../components/Footer/Footer";
import HeaderComponent from "../components/Header/HeaderComponent";
import NavComponent from "../components/Nav/NavComponent";
import OthersComponent from "../components/Others/OthersComponent";
import PayComponent from "../components/Pay/PayComponent";
import SliderComponent from "../components/Slider/SliderComponent";
import TestinomialComponent from "../components/Testimonial/TestimonialsComponent";

import TryComponent from "../components/Try/TryComponent";
import UniqueComponent from "../components/Unique/UniqueComponent";
import SignupPopup from "../components/Nav/SignUpPopup";
import axios from "axios";
import UseCasesPopup from "../components/Nav/UseCasesPopup";
import ContactUsPopup from "../components/Nav/ContactUsPopup";
import AboutYou from "../components/Verification/AboutYou";
import EmailVerification from "../components/Verification/EmailVerification";
import EnterPhone from "../components/Verification/EnterPhone";
import PhoneVerification from "../components/Verification/PhoneVerification";
import Thankyou from "../components/Verification/Thankyou";
import LoginPopup from "../components/Nav/LoginPopup";
import mixpanel from "mixpanel-browser";
import APIs from "../APIs/API";
import ForgotPassword from "./forgot-password";
import LoginVerification from "../components/Verification/LoginVerification";
import VerificationCode from "./verification-code";
import ResetPassword from "./reset-password";
import SuccessPassword from "./success-password";

const MainScreen = (props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [path, setPath] = useState(window.location.pathname);
  const gotoHome = useCallback(
    () => navigate("/", { replace: true }),
    [navigate]
  );
  const [forgotResponse, setForgotResponse] = useState({
    attempts: "",
    token: "",
    otp: "",
    email: "",
    comeFromEmail: false,
  });
  const [selectedScreen, setSelectedScreen] = useState(null);
  const [signupPopup, setSignupPopup] = useState(false);
  const [login, setLogin] = useState(false);
  const [loginverification, setLoginVerification] = useState(false);
  const [contactusPopup, setContactusPopup] = useState(false);
  const [useCasesPopup, setUseCasesPopup] = useState(false);
  const [aboutYou, setAboutYou] = useState(false);
  const [emailVerification, setEmailVerification] = useState(false);
  const [enterPhone, setEnterPhone] = useState(false);
  const [phoneVerification, setPhoneVerification] = useState(false);
  const [thankyou, setThankyou] = useState(false);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    if (
      searchParams.get("otp") &&
      searchParams.get("token") &&
      searchParams.get("email")
    ) {
      setForgotResponse((prevForgotResponse) => ({
        ...prevForgotResponse,
        otp: searchParams.get("otp"),
        token: searchParams.get("token"),
        email: atob(searchParams.get("email")),
        comeFromEmail: true,
      }));
      setSelectedScreen("verification");
    }
    if (window.location.href.includes("login")) {
      openLoginPopup();
    }
  }, []);
  useEffect(() => {
    window.addEventListener("load", (event) => {
      if (window.location.href.includes("pricing")) {
        if (document.getElementById("pricing")) {
          document.getElementById("pricing").scrollIntoView({ behavior: "smooth" });
        document.getElementById("pricing").focus();
          
          console.log("scrolling 1");
        }
      }
    });
    console.log("path updated" , path);
  }, [path]);
  useEffect(() => {
    if(window.location.href.includes("contact")){
      openContactusPopup();
    }
    setPath(window.location.pathname)
    if (window.location.href.includes("signup")) {
      openSignupPopup();
    }
  }, []);
  useEffect(() => {
    axios
      .get(`https://${props.urls.apiBaseURL}/API/GetAllCountries`)
      .then((res) => {
        setCountries(res.data.data);
      })
      .catch((err) => {});
  }, [props.urls.apiBaseURL]);

  const closeSignupPopup = () => {
    document.body.style.overflow = "auto";
    // document.body.style.position = 'unset';

    setSignupPopup(false);
  };
  const openSignupPopup = () => {
    setSignupPopup(true);
    document.body.style.overflow = "hidden";
    // document.body.style.position = 'fixed';
  };
  const closeLoginPopup = () => {
    document.body.style.overflow = "auto";
    // document.body.style.position = 'unset';

    setLogin(false);
  };
  const openLoginPopup = () => {
    setLogin(true);
    document.body.style.overflow = "hidden";
    // document.body.style.position = 'fixed';
  };
  const closeAboutYouPopup = () => {
    document.body.style.overflow = "auto";
    // document.body.style.position = 'unset';

    setAboutYou(false);
  };
  const openAboutYouPopup = () => {
    document.body.style.overflow = "hidden";
    // document.body.style.position = 'fixed';

    setAboutYou(true);
  };
  const closeEmailVerificationPopup = () => {
    document.body.style.overflow = "auto";
    // document.body.style.position = 'unset';

    setEmailVerification(false);
  };
  const openEmailVerificationPopup = () => {
    document.body.style.overflow = "hidden";
    // document.body.style.position = 'fixed';

    setEmailVerification(true);
  };
  const closeEnterPhonePopup = () => {
    document.body.style.overflow = "auto";
    // document.body.style.position = 'unset';

    setEnterPhone(false);
  };
  const openEnterPhonePopup = () => {
    document.body.style.overflow = "hidden";
    // document.body.style.position = 'fixed';

    setEnterPhone(true);
    setTimeout(() => {
      const phoneInputField = document.querySelector("#val2");
      const phoneInput = window.intlTelInput(phoneInputField, {
        preferredCountries: ["PK", "SE"],
        dropdownContainer: document.getElementById("inpsum"),
        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
      });
      // console.log("my phone input", phoneInput);
    }, 500);
  };
  const closePhoneVerificationPopup = () => {
    document.body.style.overflow = "auto";
    // document.body.style.position = 'unset';

    setPhoneVerification(false);
  };
  const openPhoneVerificationPopup = () => {
    document.body.style.overflow = "hidden";
    // document.body.style.position = 'fixed';

    setPhoneVerification(true);
  };
  const closeThankyouPopup = () => {
    document.body.style.overflow = "auto";
    // document.body.style.position = 'unset';

    setThankyou(false);
  };
  const openThankyouPopup = () => {
    document.body.style.overflow = "hidden";
    // document.body.style.position = 'fixed';

    setThankyou(true);
  };
  const openContactusPopup = () => {
    setContactusPopup(true);
    document.body.style.overflow = "hidden";
    // document.body.style.position = 'fixed';
  };
  const closeContactusPopup = () => {
    document.body.style.overflow = "auto";
    // document.body.style.position = 'unset';

    setContactusPopup(false);
  };
  const openUseCasesPopup = () => {
    document.body.style.overflow = "hidden";
    // document.body.style.position = 'fixed';

    setUseCasesPopup(true);
  };
  const closeUseCasesPopup = () => {
    document.body.style.overflow = "auto";
    // document.body.style.position = 'unset';

    setUseCasesPopup(false);
  };
  const openLoginVerification = () => {
    document.body.style.overflow = "hidden";
    // document.body.style.position = 'fixed';

    setLoginVerification(true);
  };
  const closeLoginVerification = () => {
    document.body.style.overflow = "auto";
    // document.body.style.position = 'unset';

    setLoginVerification(false);
  };

  const forgotScreenRender = useMemo(() => {
    if (selectedScreen === "forgot") {
      return (
        <ForgotPassword
          setSelectedScreen={setSelectedScreen}
          openLoginPopup={openLoginPopup}
          setForgotResponse={setForgotResponse}
        />
      );
    } else if (selectedScreen === "verification") {
      return (
        <VerificationCode
          setSelectedScreen={setSelectedScreen}
          openLoginPopup={openLoginPopup}
          setForgotResponse={setForgotResponse}
          forgotResponse={forgotResponse}
        />
      );
    } else if (selectedScreen === "reset") {
      return (
        <ResetPassword
          setSelectedScreen={setSelectedScreen}
          openLoginPopup={openLoginPopup}
          forgotResponse={forgotResponse}
          setForgotResponse={setForgotResponse}
        />
      );
    } else if (selectedScreen === "success") {
      return (
        <SuccessPassword
          setSelectedScreen={setSelectedScreen}
          openLoginPopup={openLoginPopup}
        />
      );
    }
  }, [selectedScreen, forgotResponse]);

  return (
    <>
      <div className="vdtbody">
        {aboutYou && (
          <AboutYou
            closeAboutYouPopup={closeAboutYouPopup}
            openThankyouPopup={openThankyouPopup}
          />
        )}
        {emailVerification && (
          <EmailVerification
            openSignupPopup={openSignupPopup}
            closeEmailVerificationPopup={closeEmailVerificationPopup}
            openEnterPhonePopup={openEnterPhonePopup}
          />
        )}
        {loginverification && (
          <LoginVerification
            closeLoginVerification={closeLoginVerification}
            openLoginPopup={openLoginPopup}
            openEnterPhonePopup={openEnterPhonePopup}
            openAboutYouPopup={openAboutYouPopup}
            openThankyouPopup={openThankyouPopup}
          />
        )}
        {enterPhone && (
          <EnterPhone
            closeEnterPhonePopup={closeEnterPhonePopup}
            openPhoneVerificationPopup={openPhoneVerificationPopup}
            openAboutYouPopup={openAboutYouPopup}
            countries={countries}
          />
        )}
        {phoneVerification && (
          <PhoneVerification
            openEnterPhonePopup={openEnterPhonePopup}
            closePhoneVerificationPopup={closePhoneVerificationPopup}
            openAboutYouPopup={openAboutYouPopup}
          />
        )}
        {thankyou && <Thankyou closeThankyouPopup={closeThankyouPopup} />}
        {signupPopup && (
          <SignupPopup
            mixpanel={mixpanel}
            openLoginPopup={openLoginPopup}
            closeSignupPopup={closeSignupPopup}
            openEmailVerificationPopup={openEmailVerificationPopup}
            countries={countries}
          />
        )}

        {login && (
          <LoginPopup
            openLoginVerification={openLoginVerification}
            openEnterPhonePopup={openEnterPhonePopup}
            openAboutYouPopup={openAboutYouPopup}
            openThankyouPopup={openThankyouPopup}
            closeLoginPopup={closeLoginPopup}
            openSignupPopup={openSignupPopup}
            setSelectedScreen={setSelectedScreen}
          />
        )}
        {contactusPopup && (
          <ContactUsPopup
            closeContactusPopup={closeContactusPopup}
            countries={countries}
          />
        )}
        {useCasesPopup && (
          <UseCasesPopup
            closeUseCasesPopup={closeUseCasesPopup}
            openContactusPopup={openContactusPopup}
          />
        )}
        <NavComponent
          openLoginPopup={openLoginPopup}
          openSignupPopup={openSignupPopup}
          closeSignupPopup={closeSignupPopup}
          openContactusPopup={openContactusPopup}
          closeContactusPopup={closeContactusPopup}
          openUseCasesPopup={openUseCasesPopup}
          closeUseCasesPopup={closeUseCasesPopup}
          useCasesPopup={useCasesPopup}
        />
        <HeaderComponent
          openSignupPopup={openSignupPopup}
          closeSignupPopup={closeSignupPopup}
        />
        <SliderComponent
          openSignupPopup={openSignupPopup}
          closeSignupPopup={closeSignupPopup}
        />
        <UniqueComponent />
        <OthersComponent
          openSignupPopup={openSignupPopup}
          closeSignupPopup={closeSignupPopup}
        />
        <PayComponent
          openContactusPopup={openContactusPopup}
          closeContactusPopup={closeContactusPopup}
          openSignupPopup={openSignupPopup}
          closeSignupPopup={closeSignupPopup}
        />

        <TestinomialComponent />
        <TryComponent
          openSignupPopup={openSignupPopup}
          closeSignupPopup={closeSignupPopup}
        />
        <BlogComponent />
        <Footer
          openContactusPopup={openContactusPopup}
          openSignupPopup={openSignupPopup}
        />
      </div>
      {forgotScreenRender}
    </>
    // <Blog />
  );
};
function map(state) {
  // console.log("**map", { state });
  return state;
}
export default connect(map)(MainScreen);
