import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Aos from "aos";
import startls from "../images/start_ht_desktop.png";
import startlstab from "../images/startHTtab.png";
import startlsmbl from "../images/startHTmbl.png";

import "aos/dist/aos.css";
import "./start_ht_today.css";

const StartHTToday = (props) => {
  const [innerwdt, setinnerwdt] = useState(window.innerWidth);
  // console.log("myprops" , props);
  useEffect(() => {
    Aos.init();
    setinnerwdt(window.innerWidth);
    window.addEventListener("resize", () => {
      setinnerwdt(window.innerWidth);
    });
  }, []);

  return (
    <>
      <div className="start_HT_today">
        <div className="_start_Ls_main">
          {innerwdt > 1024 ? (
            <p className="typekit_h2 webkit_primary_h">
              Start building your own Remote Clinic today
            </p>
          ) : innerwdt > 600 ? (
            <p className="typekit_h2 webkit_primary_h">
              Start building your own Remote Clinic<br></br> today.
            </p>
          ) : (
            <p className="typekit_h2 webkit_primary_h">
              Start building your own Remote Clinic today.
            </p>
          )}

          <p
            className={
              innerwdt > 1024
                ? "typekit_subheadline2 webkit_primary_h _start_ht_P"
                : innerwdt > 500
                ? "typekit_subheadline1 webkit_primary_h _start_ht_P"
                : "typekit_subheadline3 webkit_primary_h _start_ht_P"
            }
          >
            Use our powerful and easy-to-use APIs to integrate new generation of
            video and voice calls, messaging, live streaming and screen sharing
            to your TeleHealth product and transform your customers’ lives by
            bringing them into the new reality.{" "}
          </p>
        </div>
        {innerwdt > 1024 ? (
          <img src={startls} className="start-ht-img" />
        ) : innerwdt > 600 ? (
          <img src={startlstab} className="start-ht-img" />
        ) : (
          <img src={startlsmbl} className="start-ht-img" />
        )}
        <button
          className="start-ht-btn"
          // onClick={() => {
          //   props.openSignupPopup();
          // }}
        >
          TRY FOR FREE
        </button>
      </div>
    </>
  );
};

function map(state) {
  return state;
}
export default connect(map)(StartHTToday);
