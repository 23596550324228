import React, { useEffect, useState, useRef, useMemo } from "react";
import { createPortal } from "react-dom";
import axios from "axios";
import { connect } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { ReactComponent as Xcircle } from "../../assets/images/x-circle.svg";
import { ReactComponent as Close } from "../../assets/images/white_close.svg";
import VdoTok from "../../assets/images/logo.png";
import tabletLogo from "../../assets/images/vdotok_logo_tablet.png";
import mobileLogo from "../../assets/images/vdotok_logo_mobile.png";
import email from "../../assets/images/email-image.png";
import iphone from "../../assets/images/iphoneVideo.png";
import horizontal_iphone from "../../assets/images/horizontal_iphone.png";
import mobileBackground from "../../assets/images/mobile_backgound_video.png";
import Button from "../../components/common/button";
import CancelButton from "../../components/common/cancelButton";
import Input from "../../components/common/input";
import "./styles.css";

let interval;

const VerificationCode = (props) => {
  const {
    setSelectedScreen,
    setForgotResponse,
    forgotResponse,
    openLoginPopup,
  } = props;
  const [countDown, setCountDown] = useState(60);
  const [captcha, setCaptcha] = useState("");
  const [reenter, setReenter] = useState(false);
  const [codeError, setCodeError] = useState({ status: null, errMsg: "" });
  const [errorMessage, setErrorMessage] = useState({
    type: "success",
    value: "A reset code has been sent",
  });
  const [hitAgain, setHitAgain] = useState(true);
  const [hitAgainResend, setHitAgainResend] = useState(true);
  const timeoutRef = useRef(null);
  const hideNotification = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      emptyMessage();
    }, 5000);
  };

  const emptyMessage = () => {
    setErrorMessage({ type: null, value: null });
  };

  useEffect(() => {
    hideNotification();

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (countDown > 0) {
      interval = setInterval(() => {
        setCountDown(countDown - 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [countDown]);

  const codeHandler = () => {
    if (hitAgain) {
      setHitAgain(false);
      emptyMessage();
      if (forgotResponse.otp) {
        const body = {
          verification_otp: forgotResponse.otp,
          verification_token: forgotResponse.token,
        };
        axios
          .post(
            `https://${props.urls.apiBaseURL}/AdminAPI/v0/VerifyPassword`,
            body
          )
          .then((res) => {
            if (res.data.status === 200) {
              setForgotResponse((prevForgotResponse) => ({
                ...prevForgotResponse,
                attempts: res.data.attempts,
                comeFromEmail: false,
              }));
              setSelectedScreen("reset");
            } else {
              if (res.data.status === 408) {
                setCodeError((prevCodeError) => ({
                  ...prevCodeError,
                  code: res.data.status,
                  errMsg: res.data.message,
                }));
              }
              setForgotResponse((prevForgotResponse) => ({
                ...prevForgotResponse,
                attempts: res.data.attempts,
                comeFromEmail: false,
              }));
              setErrorMessage((prevState) => ({
                ...prevState,
                value: res.data.message,
                type: "error",
              }));
              hideNotification();
            }
          })
          .catch((err) => {
            setErrorMessage((prevState) => ({
              ...prevState,
              value: err.message,
              type: "error",
            }));

            hideNotification();
          })
          .finally(() => {
            setHitAgain(true);
          });
      }
    }
  };

  const otpHandler = (event) => {
    const codeRegex = /^\d+$/;
    const validateCode = codeRegex.test(event.target.value);

    if (event.target.value === "" || validateCode) {
      setForgotResponse((prevForgotResponse) => ({
        ...prevForgotResponse,
        otp: event.target.value,
      }));
    }
  };
  const captchaHandler = (value) => {
    // console.log("**map captcha value", { value });
    setCaptcha(value);
  };
  const cancelHandler = () => {
    setSelectedScreen(null);
    setForgotResponse({
      attempts: "",
      token: "",
      otp: "",
      email: "",
      comeFromEmail: false,
    });
  };

  const resendHandler = () => {
    if (hitAgainResend) {
      setHitAgainResend(false);
      emptyMessage();
      if (forgotResponse.email) {
        const body = { email: forgotResponse.email.toLowerCase() };
        axios
          .post(
            `https://${props.urls.apiBaseURL}/AdminAPI/v0/ForgetPassword`,
            body
          )
          .then((res) => {
            if (res.data.status === 200) {
              setCountDown(60);
              setReenter(true);
              setForgotResponse((prevForgotResponse) => ({
                ...prevForgotResponse,
                attempts: res.data.attempts,
                token: res.data.verification_token,
                comeFromEmail: false,
              }));
              setErrorMessage((prevState) => ({
                ...prevState,
                value: "A reset code has been sent",
                type: "success",
              }));
              hideNotification();
            } else {
              setCodeError((prevCodeError) => ({
                ...prevCodeError,
                code: res.data.status,
                errMsg: res.data.message,
              }));
              setErrorMessage((prevState) => ({
                ...prevState,
                value: res.data.message,
                type: "error",
              }));
              hideNotification();
            }
          })
          .catch((err) => {
            setErrorMessage((prevState) => ({
              ...prevState,
              value: err.message,
              type: "error",
            }));
            hideNotification();
          })
          .finally(() => {
            setHitAgainResend(true);
          });
      }
    }
  };

  const disabledHandler = useMemo(() => {
    if (forgotResponse.attempts !== 3 && forgotResponse.otp.length === 4) {
      return false;
    } else if (
      forgotResponse.attempts === 3 &&
      captcha &&
      forgotResponse.otp.length === 4
    ) {
      return false;
    }

    return true;
  }, [forgotResponse.otp, captcha, forgotResponse.attempts]);

  const disabledAllHandler = useMemo(() => {
    if (codeError.code === 408 || codeError.code === 406) {
      clearInterval(interval);
      return true;
    }
    return false;
  }, [codeError.code]);

  const inputDisabled = useMemo(() => {
    if (forgotResponse.attempts === 3 && !captcha) {
      return true;
    }
    return false;
  }, [forgotResponse.attempts, captcha]);

  const forgotNode = document.getElementById("verificatoinCodeContainer");
  return createPortal(
    <div className="verify_code_container">
      <div className="verify_code_wraper">
        <div className="vcw_left_container">
          <div className="vcw_form">
            <div className="vcw_form_logo_container">
              <img src={VdoTok} alt="logo" className="vcw_form_logo" />
            </div>
            <div className="vcwf_content_container">
              <p className="vcwfc_heading">Enter verification code</p>
              <p className="vcwfc_desc">
                Please provide the verification code sent to your registered
                e-mail.
              </p>
              <Input
                title="Enter code"
                type="text"
                placeholder="Write your code here"
                onChange={otpHandler}
                value={forgotResponse.otp}
                maxLength="4"
                disabled={disabledAllHandler || inputDisabled}
              />
              {forgotResponse.attempts === 3 && !captcha && (
                <div className="vcwfc_google_captcha">
                  <ReCAPTCHA
                    sitekey="6Lc6cmMkAAAAAGFUi-vCUmkX-7zfo0FRyMU1a41n"
                    onChange={captchaHandler}
                  />
                </div>
              )}
              <Button
                title="Next"
                className="vcwfc_send_btn"
                onClick={codeHandler}
                disabled={disabledHandler || disabledAllHandler}
              />
              <CancelButton
                title="cancel"
                className="vcwfc_cancel_btn"
                onClick={() => {
                  cancelHandler();
                  openLoginPopup();
                }}
              />
              {!disabledAllHandler && (
                <>
                  {reenter && countDown !== 0 && (
                    <p className="vcwfc_re_enter">Re-enter code</p>
                  )}
                  <p className="vcwfc_resend_code_text">
                    <span className="vcwfc_not_get">Didn’t get it? </span>
                    {countDown === 0 ? (
                      <button
                        className="vcwfc_resend_code"
                        disabled={disabledAllHandler}
                        onClick={resendHandler}
                      >
                        Resend Code
                      </button>
                    ) : (
                      <>
                        Next resend code available in:
                        <span className="vcwfc_seconds"> {countDown}s</span>
                      </>
                    )}
                  </p>
                </>
              )}
            </div>
          </div>
          {!forgotResponse.comeFromEmail &&
            !disabledAllHandler &&
            errorMessage.value && (
              <p
                className={`vcw_code_message ${
                  errorMessage.type === "error" ? "vcw_code_message_error" : ""
                }`}
              >
                {errorMessage.type !== "error" && (
                  <img
                    src={email}
                    className="vcw_code_message_image"
                    alt="email image"
                  />
                )}
                {errorMessage.value}
              </p>
            )}

          {disabledAllHandler && (
            <p
              className={`vcw_code_message ${
                disabledAllHandler ? "vcw_code_message_error" : ""
              }`}
            >
              {codeError.errMsg}
            </p>
          )}
        </div>
        <div className="vcw_right_container">
          <Xcircle className="vcwrc_close_icon" onClick={cancelHandler} />
          <Close className="vcwrc_white_close_icon" onClick={cancelHandler} />
          <div className="vcwrc_logo_container">
            <img src={tabletLogo} alt="logo" className="vcwrc_logo" />
          </div>
          <div className="vcwrc_mobile_logo_container">
            <img src={mobileLogo} alt="logo" className="vcwrcm_logo" />
          </div>
          {/* <div className="vcwrc_video_container">
            <img src={iphone} alt="iphone" className="vcwrc_video" />
          </div>
          <div className="vcwrc_horizontal_video_container">
            <img
              src={horizontal_iphone}
              alt="horizontal iphone"
              className="vcwrc_horizontal_video"
            />
          </div>
          <div className="vcwrc_mobile_video_container">
            <img
              src={mobileBackground}
              alt="mobile video"
              className="vcwrc_mobile_video"
            />
          </div> */}
        </div>
      </div>
    </div>,
    forgotNode
  );
};
function mapStatetoprops(state) {
  return state;
}
export default connect(mapStatetoprops)(VerificationCode);
