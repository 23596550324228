import React, { useEffect, useState } from "react";
import cross from "./images/x-circle.webp";
import APIs from "../../APIs/API";
import backarrow from "./images/backarrow.webp";
import { connect } from "react-redux";
import "./verification.css";
import mixpanel from "mixpanel-browser";
const LoginVerification = (props) => {
  let API_Object = new APIs();
  useEffect(() => {
    // console.log('scrollingg 8');

    window.scrollTo(0, 0);
  }, []);
  const [val1, setval1] = useState("");
  const [val2, setval2] = useState("");
  const [val3, setval3] = useState("");
  const [val4, setval4] = useState("");

  const [genericError, setGenericError] = useState("");
  const reverse = (encoded) => {
    var newString = "";
    for (var i = encoded.length - 1; i >= 0; i--) {
      newString += encoded[i];
    }
    return newString;
  };
  const setCookie = (cname, cvalue, exdays) => {
    let encoded = btoa(cvalue);
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    if (window.location.href.includes(".dev")) {
      // document.cookie=`user=; Expires=Fri, 5 Oct 2018 14:28:00 GMT;path=/;Domain=vdotok.dev;SameSite=Strict`;
      document.cookie =
        cname +
        "=" +
        reverse(encoded) +
        ";" +
        expires +
        ";path=/;Domain=vdotok.dev;SameSite=Strict";
    } else {
      document.cookie =
        cname +
        "=" +
        reverse(encoded) +
        ";" +
        expires +
        ";path=/;Domain=vdotok.com;SameSite=Strict";
      // document.cookie=`user=; Expires=Fri, 5 Oct 2018 14:28:00 GMT;path=/;Domain=vdotok.com;SameSite=Strict`;
    }
    // document.cookie = cname + "=" + reverse(encoded) + ";" + expires + ";path=/;Domain=vdotok.dev;SameSite=Strict";
  };
  const verify = (object) => {
    API_Object.LoginVerification(object, props.urls.apiBaseURL).then((data) => {
      if (data.status == 200) {
        mixpanel.track("login_verification");
        props.dispatch({ type: "TEMPUSER", userData: "" });
        props.dispatch({ type: "USER", userData: data });
        if (data.login_state == 1) {
          props.openEnterPhonePopup();
          HideMe();
        } else if (data.login_state == 2) {
          props.openAboutYouPopup();
          HideMe();
        } else if (data.login_state == 3) {
          props.openThankyouPopup();
          HideMe();
        } else {
          setCookie("user", data.auth_token, 4, data);
          props.dispatch({ type: "USERDONE", userData: data });
          window.open(`https://${props.urls.adminPanelURL}/overview`, "_self");
        }
      } else {
        mixpanel.track("email_verification_error");
        setGenericError("Oopss..! INVALID OTP. Please Try Again");
        setval1("");
        setval2("");
        setval3("");
        setval4("");
        document.getElementById("val1").focus();
      }
    });
  };
  if (val1.length == 1) {
    document.getElementById("val2").focus();
  }
  if (val2.length == 1) {
    document.getElementById("val3").focus();
  }
  if (val3.length == 1) {
    document.getElementById("val4").focus();
  }
  useEffect(() => {
    document.getElementById("val1").focus();
  }, []);
  useEffect(() => {
    if (
      val1.trim().length > 0 &&
      val2.trim().length > 0 &&
      val3.trim().length > 0 &&
      val4.trim().length > 0
    ) {
      var object = {
        email: props.tempuser.email,
        verification_token: val1 + val2 + val3 + val4,
      };
      verify(object);
    }
  }, [val1, val2, val3, val4]);
  const backpage = () => {
    HideMe();
    props.openLoginPopup();
  };

  const Resend = () => {
    let object = {
      email: props.tempuser.email,
      password: props.tempuser.password,
    };
    API_Object.Login(object, props.urls.apiBaseURL).then((data) => {
      if (data.status == 200) {
        // props.setstate({openscreen:3});
        // props.dispatch({type:"USER",userData:data});
        // HideMe();
        // props.openEnterPhonePopup();
      } else {
        //   setGenericError("Oopss..! INVALID OTP. Please Try Again");
        setval1("");
        setval2("");
        setval3("");
        setval4("");
        //   document.getElementById('val1').focus();
      }
    });
  };
  useEffect(() => {
    // document.addEventListener('mouseup', function(e) {
    //   var containersignup = document.getElementById('modal-verification');
    //   if(containersignup){
    //   if (!containersignup.contains(e.target)) {
    //    HideMe();
    //   }
    //   }
    //   });
  }, []);
  const HideMe = () => {
    props.closeLoginVerification();
  };
  return (
    <>
      <div>
        <div className="verification">
          <div id="modal-verification" className="modal-verification email">
            <img
              onClick={HideMe}
              className="x-cross-ver"
              src={cross}
              alt="cross-button"
            />
            <p className="verification-bold">Verify your email address</p>
            <p className="verification-normal">
              We’ve sent a 4 digit security code to{" "}
              <b className="normal-b">{props.tempuser.email}</b> to verify your
              e-mail address and activate your account. Please enter this code
              below to verify.
            </p>
            {genericError && <p className="errorMessage">{genericError}</p>}
            <div className="rowcount">
              <div className="blockk">
                <input
                  maxlength="1"
                  id="val1"
                  className="count"
                  value={val1}
                  onChange={(e) => {
                    setGenericError("");
                    setval1(e.target.value);
                  }}
                />
              </div>
              <div className="blockk">
                <input
                  maxlength="1"
                  id="val2"
                  className="count"
                  value={val2}
                  onChange={(e) => {
                    setGenericError("");
                    setval2(e.target.value);
                  }}
                />
              </div>
              <div className="blockk">
                <input
                  maxlength="1"
                  id="val3"
                  className="count"
                  value={val3}
                  onChange={(e) => {
                    setGenericError("");
                    setval3(e.target.value);
                  }}
                />
              </div>
              <div className="blockk">
                <input
                  maxlength="1"
                  id="val4"
                  className="count"
                  value={val4}
                  onChange={(e) => {
                    setGenericError("");
                    setval4(e.target.value);
                  }}
                />
              </div>
            </div>
            {/* <p style={{cursor:"pointer" , marginTop:"3%" , marginBottom:"-20px" , color:"red"}} className="verification-normal" onClick={Resend}>Resend Code</p> */}
            <p
              className="verification-normal"
              style={{ cursor: "pointer", marginTop: "3%" }}
            >
              Didn’t get it?{" "}
              <b className="normal-b" onClick={Resend}>
                Resend{" "}
              </b>
            </p>
            <div className="back-row" onClick={backpage}>
              <img className="backarrow" src={backarrow} alt="back-arrow" />
              <p className="backtext">Back</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function map(state) {
  return state;
}
export default connect(map)(LoginVerification);
