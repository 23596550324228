import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Aos from "aos";
import ft_tick from "../../../assets/images/ft_tick.svg";
import ft_girl from "../../../assets/images/ft_girl.webp";
import ftgirltab from "./images/ftgirl-tab.png";
import ftgirlmbl from "./images/ftgirl-mbl.png";
import img1 from "../images/2741.webp";
import img2 from "../images/2742.webp";
import img3 from "../images/2743.webp";
import img4 from "../images/2744.webp";
import img5 from "../images/2745.webp";
import img6 from "../images/2746.webp";
import img7 from "../images/2747.webp";

import "aos/dist/aos.css";
import "./vdotok_features.css";

const VdoTokFeatures = (props) => {
  const [livestream, setlivestream] = useState(true);
  const [one2one, setone2one] = useState(false);
  const [screenshare, setscreenshare] = useState(false);
  const [mixandmash, setmixandmash] = useState(false);
  const [engaging, setEngaging] = useState(false);
  const [messaging, setmessaging] = useState(false);
  const [filesharing, setfilesharing] = useState(false);
  const [innerwdt, setinnerwdt] = useState(window.innerWidth);
  useEffect(() => {
    // if(livestream){
    //     setEngaging(false); setone2one(false);setscreenshare(false);
    //     setmixandmash(false); setmessaging(false); setfilesharing(false);
    // }
    // if(one2one){
    //     setEngaging(false); setlivestream(false);setscreenshare(false);
    //     setmixandmash(false); setmessaging(false); setfilesharing(false);
    // }
    // if(screenshare){
    //     setEngaging(false); setone2one(false);setlivestream(false);
    //     setmixandmash(false); setmessaging(false); setfilesharing(false);
    // }
    // if(mixandmash){
    //     setEngaging(false); setone2one(false);setscreenshare(false);
    //     setlivestream(false); setmessaging(false); setfilesharing(false);
    // }
    // if(engaging){
    //     setlivestream(false); setone2one(false);setscreenshare(false);
    //     setmixandmash(false); setmessaging(false); setfilesharing(false);
    // }
    // if(messaging){
    //     setEngaging(false); setone2one(false);setscreenshare(false);
    //     setmixandmash(false); setlivestream(false); setfilesharing(false);
    // }
    // if(filesharing){
    //     setEngaging(false); setone2one(false);setscreenshare(false);
    //     setmixandmash(false); setmessaging(false); setlivestream(false);
    // }
  }, [
    livestream,
    one2one,
    screenshare,
    mixandmash,
    engaging,
    messaging,
    filesharing,
  ]);
  useEffect(() => {
    Aos.init();
    setinnerwdt(window.innerWidth);
    window.addEventListener("resize", () => {
      setinnerwdt(window.innerWidth);
    });
  }, []);
  const HandleClick = (id) => {
    if (id == "LS") {
      setlivestream(true);
      setEngaging(false);
      setone2one(false);
      setscreenshare(false);
      setmixandmash(false);
      setmessaging(false);
      setfilesharing(false);
    }
    if (id == "O2O") {
      setone2one(true);
      setEngaging(false);
      setlivestream(false);
      setscreenshare(false);
      setmixandmash(false);
      setmessaging(false);
      setfilesharing(false);
    }
    if (id == "ScSh") {
      setscreenshare(true);
      setEngaging(false);
      setone2one(false);
      setlivestream(false);
      setmixandmash(false);
      setmessaging(false);
      setfilesharing(false);
    }
    if (id == "MandM") {
      setmixandmash(true);
      setEngaging(false);
      setone2one(false);
      setscreenshare(false);
      setlivestream(false);
      setmessaging(false);
      setfilesharing(false);
    }
    if (id == "EF") {
      setEngaging(true);
      setlivestream(false);
      setone2one(false);
      setscreenshare(false);
      setmixandmash(false);
      setmessaging(false);
      setfilesharing(false);
    }
    if (id == "Mess") {
      setmessaging(true);
      setEngaging(false);
      setone2one(false);
      setscreenshare(false);
      setmixandmash(false);
      setlivestream(false);
      setfilesharing(false);
    }
    if (id == "FS") {
      setfilesharing(true);
      setEngaging(false);
      setone2one(false);
      setscreenshare(false);
      setmixandmash(false);
      setmessaging(false);
      setlivestream(false);
    }
  };

  return (
    <>
      <div className="_feature">
        {innerwdt > 1024 ? (
          <p className="typekit_h2 webkit_primary_black _feature_bold">
            What features can you integrate with VdoTok ?
          </p>
        ) : innerwdt > 600 ? (
          <p className="typekit_h2 webkit_primary_black _feature_bold">
            What features can you integrate <br></br>with VdoTok ?
          </p>
        ) : innerwdt > 370 ? (
          <p className="typekit_h2 webkit_primary_black _feature_bold">
            What features can you<br></br> integrate with VdoTok ?
          </p>
        ) : (
          <p className="typekit_h2 webkit_primary_black _feature_bold">
            What features can you integrate with VdoTok ?
          </p>
        )}
        {innerwdt > 1024 ? (
          <p className="_feature_normal typekit_subheadline4 webkit_primary_grey">
            Whether you are building an influencer selling solution, a tool for
            personal shopping or a live fashion show software, we have all the
            features you will need.
          </p>
        ) : innerwdt > 600 ? (
          <p className="_feature_normal typekit_subheadline1 webkit_primary_grey">
            Whether you are building an influencer selling solution, a tool for
            personal shopping or a live fashion show software, we have all the
            features you will need.
          </p>
        ) : (
          <p className="_feature_normal typekit_subheadline2 webkit_primary_grey">
            Whether you are building an influencer selling solution, a tool for
            personal shopping or a live fashion show software, we have all the
            features you will need.
          </p>
        )}
        <div className="_feature_btns_LS">
          {innerwdt > 1024 ? (
            <div className="_feature_btn_R1">
              <button
                onClick={() => {
                  HandleClick("LS");
                }}
                id="LS"
                className={
                  livestream ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"
                }
              >
                Live streaming (one-to-many)
              </button>
              <button
                onClick={() => {
                  HandleClick("O2O");
                }}
                id="O2O"
                className={
                  one2one ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"
                }
              >
                one-to-one / many-to-many
              </button>
              <button
                onClick={() => {
                  HandleClick("ScSh");
                }}
                id="ScSh"
                className={
                  screenshare ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"
                }
              >
                Screen sharing
              </button>
              <button
                onClick={() => {
                  HandleClick("MandM");
                }}
                id="MandM"
                className={
                  mixandmash ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"
                }
              >
                mix & mash features
              </button>
            </div>
          ) : (
            <div className="_feature_btn_R1">
              <button
                onClick={() => {
                  HandleClick("LS");
                }}
                id="LS"
                className={
                  livestream ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"
                }
              >
                Live streaming (one-to-many)
              </button>
              <button
                onClick={() => {
                  HandleClick("O2O");
                }}
                id="O2O"
                className={
                  one2one ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"
                }
              >
                (one-to-one / many-to-many)
              </button>
              <button
                onClick={() => {
                  HandleClick("ScSh");
                }}
                id="ScSh"
                className={
                  screenshare ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"
                }
              >
                Screen sharing
              </button>
              <button
                onClick={() => {
                  HandleClick("MandM");
                }}
                id="MandM"
                className={
                  mixandmash ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"
                }
              >
                mix & mash features
              </button>
              <button
                onClick={() => {
                  HandleClick("EF");
                }}
                id="EF"
                className={
                  engaging ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"
                }
              >
                Engaging features
              </button>
              <button
                onClick={() => {
                  HandleClick("Mess");
                }}
                id="Mess"
                className={
                  messaging ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"
                }
              >
                Messaging
              </button>
              <button
                onClick={() => {
                  HandleClick("FS");
                }}
                id="FS"
                className={
                  filesharing ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"
                }
              >
                file sharing
              </button>
            </div>
          )}
          {innerwdt > 1024 ? (
            <div className="_feature_btn_R2">
              <button
                onClick={() => {
                  HandleClick("EF");
                }}
                id="EF"
                className={
                  engaging ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"
                }
              >
                Engaging features
              </button>
              <button
                onClick={() => {
                  HandleClick("Mess");
                }}
                id="Mess"
                className={
                  messaging ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"
                }
              >
                Messaging
              </button>
              <button
                onClick={() => {
                  HandleClick("FS");
                }}
                id="FS"
                className={
                  filesharing ? "Darkbluebtn ft_btn" : "LightGreyBtn ft_btn"
                }
              >
                file sharing
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>
        {livestream && (
          <div className="_feature_content">
            {innerwdt > 1024 ? (
              <img src={img1} className="ft_girl" />
            ) : innerwdt > 600 ? (
              <img src={img1} className="ft_girl" />
            ) : (
              <img src={img1} className="ft_girl" />
            )}
            <div className="ft_right">
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  Stay in trend with Live Shopping / Live Commerce / Social
                  selling and boost your conversion rates & sales
                </p>
              </div>
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  Compatible with multiple platforms (Safari, Chrome, FireFox,
                  Opera) and mobile operating systems (iOS, Android, Flutter)
                </p>
              </div>
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  Get reliable audio and video streams with a 300 ms average and
                  79 ms lowest latency
                </p>
              </div>
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  Stream content over Wi-Fi, 4G or 3G networks
                </p>
              </div>
              <div className="ft_flx" style={{ marginBottom: 0 }}>
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  Build an app like ClubHouse with our live audio streaming APIs
                </p>
              </div>
            </div>
          </div>
        )}
        {one2one && (
          <div className="_feature_content">
            {innerwdt > 1024 ? (
              <img src={img2} className="ft_girl" />
            ) : innerwdt > 600 ? (
              <img src={img2} className="ft_girl" />
            ) : (
              <img src={img2} className="ft_girl" />
            )}
            <div className="ft_right">
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  Get closer to your customers and establish a powerful brand by
                  organising group video chats, video conferencing events and
                  1:1 consultations
                </p>
              </div>
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  Stay on top of technology trends with HD- Quality Audio/Video
                  Calls.
                </p>
              </div>
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  Reach to your audience even under weak network conditions: get
                  reliable audio and video streams with a 300 ms average and 79
                  ms lowest latency
                </p>
              </div>
            </div>
          </div>
        )}
        {screenshare && (
          <div className="_feature_content">
            {innerwdt > 1024 ? (
              <img src={img3} className="ft_girl" />
            ) : innerwdt > 600 ? (
              <img src={img3} className="ft_girl" />
            ) : (
              <img src={img3} className="ft_girl" />
            )}
            <div className="ft_right">
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  Use the power of screen sharing (mobile web browser & mobile
                  to mobile) to walk your customers through your product or your
                  online store.
                </p>
              </div>
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  We are the only one in the industry who provides mobile web
                  browser based screen sharing, so using VdoToks APIs will help
                  you create a truly innovative product in your industry.
                </p>
              </div>
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  Use screen sharing even during live streams to engage more
                  people in one session.
                </p>
              </div>
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  Built-in-privacy measures when using web-to-mobile screen
                  sharing. Your push messages, notifications etc. will not be
                  shared while doing session. And you are not sharing screens
                  outside of mobile browser.
                </p>
              </div>
            </div>
          </div>
        )}
        {mixandmash && (
          <div className="_feature_content">
            {innerwdt > 1024 ? (
              <img src={img4} className="ft_girl" />
            ) : innerwdt > 600 ? (
              <img src={img4} className="ft_girl" />
            ) : (
              <img src={img4} className="ft_girl" />
            )}
            <div className="ft_right">
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  Build an immersive experience for your customers by adding
                  multiple engaging features that can work seamlessly and
                  simultaneously.
                </p>
              </div>
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  Mix & mash any features that suit your product. For instance,
                  combine video calling, screen sharing and internal app audio
                  sharing to let your live streaming guests experience your
                  brand in a whole new way and become loyal customers.
                </p>
              </div>
            </div>
          </div>
        )}
        {engaging && (
          <div className="_feature_content">
            {innerwdt > 1024 ? (
              <img src={img5} className="ft_girl" />
            ) : innerwdt > 600 ? (
              <img src={img5} className="ft_girl" />
            ) : (
              <img src={img5} className="ft_girl" />
            )}
            <div className="ft_right">
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  Minimize cart abandonment and boost sales by creating an
                  unbeatable live shopping experience with the extra features.{" "}
                </p>
              </div>
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  Give your customers a possibility to screen cast your live
                  shopping sessions on big screens and let them enjoy easy
                  shopping from the sofa.
                </p>
              </div>
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  VdoTok exclusive feature: Play and share audio from other apps
                  such as YouTube in your Live session.
                </p>
              </div>
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  Integrate doodling/draw on screen to explain complex products
                  or to have your customers more engaged with your Live session.
                </p>
              </div>
            </div>
          </div>
        )}
        {messaging && (
          <div className="_feature_content">
            {innerwdt > 1024 ? (
              <img src={img6} className="ft_girl" />
            ) : innerwdt > 600 ? (
              <img src={img6} className="ft_girl" />
            ) : (
              <img src={img6} className="ft_girl" />
            )}
            <div className="ft_right">
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  Always free of cost messaging with VdoTok.
                </p>
              </div>
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  One-to-one or one-to-many group chatting capabilities to
                  connect customers all over the world with ultra-low latency.
                </p>
              </div>
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  Enhance your Live Shopping experience with live interactions
                  in the form of comments and messaging.
                </p>
              </div>
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  QoS strategies for optimal performance even under weak
                  networks.
                </p>
              </div>
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  Make use of unlimited scalability. Our architecture provides
                  support for massive concurrency of messages.
                </p>
              </div>
            </div>
          </div>
        )}
        {filesharing && (
          <div className="_feature_content">
            {innerwdt > 1024 ? (
              <img src={img7} className="ft_girl" />
            ) : innerwdt > 600 ? (
              <img src={img7} className="ft_girl" />
            ) : (
              <img src={img7} className="ft_girl" />
            )}
            <div className="ft_right">
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  Enhance customer engagement and/or sell complex products by
                  including files, images, or other rich media.
                </p>
              </div>
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  Use file sharing feature without navigating away from your
                  app, or compromising on security.
                </p>
              </div>
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  Open shared files within the platform and have a conversation
                  about it.
                </p>
              </div>
              <div className="ft_flx">
                <img src={ft_tick} className="ft_tick" />
                <p className="typekit_BodyRegular webkit_primary_black eltspace">
                  We don't store your files, so you have full control over your
                  files by storing them in the cloud of your choice.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

function map(state) {
  return state;
}
export default connect(map)(VdoTokFeatures);
