import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import CSmbl from "./images/CS-mbl.webp";
import toggee from "./images/toggee.svg";
import cscat from "./images/cs-cat.webp";
import Aos from "aos";

import "aos/dist/aos.css";
import "./customer_story.css";

const CustomerStory = (props) => {
  const [innerwdt, setinnerwdt] = useState(window.innerWidth);
  useEffect(() => {
    Aos.init();
    setinnerwdt(window.innerWidth);
    window.addEventListener("resize", () => {
      setinnerwdt(window.innerWidth);
    });
  }, []);

  return (
    <>
      <div className="_CustomerStory">
        <p className="typekit_h2 webkit_primary_black _customerstory_b">
          Customer story
        </p>

        <p
          className={
            innerwdt > 1024
              ? "typekit_subheadline2 webkit_primary_grey _customerstory_p"
              : innerwdt > 500
              ? "typekit_subheadline1 webkit_primary_grey _customerstory_p"
              : "typekit_subheadline2 webkit_primary_grey _customerstory_p"
          }
        >
          Our success story with Togee
        </p>
        <div className="_cusStory_flx">
          <div className="_cs_box1">
            <img src={CSmbl} className="cs-mbl" alt="image" />
            {innerwdt > 1024 ? (
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open("https://www.togee.com");
                }}
                className="_cs_txtabsolute webkit_primary_black"
              >
                www.togee.com
              </p>
            ) : (
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  window.open("https://www.togee.com");
                }}
                className="_cs_txtabsolute webkit_primary_black"
              >
                https://www.togee.com
              </p>
            )}
          </div>
          {innerwdt > 1024 ? (
            <div className="_cs_box2">
              <div className="_cs_boxabsolute">
                <img src={toggee} className="toggee_svg" alt="toggee-logo" />
              </div>
              <div className="_cs_b2">
                <p className="typekit_h4 webkit_primary_h _cs_bp1">
                  Our Success story with Togee
                </p>
                <p className="typekit_BodyRegular webkit_primary_black _cs_bp2 ">
                  Togee is a powerful and user-friendly live shopping tool with
                  screen share that allows visitors and ambassador sell online
                  straight on top of an e-commerce website.
                  <br></br>
                  <br></br>
                  With VdoTok Togee succeeded to build their own custom product
                  in{" "}
                  <b className="_cs_bold_italic">
                    4 months instead of 10 months
                  </b>{" "}
                  according to the initial plan.
                  <br></br>
                  <br></br>
                  Together with VdoTok Togee has built a platform with an
                  exclusive feature{" "}
                  <b className="_cs_bold_italic">only offered by Togee:</b>{" "}
                  "Mobile-to-web screen sharing". This feature allows users and
                  streaming hosts to interact on top of their mobile web
                  browsers. With this feature, users can host and attend live
                  shopping sessions anywhere, anytime.
                </p>
                <img src={cscat} className="_cs_categories" alt="categories" />
              </div>
            </div>
          ) : (
            <div className="_cs_box2tab">
              <div className="_cs_boxabsolutetab">
                <img src={toggee} className="toggee_svg" alt="toggee-logo" />
              </div>
              <div className="_cs_b2tab">
                <p className="typekit_h4 webkit_primary_h _cs_bp1">
                  Our Success story with Togee
                </p>
                {innerwdt > 1024 ? (
                  <div>
                    <p className="typekit_BodyRegular webkit_primary_black _cs_bp2">
                      Togee is a powerful and user-friendly live shopping tool
                      with screen share that allows visitors and ambassador sell
                      online straight on top of an e-commerce website.
                      <br></br>
                      <br></br>
                      With VdoTok Togee succeeded to build their own custom
                      product in{" "}
                      <b className="_cs_bold_italic">
                        4 months instead of 10 months
                      </b>{" "}
                      according to the initial plan.
                      <br></br>
                      <br></br>
                      Together with VdoTok Togee has built a platform with an
                      exclusive feature{" "}
                      <b className="_cs_bold_italic">
                        only offered by Togee:
                      </b>{" "}
                      "Mobile-to-web screen sharing". This feature allows users
                      and streaming hosts to interact on top of their mobile web
                      browsers. With this feature, users can host and attend
                      live shopping sessions anywhere, anytime.
                    </p>
                  </div>
                ) : (
                  <div>
                    <p className="typekit_BodyRegular webkit_primary_black _cs_bp2">
                      Togee could build their an own custom solution / product
                      in{" "}
                      <b className="_cs_bold_italic">
                        4 months instead of 10 months
                      </b>{" "}
                      according to the initial plan.
                      <br></br>
                      <br></br>
                      Using the technology that is{" "}
                      <b className="_cs_bold_italic">
                        not available at any other competitors:
                      </b>{" "}
                      mobile web browser screen sharing, allowing user to share
                      their...
                      <br></br>
                      <br></br>
                      <b className="_cs_bold_italic">Low latency.</b> even under
                      low bandwidth Togee could maintain the connection
                    </p>
                  </div>
                )}

                <img src={cscat} className="_cs_categories" alt="categories" />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

function map(state) {
  return state;
}
export default connect(map)(CustomerStory);
