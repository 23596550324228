import React , {useState , useEffect} from "react";
import { connect } from "react-redux";
import img from '../../../assets/images/deniel.png';
import Star from '../../../assets/images/Star.png';
import mixpanel from "mixpanel-browser";
import HelTecphone from '../images/bgDesktop.webp';
import HelTecphoneTab from '../images/bgTab.webp';
import HelTecphoneMbl from '../images/bgMobile.webp';

import lines from '../images/lines.png';
import linestab from '../images/linestab.png';


// import HelTecphonetab from '../../../assets/images/HelTecphonetab.webp';
// import HelTecphonembl from '../../../assets/images/HelTecphonembl.webp';
import Aos from 'aos';
import 'aos/dist/aos.css'; 
import './HealthTechHeader.css';
import '../../AboutUs/about.css';


const HealthTechHeader = (props) => {
    const [innerwdt , setinnerwdt] = useState(window.innerWidth);
    useEffect(() => {
        Aos.init();
        setinnerwdt(window.innerWidth);
        window.addEventListener('resize' , () => {
            setinnerwdt(window.innerWidth);
        })
    }, []);
    return (
        <>
            <div className="HelTecheaderComp">
                <div className="HelTecheaderchild1" data-aos="zoom-in-left">
                        { innerwdt > 1024 ? 
                        <p className="typekit_h2 webkit_primary_h HelTech1bp">
                            APIs for comprehensive <br></br> health & wellness solutions
                        </p> : innerwdt > 550 ?
                        <p className="typekit_h1 webkit_primary_black HelTech1bp">
                            APIs for comprehensive health <br></br> & wellness solutions
                        </p> :
                        <p className="typekit_h1 webkit_primary_h HelTech1bp">
                           APIs for comprehensive health & wellness solutions
                        </p>                     
                        }
                        {innerwdt > 1024 ? 
                        <p className= "typekit_subheadline2 webkit_primary_black HelTechdrdesc HelTech1p2">
                            Establish a remote health clinic. Integrate TeleHealth features, gadget patient monitoring, and live checkups with our easy-to-use APIs.  
                        </p> : innerwdt > 500 ? 
                        <p className="typekit_subheadline2 webkit_primary_black HelTechdrdesc HelTech1p2" >
                            Establish a remote health clinic. Integrate TeleHealth <br></br> features, gadget patient monitoring, and live checkups <br></br> with our easy-to-use APIs.  
                        </p> :
                         <p className="typekit_subheadline1 webkit_primary_black HelTechdrdesc HelTech1p2" >
                         Establish a remote health clinic. Integrate TeleHealth features, gadget patient monitoring, and live checkups with our easy-to-use APIs. 
                        </p>
                        }
                        
                    <div className="HelTecheaderbtns HelTeccmp1">
                        <div className="HelTec1stbuttondiv HelTecfstbtndiv">
                            <button
                                type="button"
                                className=" Darkbluebtn HelTecjoinfree"
                                // onClick={()=> {
                                //     mixpanel.track("try_for_free");
                                //     props.openSignupPopup()}
                                //
                                // }
                                style={{cursor:"pointer"}}
                                >
                            start building now
                            </button>
                        </div>
                    </div>
                </div>
                <div className="HelTecheaderchild2" data-aos="zoom-in-right">
                    { innerwdt > 1024 ? 
                    <img src={lines} alt="lines" className="htlines" /> :  innerwdt > 500 ?
                    <img src={linestab} alt="lines" className="htlines" /> :
                    <></>
                    }
                    { innerwdt > 1024 ?

                     <img src={HelTecphone} className="HelTecphone" alt="phone" /> : innerwdt > 500 ?
                     <img src={HelTecphoneTab} className="HelTecphonetab" alt="phone" /> :
                     <img src={HelTecphoneMbl} className="HelTecphonembl" alt="phone" /> }
    
                    {innerwdt < 1025 ?
                    <div className="HelTecheaderbtns HelTeccmp1show">
                    <div className="HelTec1stbuttondiv HelTecfstbtndiv">
                        <button
                            type="button"
                            className=" Darkbluebtn HelTecjoinfree"
                            // onClick={()=> {
                            //     mixpanel.track("try_for_free");
                            //     props.openSignupPopup()}
                            //
                            // }
                            style={{cursor:"pointer"}}
                            >
                        start building now
                        </button>
                    </div>
                </div> :
                <></>}
                </div>
            </div>
        </>
    )
}

function map(state) {
    return state;
  }
  export default connect(map)(HealthTechHeader);