import React, { useState, useEffect } from "react";
import Ring from "../../assets/images/Ring.png";
import Phonetab from "../../assets/images/phonetab.webp";
import Phonembl from "../../assets/images/phonembl.webp";
import { connect } from "react-redux";
import img from "../../assets/images/img.png";
import Star from "../../assets/images/Star.png";
import svg from "../../assets/images/Phone-2.webp";
import mixpanel from "mixpanel-browser";
import "./header.css";
import "animate.css";

const HeaderComponent = (props) => {
  const [checkedIn, setCheckedIn] = useState(false);
  const [innerwdt, setinnerwdt] = useState(window.innerWidth);
  useEffect(() => {
    setinnerwdt(window.innerWidth);
    window.addEventListener("resize", () => {
      setinnerwdt(window.innerWidth);
    });
  }, []);
  const reverse = (encoded) => {
    var newString = "";
    for (var i = encoded.length - 1; i >= 0; i--) {
      newString += encoded[i];
    }
    return newString;
  };
  const setCookie = (cname, cvalue, exdays) => {
    let encoded = btoa(cvalue);
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    if (window.location.href.includes(".dev")) {
      // document.cookie=`user=; Expires=Fri, 5 Oct 2018 14:28:00 GMT;path=/;Domain=vdotok.dev;SameSite=Strict`;
      document.cookie =
        cname +
        "=" +
        reverse(encoded) +
        ";" +
        expires +
        ";path=/;Domain=vdotok.dev;SameSite=Strict";
    } else {
      document.cookie =
        cname +
        "=" +
        reverse(encoded) +
        ";" +
        expires +
        ";path=/;Domain=vdotok.com;SameSite=Strict";
      // document.cookie=`user=; Expires=Fri, 5 Oct 2018 14:28:00 GMT;path=/;Domain=vdotok.com;SameSite=Strict`;
    }
  };
  useEffect(() => {
    if (Object.keys(props.userdone).length == 0) {
      setCheckedIn(false);
    } else {
      setCheckedIn(true);
    }
  }, [props]);
  return (
    <>
      <div className="headerComp ">
        <div className="headerchild1">
          {innerwdt > 1400 ? (
            <p className="typekit_h1 webkit_primary_h h1bp">
              Need easy-to-use <br></br> APIs for live media?
            </p>
          ) : (
            <p className="typekit_h1 webkit_primary_h h1bp">
              Need easy-to-use APIs for live media?
            </p>
          )}
          <p
            className={
              innerwdt > 1024
                ? "typekit_subheadline2 webkit_primary_h hdrdesc h1p2"
                : "typekit_subheadline1 webkit_primary_h hdrdesc h1p2"
            }
          >
            Integrate live streaming, screen sharing, video and voice calls into
            any app or product. Bring your customers to the new reality.
          </p>
          <p
            className={
              innerwdt > 1024
                ? "typekit_BodyLarge webkit_primary_black eltspace ttspace"
                : "typekit_BodyRegular webkit_primary_black h1p3 eltspace"
            }
          >
            Get <b>15,000 free</b> minutes to play with
          </p>
          <div className="headerbtns cmp1">
            <div className="1stbuttondiv fstbtndiv">
              {!checkedIn ? (
                <button
                  type="button"
                  className=" Darkbluebtn tryfree"
                  // onClick={() => {
                  //   mixpanel.track("try_for_free");
                  //   props.openSignupPopup();
                  // }}
                  style={{ cursor: "pointer" }}
                >
                  TRY FOR FREE
                </button>
              ) : (
                <button
                  type="button"
                  className="Darkbluebtn tryfree"
                  onClick={() => {
                    mixpanel.track("dashboard");
                    setCookie("user", props.user.auth_token, 4);
                    props.dispatch({ type: "USERDONE", userData: props.user });
                    window.open(`https://${props.urls.adminPanelURL}/overview`);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  DASHBOARD
                </button>
              )}
            </div>
            <div className="secondbuttondiv">
              <button
                type="button"
                style={{whiteSpace:"nowrap"}}
                className="Whitebtn watchdemo"
                onClick={() => {
                  mixpanel.track("watch_demo");
                  document
                    .getElementById("demo")
                    .scrollIntoView({ behavior: "smooth" });
                  // console.log('scrolling')
                }}
              >
                WATCH DEMO
              </button>
            </div>
          </div>
          {/* <div className="ratingsdiv">
                        <img src={img} alt="person" className="person-img" />
                        <p className="ratingtext">Jens Nygren   5/5 </p>
                        <img className="ratingstars" src={Star} alt="rating-stars" />
                    </div> */}
        </div>
        <div className="headerchild2 animate__animated animate__fadeInRight">
          <img src={svg} className="ring" alt="phone-image" />
          <img className="phonetab" src={Phonetab} alt="phone-img" />
          <img className="phonembl" src={Phonembl} alt="phone-img" />
        </div>
      </div>
    </>
  );
};

function map(state) {
  return state;
}
export default connect(map)(HeaderComponent);
