import React, { useEffect, useState } from "react";
import logo from "./images/logo2.webp";
import cross from "./images/x-circle.webp";
// import phoneverticle from './images/phoneverticle.webp';
import bgtab from "./images/signintab.webp";
import bgmbl from "./images/signinmbl.webp";
// import xcirclewhite from './images/x-circle-white.webp';
// import logowhite from './images/logo-white.webp';
import hideeye from "./images/hideeye.webp";
import showeye from "./images/showeye.webp";
// import keydown from './images/keydown.webp';
import { connect } from "react-redux";
// import keyup from './images/keyup.webp';
import axios from "axios";
// import tickyellow from './images/tick_yellow.webp';
// import loginbg from './images/loginbg.webp';
import "./style.css";
import APIs from "../../APIs/API";
import mixpanel from "mixpanel-browser";
const LoginPopup = (props) => {
  const [genericError, setGenericError] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const [emailErr, setEmailErr] = useState("");
  const [email, setEmail] = useState("");
  let API_Object = new APIs();
  const [innerwdt, setinnerwdt] = useState(window.innerWidth);
  useEffect(() => {
    setinnerwdt(window.innerWidth);
    window.addEventListener("resize", () => {
      setinnerwdt(window.innerWidth);
    });
  }, []);
  useEffect(() => {
    //   API_Object.setBaseURL(props.urls);
    //    API_Object.getme();
    var elmnt = document.getElementById("updiv");
    if (elmnt) {
      setTimeout(() => {
        elmnt.scrollIntoView({ behavior: "smooth" });
        // console.log("scrolling");
      }, 100);
      document.getElementById("emaillogin").focus();
    }
  }, []);
  const ForgetPassword = () => {
    mixpanel.track("forget_password");
    props.setSelectedScreen("forgot");
    props.closeLoginPopup();
    // let object = {};
    // object.email = email;
    // API_Object.ForgetPassword(object, props.urls.apiBaseURL).then((data) => {
    //   if (data.status == 200) {
    //     setGenericError("We have sent you an email, Please check.");
    //     setLoading(false);
    //   } else {
    //     setGenericError(data.message);
    //     setLoading(false);
    //   }
    // });
  };
  useEffect(() => {
    if (Object.keys(props.tempuser).length > 0) {
      setEmail(props.tempuser.email);
      setValues({ password: props.tempuser.password });
    } else {
      setEmail("");
    }
  }, [Object.keys(props.tempuser).length > 0]);
  const reverse = (encoded) => {
    var newString = "";
    for (var i = encoded.length - 1; i >= 0; i--) {
      newString += encoded[i];
    }
    return newString;
  };
  const setCookie = (cname, cvalue, exdays) => {
    let encoded = btoa(cvalue);
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    if (window.location.href.includes(".dev")) {
      // document.cookie=`user=; Expires=Fri, 5 Oct 2018 14:28:00 GMT;path=/;Domain=vdotok.dev;SameSite=Strict`;
      document.cookie =
        cname +
        "=" +
        reverse(encoded) +
        ";" +
        expires +
        ";path=/;Domain=vdotok.dev;SameSite=Strict";
    } else {
      document.cookie =
        cname +
        "=" +
        reverse(encoded) +
        ";" +
        expires +
        ";path=/;Domain=vdotok.com;SameSite=Strict";
      // document.cookie=`user=; Expires=Fri, 5 Oct 2018 14:28:00 GMT;path=/;Domain=vdotok.com;SameSite=Strict`;
    }
    // document.cookie = cname + "=" + reverse(encoded) + ";" + expires + ";path=/;Domain=vdotok.dev;SameSite=Strict";
  };
  const HideMe = () => {
    props.dispatch({ type: "TEMPUSER", userData: "" });
    props.closeLoginPopup();
  };
  const validate = () => {
    return;
  };
  const onRegister = () => {
    mixpanel.track("login_submit");
    let validate = true;
    let passwordd = values.password.trim();
    let specialChFormat = /[~!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    let chFormatForOneUpperCase = "(?=.*?[A-Z])";
    let chFormatForOneLowerCase = "(?=.*?[a-z])";
    let chFormatForOneDigitCase = "(?=.*?[0-9])";
    let emailFormat = "[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}";
    if (email.trim().length === 0) {
      setEmailErr("Please enter email !");
      validate = false;
    } else if (!email.match(emailFormat)) {
      setEmailErr("Invalid email format !");
      validate = false;
    }
    if (!passwordd) {
      setPasswordErr("Please enter password");
      validate = false;
    } else {
      validate = true;
      setEmailErr("");
    }
    if (validate) {
      setLoading(true);
      setGenericError("");
      var object = {
        email: email,
        password: values.password,
      };

      API_Object.Login(object, props.urls.apiBaseURL).then((data) => {
        if (data.status == 200) {
          mixpanel.track("successfully_login");
          props.dispatch({ type: "USER", userData: data });
          if (data.two_factor_authentication == true) {
            HideMe();
            props.dispatch({ type: "TEMPUSER", userData: object });
            props.openLoginVerification();
          } else {
            if (data.login_state == 1) {
              props.openEnterPhonePopup();
              HideMe();
            } else if (data.login_state == 2) {
              props.openAboutYouPopup();
              HideMe();
            } else if (data.login_state == 3) {
              props.openThankyouPopup();
              HideMe();
            } else {
              setCookie("user", data.auth_token, 4, data);
              props.dispatch({ type: "USERDONE", userData: data });
              window.open(
                `https://${props.urls.adminPanelURL}/overview`,
                "_self"
              );
            }
            setLoading(false);
          }
        } else {
          mixpanel.track("login_error");
          setGenericError(data.message);
          setLoading(false);
        }
      });
    }
  };
  const handleClickShowPassword = (e) => {
    e.preventDefault();
    mixpanel.track("password_eye_click");
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handlePasswordChange = (prop) => (event) => {
    mixpanel.track("adding_password");
    setPasswordErr("");
    setValues({ ...values, [prop]: event.target.value });
    validate();
  };
  return (
    <>
      <div className="signupContainer" id="signup_container">
        <div className="modalmain-signup" id="modalmain-signup">
          <div className="navmodal-signup lgnn" id="navmodal-signup">
            <div className="login partition login_left_container">
              <img
                style={{ top: "32px", position: "absolute" }}
                src={logo}
                alt="vdotok-logo"
                id="vdtlogo"
                className="vdtlogo"
              />
              <p className="heading-signin typekit_h3 webkit_primary_h">
                Sign in
              </p>

              <p
                className={
                  innerwdt > 1024
                    ? "p-signin typekit_subheadline4 webkit_primary_h"
                    : "p-signin typekit_subheadline3 webkit_primary_h"
                }
              >
                Welcome, use your e-mail to continue with VdoTok
              </p>
              <div className="parent-inputs tablet">
                <div style={{ width: "100%" }}>
                  <p className="label-signin typekit_LabelRegularBold webkit_primary_h">
                    E-mail
                  </p>
                  <input
                    id="emaillogin"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onRegister();
                      }
                    }}
                    value={email}
                    onChange={(e) => {
                      mixpanel.track("adding_email_login");
                      setEmail(e.target.value);
                      setEmailErr("");
                      setGenericError("");
                      validate();
                    }}
                    className="input-signin full"
                    type="email"
                  />
                  {emailErr && <p className="errorMessage start">{emailErr}</p>}
                </div>
              </div>
              <div className="parent-inputs mobile">
                <div style={{ width: "100%" }}>
                  <p className="label-signin typekit_LabelRegularBold webkit_primary_h">
                    E-mail
                  </p>
                  <input
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onRegister();
                      }
                    }}
                    value={email}
                    onChange={(e) => {
                      mixpanel.track("adding_email_login");
                      setEmail(e.target.value);
                      setEmailErr("");
                      validate();
                    }}
                    className="input-signin full"
                  />
                  {emailErr && <p className="errorMessage start">{emailErr}</p>}
                </div>
              </div>
              <div className="web-email">
                <p
                  id="emaillogin"
                  className="typekit_LabelRegularBold webkit_primary_h email_field"
                >
                  E-mail
                </p>
                <input
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      onRegister();
                    }
                  }}
                  value={email}
                  onChange={(e) => {
                    mixpanel.track("adding_email_login");
                    setEmail(e.target.value);
                    setEmailErr("");
                    setGenericError("");
                    validate();
                  }}
                  className="input-signin full"
                  type="email"
                />
                {emailErr && <p className="errorMessage start">{emailErr}</p>}
              </div>
              <div>
                <div className="forgetpasswordflex">
                  <p className="typekit_LabelRegularBold webkit_primary_h pass_field">
                    Password
                  </p>
                  <p
                    className="forgetpass"
                    // style={{
                    //   cursor:
                    //     email.trim().length > 0 ? "pointer" : "not-allowed",
                    // }}
                    onClick={ForgetPassword}
                  >
                    Forgot password?
                  </p>
                </div>
                <div className="input-password input-signin full">
                  <input
                    className="password"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        onRegister();
                      }
                    }}
                    type={values.showPassword ? "text" : "password"}
                    onChange={handlePasswordChange("password")}
                    value={values.password}
                  />
                  <div
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    <img
                      className="eye"
                      src={values.showPassword ? showeye : hideeye}
                    />
                  </div>
                </div>
                {passwordErr && (
                  <p className="errorMessage start">{passwordErr}</p>
                )}
              </div>
              <div className="wrapper" id="wrapper">
                <button
                  onClick={() => {
                    if (
                      email.trim().length > 0 &&
                      values.password.trim().length > 0 &&
                      !loading
                    ) {
                      onRegister();
                    }
                  }}
                  className="btn-signin"
                  style={{
                    cursor:
                      email.trim().length > 0 &&
                      values.password.trim().length > 0
                        ? "pointer"
                        : "not-allowed",
                    backgroundColor:
                      email.trim().length > 0 &&
                      values.password.trim().length > 0
                        ? "#54C295"
                        : "#8e9d97",
                  }}
                  id="btn-signin"
                >
                  LOGIN
                </button>
              </div>
              <p className="btmline typekit_LabelRegular webkit_primary_grey">
                Don't have an account yet?{" "}
                <b
                  style={{ marginLeft: "3px", cursor: "pointer" }}
                  className="typekit_LabelRegularBold webkit_primary_h"
                  onClick={() => {
                    mixpanel.track("sign_up_link");
                    HideMe();
                    props.openSignupPopup();
                  }}
                >
                  Sign up here
                </b>
              </p>
              {genericError && (
                <div className="error_message_container">
                  <p className="errorMessage">{genericError}</p>
                </div>
              )}
            </div>

            <div className="lgn withimg" id="withimg">
              <div id="updiv" style={{ height: 0, width: 0 }}></div>
              <div className="cross-div">
                <img src={logo} alt="vdotok-logo" className="vdtlogo-lgn-tab" />
                <img src={logo} alt="vdotok-logo" className="vdtlogo-lgn-mbl" />
                <img
                  onClick={HideMe}
                  className="x-cross lblack"
                  src={cross}
                  alt="cross-button"
                />
                <img
                  onClick={HideMe}
                  className="x-cross-mbl"
                  src={cross}
                  alt="cross-button"
                />
              </div>
              <div className="phonediv" id="phonediv">
                <img
                  id="bgtab"
                  className="bgtab"
                  src={bgtab}
                  alt="background"
                />
                <img
                  id="bgmbl"
                  className="bgmbl"
                  src={bgmbl}
                  alt="background"
                />
                {/* <img className="phoneicon" src={phoneverticle} alt="phone-image"  /> */}
                {/* {
                  window.innerWidth > 1024 ? 
                 <div style={{height:"600px"}}></div> :
                 <></>
                    
                 } */}
                {/* <img className="phoneicon" src={loginbg} alt="phone-image" /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
function map(state) {
  return state;
}
export default connect(map)(LoginPopup);
