import React, {useState , useEffect} from 'react';
import { connect } from 'react-redux';
import stillsell from '../../../assets/images/LS_stillsell.webp';
import frameleft from '../../../assets/images/frameleft.webp';
import frameup from '../../../assets/images/frameup.webp';
import frameright from '../../../assets/images/frameright.webp';
import video from '../images/video.png';
import calender from '../images/calender.png';
import calendertab from '../images/calendertab.png';
import calendermbl from '../images/calendertab.png';
import likebig from '../../../assets/images/likes.webp';
import likebigtab from './images/likebigtab.webp';
import likebigmbl from './images/likebigmbl.webp';
import im1 from '../images/im1.png';
import docmbl from './images/doctormbl.png';
import im2 from '../images/im2.png';

// import like from '../../../assets/images/sttr_like.png';
import girl from '../../../assets/images/stsl_girl.webp';
import '../../AboutUs/about.css';
import Aos from 'aos';
import 'aos/dist/aos.css'; 
import './StillTreating.css';


const StillTreating = (props) => {
    Aos.init();
    const [innerwdt , setinnerwdt] = useState(window.innerWidth);
    const [time830 , settime830] = useState(false);
    const [time930 , settime930] = useState(true);    
    const [time1030 , settime1030] = useState(false);
    const [time1130 , settime1130] = useState(false);
    const [time1230 , settime1230] = useState(false);
    const [time130 , settime130] = useState(false);
    const [time230 , settime230] = useState(false);
    const [time330 , settime330] = useState(false);
    const [time430 , settime430] = useState(false);
    const [time530 , settime530] = useState(false);
    const [time630 , settime630] = useState(false);    
    const [time730 , settime730] = useState(false);
    useEffect(() => {
        setinnerwdt(window.innerWidth);
        window.addEventListener('resize' , () => {
            setinnerwdt(window.innerWidth);
        })
    }, []);
    const HandleClick = (id) => {
        if(id == '1030') {
            settime1130(false); settime1230(false); settime130(false);
            settime230(false); settime330(false); settime430(false);
            settime530(false); settime630(false); settime730(false); 
            settime830(false); settime930(false); settime1030(true);
        }
        if(id == '1130'){
             settime1230(false); settime130(false); settime1130(true);
            settime230(false); settime330(false); settime430(false);
            settime530(false); settime630(false); settime730(false); 
            settime830(false); settime930(false); settime1030(false);
        }
        if(id == '1230'){
            settime1130(false); settime130(false); settime1230(true);
            settime230(false); settime330(false); settime430(false);
            settime530(false); settime630(false); settime730(false); 
            settime830(false); settime930(false); settime1030(false);
        }
        if(id == '130'){
            settime1130(false); settime1230(false); settime130(true);
            settime230(false); settime330(false); settime430(false);
            settime530(false); settime630(false); settime730(false); 
            settime830(false); settime930(false); settime1030(false);
        }
        if(id == '230'){
            settime1130(false); settime1230(false); settime130(false);
            settime330(false); settime430(false); settime230(true);
            settime530(false); settime630(false); settime730(false); 
            settime830(false); settime930(false); settime1030(false);
        }
        if(id == '330'){
            settime1130(false); settime1230(false); settime130(false);
            settime230(false);  settime430(false); settime330(true);
            settime530(false); settime630(false); settime730(false); 
            settime830(false); settime930(false); settime1030(false);
        }
        if(id == '430'){
            settime1130(false); settime1230(false); settime130(false);
            settime230(false); settime330(false); settime430(true);
            settime530(false); settime630(false); settime730(false); 
            settime830(false); settime930(false); settime1030(false);
        }
        if(id == '530'){
            settime1130(false); settime1230(false); settime130(false); settime530(true);
            settime230(false); settime330(false); settime430(false); settime630(false); settime730(false); 
            settime830(false); settime930(false); settime1030(false);
        }
        if( id == '630'){
            settime1130(false); settime1230(false); settime130(false);
            settime230(false); settime330(false); settime430(false);
            settime530(false);  settime730(false);  settime630(true);
            settime830(false); settime930(false); settime1030(false);
        }
        if(id == '730'){
            settime1130(false); settime1230(false); settime130(false);
            settime230(false); settime330(false); settime430(false);
            settime530(false); settime630(false);  settime730(true);
            settime830(false); settime930(false); settime1030(false);
        }
        if(id == '830'){
            settime1130(false); settime1230(false); settime130(false);
            settime230(false); settime330(false); settime430(false); settime830(true);
            settime530(false); settime630(false); settime730(false);  settime930(false); settime1030(false);
        }
        if(id == '930'){
            settime1130(false); settime1230(false); settime130(false);
            settime230(false); settime330(false); settime430(false);
            settime530(false); settime630(false); settime730(false); 
            settime830(false);  settime1030(false); settime930(true);
        }
    }
    return (

        <>
        <div className='StillTreating' >
            <div className='still_treat_textdiv'>
                <p className='stilltrtbold typekit_h2 webkit_primary_black'>Still treating patients like it’s 2020?</p>
                {innerwdt > 1024 ?
                <p className='stilltrtnormal typekit_subheadline2 webkit_primary_grey eltspace'>It’s time to dive into the world of TeleMedicine</p> : innerwdt > 600 && innerwdt <=1024 ?
                <p className='stilltrtnormal typekit_subheadline1 webkit_primary_grey eltspace'>It’s time to dive into the world of TeleMedicine</p> :
                <p className='stilltrtnormal typekit_subheadline2 webkit_primary_grey eltspace'>It’s time to dive into the world of TeleMedicine</p>
                }
            </div>
            <div className='stilltrt_imgbg'>
                <div className='d-top'>
                    <div className='d-top1'>

                    </div>
                    <div className='d-top2'>

                    </div>
                </div>
                <div className='dr1 bdr-right'>
                    <div data-aos="zoom-in-right" className='stilltrt_pt1' style={{position:'relative'}}>

                    <div className='sttr-r1-box'>
                    <div className='sttr_btns'>
                        <button onClick={() => {HandleClick('830')}}  className={ time830 ? 'sttr_b1 sttr_blue' : 'sttr_b1 sttr_grey '}>
                            08:30
                        </button>
                        <button onClick={() => {HandleClick('930')}}  className={ time930 ? 'sttr_b1 sttr_blue' : 'sttr_b1 sttr_grey'}>
                            09:30
                        </button>
                        <button onClick={() => {HandleClick('1030')}} className={ time1030 ? 'sttr_b1 sttr_blue' : 'sttr_b1 sttr_grey'}>
                            10:30
                        </button>
                        <button onClick={() => {HandleClick('1130')}} className={ time1130 ? 'sttr_b1 sttr_blue' : 'sttr_b1 sttr_grey'}>
                            11:30
                        </button>
                        <button onClick={() => {HandleClick('1230')}} className={ time1230 ? 'sttr_b1 sttr_blue' : 'sttr_b1 sttr_grey'}>
                            12:30
                        </button>
                        <button onClick={() => {HandleClick('130')}} className={ time130 ? 'sttr_b1 sttr_blue' : 'sttr_b1 sttr_grey'}>
                            01:30
                        </button>
                        <button onClick={() => {HandleClick('230')}} className={ time230 ? 'sttr_b1 sttr_blue' : 'sttr_b1 sttr_grey'}>
                            02:30
                        </button>
                        <button onClick={() => {HandleClick('330')}} className={ time330 ? 'sttr_b1 sttr_blue' : 'sttr_b1 sttr_grey'}>
                            03:30
                        </button>
                        <button onClick={() => {HandleClick('430')}} className={ time430 ? 'sttr_b1 sttr_blue' : 'sttr_b1 sttr_grey'}>
                            04:30
                        </button>
                        <button onClick={() => {HandleClick('530')}} className={ time530 ? 'sttr_b1 sttr_blue' : 'sttr_b1 sttr_grey '}>
                            05:30
                        </button>
                        <button onClick={() => {HandleClick('630')}} className={ time630 ? 'sttr_b1 sttr_blue' : 'sttr_b1 sttr_grey'}>
                            06:30
                        </button>
                        <button onClick={() => {HandleClick('730')}} className={ time730 ? 'sttr_b1 sttr_blue' : 'sttr_b1 sttr_grey'}>
                            07:30
                        </button>

                    </div>
                        <div className='sttr-r1B1'>
                            <div className='stTr_innerflex'>
                                {
                                    innerwdt > 600 ?
                                    <img src={im1} className='sttr_im'  /> :
                                    <img src={docmbl} className='sttr_im'  />


                                }
                                <div className='stsl_txt'>
                                    <p className='typekit_h6 webkit_primary_black stsl-txt-b'>Dr. John Doe</p>
                                    <p className= {innerwdt > 1024 || innerwdt < 600 ? 'typekit_LabelSmall webkit_primary_grey eltspace' :
                                'typekit_LabelLight webkit_primary_grey eltspace' }>Radiology</p>
                                </div>
                                <button className='str-box1-btn sttr-select-btn'>select</button>
                            </div>
                        </div>
                        <div className='sttr-r1B2'>
                            <div className='stTr_innerflex'>
                                <img src={im2} className='sttr_im'  />
                                <div className='stsl_txt'>
                                    <p className='typekit_h6 webkit_primary_black stsl-txt-b'>Dr. John Doe</p>
                                    <p className={innerwdt > 1024 || innerwdt < 600? 'typekit_LabelSmall webkit_primary_grey eltspace' :
                                'typekit_LabelLight webkit_primary_grey eltspace' }>Radiology</p>
                                </div>
                                <button className='str-box1-btn sttr-select-btn'>select</button>
                            </div>
                        </div>
                    </div>

                    </div>
                    <div className='sttr_vertical_line'></div>
                    <div data-aos="zoom-in-left" className='r1_b2 stilltrt_pt2 ' 
                    >
                        <div>
                            <div className='r1st_b2_txt sttr_inner_txt'>
                            <div className='video-box'>
                                <img src={video} className="video" />
                            </div>
                                <p className={innerwdt >1024 ? 'typekit_h6 webkit_primary_h sttr-txt2-b' : 
                                'typekit_sttr_bold r1st-b2tab webkit_primary_h sttr-txt2-b'} >38<b className='b_smoll'>%</b></p>

                                {innerwdt > 1024 ?
                                <p style={{zIndex:3}} className={innerwdt > 1024 ? 'typekit_BodyRegularMedium webkit_primary_black eltspace' :
                                'typekit_BodySmallMedium webkit_primary_black eltspace'}>Over a third of patients in US (38 %) have <br></br> received care via telehealth during 2022</p> :
                                <p style={{zIndex:3}} className={innerwdt > 1024 ? 'typekit_BodyRegularMedium webkit_primary_black eltspace' :
                                'typekit_BodySmallMedium webkit_primary_black eltspace sttr-overflow'}>Over a third of patients in US (38 %) have received care via telehealth during 2022</p> }
                                <p className='stltrt-newtext typekit_LabelThin eltspace webkit_primary_grey'>(JLL, 2022)</p>
                            </div>
                        </div>
                    </div>
                <img src={frameright} className="stilltrt_frmright" />

                </div>
                <div className={innerwdt > 600 ? 'dr2 bdr-left' : 'dr2 bdr-left' } >
                    <div data-aos="zoom-in-right" className={innerwdt > 600 ? 'stilltrt_pt1 sttr_pt1' : 'stilltrt_pt1 sttr_pt1' }  >
                        <div>
                            <div className='sttr_inner_txt'>     
                                    {/* <img src={filter} className="filter"  /> */}

                                    <div className='calender-box'>
                                    {innerwdt > 1024 ?
                                    <img src={calender} className="calender"  /> : innerwdt > 500 && innerwdt <= 1024 ?
                                    <img src={calendertab} className="calender"  /> :
                                    <img src={calendermbl} className="calender"  />

                                    }

                                    </div>
                                <p className={innerwdt > 1024 ? 'typekit_h6 webkit_YellowDark sttr-txt2-b' :
                            'typekit_sttr_bold webkit_YellowDark r2st-b1tab sttr-txt2-b'}>91<b className='b_smoll'>%</b></p>
                            {innerwdt > 1024 ? 
                            <p style={{zIndex:3}} className={innerwdt > 1024 ? 'typekit_BodyRegularMedium webkit_primary_black eltspace' :
                            '_st_centre typekit_BodySmallMedium webkit_primary_black eltspace'}><span className='webkit_YellowDark'>91%</span> of patients say telemedicine would help them stick to appointments, manage prescriptions and refills, and follow regimen recommendations.</p> :
                            <p style={{zIndex:3}} className={innerwdt > 1024 ? 'typekit_BodyRegularMedium webkit_primary_black eltspace' :
                            '_st_centre typekit_BodySmallMedium webkit_primary_black eltspace'}>91% of patients say telemedicine would help them stick to appointments, manage prescriptions and refills, and follow regimen recommendations.</p>
                            }
                            <p className='stltrt-newtext typekit_LabelThin eltspace webkit_primary_grey'>(Medical Economics, 2020)</p>
                            </div>
                        </div>
                    </div>
                    <div className='sttr_horz_line'></div>
                    <div data-aos="zoom-in-left" className={innerwdt > 500 ? ' stilltrt_pt2 sttr_pt2' : 'stilltrt_pt2 sttr_pt2'}>
                    <div className='stTr-R2-Box'>
                        <div className='r2-b2-flex'>
                            <div className='st-tr-t1'>
                                <p className='typekit_LabelLight webkit_primary_grey sttr_b2bold'>Date</p>
                                <p className='typekit_LabelSmall webkit_primary_black eltspace'>15 June 2022</p>
                            </div>
                            <div className='st-tr-t2'>
                                <p className='typekit_LabelLight webkit_primary_grey sttr_b2bold'>Time</p>
                                <p className='typekit_LabelSmall webkit_primary_black eltspace'>09:30</p>
                            </div>
                            <button className='str-box1-btn'>
                            Edit
                            </button>

                        </div>
                        <div className='r2-b2-line'></div>
                        <div className='r2-b2-flex'>
                            <div className='st-tr-t1'>
                                <p className='typekit_LabelLight webkit_primary_grey sttr_b2bold'>Doctor</p>
                                <p className='typekit_LabelSmall webkit_primary_black eltspace'>Dr. John Doe</p>
                            </div>
                            <div className='st-tr-t3'>
                                <p className='typekit_LabelLight webkit_primary_grey sttr_b2bold'>Branch</p>
                                <p className='typekit_LabelSmall webkit_primary_black eltspace'>Radiology</p>
                            </div>
                            <button className='str-box1-btn'>
                            cancel
                            </button>
                        </div>
                        </div>
                    </div>
                <img src={frameleft} className="stilltrt_frmleft"/>

                </div>
                {/* <img src={stilltrt} className="still-selling" alt='lines' /> */}
                <img src={frameup}  className="stilltrt_frmup"/>
           
            </div>
          
            <p className='stilltrtblur typekit_LabelSmall'>Stats source: <span style={{textDecoration:"underline"}}>fitsmallbusiness.com/livestream-shopping-statistics</span></p>
        </div>
        </>
    )
}


function map(state) {
    return state;
  }
  export default connect(map)(StillTreating);