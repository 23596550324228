/*import kotlinOne2One from "./kotlin-one2one.apk";
import kotlinM2M from "./m2m1023.apk";
import kotlinOne2Many from "./one2many1023.apk";*/

export const platforms = [
  {
    android: {
      android: {
        chat: {url:"https://sdk.vdotok.com/DEMO-APPS/Android/android-chat.apk",description:'Chat Application'},
        one2one: {
          url: "https://sdk.vdotok.com/DEMO-APPS/Android/android-one2one.apk",
          description: "One to One Call",
        },
        one2many: { url: "https://sdk.vdotok.com/DEMO-APPS/Android/android-one2many.apk", description: "Broadcasting App" },
        many2many: { url: "https://sdk.vdotok.com/DEMO-APPS/Android/android-m2m.apk", description: "Private Group Call" },
        // combined: { url: "5kotlin-app-url", description: "download" },
        // ok: { url: "6kotlin-app-url", description: "download" },
        // bye: { url: "7kotlin-app-url", description: "download" },
      },
      // ios: { null: "null" },
    },
  },
  {
    swift: {
      // android: { null: "null" },
      ios: {
         chat: { url: "https://testflight.apple.com/join/9hXchRpK", description: "IOS Chat App" },
  //       one2one: { url: "2ios-app-url", description: "download" },
  //       one2many: { url: "3ios-app-url", description: "download" },
  //       many2many: { url: "4ios-app-url", description: "download" },
  //       combined: { url: "5ios-app-url", description: "download" },
      },
    },
  },
  {
    flutter: {
      android: {
        chat: { url: "https://sdk.vdotok.com/DEMO-APPS/Flutter/flutter-chat.apk", description: "Chat Application" },
        one2one: { url: "https://sdk.vdotok.com/DEMO-APPS/Flutter/flutter-one2one.apk", description: "One to One Call" },
        // one2many: { url: "https://sdk.vdotok.com/DEMO-APPS/Flutter/one2many.apk", description: "download" },
        many2many: { url: "https://sdk.vdotok.com/DEMO-APPS/Flutter/flutter-m2m.apk", description: "Private Group Chat" },
        // combined: { url: "https://sdk.vdotok.com/DEMO-APPS/Flutter/chat.apk", description: "download" },
      },
  //     ios: {
  //       chat: { url: "1flutter-app-url-ios", description: "download" },
  //       one2one: {
  //         url: "2flutter-app-url-ios",
  //         description: "download",
  //       },
  //       one2many: {
  //         url: "3flutter-app-url-ios",
  //         description: "download",
  //       },
  //       many2many: {
  //         url: "4flutter-app-url-ios",
  //         description: "download",
  //       },
  //       combined: {
  //         url: "5flutter-app-url-ios",
  //         description: "download",
  //       },
  //     },
     },
  },
  {
    javascript: {
      web: {
        meeting: {
          url: "https://meeting.vdotok.com",
          description: "Conference Call",
        },
        meet: {
          url: "https://meet.vdotok.com",
          description: "Conference Call + Chat",
        },
        chat: {
          url: "https://chat.vdotok.com",
          description: "Chat application",
        },
        // rOne2One: {
        //   url: "https://react-one2one.vdotok.com",
        //   description: "calling app p2p/media",
        // },
        many2many: {
          url: "https://many2many.vdotok.com",
          description: "Private Group Call",
        },
        one2one: {
          url: "https://one2one.vdotok.com",
          description: "one to one call",
        },
        one2many: {
          url: "https://one2many.vdotok.com",
          description: "public broadcast",
        },
        vdotokPlugin: {
          url: "https://plugin.vdotok.dev",
          description: "Live Shopping plugin",
        },
      },
    },
  },
];
