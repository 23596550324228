import React , {useState, useEffect} from "react";
import { connect } from "react-redux";
import './team.css';
import teamimgtab from './images/teamimgtab.webp';
import teamimgmbl from './images/teamimgmbl.webp';
import teamimg from './images/teamimg.webp';
import SM from './images/SM.webp';
import AG from './images/AG.webp';
import FH from './images/FH.webp';

import '../about.css';
const Team = (props) => {
    const [innerwdt , setinnerwdt] = useState(window.innerWidth);
    useEffect(() => {
        setinnerwdt(window.innerWidth);
        if(window.innerWidth >= 1100 ){
            document.getElementById('teamdesktop').style.display="flex";
            document.getElementById('txteam').style.display="block";         
            document.getElementById('teamtab').style.display="none";
            document.getElementById('teammbl').style.display="none";
        }
        if(window.innerWidth > 600 && window.innerWidth < 1100){
            document.getElementById('teamdesktop').style.display="none";
            document.getElementById('teamtab').style.display="flex";
            document.getElementById('txteam').style.display="none";  
            document.getElementById('teammbl').style.display="none";
        }
        if(window.innerWidth <= 600){
            document.getElementById('teamdesktop').style.display="none";
            document.getElementById('teamtab').style.display="none";
            document.getElementById('txteam').style.display="none";  
            document.getElementById('teammbl').style.display="block";

        }
        window.addEventListener('resize' , () => {
            setinnerwdt(window.innerWidth);
            if(window.innerWidth >= 1100 ){
                document.getElementById('teamdesktop').style.display="flex";
                document.getElementById('txteam').style.display="block";  
                document.getElementById('teamtab').style.display="none";
                document.getElementById('teammbl').style.display="none";
            }
            if(window.innerWidth > 600 && window.innerWidth < 1100){
                document.getElementById('teamdesktop').style.display="none";
                document.getElementById('teamtab').style.display="flex";
                document.getElementById('teammbl').style.display="none";
                document.getElementById('txteam').style.display="none";  
            }
            if(window.innerWidth <= 600){
                document.getElementById('teamdesktop').style.display="none";
                document.getElementById('teamtab').style.display="none";
                document.getElementById('txteam').style.display="none";  
                document.getElementById('teammbl').style.display="block";
    
            }
        })
    }, []);
    return(
        <>
        <div className="main_team">
            <div className="team_text">
                <h2 className="webkit_primary_black teamh vision_headings typekit_h2">Our international team</h2>
                <p className ={innerwdt > 1024 ? "webkit_primary_black typekit_subheadline2 teamdesc eltspace" : "webkit_primary_black typekit_subheadline3 teamdesc eltspace"} >Many of our team members have never met IRL and we cannot live without video conferencing. Just like your customers ;) </p>
            </div>
            <div className="teamflex"> 
       
                <div id="txteam" className="txtteam">
                <div className="d1t">
                    <div className="locth">
                        <p className="typekit_LabelXL webkit_primary_black eltspace">Faisal Hanif</p>
                  
                        {/* <img className="sweden" src={sweden} /> */}
                    </div>
                    <div className="loctp" >
                        <p className="typekit_LabelRegular webkit_primary_black teamsub">VP Engineering</p>
                        <p className="typekit_LabelLight webkit_primary_grey eltspace">
                        Over 22 years of <br></br>
                        experience in Telecom <br></br>
                        industry
                        </p>
                    </div>
                </div>
                <div className="d2t">
                    <div className="locth">
                        <p className="typekit_LabelXL webkit_primary_black eltspace">Alina Gritckova</p>
                  
                        {/* <img className="sweden" src={sweden} /> */}
                    </div>
                    <div className="loctp" >
                        <p className="typekit_LabelRegular webkit_primary_black teamsub">CMO</p>
                        <p className="typekit_LabelLight webkit_primary_grey eltspace">
                        Over 9 years of<br></br> experience in digital marketing
                        </p>
                    </div>
                </div>
                <div className="d3t">
                    <div className="locth">
                        <p className="typekit_LabelXL webkit_primary_black eltspace">Siddiqua Malik</p>
                  
                        {/* <img className="sweden" src={sweden} /> */}
                    </div>
                    <div className="loctp" >
                        <p className="typekit_LabelRegular webkit_primary_black teamsub">Product Manager</p>
                        <p className="typekit_LabelLight webkit_primary_grey eltspace">
                        Over 12 years of <br></br> experience in IT industry
                        </p>
                    </div>
                </div>
                </div> 
           
                <div className="teamimgdiv">
                <img id="teamdesktop" className="mobileImage" style={{width:"100%"}} src={teamimg} alt="team-img" /> 
                <img id="teamtab" style={{width:"100%"}} src={teamimgtab} alt="team-img" />            
                <div id="teammbl">
                    <img style={{width:"100%"}} src={teamimgmbl} alt="team-img" />
                    <img style={{width:"100%"}} src={FH} className="namecard" alt="FH" />
                    <img style={{width:"100%"}} src={AG} className="namecard" alt="AG" />
                    <img style={{width:"100%"}} src={SM} className="namecard" alt="SM" />
                </div>
                </div>
            </div>
        </div>  
        </>
    )
}


function map(state){
    return state;
}
export default connect(map)(Team);