import React from "react";
import "./styles.css";

const Input = (props) => {
  const {
    title = "lable",
    className,
    ...rest
  } = props;
  return (
    <div className={`input_container ${className}`}>
      <p className="input_label">{title}</p>
      <div className="input_wraper">
        <input className="input_" {...rest} />
      </div>
    </div>
  );
};

export default Input;
