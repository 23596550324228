import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Footer from "../components/Footer/Footer";
import NavComponent from "../components/Nav/NavComponent";
import SignupPopup from "../components/Nav/SignUpPopup";
import axios from "axios";
import UseCasesPopup from "../components/Nav/UseCasesPopup";
import ContactUsPopup from "../components/Nav/ContactUsPopup";
import AboutYou from "../components/Verification/AboutYou";
import EmailVerification from "../components/Verification/EmailVerification";
import EnterPhone from "../components/Verification/EnterPhone";
import PhoneVerification from "../components/Verification/PhoneVerification";
import Thankyou from "../components/Verification/Thankyou";
import LoginPopup from "../components/Nav/LoginPopup";
import mixpanel from "mixpanel-browser";
import APIs from "../APIs/API";
import LoginVerification from "../components/Verification/LoginVerification";
import HealthTechHeader from "../components/HealthTech/HealthTechHeader/HealthTechHeader";
import HTSlider from "../components/HealthTech/HealthTechSlider/HTSlider";
import StillTreating from "../components/HealthTech/HTStillTreating/StillTreating";
import HTCircle from "../components/HealthTech/HealthTechCircle/healthTechCircle";
import Build_healthtech from "../components/HealthTech/BuildHealthTech/build_healthtech";
import HTFeatures from "../components/HealthTech/HTFeatures/vdotok_features";
import HTBackup from "../components/HealthTech/HTBackup/HT_backup";
import WhyVdoTokHT from "../components/HealthTech/Why_Vdotok_HT/why_vdotok_HT";
import CustomerStoryHT from "../components/HealthTech/CustomerStory/customer_story";
import StartHTToday from "../components/HealthTech/StartHT_Today/start_ht_today";
import ForgotPassword from "./forgot-password";
import VerificationCode from "./verification-code";
import ResetPassword from "./reset-password";
import SuccessPassword from "./success-password";

const HealthTechScreen = (props) => {
  const navigate = useNavigate();
  const gotoHome = useCallback(
    () => navigate("/signup", { replace: true }),
    [navigate]
  );
  const [forgotResponse, setForgotResponse] = useState({
    attempts: "",
    token: "",
    otp: "",
    email: "",
  });

  const [selectedScreen, setSelectedScreen] = useState(null);
  const [innerwdt, setinnerwdt] = useState(window.innerWidth);
  const [signupPopup, setSignupPopup] = useState(false);
  const [again, setAgain] = useState(true);

  const [login, setLogin] = useState(false);
  const [loginverification, setLoginVerification] = useState(false);
  const [contactusPopup, setContactusPopup] = useState(false);
  const [useCasesPopup, setUseCasesPopup] = useState(false);
  const [aboutYou, setAboutYou] = useState(false);
  const [emailVerification, setEmailVerification] = useState(false);
  const [enterPhone, setEnterPhone] = useState(false);
  const [phoneVerification, setPhoneVerification] = useState(false);
  const [thankyou, setThankyou] = useState(false);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    if (window.location.href.includes("login")) {
      openLoginPopup();
    }
  }, []);
  useEffect(() => {
    if (window.location.href.includes("signup")) {
      openSignupPopup();
    }
  }, []);
  useEffect(() => {
    axios
      .get(`https://${props.urls.apiBaseURL}/API/GetAllCountries`)
      .then((res) => {
        setCountries(res.data.data);
      })
      .catch((err) => {});
  }, [props.urls.apiBaseURL]);

  useEffect(() => {
    if (window.location.href.includes("healthtech")) {
      setTimeout(() => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0;
      }, 80);
    }
  }, []);
  useEffect(() => {
    setinnerwdt(window.innerWidth);
    window.addEventListener("resize", () => {
      setinnerwdt(window.innerWidth);
      setAgain(false);
      setTimeout(() => {
        setAgain(true);
      }, 100);
    });
  }, []);
  const closeSignupPopup = () => {
    document.body.style.overflow = "auto";
    document.body.style.position = "unset";

    setSignupPopup(false);
  };
  // const openSignupPopup=()=>{
  //     setSignupPopup(true);
  //     document.body.style.overflow = "hidden";

  // }
  const openSignupPopup = () => {
    // gotoHome();

    setSignupPopup(true);

    document.body.style.overflow = "hidden";
    // document.body.style.position = 'fixed';
  };
  const closeLoginPopup = () => {
    document.body.style.overflow = "auto";
    // document.body.style.position = 'unset';

    setLogin(false);
  };
  const openLoginPopup = () => {
    setLogin(true);
    document.body.style.overflow = "hidden";
    // document.body.style.position = 'fixed';
  };
  const closeAboutYouPopup = () => {
    document.body.style.overflow = "auto";
    // document.body.style.position = 'unset';

    setAboutYou(false);
  };
  const openAboutYouPopup = () => {
    document.body.style.overflow = "hidden";
    // document.body.style.position = 'fixed';

    setAboutYou(true);
  };
  const closeEmailVerificationPopup = () => {
    document.body.style.overflow = "auto";
    // document.body.style.position = 'unset';

    setEmailVerification(false);
  };
  const openEmailVerificationPopup = () => {
    document.body.style.overflow = "hidden";
    // document.body.style.position = 'fixed';

    setEmailVerification(true);
  };
  const closeEnterPhonePopup = () => {
    document.body.style.overflow = "auto";
    // document.body.style.position = 'unset';

    setEnterPhone(false);
  };
  const openEnterPhonePopup = () => {
    document.body.style.overflow = "hidden";
    // document.body.style.position = 'fixed';

    setEnterPhone(true);
    setTimeout(() => {
      const phoneInputField = document.querySelector("#val2");

      const phoneInput = window.intlTelInput(phoneInputField, {
        preferredCountries: ["PK", "SE"],
        dropdownContainer: document.getElementById("inpsum"),

        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
      });
    }, 500);
  };
  const closePhoneVerificationPopup = () => {
    document.body.style.overflow = "auto";
    setPhoneVerification(false);
  };
  const openPhoneVerificationPopup = () => {
    document.body.style.overflow = "hidden";
    // document.body.style.position = 'fixed';

    setPhoneVerification(true);
  };
  const closeThankyouPopup = () => {
    document.body.style.overflow = "auto";
    // document.body.style.position = 'unset';

    setThankyou(false);
  };
  const openThankyouPopup = () => {
    document.body.style.overflow = "hidden";
    // document.body.style.position = 'fixed';

    setThankyou(true);
  };
  const openContactusPopup = () => {
    setContactusPopup(true);
    document.body.style.overflow = "hidden";
    // document.body.style.position = 'fixed';
  };
  const closeContactusPopup = () => {
    document.body.style.overflow = "auto";
    // document.body.style.position = 'unset';

    setContactusPopup(false);
  };
  const openUseCasesPopup = () => {
    document.body.style.overflow = "hidden";
    // document.body.style.position = 'fixed';

    setUseCasesPopup(true);
  };
  const closeUseCasesPopup = () => {
    document.body.style.overflow = "auto";
    // document.body.style.position = 'unset';

    setUseCasesPopup(false);
  };
  const openLoginVerification = () => {
    document.body.style.overflow = "hidden";
    // document.body.style.position = 'fixed';

    setLoginVerification(true);
  };
  const closeLoginVerification = () => {
    document.body.style.overflow = "auto";
    // document.body.style.position = 'unset';

    setLoginVerification(false);
  };
  const forgotScreenRender = useMemo(() => {
    if (selectedScreen === "forgot") {
      return (
        <ForgotPassword
          setSelectedScreen={setSelectedScreen}
          openLoginPopup={openLoginPopup}
          setForgotResponse={setForgotResponse}
        />
      );
    } else if (selectedScreen === "verification") {
      return (
        <VerificationCode
          setSelectedScreen={setSelectedScreen}
          openLoginPopup={openLoginPopup}
          setForgotResponse={setForgotResponse}
          forgotResponse={forgotResponse}
        />
      );
    } else if (selectedScreen === "reset") {
      return (
        <ResetPassword
          setSelectedScreen={setSelectedScreen}
          openLoginPopup={openLoginPopup}
          forgotResponse={forgotResponse}
          setForgotResponse={setForgotResponse}
        />
      );
    } else if (selectedScreen === "success") {
      return (
        <SuccessPassword
          setSelectedScreen={setSelectedScreen}
          openLoginPopup={openLoginPopup}
        />
      );
    }
  }, [selectedScreen, forgotResponse]);
  return (
    <>
      <div className="vdtbody">
        {aboutYou && (
          <AboutYou
            closeAboutYouPopup={closeAboutYouPopup}
            openThankyouPopup={openThankyouPopup}
          />
        )}
        {emailVerification && (
          <EmailVerification
            openSignupPopup={openSignupPopup}
            closeEmailVerificationPopup={closeEmailVerificationPopup}
            openEnterPhonePopup={openEnterPhonePopup}
          />
        )}
        {loginverification && (
          <LoginVerification
            closeLoginVerification={closeLoginVerification}
            openLoginPopup={openLoginPopup}
            openEnterPhonePopup={openEnterPhonePopup}
            openAboutYouPopup={openAboutYouPopup}
            openThankyouPopup={openThankyouPopup}
          />
        )}
        {enterPhone && (
          <EnterPhone
            closeEnterPhonePopup={closeEnterPhonePopup}
            openPhoneVerificationPopup={openPhoneVerificationPopup}
            openAboutYouPopup={openAboutYouPopup}
            countries={countries}
          />
        )}
        {phoneVerification && (
          <PhoneVerification
            openEnterPhonePopup={openEnterPhonePopup}
            closePhoneVerificationPopup={closePhoneVerificationPopup}
            openAboutYouPopup={openAboutYouPopup}
          />
        )}
        {thankyou && <Thankyou closeThankyouPopup={closeThankyouPopup} />}
        {signupPopup && (
          <SignupPopup
            mixpanel={mixpanel}
            openLoginPopup={openLoginPopup}
            closeSignupPopup={closeSignupPopup}
            openEmailVerificationPopup={openEmailVerificationPopup}
            countries={countries}
          />
        )}
        {login && (
          <LoginPopup
            openLoginVerification={openLoginVerification}
            openEnterPhonePopup={openEnterPhonePopup}
            openAboutYouPopup={openAboutYouPopup}
            openThankyouPopup={openThankyouPopup}
            closeLoginPopup={closeLoginPopup}
            openSignupPopup={openSignupPopup}
            setSelectedScreen={setSelectedScreen}
          />
        )}
        {contactusPopup && (
          <ContactUsPopup
            closeContactusPopup={closeContactusPopup}
            countries={countries}
          />
        )}
        {useCasesPopup && (
          <UseCasesPopup
            closeUseCasesPopup={closeUseCasesPopup}
            openContactusPopup={openContactusPopup}
          />
        )}
        <NavComponent
          openLoginPopup={openLoginPopup}
          openSignupPopup={openSignupPopup}
          closeSignupPopup={closeSignupPopup}
          openContactusPopup={openContactusPopup}
          closeContactusPopup={closeContactusPopup}
          openUseCasesPopup={openUseCasesPopup}
          closeUseCasesPopup={closeUseCasesPopup}
          useCasesPopup={useCasesPopup}
        />
        <HealthTechHeader
          openSignupPopup={openSignupPopup}
          closeSignupPopup={closeSignupPopup}
        />
        <HTSlider />
        <StillTreating />
        {again ? (
          <HTCircle />
        ) : (
          <div
            style={{
              backgroundColor: "#F7F7F9",
              height:
                window.innerWidth > 1024
                  ? 1162
                  : window.innerWidth > 600 && window.innerWidth < 1024
                  ? 932
                  : 719,
            }}
          ></div>
        )}

        <Build_healthtech />
        <HTFeatures />
        {/* <HTBackup /> */}
        <WhyVdoTokHT />
        <CustomerStoryHT />
        <StartHTToday openSignupPopup={openSignupPopup} />
        <Footer
          openContactusPopup={openContactusPopup}
          openSignupPopup={openSignupPopup}
        />
      </div>
      {forgotScreenRender}
    </>
    // <Blog />
  );
};
function map(state) {
  return state;
}
export default connect(map)(HealthTechScreen);
