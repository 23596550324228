
const user=(state=[],action)=>{
  switch(action.type){
    case 'USER':
      return action.userData
      default:
        return state;
  }

};
export {user}

