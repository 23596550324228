import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import logo from "./images/logo2.webp";
// import Vector from './images/Vector.webp';
import logowhite from "./images/logo-white.webp";
import unionwhite from "./images/union_white.webp";
import union from "./images/union-updated.png";
import "./nav.css";
import SignupPopup from "./SignUpPopup";
import UseCasesPopup from "./UseCasesPopup";
import Close from "./images/Close.webp";
// import vectorUp from './images/vector-open.webp';
import vectorUpMbl from "./images/vector-open-mob.webp";
import vectorDownMbl from "./images/vector-close-mob.webp";
import edtech from "./images/EdTechDesktop.png";
import htech from "./images/HealTechDesktop.png";
import shopping from "./images/Shoppingcart.png";
import Social from "./images/Social.webp";
import { useMediaQuery } from "react-responsive";
import APIs from "../../APIs/API";
import mixpanel from "mixpanel-browser";

const NavComponent = (props) => {
  const [innerwdt, setinnerwdt] = useState(window.innerWidth);
  //    useEffect(() => {
  //       console.log('scrollingg 4');
  //       window.location.replace="/";
  //   }, []);
  const API_Object = new APIs();
  var keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

  const navigate = useNavigate();
  const gotoHome = useCallback(
    () => navigate("/", { replace: true }),
    [navigate]
  );
  const [FAQ, setFAQ] = useState(false);
  const [industryPopup, setIndustryPopup] = useState(false);
  const [usecaseweb, setUsecaseWeb] = useState(false);
  const [navpopup, setnavpopup] = useState(false);
  const [useCasesMob, setUseCasesMob] = useState(false);
  const [checkedIn, setCheckedIn] = useState(false);
  const handlePageshift = useCallback(
    () => navigate("/pricing", { replace: true }),
    [navigate]
  );
  useEffect(() => {
    if (useCasesMob) {
      if (document.getElementById("usecasesline")){
        document.getElementById("usecasesline").style.borderBottom = "none";

      }
    } else {
      if (document.getElementById("usecasesline")) {
        document.getElementById("usecasesline").style.borderBottom =
          "1px solid #E2E2EA";
      }
    }

    if (industryPopup) {
      document.getElementById("industryline").style.borderBottom = "none";
    } else {
      if (document.getElementById("industryline")) {
        document.getElementById("industryline").style.borderBottom =
          "1px solid #E2E2EA";
      }
    }
  }, [useCasesMob, industryPopup]);
  const HandleOnClick = () => {
    mixpanel.track("pricing");
    if (document.getElementById("pricing")) {
      document.getElementById("pricing").scrollIntoView({ behavior: "smooth" });

      // console.log("scrolling");
    } else {
      handlePageshift();
      // document.getElementById('pricing').scrollIntoView({ behavior: "smooth" })
    }
  };
  const HandleOnClickPricing = () => {
    mixpanel.track("pricing");
    setnavpopup(false);
    if (document.getElementById("pricing")) {
      document.getElementById("pricing").scrollIntoView({ behavior: "smooth" });
      // console.log("scrolling");
    } else {
      handlePageshift();
      // document.getElementById('pricing').scrollIntoView({ behavior: "smooth" })
    }
  };
  const handlePageshiftBlog = useCallback(
    () => navigate("/blog", { replace: true }),
    [navigate]
  );
  const HandleBlog = () => {
    mixpanel.track("blog");
    if (document.getElementById("documentation")) {
      document.getElementById("documentation").scrollIntoView({ behavior: "smooth" });
      // console.log("scrolling");
    } else {
      handlePageshiftBlog();
      // document.getElementById('pricing').scrollIntoView({ behavior: "smooth" })
    }
  };
  const HandleBlogMob = () => {
    mixpanel.track("blog");
    setnavpopup(false);
    if (document.getElementById("documentation")) {
      document
        .getElementById("documentation")
        .scrollIntoView({ behavior: "smooth" });
      // console.log("scrolling");
    } else {
      handlePageshiftBlog();
      // document.getElementById('pricing').scrollIntoView({ behavior: "smooth" })
    }
  };
  useEffect(() => {
    if (window.location.href.includes("/blog")) {
      document
        .getElementById("documentation")
        .scrollIntoView({ behavior: "smooth" });
      // console.log("scrolling");
    }
    if (window.location.href.includes("/pricing")) {
      document.getElementById("pricing").scrollIntoView({ behavior: "smooth" });
      // console.log("scrolling");
    }
  }, []);
  const reverse = (encoded) => {
    var newString = "";
    for (var i = encoded.length - 1; i >= 0; i--) {
      newString += encoded[i];
    }
    return newString;
  };
  const setCookie = (cname, cvalue, exdays) => {
    let encoded = btoa(cvalue);
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    // document.cookie = cname + "=" + reverse(encoded) + ";" + expires + ";path=/;Domain=vdotok.dev;SameSite=Strict";
    if (window.location.href.includes(".dev")) {
      // document.cookie=`user=; Expires=Fri, 5 Oct 2018 14:28:00 GMT;path=/;Domain=vdotok.dev;SameSite=Strict`;
      document.cookie =
        cname +
        "=" +
        reverse(encoded) +
        ";" +
        expires +
        ";path=/;Domain=vdotok.dev;SameSite=Strict";
    } else {
      document.cookie =
        cname +
        "=" +
        reverse(encoded) +
        ";" +
        expires +
        ";path=/;Domain=vdotok.com;SameSite=Strict";
      // document.cookie=`user=; Expires=Fri, 5 Oct 2018 14:28:00 GMT;path=/;Domain=vdotok.com;SameSite=Strict`;
    }
  };
  useEffect(() => {
    if (usecaseweb) {
      props.openUseCasesPopup();
      // document.getElementById('usecasevector').src = vectorUp;
    } else {
      props.closeUseCasesPopup();
      // document.getElementById('usecasevector').src = Vector;
    }
  }, [usecaseweb]);
  useEffect(() => {
    if (Object.keys(props.userdone).length == 0) {
      setCheckedIn(false);
    } else {
      setCheckedIn(true);
    }
  }, [props]);
  useEffect(() => {
    setinnerwdt(window.innerWidth);
    window.addEventListener("resize", () => {
      setinnerwdt(window.innerWidth);
    });
  }, []);
  useEffect(() => {
    if (innerwdt > 500) {
      setnavpopup(false);
    }
  }, [innerwdt]);
  // const  preventDefault = (e) => {
  //    e.preventDefault();
  //  }
  //  const preventDefaultForScrollKeys = (e)  =>{
  //    if (keys[e.keyCode]) {
  //      preventDefault(e);
  //      return false;
  //    }
  //  }
  //  var supportsPassive = false;
  // try {
  //   window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
  //     get: function () { supportsPassive = true; }
  //   }));
  // } catch(e) {}
  //  var wheelOpt = supportsPassive ? { passive: false } : false;
  // var wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
  // const disableScroll = () => {
  //    window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
  //    window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
  //    window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  //    window.addEventListener('keydown', preventDefaultForScrollKeys, false);
  // }
  // const enableScroll = () => {
  //    window.removeEventListener('DOMMouseScroll', preventDefault, false);
  //   window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
  //   window.removeEventListener('touchmove', preventDefault, wheelOpt);
  //   window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
  //  }

  useEffect(
    (e) => {
      if (navpopup) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";

        // enableScroll();
      }
    },
    [navpopup]
  );
  const handleUseCaseWeb = () => {
    setUsecaseWeb(!usecaseweb);
  };
  const isMobile = useMediaQuery({
    query: "(max-width: 550px)",
  });

  const handlePopup = () => {
    setnavpopup(!navpopup);
  };
  const handleIndustry = () => {
    setIndustryPopup(!industryPopup);
  };
  const HandleFindIndustry = () => {};
  const handleUseCasesMob = () => {
    setUseCasesMob(!useCasesMob);
  };
  useEffect(() => {
    let nav = document.getElementById("vdtnavbar");
    let logowht = document.getElementById("vdtlogowht");
    let unionwht = document.getElementById("unionwht");
    let vdtbrandlogo = document.getElementById("vdtbrandlogo");
    let union = document.getElementById("union");

    if (window.location.href.includes("FAQ")) {
      setFAQ(true);
      if (isMobile) {
        nav.style.background = "transparent";
        logowht.style.display = "block";
        logowht.style.width = "41px";
        logowht.style.height = "24px";
        unionwht.style.display = "block";
        unionwht.style.position = "absolute";
        unionwht.style.top = "33%";
        unionwht.style.right = "6%";
        vdtbrandlogo.style.display = "none";
        union.style.display = "none";
        // nav.removeClass("sticky-top");
        nav.classList.remove("sticky-top");
        nav.style.position = "absolute";
        nav.style.paddingLeft = "0px";
      }
    }
  }, []);
  const InDustryClose = () => {
    setIndustryPopup(false);
    setnavpopup(false);
    props.openContactusPopup();
  };
  const Logout = () => {
    mixpanel.track("logout_click");
    let object = {
      auth_token: props.user.auth_token,
    };
    API_Object.Logout(object, props.urls.apiBaseURL).then((data) => {
      if (data.status == 200) {
        props.dispatch({ type: "USERDONE", userData: "" });
        props.dispatch({ type: "USER", userData: "" });
        props.dispatch({ type: "TEMPUSER", userData: "" });
        mixpanel.track("logged_out");
        // mixpanel.track('logout');
        if (window.location.href.includes(".dev")) {
          document.cookie = `user=; Expires=Fri, 5 Oct 2018 14:28:00 GMT;path=/;Domain=vdotok.dev;SameSite=Strict`;
          // document.cookie = cname + "=" + reverse(encoded) + ";" + expires + ";path=/;Domain=vdotok.dev;SameSite=Strict";
        } else {
          // document.cookie = cname + "=" + reverse(encoded) + ";" + expires + ";path=/;Domain=vdotok.com;SameSite=Strict";
          document.cookie = `user=; Expires=Fri, 5 Oct 2018 14:28:00 GMT;path=/;Domain=vdotok.com;SameSite=Strict`;
        }
      }
    });
  };
  return (
    <>
      <div className="navmain" id="navmain">
        <div className="vdtnavbar sticky-top" id="vdtnavbar">
          <div className="navflx">
            <a className="navbar-brand" onClick={gotoHome}>
              <img
                src={logo}
                className="vdtbrandlogo"
                id="vdtbrandlogo"
                alt="logo"
              />
              <img
                src={logowhite}
                className="vdtbrandlogowht"
                id="vdtlogowht"
                alt="logo"
              />
            </a>

            <img
              onClick={handlePopup}
              src={union}
              id="union"
              className="union"
              alt="menu-button"
            />
            <img
              onClick={handlePopup}
              src={unionwhite}
              className="unionwht"
              id="unionwht"
              alt="menu-button"
            />
            <ul className="mynavbar-nav">
              <li
                
                className="nav-item nav-space"
              >
                <a onClick={() => {
                  mixpanel.track("use_cases");
                  handleUseCaseWeb();
                }} className="mynav-link">Use Cases</a>
                {/* <img style={{cursor:"pointer"}} id="usecasevector" className="vector-desk" src={Vector} alt="dropdown-button" /> */}
              </li>
              {/* <li className="nav-item">
               <a className="mynav-link"
               //    onClick={()=>{
               //       mixpanel.track("doc_and_tool");
               // window.open(`https://${props.urls.docURL}`)
               // }} 
               //  href="#services"
               >
               Products
               </a>
            </li> */}
              <li className="nav-item">
                <a
                  className="mynav-link"
                  // onClick={() => {
                  //   mixpanel.track("doc_and_tool");
                  //   window.open(`https://${props.urls.docURL}`);
                  // }}
                  //  href="#services"
                >
                  Doc & Tools
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="mynav-link"
                  onClick={HandleOnClick}
                  // href="#pricing"
                >
                  Pricing
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="mynav-link"
                  //onClick={() => {window.open('https://blog.vdotok.com/' , "_self")}}
                  //  href="#documentation"
                >
                  Blog
                </a>
              </li>
            </ul>
          </div>

          <div className="navparent" id="">
            <ul
              className="mynavbar-nav"
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <li className="nav-item">
                <div className="text-center navbtns">
                  {!checkedIn ? (
                    <button
                      type="button"
                      className=" navlogin"
                      //onClick={() => props.openLoginPopup()}
                    >
                      LOGIN
                    </button>
                  ) : (
                    <button
                      type="button"
                      className=" navlogin"
                      onClick={Logout}
                    >
                      Logout
                    </button>
                  )}
                </div>
              </li>
              <li className="nav-item">
                <div className="text-center">
                  {!checkedIn ? (
                    <button
                      type="button"
                      className=" showbutton"
                      // onClick={() => {
                      //   props.openSignupPopup();
                      // }}
                      style={{ cursor: "pointer", whiteSpace: "nowrap" }}
                    >
                      TRY FOR FREE
                    </button>
                  ) : (
                    <button
                      type="button"
                      className=" showbutton"
                      onClick={() => {
                        setCookie("user", props.user.auth_token, 4);
                        props.dispatch({
                          type: "USERDONE",
                          userData: props.user,
                        });
                        window.open(
                          `https://${props.urls.adminPanelURL}/overview`
                        );
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      DASHBOARD
                    </button>
                  )}
                </div>
              </li>
            </ul>
          </div>
        </div>
        {navpopup ? (
          <>
            <div className="navpopupmbl">
              <div className="navupper">
                <div className="header-nav">
                  <a className="navbar-brand" href="#">
                    <img src={logo} className="vdtbrandlogo" alt="logo" />
                  </a>
                  <img
                    onClick={handlePopup}
                    src={Close}
                    className="close"
                    alt="menu-button"
                  />
                </div>

                <ul
                  className="mynavbar-nav-mbl"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <li
                    id="usecasesline"
                    onClick={() => props.openUseCasesPopup()}
                    className="nav-item nav-space active"
                  >
                    <div className="nav-flex" >
                      <a className="mynav-link" onClick={handleUseCasesMob}>Use Cases</a>
                      {useCasesMob ? (
                        <img
                          style={{ cursor: "pointer" }}
                          className="vector-mob"
                          src={vectorUpMbl}
                          alt="dropdown-button"
                          onClick={handleUseCasesMob}
                        />
                      ) : (
                        <img
                          style={{ cursor: "pointer" }}
                          className="vector-mob"
                          src={vectorDownMbl}
                          alt="dropdown-button"
                          onClick={handleUseCasesMob}
                        />
                      )}
                    </div>
                  </li>
                  {innerwdt < 500
                    ? useCasesMob && (
                        <div className="usecases-mob">
                          <div className="mobcard-usecases">
                            <div className="modalcard-mob">
                              <div className="img-div-navicons">
                                <img
                                  className="img-icon"
                                  src={shopping}
                                  alt="icon-shopping"
                                />
                              </div>
                              <div className="cardcontent">
                                <p
                                  onClick={() => {
                                    mixpanel.track("live_shopping");
                                    window.open("/liveshopping");
                                  }}
                                  className="modal-h typekit_LabelRegularBold webkit_primary_black"
                                >
                                  Live shopping
                                </p>
                                <p
                                  onClick={() => {
                                    mixpanel.track("health_tech");
                                    window.open("/liveshopping");
                                  }}
                                  className="modal-p typekit_LabelLight webkit_primary_black"
                                >
                                  Integrate live influencer selling, fashion
                                  show streaming and more
                                </p>
                              </div>
                            </div>
                            <div className="modalcard-mob">
                              <div className="img-div-navicons">
                                <img
                                  className="img-icon"
                                  src={htech}
                                  alt="icon-healtech"
                                />
                              </div>
                              <div className="cardcontent">
                                <p
                                  onClick={() => {
                                    mixpanel.track("health_tech");
                                    window.open("/healthtech");
                                  }}
                                  className="modal-h typekit_LabelRegularBold webkit_primary_black"
                                >
                                  HealthTech
                                </p>
                                <p
                                  onClick={() => {
                                    mixpanel.track("health_tech");
                                    window.open("/healthtech");
                                  }}
                                  className="modal-p typekit_LabelLight webkit_primary_black"
                                >
                                  Build in real-time video visits, remote
                                  monitoring and more
                                </p>
                              </div>
                            </div>
                            <div className="modalcard-mob">
                              <div className="img-div-navicons">
                                <img
                                  className="img-icon"
                                  src={Social}
                                  alt="icon-social"
                                />
                              </div>
                              <div className="cardcontent">
                                <p
                                  className="modal-h typekit_LabelRegularBold webkit_primary_black"
                                  style={{
                                    cursor: "not-allowed",
                                    pointer: "none",
                                    opacity: "0.5",
                                  }}
                                >
                                  Social (coming soon)
                                </p>
                                <p
                                  className="modal-p typekit_LabelLight webkit_primary_black"
                                  style={{
                                    cursor: "not-allowed",
                                    pointer: "none",
                                    opacity: "0.5",
                                  }}
                                >
                                  Boost customer interacion: co-shopping,
                                  co-surfing and more
                                </p>
                              </div>
                            </div>
                            <div className="modalcard-mob">
                              <div className="img-div-navicons">
                                <img
                                  className="img-icon"
                                  src={edtech}
                                  alt="icon-edtech"
                                />
                              </div>
                              <div className="cardcontent">
                                <p
                                  className="modal-h typekit_LabelRegularBold webkit_primary_black"
                                  style={{
                                    cursor: "not-allowed",
                                    pointer: "none",
                                    opacity: "0.5",
                                  }}
                                >
                                  EdTech (coming soon)
                                </p>
                                <p
                                  className="modal-p typekit_LabelLight webkit_primary_black"
                                  style={{
                                    cursor: "not-allowed",
                                    pointer: "none",
                                    opacity: "0.5",
                                  }}
                                >
                                  Create online classrooms, co-learning
                                  experiences and more
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    : useCasesMob && <></>}
                  <li className="nav-item">
                    <div>
                      <a
                        className="mynav-link"
                        onClick={() => {
                          window.open(`https://${props.urls.docURL}`);
                        }}
                        //  href="#services"
                      >
                        Doc & Tools
                      </a>
                    </div>
                  </li>
                  <li className="nav-item">
                    <div>
                      <a
                        className="mynav-link"
                        onClick={HandleOnClickPricing}
                        // href="#pricing"
                      >
                        Pricing
                      </a>
                    </div>
                  </li>
                  <li className="nav-item">
                    <dv>
                      <a
                        className=" mynav-link"
                        onClick={() => {window.open('https://blog.vdotok.com/' , "_self")}}
                        //  href="#documentation"
                      >
                        Blog
                      </a>
                    </dv>
                  </li>
                  <li id="industryline" className="nav-item nav-space active">
                    <div className="nav-flex" onClick={handleIndustry}>
                      <a className="mynav-link">Couldn't find your industry?</a>
                      {industryPopup ? (
                        <img
                          style={{ cursor: "pointer" }}
                          className="vector-mob"
                          src={vectorUpMbl}
                          alt="dropdown-button"
                        />
                      ) : (
                        <img
                          style={{ cursor: "pointer" }}
                          className="vector-mob"
                          src={vectorDownMbl}
                          alt="dropdown-button"
                        />
                      )}
                    </div>
                  </li>
                  {industryPopup && (
                    <div className="industryflex">
                      <p className="pindustry typekit_LabelLight webkit_primary_grey">
                        Contact us and we will help you find the right live
                        media solution for your product
                      </p>
                      <button
                        style={{ whiteSpace: "nowrap" }}
                        className="industrybtn"
                        //onClick={InDustryClose}
                      >
                        Contact us
                      </button>
                    </div>
                  )}
                </ul>
              </div>
              <div className="navbottom">
                <ul className="ul-bottom">
                  <li className="nav-item-bottom">
                    <div className="navbtns">
                      {!checkedIn ? (
                        <button
                          type="button"
                          className=" navlogin"
                          onClick={() => {
                            mixpanel.track("login_click");
                            props.openLoginPopup();
                          }}
                        >
                          LOGIN
                        </button>
                      ) : (
                        <button
                          type="button"
                          className=" navlogin"
                          onClick={Logout}
                        >
                          Logout
                        </button>
                      )}
                    </div>
                  </li>
                  <li className="nav-item-bottom">
                    <div className="">
                      {!checkedIn ? (
                        <button
                          type="button"
                          className=" showbutton"
                          // onClick={() => {
                          //   props.openSignupPopup();
                          // }}
                          style={{ cursor: "pointer" }}
                        >
                          TRY FOR FREE
                        </button>
                      ) : (
                        <button
                          type="button"
                          className=" showbutton"
                          onClick={() => {
                            setCookie("user", props.user.auth_token, 4);
                            props.dispatch({
                              type: "USERDONE",
                              userData: props.user,
                            });
                            window.open(
                              `https://${props.urls.adminPanelURL}/overview`
                            );
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          DASHBOARD
                        </button>
                      )}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
function map(state) {
  return state;
}
export default connect(map)(NavComponent);
